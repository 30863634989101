import { Account } from "@brenger/api-client";
import { Card, IconMedal, IconSmile, IconVan, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { ExternalLink, Grid } from "../../../../components";
import { useAuth, useDashboardQuery, useTranslation } from "../../../../hooks";
import { CacheKey, Routes, coreClient, getDeletedUserList, getSanitizedUserList } from "../../../../utils";
import { SubAccountCard } from "./SubAccountCard";
import { Link } from "react-router-dom";

export const DashboardForAdmin: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const accountData = useQuery(
    [CacheKey.RETRIEVE_ACCOUNT, auth.user?.account?.["@id"]],
    () => coreClient.accounts.retrieve({ id: getIdFromIri(auth.user?.account) as string }),
    {
      enabled: !!auth.user,
    }
  );

  const accountUsers = useQuery(
    [CacheKey.RETRIEVE_USERS, auth.user?.account?.["@id"]],
    () => coreClient.accounts.listUsers({ id: getIdFromIri(auth.user?.account) as string }),
    {
      enabled: !!auth.user,
    }
  );

  const userList = accountUsers.data?.["hydra:member"];
  const sanitizedUserList = userList ? getSanitizedUserList(userList) : [];
  const deletedUserList = userList ? getDeletedUserList(userList) : [];

  const jobsData = useDashboardQuery();

  return (
    <div>
      <div className={cn("flex", "flex-col", "items-center")}>
        <h4 className="text-center">{t((d) => d.dashboard.page_desc_account)}</h4>
        <h2 className="text-center">Hi {(auth.user?.account as Account | undefined)?.name}</h2>
        <Spacer h={4} />
        <div className={cn("flex", "items-center", "justify-center", "rounded-full", "bg-green-400", "h-24", "w-24")}>
          <IconMedal className={cn("w-16", "h-16")} />
        </div>
        <Spacer h={4} />
        <h4 className="text-center">{t((d) => d.dashboard.status_desc)}</h4>
        <h2 className="text-center">{t((d) => d.level[auth.accountLevel.raw])}</h2>
      </div>
      <Spacer h={4} />
      <Grid cols={{ default: 2 }} gap={4}>
        <ExternalLink url={t((d) => d.dashboard.performance_tips_url)}>
          <Card className="flex items-center  justify-between gap-4 cursor-pointer">
            <div>{t((d) => d.dashboard.performance_tips)}</div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4")} />
          </Card>
        </ExternalLink>
        <ExternalLink url={t((d) => d.dashboard.create_sub_account_url)}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer">
            <div className={cn("inline-block")}>{t((d) => d.profile.add_user)}</div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4")} />
          </Card>
        </ExternalLink>
      </Grid>
      <Spacer h={6} />
      <div className={cn("flex", "justify-center")}>
        <IconVan className={cn("w-12", "h-12")} />
      </div>
      <Spacer h={6} />
      <Grid cols={{ default: 2 }} gap={4}>
        <Link to={Routes.transports.home({ type: "planning" })}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.planned)}</div>
              <div>{jobsData.plannedJobsCount || "--"}</div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
        <Link to={Routes.transports.home({ type: "planning" })}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.pending)}</div>
              <div>{jobsData.pendingJobsCount || "--"}</div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
        <Link to={Routes.transports.home({ type: "delivered" })}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.transports_total)}</div>
              <div>{jobsData.totalJobsCount || "--"}</div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
        <Link to={Routes.transports.home({ type: "delivered" })}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.transports_last_90_days)}</div>
              <div>{jobsData.lastQuarterJobsCount || "--"}</div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
        <Link to={Routes.transports.home({ type: "delivered" })}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.total_confirmation_with_proximity_success_rate_15june_onward)}</div>
              <div>
                {accountData.data?.total_confirmation_with_proximity_success_rate
                  ? `${Math.round(accountData.data.total_confirmation_with_proximity_success_rate * 100)}%`
                  : "--"}
              </div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
      </Grid>
      <Spacer h={6} />
      <div className={cn("w-full", "text-center")}>
        <h2>
          {t((d) => d.dashboard.overview_couriers)} ({accountUsers.data?.["hydra:totalItems"] || "--"})
        </h2>
      </div>
      <Spacer h={6} />
      <Grid cols={{ lg: 2 }} gap={4}>
        {sanitizedUserList.map((user) => (
          <SubAccountCard key={user["@id"]} user={user} />
        ))}
      </Grid>
      {deletedUserList.length !== 0 && (
        <>
          <div className={cn("flex", "items-center", "pt-4")}>
            <hr className={cn("grow")} />
            <div className={cn("px-4")}>
              <div>{t((d) => d.overview_couriers_deleted)}</div>
            </div>
            <hr className={cn("grow")} />
          </div>
          <div className={cn("text-center", "pb-4")}>
            <div>{t((d) => d.overview_couriers_deleted_explain)}</div>
          </div>
          <Grid cols={{ lg: 2 }} gap={4}>
            {deletedUserList.map((user) => (
              <SubAccountCard key={user["@id"]} user={user} />
            ))}
          </Grid>
        </>
      )}
      <Spacer h={6} />
    </div>
  );
};
