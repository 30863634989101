import * as React from "react";

import { Route, Redirect, RouteProps } from "react-router-dom";

interface ProtectedRouteProps extends RouteProps {
  isAuthorized: boolean;
  redirectTo: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAuthorized, redirectTo, ...rest }) => {
  return isAuthorized ? <Route {...rest} /> : <Redirect to={redirectTo} />;
};
