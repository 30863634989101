import { DayRouteActivity } from "@brenger/api-client";
import { HorizontalLine, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { AddressDetails } from "../../../components";
import { useAuth, useCache, useFormatTimeframe, useStopContext, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, DayRouteStopParams, Routes } from "../../../utils";
import { SheetModal } from "../../../layout";
import { CancelOrRevoke } from "../CancelOrRevoke";
import { EditCommittedDtp } from "../EditCommittedDtp";
import { EditDriver } from "../EditDriver";
import { NotAllowed } from "../NotAllowed";
import { RouteOverViewBundled } from "../RouteOverviewBundled";
import { RouteOverViewNormal } from "../RouteOverviewNormal";

interface Props {
  activity: DayRouteActivity;
  isActive: boolean;
  closeHandler(): void;
}

export const DayRouteEditBrengerStop: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const params = useParams<DayRouteStopParams>();
  const formatDTP = useFormatTimeframe();
  const auth = useAuth();

  const stopId = getIdFromIri(props.activity.pickup || props.activity.delivery);
  const stop = useQuery([CacheKey.RETRIEVE_STOP, stopId], () => coreClient.stops.retrieve({ id: stopId as string }), {
    enabled: !!stopId,
  });
  const tjId = getIdFromIri(stop.data?.transport_job);
  const tjal = useQuery(
    [CacheKey.RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK, tjId],
    () => coreClient.transportJobs.retrieveOwnTjal({ id: tjId as string }),
    {
      enabled: !!tjId,
    }
  );
  const cache = useCache();
  const onSuccessDTPupdate = (): void => {
    cache.clear();
    cache.queryClient.resetQueries([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date]);
  };
  const tjalId = getIdFromIri(tjal.data);
  const stopContext = useStopContext(stop.data || null);
  const isBundled = stopContext.transportJob?.bundled;

  if (!auth.isAccountAdmin) {
    return (
      <SheetModal
        header={t((d) => d.stop_edit.headings.change_cancel_stop)}
        isLoading={stopContext.loading}
        isOpen={props.isActive}
        close={props.closeHandler}
      >
        <NotAllowed />
      </SheetModal>
    );
  }

  return (
    <SheetModal
      header={t((d) => d.stop_edit.headings.change_cancel_stop)}
      isLoading={stopContext.loading}
      isOpen={props.isActive}
      close={props.closeHandler}
    >
      <h6 className={cn("flex", "justify-between", "items-center", "uppercase")}>
        {t((d) => d.transport_job.headings[props.activity.type === "pickup" ? "pickup" : "delivery"])}
      </h6>
      <HorizontalLine type="gray" className={cn("my-2")} />
      <b>{t((d) => d.transport_job.headings.address)}</b>
      <AddressDetails
        presentation={"planning-stop-edit"}
        address={stopContext.address}
        instructions={stop.data?.details.instructions}
      />
      <Spacer h={2} />
      <b>{t((d) => d.stop_edit.headings.available_dtp)}</b>
      {stop.data?.available_datetime_periods.map((dtp, i) => {
        return <div key={i}>{formatDTP(dtp)}</div>;
      })}
      <Spacer h={2} />
      {
        <EditCommittedDtp
          availableDtps={stop.data?.available_datetime_periods}
          committedDtp={stopContext.committedDtps?.[0] || null}
          checkDisabled={true}
          onSuccess={onSuccessDTPupdate}
        />
      }
      <Spacer h={4} />
      <h6>{t((d) => d.stop_edit.headings[isBundled ? "change_bundle" : "change_single_job"])}</h6>
      <HorizontalLine type="gray" className={cn("my-2")} />
      <Spacer h={1} />
      {!isBundled && (
        <RouteOverViewNormal
          tjId={stopContext.transportJob?.short_id}
          isPickup={props.activity.type === "pickup"}
          pickup={stopContext.transportJob?.pickups[0]}
          delivery={stopContext.transportJob?.deliveries[0]}
        />
      )}
      {isBundled && (
        <RouteOverViewBundled
          tjId={stopContext.transportJob?.short_id}
          activeStop={stop.data || null}
          route={stopContext.route?.stops}
          tj={stopContext.transportJob}
        />
      )}
      <Spacer h={2} />
      <EditDriver tjalId={tjalId || ""} onSuccess={props.closeHandler} />
      <Spacer h={4} />
      <CancelOrRevoke
        tjalId={tjalId || ""}
        isBundled={stopContext.transportJob?.bundled}
        isDirectClaimable={stopContext.transportJob?.directly_claimable}
        successPath={Routes.dayroutes.details(params)}
      />
    </SheetModal>
  );
};
