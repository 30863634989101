import {
  OpenTransportJob,
  TransportJob,
  ItemSet,
  RouteMeta,
  DateTimePeriod,
  Pickup,
  Delivery,
  TransportJobAccountLink,
  PickupCommitment,
  DeliveryCommitment,
  User,
  Contact,
  DriverUser,
  CountryCode,
  Address,
  Item,
  Payout,
} from "@brenger/api-client";

/**
 * Generic data utils go here.
 */
export const dataURItoBlob = (dataURI: string): Blob => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString = null;
  if (dataURI.split(",")[0].includes("base64")) {
    byteString = atob(dataURI.split(",")[1]);
  } else {
    byteString = unescape(dataURI.split(",")[1]);
  }

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

const administrativeAreas: { [key: string]: string } = {
  Drenthe: "DR",
  Flevoland: "FL",
  Friesland: "FR",
  Gelderland: "GE",
  Groningen: "GR",
  Limburg: "LI",
  "Noord-Brabant": "NB",
  "Noord-Holland": "NH",
  Overijssel: "OV",
  Utrecht: "UT",
  Zeeland: "ZE",
  "Zuid-Holland": "ZH",
};

/**
 * Accepts an address and returns a locality + adminstrative area summary string
 */
export const formatLocality = (locality?: string, administrativeArea?: string): string => {
  if (!locality || !administrativeArea) {
    return locality || "";
  }

  // Use the abbreviated version of the administrative area if available
  const abbreviatedAdministrativeArea = administrativeAreas[administrativeArea] || administrativeArea;

  return `${locality}, ${abbreviatedAdministrativeArea}`;
};

export const isEmailValid = (email: string): boolean => {
  const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
  return emailRegex.test(email);
};

export const serializeAddress = (address?: Address | null): string => {
  if (!address) return "--";

  const { line1, line2, administrative_area: adminArea, locality } = address;
  const line = [line1, line2].filter(Boolean).join(", ");

  return `${line} ${locality && `${formatLocality(locality, adminArea)}`}`;
};

export const isComboJob = (tj: OpenTransportJob | TransportJob): boolean => {
  // A combo job must be less than or equal to 70km total distance
  const DISTANCE_THRESHOLD = 70;
  // A combo job may not have more than 5 stops
  const satisfiesStopCountReq = tj.bundled && tj.stop_count <= 5;

  let satisfiesDistanceReq = false;

  // Because this util accepts a heterogeneous TJ - must check both types.
  if ((tj as OpenTransportJob).total_distance_km) {
    satisfiesDistanceReq = (tj as OpenTransportJob).total_distance_km <= DISTANCE_THRESHOLD;
  }

  if ((tj as TransportJob).route) {
    satisfiesDistanceReq = ((tj as TransportJob).route as RouteMeta)?.distance <= DISTANCE_THRESHOLD;
  }

  return satisfiesDistanceReq && satisfiesStopCountReq;
};

/**
 * A regex that parses the digit length in a string (useful for validating phone numbers).
 */
export const getDigitLength = (val: string): number => {
  // Only care about digit length - ignore everything else (eg: '+' or '()' or '-')
  return val?.match(/\d/g)?.length || 0;
};

/**
 * Simple function to check if an item set has product selection services
 */
export const hasProductSelectionService = (itemSets: ItemSet[] | undefined, service: "fragile" | "heavy"): boolean => {
  return Boolean(itemSets && itemSets.some((itemSet) => (itemSet.items as Item[]).some((item) => item[service])));
};

/**
 * Add or remove element fron an array based on whether it is already present in the list.
 */
export function addOrRemoveFromArray<T>(val: T, arr: T[]): T[] {
  return arr.some((v) => v === val) ? arr.filter((v) => v !== val) : arr.concat(val);
}

/**
 * Parse the driver's committed DTPs from a stop.
 * This is to deal with data inconsistencies on stop entities.
 * When fetching a stop directly (ie: Pickup or Delivery) the committment will be included.
 * When grabbing the stop from the TJ, the committments will not be available and should
 * therefore come from the TJAL.
 */
export const getCommittedDtpsForStop = (
  stop: Pickup | Delivery,
  tjal?: Pick<TransportJobAccountLink, "delivery_commitments" | "pickup_commitments"> | null
): DateTimePeriod[] | null => {
  let dtps: DateTimePeriod[] | null = null;

  if (tjal) {
    const pickupCommitmentsForStop = (tjal.pickup_commitments as PickupCommitment[]).filter((d) => {
      return (d.pickup as Pickup)["@id"] === stop["@id"];
    });

    if (pickupCommitmentsForStop.length > 0) {
      dtps = pickupCommitmentsForStop.map((d) => d.committed_datetime_period);
    }

    const deliveryCommitmentsForstop = (tjal.delivery_commitments as DeliveryCommitment[]).filter((d) => {
      return (d.delivery as Delivery)["@id"] === stop["@id"];
    });

    if (deliveryCommitmentsForstop.length > 0) {
      dtps = deliveryCommitmentsForstop.map((d) => d.committed_datetime_period);
    }
  }

  const pickup = stop as Pickup;
  if (pickup.pickup_commitments) {
    dtps = pickup.pickup_commitments.map((d) => d.committed_datetime_period);
  }

  const delivery = stop as Delivery;
  if (delivery.delivery_commitments) {
    dtps = delivery.delivery_commitments.map((d) => d.committed_datetime_period);
  }

  return dtps?.length ? dtps : null;
};

/**
 * Concats Driver's first and last name and accounts for null fields.
 */
export const getFirstAndLastName = (user?: User | Contact | DriverUser | null): string => {
  if (!user) return "";

  return `${user.first_name || ""} ${user.last_name || ""}`.trim();
};

export const isDriverPayout = (payout: Payout): boolean => {
  return ["individual", "collective_part", "penalty"].includes(payout.type);
};

/**
 * NOTE: This is a subset of core's country codes. We assume that Brenger drivers
 * may potentially operate in any one of them.
 * NOTE: This subset was derived from:
 * https://ec.europa.eu/eurostat/statistics-explained/index.php/Glossary:Country_codes
 */
export const driverCountryCodes: CountryCode[] = [
  "BE",
  "BG",
  "CH",
  "CZ",
  "DK",
  "DE",
  "EE",
  "IS",
  "IE",
  "ES",
  "FR",
  "HR",
  "IT",
  "CY",
  "LI",
  "LV",
  "LT",
  "LU",
  "HU",
  "MT",
  "NL",
  "NO",
  "AT",
  "PL",
  "PT",
  "RO",
  "SI",
  "SK",
  "FI",
  "SE",
  "GB",
];
