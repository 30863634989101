import {
  IconChat,
  IconDashboard,
  IconHeart,
  IconID,
  IconLock,
  IconPersonOne,
  IconPersonTwo,
  IconPhone,
  IconSettings,
  IconSignOut,
  IconStarOutline,
  IconVehicle,
  Message,
} from "@brenger/react";
import cn from "classnames";
import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { useAppInfo, useAuth, useBrowser, useTranslation } from "../../../hooks";
import { Routes, getCustomerAppUrl } from "../../../utils";
import { Section } from "../../../layout";
import { Content } from "../../../layout/Content";
import { MoreLinkList, MoreLinkListItem } from "./MoreLinkList";

export const MoreHome: React.FC = () => {
  const auth = useAuth();
  const appInfo = useAppInfo();
  const browser = useBrowser();
  const { t, i18n } = useTranslation();

  const firstList: MoreLinkListItem[] = [
    { link: Routes.more.inbox(), label: t((d) => d.more.inbox), icon: <IconChat /> },
    {
      link: Routes.more.dashboard(),
      label: t((d) => d.more.dashboard),
      icon: <IconDashboard style={{ fontSize: 20 }} />,
    },
    { link: Routes.more.reviews(), label: t((d) => d.more.reviews), icon: <IconStarOutline /> },
    {
      link: Routes.more.brengerId(),
      label: t((d) => d.more.brenger_id),
      icon: <IconID style={{ fontSize: 21 }} />,
    },
  ];

  const secondList: MoreLinkListItem[] = [
    {
      link: Routes.more.profile(),
      label: t((d) => d.more.profile),
      icon: <IconPersonOne style={{ fontSize: 26 }} />,
    },
  ];
  if (auth.isAccountAdmin) {
    secondList.push(
      {
        link: Routes.more.vehicles(),
        label: t((d) => d.more.vehicles),
        icon: <IconVehicle style={{ fontSize: 20 }} />,
      },
      {
        link: Routes.more.couriers(),
        label: t((d) => d.more.drivers),
        icon: <IconPersonTwo style={{ fontSize: 20 }} />,
      },
      {
        link: Routes.more.favouriteRoutes(),
        label: t((d) => d.more.favourite_routes),
        icon: <IconHeart style={{ fontSize: 27 }} />,
      }
    );
  }

  const thirdList: MoreLinkListItem[] = [
    {
      label: t((d) => d.more.change_password),
      icon: <IconLock style={{ fontSize: 24 }} />,
      onClick: () => browser.open({ url: `${getCustomerAppUrl(i18n.locale)}/customer/password_reset` }),
    },
    {
      link: Routes.more.appSettings(),
      label: t((d) => d.more.location_language),
      icon: <IconSettings style={{ fontSize: 21 }} />,
    },
    {
      label: <span className="text-blue-600">{t((d) => d.more.log_out)}</span>,
      icon: <IconSignOut style={{ fontSize: 22 }} />,
      withCarret: false,
      link: Routes.auth.logout(),
    },
  ];
  if (appInfo?.build) {
    thirdList.splice(2, 0, {
      label: (
        <div className={cn("flex", "justify-between")}>
          <div>{t((d) => d.more.app_version)}</div>
          <div className={cn("text-gray-500")}>
            {appInfo?.version} #{appInfo?.build}
          </div>
        </div>
      ),
      icon: <IconPhone style={{ fontSize: 30 }} />,
      withCarret: false,
    });
  }
  return (
    <Content>
      <Section type="single-details" isTop={true}>
        <div className={cn("flex", "items-center", "gap-5", "mb-8")}>
          <img
            className={cn("h-[4.5rem]", "w-[4.5rem]", "object-cover", "rounded-full")}
            src={auth.user?.profile_image || missingImageSrc}
          />
          <div>
            <h5>{auth.user?.first_name}</h5>
            <h6 className={cn("br-font", "text-gray-500")}>
              {t((d) => d.more[auth.isAccountAdmin ? "main_account" : "sub_account"])}
            </h6>
          </div>
        </div>
        {auth.isSuspended && (
          <div className="my-4">
            <Message type="error">{t((d) => d.auth.messages.suspended)}</Message>
          </div>
        )}
        <div className="mb-8">
          <MoreLinkList split={true} links={firstList} />
        </div>
        <div className={cn("md:flex", "md:gap-x-12")}>
          <div className="mb-8 w-full">
            <MoreLinkList title={t((d) => d.more.heading_list_2)} links={secondList} />
          </div>
          <MoreLinkList
            title={t((d) => d.more[appInfo?.build ? "heading_list_3_app" : "heading_list_3"])}
            links={thirdList}
          />
        </div>
      </Section>
    </Content>
  );
};
