import { HorizontalLine, Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useParams } from "react-router-dom";
import { useAuth, useTjalContext, useTranslation } from "../../../hooks";
import { Routes, TransportsJobParams } from "../../../utils";
import { SheetModal } from "../../../layout";
import { CancelOrRevoke } from "../CancelOrRevoke";
import { EditDriver } from "../EditDriver";
import { NotAllowed } from "../NotAllowed";
import { RouteOverViewBundled } from "../RouteOverviewBundled";
import { RouteOverViewNormal } from "../RouteOverviewNormal";

export const TransportJobEdit: React.FC<{ isActive: boolean; closeHandler(): void }> = (props) => {
  const { t } = useTranslation();
  const params = useParams<TransportsJobParams>();
  const auth = useAuth();

  const tjalContext = useTjalContext(params.job_id);

  const isBundled = tjalContext.tj?.data?.bundled;

  if (!auth.isAccountAdmin) {
    return (
      <SheetModal
        header={t((d) => d.stop_edit.headings.change_cancel_stop)}
        isLoading={tjalContext.tj.isLoading}
        isOpen={props.isActive}
        close={props.closeHandler}
      >
        <NotAllowed />
      </SheetModal>
    );
  }
  return (
    <SheetModal
      header={t((d) => d.stop_edit.headings.change_cancel_stop)}
      isLoading={tjalContext.tj.isLoading}
      isOpen={props.isActive}
      close={props.closeHandler}
    >
      <h6 className={cn("flex", "justify-between", "items-center", "uppercase")}>Job</h6>
      <h5>{t((d) => d.stop_edit.headings[isBundled ? "change_bundle" : "change_single_job"])}</h5>
      <HorizontalLine type="gray" className={cn("my-2")} />
      <Spacer h={1} />
      {!isBundled && (
        <RouteOverViewNormal
          tjId={tjalContext.tj?.data?.short_id}
          pickup={tjalContext.tj?.data?.pickups[0]}
          delivery={tjalContext.tj?.data?.deliveries[0]}
        />
      )}
      {isBundled && (
        <RouteOverViewBundled
          tjId={tjalContext.tj?.data?.short_id}
          activeStop={null}
          route={tjalContext.route.data?.stops}
          tj={tjalContext.tj.data || null}
        />
      )}
      <Spacer h={2} />
      <EditDriver tjalId={params.job_id} onSuccess={props.closeHandler} />
      <Spacer h={4} />
      <CancelOrRevoke
        tjalId={params.job_id}
        isBundled={isBundled}
        isDirectClaimable={tjalContext.tj.data?.directly_claimable}
        successPath={Routes.transports.home(params)}
        onSuccess={props.closeHandler}
      />
    </SheetModal>
  );
};
