import { IconCaretRight } from "@brenger/react";
import cn from "classnames";
import { Link } from "react-router-dom";

interface Props {
  title?: string;
  links: MoreLinkListItem[];
  split?: boolean;
}

export interface MoreLinkListItem {
  label: string | React.ReactNode;
  link?: string;
  icon?: React.ReactNode;
  withCarret?: boolean;
  onClick?(): void;
  isLast?: boolean;
}

export const MoreLinkList: React.FC<Props> = ({ title, links, split }) => {
  const linksLength = links.length;
  return (
    <div className={cn("w-full")}>
      {title && <h5 className={cn("mb-2")}>{title}</h5>}
      <div className={cn("text-blue-600", { "md:grid md:grid-rows-2 md:grid-flow-col md:gap-x-12": split })}>
        {links.map((l, i) => {
          if (l.link) {
            return (
              <Link to={l.link} key={i}>
                <LinkContent {...l} isLast={!split && linksLength - 1 === i} />
              </Link>
            );
          }
          return (
            <div className={"text-blue-600 cursor-pointer"} onClick={l.onClick} key={i}>
              <LinkContent {...l} isLast={!split && linksLength - 1 === i} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
const LinkContent: React.FC<MoreLinkListItem> = ({ label, icon, isLast, withCarret = true }) => (
  <div
    style={{ marginTop: "-1px" }}
    className={cn("flex", "items-center", "gap-4", { "border-b": !isLast }, "border-gray-400")}
  >
    {icon && <div className={cn("w-8")}>{icon}</div>}
    <div className={cn("br-text-default", "grow", "py-4")}>{label}</div>
    {withCarret && <IconCaretRight style={{ fontSize: "25px" }} className={cn("ml-auto")} />}
  </div>
);
