import * as React from "react";

import { ItemSet } from "@brenger/api-client";
import {
  Button,
  Card,
  HorizontalLine,
  IconCaretLeft,
  IconCaretRight,
  IconEditBox,
  IconHorizontalDots,
  IconWhatsapp,
  Message,
  Spacer,
  useModalState,
} from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import { useQuery } from "@tanstack/react-query";
import { useHistory, useParams } from "react-router-dom";
import {
  AddressDetails,
  ClientReferences,
  ConfirmationSignatures,
  ContactDetails,
  ExternalInvoices,
  ImageGallery,
  ItemDetails,
  ItemSetAttributes,
  MetaItem,
  PhotoProofs,
  StaticMap,
  StopConfirmedAtPill,
  StopDetailPills,
  TooltipDressCode,
  UserGeneratedContent,
} from "../../../components";
import { FormatCommitedStopTime } from "../../../components/data/DayRouteStopTime";
import { useAuth, useCanConfirmStop, useMapContext, useStopContext, useTranslation } from "../../../hooks";
import {
  CacheKey,
  Routes,
  TransportsStopParams,
  coreClient,
  getStopContactActions,
  getStopOnTimeActions,
  getStopRunningLateActions,
} from "../../../utils";
import { TransportJobEditStop } from "../../edit/TransportJob/editStop";
import { ActionSheetModal, ActionSheetModalOption, Section } from "../../../layout";
import { Content } from "../../../layout/Content";
import { PageHead } from "../../../layout/PageHead";

export const TransportsStop: React.FC = () => {
  const params = useParams<TransportsStopParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const [actions, setActions] = React.useState<{
    header: string;
    options: ActionSheetModalOption[];
  } | null>(null);
  const actionModal = useModalState();
  const editModal = useModalState();
  const stop = useQuery([CacheKey.RETRIEVE_STOP, params.stop_id], () =>
    coreClient.stops.retrieve({ id: params.stop_id })
  );

  const itemSets = stop.data?.item_sets as ItemSet[] | undefined;

  const stopContext = useStopContext(stop.data || null);
  const shortId = stopContext.transportJob?.["@id"].slice(-6) || "";

  const { canConfirmStop, cannotConfirmStopReason, messageType } = useCanConfirmStop({
    iri: stop.data?.["@id"],
    showSuccessMessage: false,
  });

  const mapContext = useMapContext({ presentation: "planning", tj: stopContext.transportJob, stopId: params.stop_id });

  if (!stop.data || stop.isLoading || stop.isError || !itemSets) {
    const errorFromCore = stop.error;
    const error = errorFromCore ? (errorFromCore as Error)?.message || "Something went wrong" : undefined;
    return (
      <Content
        header={
          <PageHead
            sectionType={"split-details"}
            backUrl={Routes.transports.job(params)}
            title={`Short ID: ${shortId}`}
          />
        }
        message={error}
        messageWrap="split-details"
        isLoading={stop.isLoading}
      />
    );
  }

  const stopType = stop.data?.["@type"];
  const { phone, email } = stopContext.contact || {};

  /**
   * Set up shared action sheet items
   */
  const runningLateActions = getStopRunningLateActions({
    phone,
    driverName: auth.user?.first_name,
    t,
  });
  const onTimeActions = getStopOnTimeActions({
    phone,
    driverName: auth.user?.first_name,
    t,
  });
  const contactOptions = getStopContactActions({ phone, email, t });
  /**
   * Set up Action Sheet items related to the Address
   */
  const addressActions: ActionSheetModalOption[] = [
    {
      title: t((d) => d.planning.actions.open_google_maps),
      onClick: () => window.open(mapContext.googleMapsUrl, "_blank"),
    },
    {
      // Related stop link (pick -> delivery, vice versa)
      title: t((d) => d.transport_job.actions[stopType === "Pickup" ? "view_delivery" : "view_pickup"]),
      onClick: () => history.push(Routes.transports.stop({ ...params, stop_id: stopContext.relatedStopId as string })),
    },
  ];

  /**
   * Determine if stop is confirmed
   */
  let isConfirmed = false;
  if (stopType === "Pickup") {
    isConfirmed = itemSets.some((d) => !!d.driver_pickup_confirmed_at);
  }
  if (stopType === "Delivery") {
    isConfirmed = itemSets.some((d) => !!d.driver_delivery_confirmed_at);
  }

  return (
    <>
      <Content
        header={
          <PageHead
            sectionType="split-details"
            backUrl={Routes.transports.job(params)}
            title={`Short ID: ${shortId}`}
            afterTitle={
              !isConfirmed && (
                <div className={cn("text-blue-600", "flex", "items-center", "cursor-pointer")} onClick={editModal.open}>
                  <IconEditBox className={cn("mr-1")} /> {t((d) => d.actions.edit)}
                </div>
              )
            }
          />
        }
        footer={
          <Section type="split-details">
            <div className="flex lg:justify-start gap-x-2">
              <div className="grow lg:grow-0 whitespace-nowrap">
                <Button
                  onClick={() => history.push(Routes.transports.confirmStop(params))}
                  disabled={!canConfirmStop}
                  className={"w-full lg:w-56 2xl:w-80"}
                  buttonType="secondary"
                >
                  {t(
                    (d) =>
                      d.transport_job.actions[stop.data?.["@type"] === "Pickup" ? "confirm_pickup" : "confirm_delivery"]
                  )}
                </Button>
              </div>
              <Button
                className="w-12 !bg-color-light-blue"
                buttonType="primary-light"
                onClick={() =>
                  history.push(Routes.transports.stop({ ...params, stop_id: stopContext.previousStopId as string }))
                }
                disabled={!stopContext.previousStopId}
              >
                <IconCaretLeft
                  style={{
                    fontSize: "1.75em",
                    position: "relative",
                    left: "-0.1em",
                    top: "0.1em",
                    marginTop: "-0.2em",
                  }}
                />
              </Button>
              <Button
                className="w-12 !bg-color-light-blue"
                buttonType="primary-light"
                onClick={() =>
                  history.push(Routes.transports.stop({ ...params, stop_id: stopContext.nextStopId as string }))
                }
                disabled={!stopContext.nextStopId}
              >
                <IconCaretRight
                  style={{ fontSize: "1.75em", position: "relative", top: "0.1em", marginTop: "-0.2em" }}
                />
              </Button>
            </div>
          </Section>
        }
      >
        <Section type="split-details" isTop={true}>
          {/* PICKUP OR DELIVERY */}
          <div className={cn("flex", "justify-between", "items-center")}>
            <h6 className={cn("uppercase")}>
              {t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}
            </h6>
          </div>
          {/* SHORT ADDRESS */}
          <b>
            <AddressDetails address={stopContext.address} presentation="dayroute-details" />
          </b>
          {/* COMMITED DATETIME*/}
          <div className={cn("flex", "items-center")}>
            {stopContext.committedDtps && stopContext.committedDtps.length !== 0 && (
              <FormatCommitedStopTime
                textClassname={["mr-2"]}
                start={stopContext.committedDtps[0].start}
                end={stopContext.committedDtps[0].end}
              />
            )}
          </div>
          <Spacer h={2} />
          <div>
            <StopConfirmedAtPill stopType={stopType} itemSets={itemSets} committedDtps={stopContext.committedDtps} />
          </div>

          {!canConfirmStop && cannotConfirmStopReason && (
            <Message type={messageType} className={cn("my-4")}>
              {cannotConfirmStopReason}
            </Message>
          )}

          {/* RUNNING LATE CARD*/}
          {phone && canConfirmStop && (
            <Card className={cn("mt-2", "no-print", "!bg-color-light-blue")}>
              <div>{t((d) => d.planning.running_late.desc)}</div>

              <div className={cn("flex", "mt-3")}>
                <Button
                  size="md"
                  icon={<IconWhatsapp />}
                  className={cn("w-full", "whitespace-nowrap")}
                  iconPos="left"
                  onClick={() => {
                    setActions({
                      header: t((d) => d.planning.on_time.title),
                      options: onTimeActions,
                    });
                    actionModal.open();
                  }}
                >
                  {t((d) => d.planning.on_time.cta)}
                </Button>
                <Spacer w={2} />
                <Button
                  size="md"
                  icon={<IconWhatsapp />}
                  className={cn("w-full", "whitespace-nowrap")}
                  iconPos="left"
                  onClick={() => {
                    setActions({
                      header: t((d) => d.planning.running_late.title),
                      options: runningLateActions,
                    });
                    actionModal.open();
                  }}
                >
                  {t((d) => d.planning.running_late.cta)}
                </Button>
              </div>
            </Card>
          )}
          {itemSets.flatMap((i) => i.services).includes("safety_shoes_required") && (
            <>
              <Spacer h={2} />
              <TooltipDressCode />
            </>
          )}
          <Spacer h={3} />
          <StaticMap zoom="15" markers={mapContext.markers} googleMapsUrl={mapContext.googleMapsUrl} />
          <Spacer h={8} />

          <h3>
            {t((d) =>
              stopType === "Pickup"
                ? d.transport_job.headings.pickup_information
                : d.transport_job.headings.delivery_information
            )}
          </h3>
          <Spacer h={3} />
          <HorizontalLine type="gray" />
          <Spacer h={3} />
          <StopDetailPills details={stop.data.details} itemSets={itemSets} />
          <Spacer h={3} />
          <h5>{t((d) => d.transport_job.id)}</h5>
          <div>{`#${getIdFromIri(stop.data.transport_job)?.slice(-6)}`}</div>
          <Spacer h={3} />
          <h5>{t((d) => d.transport_job.headings[stopType === "Pickup" ? "pickup" : "delivery"])}</h5>

          {/* TITLE & DESCRIPTION OF EACH ITEM SET FOR THIS STOP */}
          {itemSets.map((itemSet, idx) => {
            return (
              <div className={cn("mb-2", "flex", "flex-col")} key={idx}>
                <div>{itemSet.title}</div>
                {itemSet.description && <div> {itemSet.description}</div>}
                {itemSet.assembly_description && (
                  <MetaItem
                    name={t((d) => d.transport_job.stop_details.services.assembly)}
                    value={<UserGeneratedContent text={itemSet.assembly_description} />}
                  />
                )}
                <ClientReferences itemSets={[itemSet]} />
                <ItemSetAttributes itemSets={[itemSet]} />
                <ItemDetails itemSets={[itemSet]} />
                <ExternalInvoices itemSets={[itemSet]} />
              </div>
            );
          })}

          {/* ADDRESS DETAILS + ASSOCIATED ACTIONS */}
          <div className={cn("flex", "items-center", "justify-between", "mt-3")}>
            <h5>{t((d) => d.transport_job.headings.address)}</h5>
            <button
              className={cn("px-1", "border", "rounded-full", "border-gray-800")}
              onClick={() => {
                setActions({
                  header: t((d) => d.actions.more),
                  options: addressActions,
                });
                actionModal.open();
              }}
            >
              <IconHorizontalDots />
            </button>
          </div>
          <AddressDetails
            address={stopContext.address}
            instructions={stop.data.details.instructions}
            presentation="planning"
          />
          {/* NOT EVERY STOP HAS A CONTACT */}
          {stopContext.contact && (
            <>
              <div className={cn("flex", "items-center", "justify-between", "mt-3")}>
                <h5>{t((d) => d.transport_job.headings.contact)}</h5>
                <button
                  className={cn("px-1", "border", "rounded-full", "border-gray-800")}
                  onClick={() => {
                    setActions({
                      header: t((d) => d.actions.more),
                      options: contactOptions,
                    });
                    actionModal.open();
                  }}
                >
                  <IconHorizontalDots />
                </button>
              </div>
              <ContactDetails contact={stopContext.contact} companyName={stopContext.companyName} />
            </>
          )}
          {/* SIGNATURES */}
          <Spacer h={3} />
          <h5>{t((d) => d.transport_job.headings.signatures)}</h5>
          <ConfirmationSignatures
            itemSets={itemSets}
            stopType={stopType}
            emptyStateText={t((d) => d.app.empty_state.generic)}
          />
          {/* PHOTO PROOFS */}
          <Spacer h={3} />
          <h5>{t((d) => d.transport_job.headings.photo_proofs)}</h5>
          <PhotoProofs itemSets={itemSets} stopType={stopType} emptyStateText={t((d) => d.app.empty_state.generic)} />
          {/* JOB ITEM IMAGES */}
          <Spacer h={3} />
          <ImageGallery itemSets={itemSets} />
        </Section>
      </Content>

      <TransportJobEditStop isActive={editModal.isActive} closeHandler={editModal.close} />
      <ActionSheetModal {...actions} isOpen={actionModal.isActive} close={actionModal.close} />
    </>
  );
};
