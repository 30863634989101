import addMinutes from "date-fns/addMinutes";
import subMinutes from "date-fns/subMinutes";
import isSameDay from "date-fns/isSameDay";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { Pickup, Delivery, DateTimePeriod } from "@brenger/api-client";

export interface StopReadyForUpdate {
  stop: Pickup | Delivery;
  availableDtp: DateTimePeriod;
  dtp: DateTimePeriod;
  isUpdated: boolean;
  availableMinutesToDecrement: number;
  availableMinutesToIncrement: number;
}

export const initialState = [] as StopReadyForUpdate[];

const SET_STOPS = "SET_STOPS";
const INCREMENT = "INCREMENT";
const DECREMENT = "DECREMENT";

export interface UpdateStop {
  type: typeof INCREMENT | typeof DECREMENT;
  payload: {
    stop: Pickup | Delivery;
  };
}

interface StopDtp {
  /**
   * Stop IRI
   */
  stop: string;
  dtp: DateTimePeriod;
}

export interface SetStops {
  type: typeof SET_STOPS;
  payload: {
    stops: Array<Pickup | Delivery>;
    dtps: StopDtp[];
  };
}

// The fixed interval by which drivers can increment/decrement DTPs
const INTERVAL = 30;

/**
 * Returns the difference in minutes between the left and right date.
 * This util slightly enhances the date-fns one by clamping results at 0 so
 * we do not have to deal with negative numbers.
 */
const getDifferenceInMinutes = (dateLeft: string, dateRight: string): number => {
  let result = 0;

  const difference = differenceInMinutes(new Date(dateLeft), new Date(dateRight));

  if (difference > 0) {
    result = difference > INTERVAL ? INTERVAL : difference;
  }

  return result;
};

const getInterval = (remainder: number): number => {
  // If the remaining available minutes to increment/decrement is
  return remainder > 0 && remainder % INTERVAL === 0 ? INTERVAL : 0;
};

type Action = SetStops | UpdateStop;

export const reducer = (state = initialState, action: Action): StopReadyForUpdate[] => {
  switch (action.type) {
    case "SET_STOPS": {
      return action.payload.stops.map((stop) => {
        // Iterate over all the commitments on this TJAL and find the one matches up with the current stop ID.
        const commitment = action.payload.dtps.find((stopDtp) => {
          return stop["@id"] === stopDtp.stop;
        });

        // We use the "find" method above, which can technically return undefined, so we must coerce the type to be sure.
        // However, something much bigger is broken if we have a TJ with stops and no related commitments.
        const dtp = commitment?.dtp as DateTimePeriod;

        const availableDtp = stop.available_datetime_periods.find(({ start }) => {
          return isSameDay(new Date(dtp.start), new Date(start));
        });
        const safeAvailableDtp =
          availableDtp || stop.available_datetime_periods[stop.available_datetime_periods.length - 1];

        return {
          stop,
          dtp,
          availableDtp: safeAvailableDtp,
          availableMinutesToIncrement: getDifferenceInMinutes(safeAvailableDtp.end, dtp.end),
          availableMinutesToDecrement: getDifferenceInMinutes(dtp.start, safeAvailableDtp.start),
          isUpdated: false,
        };
      });
    }
    case "INCREMENT":
      return state.map((stop) => {
        if (stop.stop["@id"] === action.payload.stop["@id"]) {
          const nextStartInterval = getInterval(stop.availableMinutesToIncrement);
          const nextStart = addMinutes(new Date(stop.dtp.start), nextStartInterval).toISOString();

          const nextEndInterval = getInterval(stop.availableMinutesToIncrement);
          const nextEnd = addMinutes(new Date(stop.dtp.end), nextEndInterval).toISOString();

          return {
            ...stop,
            isUpdated: true,
            availableMinutesToIncrement: getDifferenceInMinutes(stop.availableDtp.end, nextEnd),
            availableMinutesToDecrement: getDifferenceInMinutes(nextStart, stop.availableDtp.start),
            dtp: {
              ...stop.dtp,
              start: nextStart,
              end: nextEnd,
            },
          };
        }

        return stop;
      });
    case "DECREMENT":
      return state.map((stop) => {
        if (stop.stop["@id"] === action.payload.stop["@id"]) {
          const nextStartInterval = getInterval(stop.availableMinutesToDecrement);
          const nextStart = subMinutes(new Date(stop.dtp.start), nextStartInterval).toISOString();

          const nextEndInterval = getInterval(stop.availableMinutesToDecrement);
          const nextEnd = subMinutes(new Date(stop.dtp.end), nextEndInterval).toISOString();

          return {
            ...stop,
            isUpdated: true,
            availableMinutesToIncrement: getDifferenceInMinutes(stop.availableDtp.end, nextEnd),
            availableMinutesToDecrement: getDifferenceInMinutes(nextStart, stop.availableDtp.start),
            dtp: {
              ...stop.dtp,
              start: nextStart,
              end: nextEnd,
            },
          };
        }

        return stop;
      });
    default:
      return state;
  }
};
