import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import { formatDate } from "@brenger/utils";

import { DeliveredFilterState, DeliveredFilterAction } from "./types";

export const SET_FILTER_DATE_FROM = "SET_FILTER_DATE_FROM";
export const SET_FILTER_DATE_TO = "SET_FILTER_DATE_TO";
export const RESET_DELIVERED_FILTERS = "RESET_DELIVERED_FILTERS";

export const deliveredFilterActions = {
  setDateFrom: (date: string): DeliveredFilterAction => {
    return { type: SET_FILTER_DATE_FROM, payload: date };
  },
  setDateTo: (date: string): DeliveredFilterAction => {
    return { type: SET_FILTER_DATE_TO, payload: date };
  },
  reset: (): DeliveredFilterAction => {
    return { type: RESET_DELIVERED_FILTERS };
  },
};

const defaultTimeZone = {
  locale: "nl-NL",
  timeZone: "'Europe/Amsterdam'",
};
const defaultState: DeliveredFilterState = {
  dateFrom: formatDate(subDays(new Date(), 30).toISOString(), "api-date", defaultTimeZone),
  dateFromActive: false,
  dateTo: formatDate(addDays(new Date(), 7).toISOString(), "api-date", defaultTimeZone),
  dateToActive: false,
};

export const deliveredFilter = (state = defaultState, action: DeliveredFilterAction): DeliveredFilterState => {
  switch (action.type) {
    case SET_FILTER_DATE_FROM:
      return {
        ...state,
        dateFrom: action.payload,
        dateFromActive: action.payload !== defaultState.dateFrom,
      };
    case SET_FILTER_DATE_TO:
      return {
        ...state,
        dateTo: action.payload,
        dateToActive: action.payload !== defaultState.dateTo,
      };
    case RESET_DELIVERED_FILTERS:
      return defaultState;
    default:
      return state;
  }
};
