import * as React from "react";
import cn from "classnames";
import { useQuery } from "@tanstack/react-query";
import { ItemSet } from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import { Emphasis } from "@brenger/react";

import { useTranslation } from "../../hooks";
import { CacheKey, coreClient } from "../../utils";
import { StopType } from "../../types";

interface Props {
  itemSets: ItemSet[];
  stopType: StopType;
  emptyStateText: string;
}

export const ConfirmationSignatures: React.FC<Props> = ({ itemSets, stopType, emptyStateText }) => {
  const signatureIds = itemSets
    .flatMap((itemSet) => itemSet.signatures)
    // Check if signature type (eg: "PickupSignature") includes the stop type (eg "Pickup")
    // Else, add a stringified "undefined".
    .filter((signature) => signature["@type"].startsWith(stopType || String(undefined)))
    .map((signature) => getIdFromIri(signature["@id"]) || "")
    // Remove any empty strings
    .filter(Boolean);

  // No signatures to show
  if (!signatureIds.length) {
    return (
      <div>
        <Emphasis>{emptyStateText}</Emphasis>
      </div>
    );
  }

  // lets render!
  return (
    <div className={cn("flex", "flex-wrap", "-mx-2")}>
      {signatureIds.map((id) => {
        return <Signature key={id} signatureId={id} stopType={stopType} />;
      })}
    </div>
  );
};

interface SignatureProps {
  signatureId: string;
  stopType: StopType;
}

const Signature: React.FC<SignatureProps> = ({ signatureId, stopType }) => {
  const { t } = useTranslation();

  const pickupSignature = useQuery(
    [CacheKey.RETRIEVE_SIGNATURE, "PICKUP", signatureId],
    () => coreClient.signatures.retrievePickupResource({ id: signatureId }),
    {
      enabled: stopType === "Pickup",
    }
  );

  const deliverySignature = useQuery(
    [CacheKey.RETRIEVE_SIGNATURE, "DELIVERY", signatureId],
    () => coreClient.signatures.retrieveDeliveryResource({ id: signatureId }),
    {
      enabled: stopType === "Delivery",
    }
  );

  const signature = stopType === "Pickup" ? pickupSignature : deliverySignature;

  if (!signature.data || signature.error) return null;

  return (
    <div className={cn("p-2")}>
      <img
        className={cn("rounded", "border")}
        style={{ maxWidth: 150 }}
        src={`${signature.data.signature_image.download_link}?w=150`}
      />
      {signature.data.signed_by && (
        <div className={cn("flex", "flex-col", "items-center")} style={{ maxWidth: 150 }}>
          <div>{t((d) => d.transport_job.headings.signed_by)}:</div>
          <div>{signature.data.signed_by}</div>
        </div>
      )}
    </div>
  );
};
