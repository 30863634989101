import {
  List,
  PickupCommitment,
  TransportJobAccountLink,
  TransportJobAccountLinkListParams,
} from "@brenger/api-client";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import groupBy from "lodash/groupBy";
import * as React from "react";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { DeliveredFilterState } from "../store/delivered/types";
import { PlanningFilterState } from "../store/planning/types";
import { CacheKey, TransportsType, coreClient } from "../utils";
import { useFormatDate, useSelector } from "./";

/**
 * Filtered TJALS are grouped by date.
 */
export interface FilteredTjals {
  [date: string]: TransportJobAccountLink[];
}

interface Params {
  presentation: TransportsType;
  page?: number;
}

interface UseTransportsFilter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: UseQueryResult<List<any>>;
  filteredTjals: FilteredTjals;
  filteredTjalsCount: number;
  isLoading: boolean;
  activeFilterCount: number;
}

/**
 * This hook takes care of making the request for planning and delivered TJALs as well as
 * filtering the resulting tjals.
 */

export const useTransportsFilter = (params: Params): UseTransportsFilter => {
  const page = params?.page || 1;
  const formatDateForApi = useFormatDate("api-date");
  const filter = useSelector(
    (state) => state[params?.presentation === "delivered" ? "deliveredFilter" : "planningFilter"]
  );
  const args: TransportJobAccountLinkListParams = {
    page,
    presentation: params.presentation,
    date_from: (filter as DeliveredFilterState).dateFrom || formatDateForApi(subDays(new Date(), 14).toISOString()),
    date_to: (filter as DeliveredFilterState).dateTo || formatDateForApi(addDays(new Date(), 365).toISOString()),
    order_pickup_date: "desc",
  };
  const query = useQuery(
    [CacheKey.LIST_TJALS, ...Object.keys(args).map((key) => args[key as keyof TransportJobAccountLinkListParams])],
    () => coreClient.transportJobAccountLinks.list(args)
  );

  const tjals = query.data?.["hydra:member"] || [];

  /**
   * Filtering all this data is an expensive operation.
   */
  const user = (filter as PlanningFilterState).user || null;
  const tjalStates = (filter as PlanningFilterState).tjalStates || [];

  /**
   * Count active filters
   */
  let count = 0;
  // Is there a user?
  if (user) count++;
  // Do the tjal state have a length?
  if (tjalStates.length) count++;
  // Is there a "date from" or "date to" active?
  if ((filter as DeliveredFilterState).dateFromActive || (filter as DeliveredFilterState).dateToActive) count++;

  const filteredTjals: FilteredTjals = React.useMemo(() => {
    let filterResult = tjals || [];
    /**
     * Only show TJAL for particular user when one has been selected.
     */
    if (user) {
      filterResult = filterResult.filter((tjal) => {
        return tjal.driver_user["@id"] === user;
      });
    }

    /**
     * Group by TJAL state (only if certain TJAL states are selected).
     */
    if (tjalStates.length) {
      filterResult = filterResult.filter((tjal) => {
        return tjalStates.includes(tjal.state);
      });
    }

    /**
     * Group list by dates.
     */
    return groupBy(filterResult, (tjal: TransportJobAccountLink) => {
      const firstPickupCommitment = (tjal.pickup_commitments[0] as PickupCommitment).committed_datetime_period.start;
      return firstPickupCommitment ? formatDateForApi(firstPickupCommitment) : null;
    });
  }, [tjals, user, tjalStates]);

  return {
    query,
    filteredTjals,
    filteredTjalsCount: Object.values(filteredTjals).reduce((p, c) => p + c.length, 0),
    isLoading: query.isLoading,
    activeFilterCount: count,
  };
};
