/* eslint-disable no-undef, @typescript-eslint/naming-convention */

export const LOCALE_TEMPLATE = "{{locale}}";

export const Config = Object.freeze({
  NODE_ENV: process.env.NODE_ENV,
  IS_PROD: ["production", "android", "ios"].includes(process.env.NODE_ENV || "development"),
  API_URL: process.env.API_URL || "https://api.brenger.nl",
  ROUTE_PLANNER_URL: process.env.ROUTE_PLANNER_URL || "https://geo.brenger.nl",
  SENTRY_DSN: process.env.SENTRY_DSN,
  TRANSLATION_KEY: process.env.TRANSLATION_KEY,
  // NOTE: The app URL
  URL: process.env.URL,
  USER_DATA_URL: process.env.USER_DATA_URL || "https://user-data.brenger.nl",
  STATIC_MAPS_KEY: process.env.STATIC_MAPS_KEY,
  // NOTE: COMMIT_SHA is provided by gitlab CI
  COMMIT_SHA: process.env.CI_COMMIT_SHA,
  GTM_ID: process.env.GTM_ID,
  GTM_ENV_PARAMS: process.env.GTM_ENV_PARAMS,
  STATIC_MAPS_ROOT_URL: "https://d16vg3w4yon3ol.cloudfront.net/maps/api/staticmap",
  I18N_URL: "https://api.brenger.nl/i18n/translations",
  IOS_APP_STORE_URL: "https://apps.apple.com/nl/app/brenger-voor-koeriers/id1504096160",
  GOOGLE_PLAY_STORE_URL: "https://play.google.com/store/apps/details?id=nl.brenger.driverdashboard",
  TRANSLATION_URL: "https://translation.googleapis.com/language/translate/v2",
  DRIVER_APPLICATION_URL: "https://www.fountain.com/brenger/apply/amsterdam-example-position",
  CUSTOMER_URL: process.env.CUSTOMER_URL || "https://www.brenger.nl",
  USER_LOCATION_URL: process.env.USER_LOCATION_URL || "https://livetracking.brenger.nl/user_locations",
  DRIVER_SUPPORT_URL: `https://brenger.nl/${LOCALE_TEMPLATE}/driver-support`,
  DRIVER_SIGN_UP_URL: `https://brenger.nl/${LOCALE_TEMPLATE}/driver-sign-up`,
  DRIVER_AGREEMENT_URL: `https://brenger.nl/${LOCALE_TEMPLATE}/terms-transport-agreement`,
  FRESH_CHAT_TOKEN: process.env.FRESH_CHAT_TOKEN,
  ONE_SIGNAL_FCM_ID: process.env.ONE_SIGNAL_FCM_ID,
});
