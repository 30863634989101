import * as React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import subMinutes from "date-fns/subMinutes";
import isBefore from "date-fns/isBefore";
import { TransportJob } from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import { Message } from "@brenger/react";

import { useAuth, useTranslation } from ".";

/**
 * This hook accepts a transport job and returns a "Gated UI" to display to the user.
 * Depending on the situation, this could be a Message component or a CTA Button.
 */
export const useClaimJobGate = (tj?: TransportJob): React.ReactNode | null => {
  const auth = useAuth();
  const { t } = useTranslation();

  // What is the minimum required level for this job? Provide default just in case.
  const minLevelForJob = tj?.minimum_progress_level_required || "bronze";

  if (tj?.not_claimable_by_current_account_reason === "bronze_account_claimed_jobs_limit_reached") {
    return (
      <Message type="info">
        {t((d) => d.search.not_claimable_reasons.bronze_account_claimed_jobs_limit_reached)}
      </Message>
    );
  }

  if (tj?.not_claimable_by_current_account_reason === "account_level_insufficient") {
    return (
      <Message type="info">
        {t((d, withTemplate) => {
          return withTemplate(d.search.not_claimable_reasons.account_level_insufficient, {
            driver_progress_level: d.level[auth.accountLevel.raw],
            minimum_progress_level: d.level[minLevelForJob],
          });
        })}
      </Message>
    );
  }

  // State "cancelled" is correct, but preferebly we check against "reason", state needs to be checked until BE is aligned with FE
  if (tj?.not_claimable_by_current_account_reason === "tj_cancelled" || tj?.state === "cancelled") {
    return <Message type="info">{t((d) => d.search.not_claimable_reasons.tj_is_cancelled)}</Message>;
  }

  if (tj?.not_claimable_by_current_account_reason === "already_claimed") {
    return <Message type="info">{t((d) => d.search.not_claimable_reasons.already_claimed)}</Message>;
  }

  // This is a catch-all code from core. If this is the reason, it's likely the case that
  // the open TJ list is returning bad TJs.
  if (tj?.not_claimable_by_current_account_reason === "tj_not_claimable") {
    return <Message type="info">{t((d) => d.search.not_claimable_reasons.tj_not_claimable)}</Message>;
  }

  /**
   * This user already has made an offer. Give them a button to view the offer in planning.
   */
  if (tj?.own_transport_job_account_link?.state === "pending") {
    return (
      <Message type="info">
        <Link className={cn("font-bariol", "underline")} to={`/planning/${getIdFromIri(tj["@id"])}`}>
          {t((d) => d.search.messages.view_offer)}
        </Link>
      </Message>
    );
  }

  // If in the future we introduced a new reason for not claimable, but frontend is not aware yet then we should show here a generic message
  // NOTE: This also means that any specific message should be above this one.
  if (tj?.claimable_by_current_account === false) {
    return <Message type="info">{t((d) => d.search.not_claimable_reasons.generic)}</Message>;
  }

  /**
   * https://gitlab.com/brenger/frontend/dd-v2/-/issues/7
   * Ensure there is an available datetime period that is still far enough in advance for the driver
   * to reasonably claim it.
   * For now, we are being very lenient, and allowing drivers to claim a job on the same day as long as there
   * is 30 minutes left before the expected pickup end period.
   */
  const allPickupEndPeriods = tj?.pickups.flatMap((pickup) => {
    return pickup.available_datetime_periods.map((period) => period.end);
  });

  const MINIMUM_LEAD_TIME_IN_MINUTES = 30;
  /**
   * If there are no pickup end periods, then this is likely a non-guaranteed job.
   * When that is the case, a driver can only make an offer, so no need to check, formatDate for available periods.
   */
  if (allPickupEndPeriods && allPickupEndPeriods.length > 0) {
    const hasOneValidPeriod = allPickupEndPeriods.some((pickupEndPeriod) => {
      const sanitizedDate = new Date(pickupEndPeriod).toISOString();
      return isBefore(new Date(), subMinutes(new Date(sanitizedDate), MINIMUM_LEAD_TIME_IN_MINUTES));
    });
    if (!hasOneValidPeriod) {
      return <Message type="info">{t((d) => d.search.messages.insufficient_lead_time)}</Message>;
    }
  }

  return null;
};
