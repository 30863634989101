import { DayRouteActivity } from "@brenger/api-client";
import { Button, Message, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { useCache, useConfirmModal, useTranslation } from "../../hooks";
import { coreClient } from "../../utils";

interface Props {
  activity: DayRouteActivity;
  returnUrl: string;
}

export const CancelCustomStop: React.FC<Props> = ({ activity, returnUrl }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const cache = useCache();

  const onSuccess = (): void => {
    cache.clear();
    history.push(returnUrl);
  };

  const deleteActivity = useMutation(coreClient.dayRoutesActivities.delete, { onSuccess });

  const [ConfirmModal, openModal] = useConfirmModal(() => {
    deleteActivity.mutate({ id: getIdFromIri(activity["@id"]) || "" });
  });

  return (
    <div>
      <div>
        <b>{t((d) => d.stop_edit.cancelation.heading)}</b>
      </div>
      <div className={cn("mb-2")}>{t((d) => d.stop_edit.cancelation.intro_custom_stop)}</div>
      <Spacer h={1} />
      {deleteActivity.error && (
        <Message className={cn("my-4")} type="error">
          {deleteActivity.error as string}
        </Message>
      )}
      <Button
        loading={deleteActivity.isLoading}
        disabled={deleteActivity.isLoading}
        buttonType={"warning"}
        onClick={() =>
          openModal({
            title: t((d) => d.actions.remove),
            message: t((d) => d.actions.are_you_sure),
          })
        }
      >
        {t((d) => d.stop_edit.cancelation.cta)}
      </Button>
      <ConfirmModal buttonType="warning" />
    </div>
  );
};
