import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { CacheKey, coreClient, DayRouteStopParams } from "../../../utils";
import { getActivityAndHumanIndex } from "../utils";
import { DayRouteCustomStopDetails } from "./DayRouteCustomStopDetails";
import { DayRouteNormalStopDetails } from "./DayRouteNormalStopDetails";

export const DayRouteStopDetails: React.FC = () => {
  const params = useParams<DayRouteStopParams>();
  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );
  const { activity, humanIndex } = getActivityAndHumanIndex({
    activities: dayRoute.data?.day_route_activities || [],
    activityId: params.stop_id,
  });
  if (["pickup", "delivery"].includes(activity?.type || ""))
    return <DayRouteNormalStopDetails activity={activity} humanIndex={humanIndex} />;

  if (!["pickup", "delivery"].includes(activity?.type || ""))
    return <DayRouteCustomStopDetails activity={activity} humanIndex={humanIndex} />;

  return null;
};
