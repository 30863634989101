import * as React from "react";
import cn from "classnames";
import { DateTimePeriodParams } from "@brenger/api-client";
import {} from "@brenger/react";

import { useFormatDate, useFormatTimeframe } from "../../hooks";

interface Props {
  periods: DateTimePeriodParams[];
}

/**
 *  A summary of datetime periods.
 */
export const DateTimePeriods: React.FC<Props> = (props) => {
  const formatDateFull = useFormatDate("date-full");
  const formatTimeframe = useFormatTimeframe();

  return (
    <div className={cn("flex", "flex-col")}>
      {props.periods.map((period, idx) => {
        return (
          <div key={idx} className={cn("capitalize")}>
            {formatDateFull(period.start)} ({formatTimeframe({ start: period.start, end: period.end })})
          </div>
        );
      })}
    </div>
  );
};
