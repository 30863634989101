import { H5, IconEditPencil, Strong } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useTranslation } from "../../hooks";

export const NotAllowed: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={cn("grow", "flex", "items-center", "justify-center")}>
      <div className={cn("text-center", "mb-16")}>
        <IconEditPencil className={cn("text-blue-600", "mb-4", "inline", "text-3xl")} />
        <H5 className={"text-blue-600"}>
          <Strong>{t((d) => d.stop_edit.not_allowed.heading)}</Strong>
        </H5>
        <div>{t((d) => d.stop_edit.not_allowed.text)}</div>
      </div>
    </div>
  );
};
