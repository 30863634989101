import { RouteStop, TransportJob, Address, Delivery, Pickup } from "@brenger/api-client";
import { Pill, Timeline, ToggleMore } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useTranslation } from "../../hooks";

interface Props {
  tjId?: string;
  tj: TransportJob | null;
  activeStop: Pickup | Delivery | null;
  route?: RouteStop[];
}

export const RouteOverViewBundled: React.FC<Props> = ({ tjId, tj, activeStop, route }) => {
  const { t } = useTranslation();
  const [showRoute, setShowRoute] = React.useState(false);
  if (!tj || !route?.length) {
    return null;
  }
  // FIXME: Could load edit bundle functionality from <ClaimBundle />
  return (
    <ToggleMore
      type={"gray"}
      isOpen={showRoute}
      toggle={() => setShowRoute(!showRoute)}
      label={t((d) => d.transport_job[!showRoute ? "show_route" : "hide_route"])}
    >
      <Pill type="blue">
        {t((d) => d.transport_job.bundle)}: {tjId}
      </Pill>
      {(route || []).map((stop, i) => {
        let renderStop = undefined as Pickup | Delivery | undefined;
        if (stop.pickup) {
          renderStop = tj.pickups.find((p) => p["@id"] === stop.pickup);
        }
        if (stop.delivery) {
          renderStop = tj.deliveries.find((d) => d["@id"] === stop.delivery);
        }
        if (!renderStop) {
          return null;
        }
        return (
          <Timeline key={i} isFirst={i === 0} isLast={i === route.length - 1}>
            <div className={cn({ "br-font-bold": renderStop["@id"] === activeStop?.["@id"] })}>
              {(renderStop.address as Address).line1}, {(renderStop.address as Address).locality}
            </div>
          </Timeline>
        );
      })}
    </ToggleMore>
  );
};
