import * as React from "react";

import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useAuth, useFormatDate } from "../../hooks";
import { Routes } from "../../utils";
import { SplitView } from "../../layout";
import { DayRouteAdd } from "./DayRouteAdd";
import { DayRouteConfirmStop } from "./DayRouteConfirmStop";
import { DayRouteDetails } from "./DayRouteDetails";
import { DayRouteStopDetails } from "./DayRouteStopDetails";

export const DayRouteHome: React.FC = () => {
  const location = useLocation();
  const isHome = matchPath(location.pathname, Routes.dayroutes.home());
  const history = useHistory();
  const auth = useAuth();
  const formatDate = useFormatDate("api-date");

  React.useEffect(() => {
    if (!isHome?.isExact) return;
    history.replace(
      Routes.dayroutes.details({ user_id: auth.userId as string, date: formatDate(new Date().toISOString()) })
    );
  }, [isHome?.isExact]);

  if (isHome?.isExact) {
    return null;
  }

  return (
    <SplitView
      list={{ path: Routes.dayroutes.details({ date: ":date", user_id: ":user_id" }), component: DayRouteDetails }}
      details={[
        {
          path: Routes.dayroutes.stop({ date: ":date", user_id: ":user_id", stop_id: ":stop_id" }),
          component: DayRouteStopDetails,
        },
        {
          path: Routes.dayroutes.confirmStop({ date: ":date", user_id: ":user_id", stop_id: ":stop_id" }),
          component: DayRouteConfirmStop,
        },
        {
          path: Routes.dayroutes.add({ date: ":date", user_id: ":user_id" }),
          component: DayRouteAdd,
        },
        {
          path: Routes.dayroutes.add({ date: ":date", user_id: ":user_id", type: ":type" }),
          component: DayRouteAdd,
        },
      ]}
    />
  );
};
