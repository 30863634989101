import { useCallback, useEffect, useState } from "react";

export type UsePullToRefreshParams = {
  ref: HTMLElement | null;
  onRefresh: () => Promise<void>;
  maximumPullLength?: number;
  refreshThreshold?: number;
  isDisabled?: boolean;
};
export type UsePullToRefresh = {
  isRefreshing: boolean;
  pullPosition: number;
};

export const usePullToRefresh = ({
  ref,
  onRefresh,
  maximumPullLength = 240,
  refreshThreshold = 180,
  isDisabled = false,
}: UsePullToRefreshParams): UsePullToRefresh => {
  const [pullStartPosition, setPullStartPosition] = useState(0);
  const [pullPosition, setPullPosition] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const onPullStart = useCallback(
    (e: TouchEvent) => {
      if (isDisabled) return;

      const touch = e.touches[0];
      if (touch) setPullStartPosition(touch.pageY);
    },
    [isDisabled]
  );

  const onPulling = useCallback(
    (e: TouchEvent) => {
      if (isDisabled) return;

      const touch = e.touches[0];
      if (!touch) return;
      const currentPullLength = pullStartPosition < touch.pageY ? Math.abs(touch.pageY - pullStartPosition) : 0;
      if (currentPullLength <= maximumPullLength && pullStartPosition < maximumPullLength)
        setPullPosition(() => currentPullLength);
    },
    [isDisabled, maximumPullLength, pullStartPosition]
  );

  const onEndPull = useCallback(() => {
    if (isDisabled) return;

    setPullStartPosition(0);
    setPullPosition(0);

    if (pullPosition < refreshThreshold) return;

    setIsRefreshing(true);
    setTimeout(() => {
      const cb = onRefresh();

      if (typeof cb === "object") return void cb.finally(() => setIsRefreshing(false));

      setIsRefreshing(false);
    }, 500);
  }, [isDisabled, onRefresh, pullPosition, refreshThreshold]);

  useEffect(() => {
    if (!ref || isDisabled) return;

    const ac = new AbortController();
    const options = {
      passive: true,
      signal: ac.signal,
    };

    ref.addEventListener("touchstart", onPullStart, options);
    ref.addEventListener("touchmove", onPulling, options);
    ref.addEventListener("touchend", onEndPull, options);

    return () => void ac.abort();
  }, [isDisabled, onEndPull, onPullStart, onPulling, ref]);

  return { isRefreshing, pullPosition };
};
