// eslint-disable-next-line custom-rules/hook-use-cache
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { CachePrefix, logger } from "../utils";

/**
 * About cache:
 * Clear all cache was a little problematic since the update of React-query to v4
 * Eventually we want to update to v5 so we had to take this step.
 * Landed on this:
 * - Use clear to invalidate cache, it will be refresh. Example usage: courier updates DTP, call cache.clear() to invalidate planning list and job view.
 * - logout cleares cache, but only auth cache, functional data is prefixed with TJ/DAYROUTE/TJAL IDs so never popup for other users
 * - When logging in we don't clear cache, but update the auth data, so only one state update is triggered
 * - I stick with invalidateQueries, it is computation friendlier operation and only fetches new when needed, causing less updates
 *
 * Painful experiences past experiences:
 * - Clearing all cache was causing the geo location plugin not to load: the auth hook didn't receive an update.
 * - Janky rendering when clearing all cache, because both auth and translations are in there
 */
interface UseCache {
  /**
   * Clear functional data only, which you basically want most of the time
   */
  clear(): void;
  /**
   * Clear any prefixed data
   */
  clearByPrefixes(prefixes: CachePrefix[]): void;
  queryClient: QueryClient;
}

/**
 * Simple hook to standardise how we interact with cache.
 * Designed to clear cache responsibly with less side effects.
 */
export function useCache(): UseCache {
  const queryClient = useQueryClient();

  const clearByPrefixes = (prefixes: CachePrefix[]): void =>
    prefixes.forEach((prefix) => {
      logger.dev(`Clear cache with prefix: ${prefix}`);
      queryClient.invalidateQueries({
        predicate: (query) => !!(query.queryKey[0] as string | undefined)?.startsWith(prefix),
      });
    });

  const clear = (): void => clearByPrefixes(["FUNC"]);

  return {
    clearByPrefixes,
    clear,
    queryClient,
  };
}
