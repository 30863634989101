import { DayRouteActivity } from "@brenger/api-client";
import {
  HorizontalLine,
  IconCheck,
  IconEditPencil,
  IconHorizontalDots,
  Pill,
  SeperateLine,
  Spacer,
  Strong,
  useModalState,
} from "@brenger/react";
import cn from "classnames";
import { isAfter } from "date-fns";
import { useParams } from "react-router-dom";

import { AddressDetails, FormatCommitedStopTime, StaticMap } from "../../../components";
import { createGoogleMapsLinkFromSingleAddress, useFormatDate, useTranslation } from "../../../hooks";
import { DayRouteStopParams, Routes } from "../../../utils";
import { DayRouteEditCustomStop } from "../../edit/DayRoute/DayRouteEditCustomStop";
import { ActionSheetModal, ActionSheetModalOption, Content, PageHead, Section } from "../../../layout";
import { DayRouteStopStickyFooter } from "./DayRouteStopStickyFooter";

interface Props {
  activity?: DayRouteActivity | null;
  humanIndex?: number;
}

export const DayRouteCustomStopDetails: React.FC<Props> = ({ activity, humanIndex }) => {
  const { t } = useTranslation();
  const formatHourMinute = useFormatDate("hour-minute");
  const params = useParams<DayRouteStopParams>();
  const editModal = useModalState();
  const actionModal = useModalState();

  const googleMapsUrl = activity?.address ? createGoogleMapsLinkFromSingleAddress(activity.address) : "";
  const addressActions: ActionSheetModalOption[] = [
    {
      title: t((d) => d.planning.actions.open_google_maps),
      onClick: () => window.open(googleMapsUrl, "_blank"),
    },
  ];

  const stopType = activity?.type;

  const canConfirm = !activity?.confirmed_by_driver_at && isAfter(new Date(), new Date(params.date));

  return (
    <>
      <Content
        isLoading={!activity}
        header={
          <PageHead
            sectionType={"split-details"}
            backUrl={Routes.dayroutes.details(params)}
            title={`Stop: ${humanIndex}`}
            afterTitle={
              activity?.confirmed_by_driver_at ? (
                <Pill type="green-outline" className={cn("mb-2")}>
                  <span className={cn("flex", "items-center")}>
                    <IconCheck className={cn("mr-1")} />
                    {formatHourMinute(activity?.confirmed_by_driver_at)}
                  </span>
                </Pill>
              ) : (
                <div className={cn("text-blue-600", "flex", "items-center", "cursor-pointer")} onClick={editModal.open}>
                  <IconEditPencil className={cn("mr-1")} /> {t((d) => d.actions.edit)}
                </div>
              )
            }
          />
        }
        footer={!activity ? undefined : <DayRouteStopStickyFooter activity={activity} canConfirm={canConfirm} />}
      >
        <Section type="split-details" isTop={true}>
          {/* PICKUP OR DELIVERY */}
          <div className={cn("flex", "items-center", "justify-between")}>
            <div className={cn("inline-flex", "items-center")}>
              <h6 className="uppercase">
                {t((d) => d.transport_job.headings[stopType === "custom_pickup" ? "pickup" : "delivery"])}
              </h6>
              <SeperateLine spaceWidth={2} type="blueGray" />

              <h6 className={cn("text-gray-500", "uppercase")}>
                {/* OWN STOP */}
                {t((d) => d.day_route.details.own_stop)}
              </h6>
            </div>
          </div>
          <Spacer h={3} />
          <HorizontalLine type="gray" />
          <Spacer h={3} />
          {/* SHORT ADDRESS */}
          <h6>
            <AddressDetails address={activity?.address || null} presentation="dayroute-details" />
          </h6>
          {/* COMMITED DATETIME & SERVICE TIME */}
          <div className={cn("flex", "items-center")}>
            <FormatCommitedStopTime
              textClassname={["mr-2"]}
              start={activity?.start_time || null}
              end={activity?.end_time || null}
            />
            {activity?.service_time_seconds && (
              <>
                <div>
                  {t((d) => d.transport_job.service_time)}: {activity?.service_time_seconds / 60}
                </div>
                <div className={cn("m-1")}> {t((d) => d.calendar.minute_full)}</div>
              </>
            )}
          </div>
          {activity?.confirmed_by_driver_at && (
            <div>
              <Pill type="green" className={cn("mb-2")}>
                <span className={cn("flex", "items-center")}>
                  <IconCheck className={cn("mr-1")} />
                  {formatHourMinute(activity?.confirmed_by_driver_at)}
                </span>
              </Pill>
            </div>
          )}
          <Spacer h={8} />
          <StaticMap
            zoom="15"
            markers={[
              {
                location: {
                  lat: activity?.address?.lat || 0,
                  lng: activity?.address?.lng || 0,
                },
                stopNumber: String(humanIndex),
              },
            ]}
            googleMapsUrl={googleMapsUrl}
          />
          <Spacer h={8} />
          {/* PICKUP OR DELIVERY INFORMATION*/}
          <h6>
            {stopType !== "custom_other" &&
              t((d) =>
                stopType === "custom_pickup"
                  ? d.transport_job.headings.pickup_information
                  : d.transport_job.headings.delivery_information
              )}
          </h6>
          <Spacer h={3} />
          <HorizontalLine type="gray" />
          <Spacer h={3} />
          {/* ADDRESS DETAILS + ASSOCIATED ACTIONS */}
          <div className={cn("flex", "items-center", "justify-between", "mt-3")}>
            <Strong>
              <h6>{t((d) => d.transport_job.headings.address)}</h6>{" "}
            </Strong>
            <button className={cn("px-1", "border", "rounded-full", "border-gray-800")} onClick={actionModal.open}>
              <IconHorizontalDots />
            </button>
          </div>
          <AddressDetails
            address={activity?.address || null}
            presentation="planning"
            instructions={activity?.description || ""}
          />
        </Section>
      </Content>
      {activity && (
        <DayRouteEditCustomStop activity={activity} isActive={editModal.isActive} closeHandler={editModal.close} />
      )}
      <ActionSheetModal
        options={addressActions}
        header={t((d) => d.actions.select_option)}
        isOpen={actionModal.isActive}
        close={actionModal.close}
      />
    </>
  );
};
