// ADD COMMON TYPES THAT ARE NOT SUPPORTED BY THE API-CLIENT HERE

import { TransportJobAccountLinkListParams } from "@brenger/api-client";

/**
 * These are the main views in the driver dashboard. Many components are the same across all views.
 * However, there is often some slight different in how the data is displayed. Therefore, we use
 * this type to dictate the context and do imperative logic around what to display.
 */
export type JobPresentation = "search" | "planning" | "delivered" | "dayroute-details" | "planning-stop-edit";
export type ProfilePresentation = "reviews" | "details";

/**
 * A nice utility to filter out empty items from a list with type safety.
 */
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export type TransportsType = TransportJobAccountLinkListParams["presentation"];

export interface TransportsListParams {
  type: TransportsType | ":type";
}

export interface TransportsJobParams {
  type: TransportsType | ":type";
  job_id: string;
}

export interface TransportsStopParams {
  type: TransportsType | ":type";
  job_id: string;
  stop_id: string;
}

export interface NewJobParams {
  job_id: string;
}

export interface NewStopParams {
  job_id: string;
  stop_id: string;
}

export interface DayRouteParams {
  date: string;
  user_id: string;
}

export interface DayRouteStopParams extends DayRouteParams {
  stop_id: string;
}

export type DayRouteAddType = "pickup" | "delivery" | "job" | "other";
export interface DayRouteAddParams extends DayRouteParams {
  type?: DayRouteAddType | ":type";
}
