import { EuroNorm } from "@brenger/api-client";

export const euroNorms: Array<{ val: EuroNorm; text: string }> = [
  { val: "euro1", text: "Euro 1" },
  { val: "euro2", text: "Euro 2" },
  { val: "euro3", text: "Euro 3" },
  { val: "euro4", text: "Euro 4" },
  { val: "euro5", text: "Euro 5" },
  { val: "euro6", text: "Euro 6" },
];

export interface MakeModel {
  make: string;
  model: string;
}

export const makeModels: MakeModel[] = [
  { make: "citroen", model: "berlingo" },
  { make: "citroen", model: "jumper" },
  { make: "citroen", model: "jumpy" },
  { make: "fiat", model: "ducato" },
  { make: "fiat", model: "ducato bakwagen" },
  { make: "fiat", model: "fiorino" },
  { make: "fiat", model: "talento" },
  { make: "ford", model: "transit" },
  { make: "ford", model: "transit bakwagen" },
  { make: "iveco", model: "daily" },
  { make: "iveco", model: "daily bakwagen" },
  { make: "man", model: "tge" },
  { make: "mercedes", model: "citan" },
  { make: "mercedes", model: "sprinter" },
  { make: "mercedes", model: "sprinter bakwagen" },
  { make: "mercedes", model: "vito" },
  { make: "opel", model: "combo" },
  { make: "opel", model: "movano" },
  { make: "opel", model: "movano bakwagen" },
  { make: "opel", model: "vivaro" },
  { make: "peugeot", model: "combo" },
  { make: "peugeot", model: "boxer" },
  { make: "peugeot", model: "boxer bakwagen" },
  { make: "peugeot", model: "expert" },
  { make: "peugeot", model: "partner" },
  { make: "renault", model: "master" },
  { make: "renault", model: "master bakwagen" },
  { make: "renault", model: "traffic" },
  { make: "volkswagen", model: "caddy" },
  { make: "volkswagen", model: "crafter" },
  { make: "volkswagen", model: "crafter bakwagen" },
  { make: "volkswagen", model: "sharan" },
  { make: "volkswagen", model: "transporter" },
];
