import cn from "classnames";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";

import { useParams } from "react-router-dom";
import { useTranslation } from "../../../hooks";
import { CacheKey, Routes, StaleTTL, coreClient } from "../../../utils";
import { Content, PageHead, Section } from "../../../layout";
import { Conversation } from "./Conversation";
import { ConversationParty } from "./ConversationParty";

export const MoreInboxDetails: React.FC = () => {
  const params = useParams<{ tjal_id: string }>();
  const { t } = useTranslation();
  const tjal = useQuery(
    [CacheKey.RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK, params.tjal_id],
    () => coreClient.transportJobAccountLinks.retrieve({ id: params.tjal_id }),
    {
      staleTime: StaleTTL.XS,
    }
  );

  // Handle loading and errors here.
  // This is also a chance to handle incorrect TJAL ID from the URL param before
  // mounting any subsequent components that also rely on the TJAL ID.
  if (!tjal.data || tjal.error) {
    return (
      <Content
        message={(tjal.error as Error)?.message}
        header={<PageHead sectionType="single-details" title={t((d) => d.more.inbox)} backUrl={Routes.more.inbox()} />}
      />
    );
  }

  return (
    <Content
      header={<PageHead sectionType="single-details" title={t((d) => d.more.inbox)} backUrl={Routes.more.inbox()} />}
    >
      <Section type="single-details" isTop={true}>
        <div className={cn("mb-4", "sticky", "top-0", "px-2", "bg-white")}>
          <ConversationParty tjalId={params.tjal_id} />
        </div>
        <div className={cn("px-1")}>
          <Conversation tjalId={params.tjal_id} />
        </div>
      </Section>
    </Content>
  );
};
