import * as React from "react";
import { User } from "@brenger/api-client";
import { Pill } from "@brenger/react";

import { useTranslation } from "../../hooks";

interface Props {
  user: User;
}

export const UserStatusPill: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  if (props.user.is_suspended) {
    return <Pill type="red-outline">{t((d) => d.profile.status.suspended)}</Pill>;
  }
  if (props.user.deletion_token !== null) {
    return <Pill type="red">{t((d) => d.profile.status.deleted)}</Pill>;
  }
  return <Pill type="green">{t((d) => d.profile.status.active)}</Pill>;
};
