import cn from "classnames";
import * as React from "react";

import { Strong, IconDresscodeWithWhiteCircle } from "@brenger/react";
import { ExternalLink } from "..";
import { Config, LOCALE_TEMPLATE } from "../../config";
import { useTranslation } from "../../hooks";
import { PersistedStorageKey } from "../../utils";
import { ToolTip } from "./ToolTip";

export const TooltipDressCode: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={cn("relative", "flex", "flex-col", "items-center", "group")}>
      <div className={cn("w-full")}>
        <div
          className={cn(
            "flex",
            "items-center",
            "p-2",
            "rounded",
            "border",
            "w-full",
            "bg-blue-50",
            "text-blue-600",
            "border-50-600"
          )}
        >
          <IconDresscodeWithWhiteCircle className={cn("br-mr-4")} />
          <Strong> {t((d) => d.transport_job.stop_details.services.dress_code)}</Strong>

          <div className={cn("mr-0", "ml-auto")}>
            <ToolTip withPersistedState={PersistedStorageKey.HAS_SEEN_DAY_DRESSCODE}>
              <div className={cn("inline-flex", "flex-wrap", "whitespace-nowrap")}>
                <div> {t((d) => d.transport_job.dresscode)}</div>
                <ExternalLink
                  url={Config.DRIVER_SUPPORT_URL.replace(LOCALE_TEMPLATE, i18n.locale)}
                  className={cn("mx-1", "lowercase", "cursor-pointer", "underline", "italic")}
                >
                  <div>
                    <Strong> {t((d) => d.transport_job.stop_details.services.dresscode + ".")}</Strong>
                  </div>
                </ExternalLink>
                <div>{t((d) => d.transport_job.dresscode_no_go)}</div>
              </div>
            </ToolTip>
          </div>
        </div>
      </div>
    </div>
  );
};
