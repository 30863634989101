import { formatDate, DateFormatType, sanitizeLocale } from "@brenger/utils";
import { useSelector } from ".";

/**
 * This date format util function is a hook because we need to "hook into" the
 * redux state and grab the current language settings for the user.
 * Therefore, we wrap the regular non-React format util with a hook and
 * pass in the current locale as a convienance.
 */
export const useFormatDate = (format: DateFormatType): ((date: string | null) => string) => {
  const { locale, timeZone } = useSelector((state) => state.settings);

  return (date: string | null) => {
    if (!date) return "invalid Date";

    return formatDate(date, format, { locale: sanitizeLocale(locale), timeZone });
  };
};

export const sanitizeDate = (dateStr: string): string => {
  // Is date already in utc format
  // Check if the date string contains a +00:00 or "Z" type offset to incidate UTC.
  const hasOffset = dateStr.match(/[+-]\d\d:\d\d/) || dateStr.endsWith("Z");

  // When present, simply return and move on with life.
  if (hasOffset) return dateStr;

  // When missing, add the default UTC offset.
  return `${dateStr}+00:00`;
};

/**
 * Returns: 9:00 – 18:00
 */
export const useFormatTimeframe = (): ((args: { start: string | null; end: string | null }) => string | null) => {
  const formatDateTime = useFormatDate("hour-minute");

  return (args: { start: string | null; end: string | null }): string | null => {
    if (!args.start || !args.end) return null;
    const start = formatDateTime(sanitizeDate(args.start));
    const end = formatDateTime(sanitizeDate(args.end));
    return `${start} – ${end}`;
  };
};
