import { DayRouteActivityForUpdate } from "@brenger/api-client";
import { Button, Message } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import insert from "flatmap-fns/insert";
import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { useForm, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, DayRouteParams, Routes } from "../../../utils";

import { useParams } from "react-router-dom";
import { Content, PageHead, Section } from "../../../layout";
import {
  addIndex,
  calculateIndexForActivity,
  convertStopFormDataIntoDayRouteActivity,
  formatActivities,
} from "../utils";
import {
  DayRouteActivityForm,
  DayRouteActivityFormType,
  getDefaultDatetimePeriodForDayRouteActivity,
} from "./DayRouteActivityForm";

const getInitialState = (date: string): DayRouteActivityFormType => {
  const { start, end } = getDefaultDatetimePeriodForDayRouteActivity(date);

  return {
    type: "custom_delivery",
    search: "",
    place: undefined,
    // 5 minutes in seconds
    service_time_seconds: 60 * 5,
    start_time: start.toISOString(),
    end_time: end.toISOString(),
    index: 0,
    capacity_m3: 5,
  };
};

export const DayRouteAddDelivery: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<DayRouteParams>();

  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );

  const activities = dayRoute.data?.day_route_activities || [];

  const form = useForm({
    initialState: getInitialState(params.date),
    validators: {
      place: (place) => !place,
      start_time: (start_time) => !start_time,
      end_time: (end_time) => !end_time,
      capacity_m3: (capacity_m3) => capacity_m3 === undefined || capacity_m3 <= 0 || capacity_m3 > 10,
    },
  });

  const onSuccess = (): void => {
    window.location.assign(Routes.dayroutes.details(params));
  };

  const updateDayRoute = useMutation(coreClient.dayRoutes.update, { onSuccess });

  const onSubmitPickup = (): void => {
    const newActivity = convertStopFormDataIntoDayRouteActivity(form);
    const calculatedIndex = calculateIndexForActivity({ newActivity, activities });
    const selectedIndex = form.data.index?.value;
    const index = selectedIndex ?? calculatedIndex;

    const updatedActivities =
      activities.length > 0
        ? activities
            .flatMap(insert<DayRouteActivityForUpdate>(newActivity, index))
            .flatMap(formatActivities())
            .map(addIndex())
        : [newActivity].flatMap(formatActivities()).map(addIndex());

    updateDayRoute.mutate({
      routeId: getIdFromIri(dayRoute.data) || "",
      dayRoute: {
        day_route_activities: updatedActivities,
      },
    });
  };

  return (
    <Content
      header={
        <PageHead
          sectionType="split-details"
          backUrl={Routes.dayroutes.details(params)}
          title={t((d) => d.day_route.create_stop.submit)}
        />
      }
    >
      <Section type="split-details" isTop={true}>
        <DayRouteActivityForm activities={activities} form={form} />
        {updateDayRoute.isError && (
          <Message className={cn("mt-4")} type="error">
            {(updateDayRoute.error as Error)?.message}
          </Message>
        )}
        <Button
          className={cn("w-full mt-4")}
          buttonType="secondary"
          disabled={form.hasErrors}
          onClick={onSubmitPickup}
          loading={updateDayRoute.isLoading}
        >
          {t((d) => d.day_route.create_stop.submit)}
        </Button>
      </Section>
    </Content>
  );
};
