import * as React from "react";
import {} from "@brenger/react";

import { useTranslation } from "../../hooks";

interface Props {
  /**
   * Total number of items.
   */
  totalItems?: number;
  /**
   * Where does the current range start.
   */
  currentRangeStart: number;
  /**
   * Where does the current range end.
   */
  currentRangeEnd: number;
}

export const ListSummary: React.FC<Props> = ({ totalItems = 0, currentRangeStart, currentRangeEnd }) => {
  const { t } = useTranslation();
  // Nothing to show when the list has no results
  if (!totalItems) {
    return null;
  }

  return (
    <div>
      {t((d, withTemplate) => {
        return withTemplate(d.pagination.summary.showing, {
          current_range_start: currentRangeStart,
          current_range_end: currentRangeEnd,
          total: totalItems,
        });
      })}
    </div>
  );
};
