import * as React from "react";
import { Browser, OpenOptions } from "@capacitor/browser";

interface UseBrowser {
  open: (options: OpenOptions) => Promise<void>;
}

export function useBrowser(): UseBrowser {
  const open = React.useCallback(
    async (options: OpenOptions) => {
      await Browser.open(options);
    },
    [Browser]
  );

  return {
    open,
  };
}
