import cn from "classnames";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  value: string;
  options: {
    label: string;
    value: string;
    path: string;
    onClick?(): void;
  }[];
}

export const TransportsListSwitch: React.FC<Props> = ({ value, options }) => {
  const activeIndex = options.findIndex((o) => o.value === value) || 0;
  return (
    <div className={cn("flex", "relative")}>
      {options.map((option, i) => {
        return (
          <Link
            className={cn("w-1/2", "text-center", "py-[.65rem]", "br-font-bold")}
            key={i}
            to={option.path}
            onClick={option.onClick}
          >
            <span
              className={cn({
                "text-blue-600": value === option.value,
                "text-gray-600": value !== option.value,
              })}
            >
              {option.label}
            </span>
          </Link>
        );
      })}
      <div
        className={cn("w-1/2", "absolute", "border-b", "border-blue-600", "transition-all", {
          "left-0": activeIndex === 0,
          "left-1/2": activeIndex === 1,
        })}
        style={{ bottom: "-1px" }}
      ></div>
    </div>
  );
};
