"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatMeters = exports.formatKilometers = exports.getMilesFromKilometers = exports.KILOMETERS_PER_MILE = exports.formatUnit = void 0;
var locale_1 = require("./locale");
var formatUnit = function (_a) {
    var amount = _a.amount, locale = _a.locale, unit = _a.unit, _b = _a.unitDisplay, unitDisplay = _b === void 0 ? 'short' : _b, _c = _a.maximumFractionDigits, maximumFractionDigits = _c === void 0 ? 1 : _c;
    // Why sanitize? Bc sometimes core still formats locale `nl_NL` using an underscore instead of hypen.
    var sanitizedLocale = locale_1.sanitizeLocale(locale);
    /**
     * ALERT - SAFARI ISSUE is the reason for the try/catch:
     * https://stackoverflow.com/questions/60566942/why-doesnt-intl-numberformat-work-with-units-in-safari-and-firefox
     */
    try {
        var formatConfig = { style: 'unit', unit: unit, unitDisplay: unitDisplay, maximumFractionDigits: maximumFractionDigits };
        var format = new Intl.NumberFormat(sanitizedLocale, formatConfig).format;
        return format(amount);
    }
    catch (err) {
        var formatConfig = { style: 'decimal', unit: unit, unitDisplay: unitDisplay, maximumFractionDigits: maximumFractionDigits };
        var format = new Intl.NumberFormat(sanitizedLocale, formatConfig).format;
        return format(amount);
    }
};
exports.formatUnit = formatUnit;
exports.KILOMETERS_PER_MILE = 1.609;
var getMilesFromKilometers = function (kilometers) {
    return kilometers / exports.KILOMETERS_PER_MILE;
};
exports.getMilesFromKilometers = getMilesFromKilometers;
var formatKilometers = function (_a) {
    var kilometers = _a.kilometers, _b = _a.convertToMiles, convertToMiles = _b === void 0 ? false : _b, rest = __rest(_a, ["kilometers", "convertToMiles"]);
    var unit = convertToMiles ? 'mile' : 'kilometer';
    var amount = unit === 'kilometer' ? kilometers : exports.getMilesFromKilometers(kilometers);
    return exports.formatUnit(__assign({ amount: amount, unit: unit }, rest));
};
exports.formatKilometers = formatKilometers;
var formatMeters = function (_a) {
    var meters = _a.meters, rest = __rest(_a, ["meters"]);
    return exports.formatUnit(__assign({ amount: meters, unit: 'meter', maximumFractionDigits: 2 }, rest));
};
exports.formatMeters = formatMeters;
