import { CancelTransportJobAccountLinkError, CancelTransportJobAccountLinkErrorDescription } from "@brenger/api-client";
import { Button, Message, Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { useCache, useConfirmModal, useTranslation } from "../../hooks";
import { coreClient } from "../../utils";

interface Props {
  isBundled?: boolean;
  isDirectClaimable?: boolean;
  tjalId: string;
  successPath?: string;
  onSuccess?(): void;
}

export const CancelOrRevoke: React.FC<Props> = ({
  tjalId,
  isBundled,
  isDirectClaimable,
  successPath,
  onSuccess: onSuccessCallback,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const cache = useCache();
  const onSuccess = (): void => {
    // Success callback for cancel TJAL mutation
    // 1. Reset the cache
    cache.clear();
    // 2. Move to the success path
    if (successPath) history.push(successPath);
    onSuccessCallback?.();
  };
  const cancelTjal = useMutation(coreClient.transportJobAccountLinks.cancel, { onSuccess });

  // Cast the core error to the correct type (using type from api-client)
  const cancelTjalError = (cancelTjal.error as CancelTransportJobAccountLinkError)
    ?.message as CancelTransportJobAccountLinkErrorDescription;

  const [ConfirmCancelModal, activateConfirmCancelModal] = useConfirmModal(() => {
    cancelTjal.reset();
    cancelTjal.mutate({ id: tjalId });
  });

  const revokeTjal = useMutation(coreClient.transportJobAccountLinks.revoke, { onSuccess });

  // Cast the core error to the correct type (using type from api-client)
  // @TODO: CREATE A MORE SPECIFIC CANCEL ERROR OBJECT IN API CLIENT
  const revokeTjalError = (revokeTjal.error as CancelTransportJobAccountLinkError)
    ?.message as CancelTransportJobAccountLinkErrorDescription;

  const [ConfirmRevokeModal, activateConfirmRevokeModal] = useConfirmModal(() => {
    revokeTjal.reset();
    revokeTjal.mutate({ id: tjalId });
  });

  const triggerModal = (): void => {
    if (isBundled) {
      activateConfirmCancelModal({
        title: t((d) => d.planning.messages.cancel_tjal_bundle_cta),
        message: t((d) => d.planning.messages.cancel_tjal_bundle),
        okButtonTitle: t((d) => d.actions.accept),
        cancelButtonTitle: t((d) => d.actions.close),
      });
      return;
    }
    if (isDirectClaimable) {
      activateConfirmCancelModal({
        title: t((d) => d.planning.messages.cancel_tjal_cta),
        message: t((d) => d.planning.messages.cancel_tjal),
        okButtonTitle: t((d) => d.actions.accept),
        cancelButtonTitle: t((d) => d.actions.close),
      });
      return;
    }
    activateConfirmRevokeModal({
      title: t((d) => d.planning.messages.withdraw_tjal_cta),
      message: t((d) => d.planning.messages.withdraw_tjal),
      okButtonTitle: t((d) => d.actions.accept),
      cancelButtonTitle: t((d) => d.actions.close),
    });
  };

  if (typeof isBundled === "undefined" || typeof isDirectClaimable === "undefined") {
    return null;
  }
  return (
    <div>
      <div>
        <b>{t((d) => d.stop_edit.cancelation.heading)}</b>
      </div>
      <div className={cn("mb-2")}>{t((d) => d.stop_edit.cancelation.intro)}</div>
      <div className={cn("border-t", "border-gray-200", "flex", "justify-between", "py-2")}>
        <b>{t((d) => d.stop_edit.cancelation["48_prior"])}</b>
        <b>&euro; 0,-</b>
      </div>
      <div className={cn("border-t", "border-gray-200", "flex", "justify-between", "py-2")}>
        <b>{t((d) => d.stop_edit.cancelation["48_24_prior"])}</b>
        <b>&euro; 25,-</b>
      </div>
      <div className={cn("border-t", "border-gray-200", "flex", "justify-between", "py-2")}>
        <b>{t((d) => d.stop_edit.cancelation["24_prior"])}</b>
        <b>&euro; 50,-</b>
      </div>
      <Spacer h={1} />
      {cancelTjalError && (
        <Message className={cn("my-4")} type="error">
          {t((d) => d.planning.errors[cancelTjalError] || cancelTjalError)}
        </Message>
      )}
      {revokeTjalError && (
        <Message className={cn("my-4")} type="error">
          {t((d) => d.planning.errors[revokeTjalError] || revokeTjalError)}
        </Message>
      )}
      <Button buttonType={"warning"} onClick={triggerModal}>
        {t((d) => (isBundled ? d.stop_edit.cancelation.cta_bundle : d.stop_edit.cancelation.cta_job))}
      </Button>
      <ConfirmRevokeModal buttonType="warning" />
      <ConfirmCancelModal buttonType="warning" />
    </div>
  );
};
