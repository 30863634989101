import { TransportJobAccountLinkCreateErrorDescription } from "@brenger/api-client";

import { useTranslation } from ".";

/**
 * The below types / map are to map errors from core to something more sane that we can
 * use to access translation strings.
 * The real, long-term fix should be done on core, and the core's hydra:description
 * should just directly return the snake_cased description seen below/
 */
type TjalCreateErrorTransKey =
  | "already_has_accepted_offer"
  | "max_number_of_offers_reached"
  | "commitment_datetime_period_not_between_available_timeframe";

export const tjalCreateErrorTranMap: {
  [key in TransportJobAccountLinkCreateErrorDescription]?: TjalCreateErrorTransKey;
} = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Transport job already has an accepted offer.": "already_has_accepted_offer",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Max number of offers reached.": "max_number_of_offers_reached",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  commitment_datetime_period_not_between_available_timeframe:
    "commitment_datetime_period_not_between_available_timeframe",
};

export const useCreateLinkError = (error: Error | null): string | null => {
  const { t } = useTranslation();

  if (!error) return null;

  // Parse out the raw error from the hydra description
  const createLinkError = error.message;

  // Then, check the trans map if we have a key for it
  const createLinkErrorTransKey =
    tjalCreateErrorTranMap[createLinkError as TransportJobAccountLinkCreateErrorDescription];

  // If we have a key, use it and return the translation directly.
  if (createLinkErrorTransKey) {
    return t((d) => d.search.claim_job_errors[createLinkErrorTransKey]);
  }

  // otherwise return raw parsed message!
  return createLinkError;
};
