import * as React from "react";
import cn from "classnames";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import { DateTimePeriod, ItemSet } from "@brenger/api-client";
import { Pill, IconCheck, IconCancel, IconUpdate } from "@brenger/react";
import { StopType } from "../../types";
import { useFormatDate } from "../../hooks";

interface Props {
  stopType: StopType;
  itemSets: ItemSet[];
  committedDtps?: DateTimePeriod[] | null;
}

/**
 * This component renders the time at which the driver confirmed a stop.
 * Depending on whether the confirmation time is inside/outside the bounds
 * of the stop's date time period, the pill will render a different color/icon.
 */
export const StopConfirmedAtPill: React.FC<Props> = (props) => {
  const formatMonthDay = useFormatDate("month-day");
  const formatHourMinute = useFormatDate("hour-minute");

  if (!props.committedDtps) return null;

  const typedItemSets = props.itemSets as ItemSet[];

  const isForgiven = typedItemSets.some((d) => d.forgiven);

  let timestamps = null;

  if (props.stopType === "Pickup") {
    timestamps = typedItemSets.map((d) => d.driver_pickup_confirmed_at);
  }

  if (props.stopType === "Delivery") {
    timestamps = typedItemSets.map((d) => d.driver_delivery_confirmed_at);
  }

  const areAllItemSetsConfirmed = timestamps?.every(Boolean);
  // Grab the "latest" confirmation time and make it representative of the whole stop.
  const latestConfirmationTimestamp = timestamps?.filter(Boolean).sort().pop();

  if (!areAllItemSetsConfirmed || !latestConfirmationTimestamp) return null;

  const confirmationDate = new Date(latestConfirmationTimestamp);

  // Check if the stop was confirmed on at least one of the committed DTPs
  const wasConfirmedSameDay = props.committedDtps.some((dtp) => {
    return isSameDay(new Date(dtp.start), confirmationDate);
  });
  const formattedTimeStamp = wasConfirmedSameDay
    ? formatHourMinute(latestConfirmationTimestamp)
    : formatMonthDay(latestConfirmationTimestamp);

  /**
   * PREFERRED WAY
   * Reading if the stop was confirmed successfully
   */
  const succesFullyConfirmed =
    props.stopType === "Pickup"
      ? typedItemSets?.[0]?.driver_pickup_successfully_confirmed
      : typedItemSets?.[0]?.driver_delivery_successfully_confirmed;
  if (
    succesFullyConfirmed?.value ||
    (succesFullyConfirmed?.value === false && ["forgiven", "perfect_review"].includes(succesFullyConfirmed.reason))
  ) {
    return (
      <Pill type={succesFullyConfirmed.reason === "forgiven" ? "orange" : "green"}>
        <span className={cn("flex", "items-center")}>
          <IconUpdate className={cn("h-4", "w-4", "mr-1")} />
          <span>{formattedTimeStamp}</span>
        </span>
      </Pill>
    );
  }
  if (succesFullyConfirmed?.value === false) {
    return (
      <Pill type="red">
        <span className={cn("flex", "items-center")}>
          <IconCancel className={cn("h-4", "w-4", "mr-1")} />
          <span>{formattedTimeStamp}</span>
        </span>
      </Pill>
    );
  }

  /**
   * LEGACY
   * Checks for printing the right confirmed at pill.
   */
  if (wasConfirmedSameDay === false) {
    // If confirmed on an entirely different day, we show the DATE (not the exact time)
    if (isForgiven) {
      return (
        <Pill type="green">
          <span className={cn("flex", "items-center")}>
            <IconUpdate className={cn("h-4", "w-4", "mr-1")} />
            <span>{formatMonthDay(latestConfirmationTimestamp)}</span>
          </span>
        </Pill>
      );
    }
    // If forgiven, we show a green pill with the date.

    // If not forgiven, it's a red pill with the date.
    return (
      <Pill type="red">
        <span className={cn("flex", "items-center")}>
          <IconCancel className={cn("h-4", "w-4", "mr-1")} />
          <span>{formatMonthDay(latestConfirmationTimestamp)}</span>
        </span>
      </Pill>
    );
  }

  // Check if the stop was confirmed within the time frame of at least one of the committed DTPs
  const wasConfirmedOnTime = props.committedDtps.some((dtp) => {
    const wasConfirmedAfterStart = isAfter(confirmationDate, new Date(dtp.start));
    const wasConfirmedBeforeEnd = isBefore(confirmationDate, new Date(dtp.end));
    return wasConfirmedAfterStart && wasConfirmedBeforeEnd;
  });

  // If forgiven and completed on the correct day, show a green pill with exact time.
  if (isForgiven) {
    return (
      <Pill type="green">
        <span className={cn("flex", "items-center")}>
          <IconUpdate className={cn("h-4", "w-4", "mr-1")} />
          <span>{formatHourMinute(latestConfirmationTimestamp)}</span>
        </span>
      </Pill>
    );
  }

  // If not forgiven and completed on the correct day, determine if GREEN OR ORANGE
  const pillType = wasConfirmedOnTime ? "green" : "orange";

  return (
    <Pill type={pillType}>
      <span className={cn("flex", "items-center")}>
        <IconCheck className={cn("mr-1")} />
        <span>{formatHourMinute(latestConfirmationTimestamp)}</span>
      </span>
    </Pill>
  );
};
