import { IconLoader, Message, MessageType } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { Section, SectionType } from ".";
import "./layout.scss";
import { usePullToRefresh, useRestoreScroll } from "../hooks";

interface Props {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  isLoading?: boolean;
  message?: string | React.ReactNode | null;
  messageType?: MessageType;
  className?: string;
  style?: "white" | "primary";
  messageWrap?: SectionType;
  bordered?: boolean;
  headerClassname?: string;
  /**
   * Should scroll to this position
   */
  scrollCoords?: { left: number; top: number };
  disablePullToRefresh?: boolean;
}

export const Content: React.FC<Props> = ({
  header,
  footer,
  isLoading,
  message,
  messageType = "error",
  className,
  style = "white",
  children,
  messageWrap,
  headerClassname,
  bordered = true,
  scrollCoords,
  disablePullToRefresh = false,
}) => {
  const scrollEl = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!scrollCoords) return;
    scrollEl.current?.scrollTo({ ...scrollCoords, behavior: "smooth" });
  }, [scrollCoords]);

  const { isRefreshing, pullPosition } = usePullToRefresh({
    ref: scrollEl.current,
    onRefresh: async () => window.location.reload(),
    isDisabled: disablePullToRefresh,
  });

  useRestoreScroll({ elId: "content-wrapper" });

  return (
    <div
      className={cn(
        "flex",
        "flex-col",
        "w-full",
        "h-full",
        "p-safe-area",
        {
          "bg-white": style === "white",
          "bg-primary-match-menu": style === "primary",
        },
        className
      )}
    >
      <div
        // See class for more info
        className={cn("status-bar-style", {
          "bg-white": style === "white",
          "bg-blue-600": style === "primary",
        })}
      />
      {header && (
        <div className={cn("grow-0", "empty:hidden", { "py-4 border-b border-b-gray-300": bordered }, headerClassname)}>
          {header}
        </div>
      )}
      <div ref={scrollEl} id="content-wrapper" className="grow max-h-full overflow-auto pb-4 lg:pb-8">
        <div className="relative z-30">
          <div
            style={{
              top: (isRefreshing ? 190 : pullPosition) / 5,
              opacity: isRefreshing || pullPosition > 0 ? 1 : 0,
            }}
            className="bg-white absolute inset-x-1/2 h-8 w-8 -translate-x-1/2 rounded-full p-2 shadow"
          >
            <div
              className={cn("h-full w-full", { "animate-spin": isRefreshing })}
              style={!isRefreshing ? { transform: `rotate(${pullPosition}deg)` } : undefined}
            >
              <IconLoader className={cn("w-4", "h-4", "!animate-none")} />
            </div>
          </div>
        </div>
        <div className="relative z-20">
          {isLoading && (
            <div className={cn("flex", "justify-center", "items-center", "h-[33vh]")}>
              <IconLoader className={cn("w-6", "h-6")} />
            </div>
          )}
          {!isLoading && message && (
            <MessageWrap type={messageWrap}>
              <Message className={cn("mt-4")} type={messageType}>
                {message}
              </Message>
            </MessageWrap>
          )}
          {!isLoading && !message && children}
        </div>
      </div>
      {footer && (
        <div className={cn("grow-0", "empty:hidden", { "py-4 border-t border-t-gray-300": bordered })}>{footer}</div>
      )}
    </div>
  );
};

const MessageWrap: React.FC<{ type?: SectionType }> = ({ type, children }) => {
  if (type)
    return (
      <Section type={type} className="mt-4">
        {children}
      </Section>
    );
  return <>{children}</>;
};
