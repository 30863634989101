import React from "react";
import axios, { CancelTokenSource } from "axios";
import { useQuery } from "@tanstack/react-query";
import { GeoAutocomplete, GeoLocationDetails, RetrieveAutocompleteParams } from "@brenger/api-client";

import { InputTextWithAutocomplete, AutocompleteOption } from "..";
import { CacheKey, StaleTTL, logger, routePlannerClient } from "../../utils";
import { useDebounce, useTranslation } from "../../hooks";

interface Props {
  disabled?: boolean;
  value: string;
  onSelect: (place: GeoLocationDetails) => void;
  onChange: (value: string) => void;
  /**
   * This flag forces geo to try to include the house number at line_1, which is a bit quirky.
   */
  includeHouseNumber: boolean;
}

let source: CancelTokenSource | undefined = undefined;

const retrieveAutocomplete = (params: RetrieveAutocompleteParams): Promise<GeoAutocomplete[]> => {
  if (source !== undefined) source.cancel();
  source = axios.CancelToken.source();
  return routePlannerClient.geo.retrieveAutocomplete({ ...(params || {}), cancelToken: source.token });
};

export const InputTextWithGeoAutocomplete: React.FC<Props> = ({
  value,
  onSelect,
  onChange,
  includeHouseNumber,
  disabled,
}) => {
  const [placeId, setPlaceId] = React.useState<string>();
  const debounced = useDebounce(value, 200);
  const { t } = useTranslation();

  const autoCompleteOptions = useQuery(
    [CacheKey.RETRIEVE_GEO_AUTOCOMPLETE, debounced],
    () =>
      retrieveAutocomplete({
        query: debounced,
        sessionToken: logger.sid,
      }),
    {
      // Do not search for more auto complete options when user has a selected one in state.
      // Do not begin making network requests until user has entered at least three chars
      enabled: !placeId && !!debounced && debounced.length > 2,
      staleTime: StaleTTL.XS,
    }
  );
  const options = value && value.length > 0 ? autoCompleteOptions.data : null;

  const place = useQuery(
    [CacheKey.RETRIEVE_GEO_LOCATION_DETAILS, placeId],
    () =>
      routePlannerClient.geo.retrieveLocationDetails({
        placeId: placeId || "",
        sessionToken: logger.sid,
        includeHouseNumber,
      }),
    {
      enabled: !!placeId,
    }
  );

  // Whenever a new place is fetched, pass result to controlling onSelect method.
  React.useEffect(() => {
    if (placeId && place.data?.label) onSelect(place.data);
  }, [placeId, place.data?.label]);

  const showZeroResultsMessage = !autoCompleteOptions.isLoading && debounced.length > 2;

  return (
    <InputTextWithAutocomplete
      disabled={disabled}
      value={value}
      onChange={(nextValue) => {
        setPlaceId(undefined);
        onChange(nextValue);
      }}
      zeroResultsMessage={showZeroResultsMessage ? t((d) => d.geo_autocomplete.zero_results) : undefined}
      options={options?.map((option, idx) => {
        return (
          <AutocompleteOption
            key={idx}
            onClick={() => {
              // Override the user-provided search string with the selected label
              onChange(option.label);
              // Add the select option to state so we can act on it in the fromPlace look-up query.
              setPlaceId(option.place_id);
            }}
          >
            {option.label}
          </AutocompleteOption>
        );
      })}
    />
  );
};
