import { Card, ExtraSmall, Timeline } from "@brenger/react";
import cn from "classnames";
import * as React from "react";

import { useFormatDate } from "../../../hooks";

interface Props {
  type: "start" | "end";
  time?: string;
}

export const DayRouteStartEndWrapper: React.FC<Props> = (props) => {
  const formatHourMinute = useFormatDate("hour-minute");

  return (
    <Timeline
      isFirst={props.type === "start"}
      isLast={props.type === "end"}
      showPoint={Boolean(!props.time)}
      labelCenter={
        props.time ? <ExtraSmall className={cn("bg-white")}>{formatHourMinute(props.time)}</ExtraSmall> : undefined
      }
    >
      <Card className="!mb-0">{props.children}</Card>
    </Timeline>
  );
};
