import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { useHistory, useParams } from "react-router-dom";
import { JobFunnelEvent, useCache, useClaimJobGate, useTracking, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, logger, NewJobParams, PersistedStorageKey, Routes } from "../../../utils";
import { Content, PageHead } from "../../../layout";
import { ClaimBundledJob } from "./ClaimBundledJob";
import { ClaimJobWithAvailableDates } from "./ClaimJobWithAvailableDates";
import { ClaimJobWithoutAvailableDates } from "./ClaimJobWithoutAvailableDates";

export const NewClaim: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<NewJobParams>();
  const shortId = params.job_id.slice(-6);
  // Fetch the transport job
  const tj = useQuery([CacheKey.RETRIEVE_TRANSPORT_JOB, params.job_id], () =>
    coreClient.transportJobs.retrieve({ id: params.job_id })
  );

  const history = useHistory();
  const cache = useCache();
  const tracking = useTracking();

  /**
   * Success action - to be executed after a job has been claimed, no matter what job type
   */
  const onSuccessFullyClaimed = (): void => {
    // 1. Reset the cache
    cache.clear();

    try {
      const jobEventRaw = sessionStorage.getItem(PersistedStorageKey.BRENGER_LIST_JOB_SCORE);
      // Send tracking event
      if (jobEventRaw) {
        // retrieve list position from storage
        const jobEvent: JobFunnelEvent = JSON.parse(jobEventRaw);
        // Check if same id
        if (jobEvent.id === params.job_id) {
          // Send same details, but update action and timestamp
          tracking.sendEvent({
            ...jobEvent,
            // In UTC
            timeStamp: new Date(new Date().toUTCString()).toISOString(),
            action: "claim",
          });
        }
      }
    } catch (e) {
      logger.dev(e);
    }
    // 3. send them of to planning
    history.push(Routes.transports.home({ type: "planning", includeNps: true }));
  };

  // Use this gate to determine whether user can the job.
  const GatedUI = useClaimJobGate(tj.data);
  // Handle all loading and error states
  if (!tj.data || tj.isError || GatedUI) {
    const errorFromCore = tj.error;
    let error = errorFromCore ? (errorFromCore as Error)?.message || "Something went wrong" : undefined;
    if (tj.data?.state === "unbundled") error = t((d) => d.app.errors.general);
    return (
      <Content
        header={<PageHead sectionType="split-details" backUrl={Routes.new.job(params)} title={`Claim: ${shortId}`} />}
        messageWrap="split-details"
        message={error}
        isLoading={tj.isLoading}
      >
        {GatedUI}
      </Content>
    );
  }

  if (tj.data.bundled) {
    return <ClaimBundledJob tj={tj.data} onSuccessFullyClaimed={onSuccessFullyClaimed} />;
  }

  // If the job is both DIRECTLY_CLAIMABLE then the driver can directly claim it by
  // selecting from pre-ordained DTPs in the claim form.
  if (tj.data.directly_claimable) {
    return (
      <ClaimJobWithAvailableDates
        tj={tj.data}
        claimCta={t((d) => d.transport_job.actions.claim_job)}
        onSuccessFullyClaimed={onSuccessFullyClaimed}
      />
    );
  }

  // Check if both pickup and delivery has available DTPs.
  const hasAvailableDtps =
    tj.data.pickups.some((s) => s.available_datetime_periods.length > 0) &&
    tj.data.deliveries.some((s) => s.available_datetime_periods.length > 0);

  // Non-guaranteed jobs can also have DTPs. The only meaningful difference between this claim form and
  // that of a directly claimable job is the CTA copy ("claim" vs "make an offer").
  if (hasAvailableDtps) {
    return (
      <ClaimJobWithAvailableDates
        tj={tj.data}
        claimCta={t((d) => d.transport_job.actions.make_offer)}
        onSuccessFullyClaimed={onSuccessFullyClaimed}
      />
    );
  }

  // In all other cases, show a form that allows a driver to freely select datetime periods.
  return <ClaimJobWithoutAvailableDates tj={tj.data} onSuccessFullyClaimed={onSuccessFullyClaimed} />;
};
