import * as React from "react";
import cn from "classnames";
import { Address } from "@brenger/api-client";
import {} from "@brenger/react";

import { JobPresentation } from "../../utils";
import { useTranslation, useUserGeneratedContent } from "../../hooks";
import { TextPlaceholder, MetaItems } from "..";

interface Props {
  address: Address | null;
  /**
   * Read from stop details
   */
  instructions?: string;
  /**
   * This prop dictates the degree to which the address is revealed to the user.
   * Search shows the least details. Planning shows the full address, of course.
   */
  presentation: JobPresentation;
}

export const serializeAddress = (address: Partial<Address>): string => {
  return [
    address.line1,
    address.line2,
    address.postal_code,
    address.locality,
    address.administrative_area,
    address.country_code,
  ]
    .filter(Boolean)
    .join(", ");
};

export const AddressDetails: React.FC<Props> = ({ address, instructions, presentation }) => {
  const { t } = useTranslation();
  const translatedInstructions = useUserGeneratedContent(instructions);

  if (!address) {
    return (
      <div className={cn("flex", "flex-col")}>
        <TextPlaceholder />
        {presentation === "planning" && <TextPlaceholder />}
      </div>
    );
  }

  const {
    line1,
    line2,
    postal_code: postalCode,
    locality,
    administrative_area: adminArea,
    country_code: countryCode,
  } = address;

  // Show full address details during planning view only.
  if (presentation === "planning") {
    return (
      <div>
        <div>
          {line1 && `${line1},`} {line2 && `${line2},`} {postalCode && `${postalCode},`} {locality && `${locality}`}
        </div>
        <div>
          {adminArea && `${adminArea},`} {countryCode}
        </div>
        {translatedInstructions.text && (
          <MetaItems
            className={cn("mt-1")}
            items={[{ name: t((d) => d.search.labels.instructions), value: translatedInstructions.text }]}
          />
        )}
      </div>
    );
  }

  // Show full address details during planning view only.
  if (presentation === "dayroute-details" || presentation === "planning-stop-edit") {
    return (
      <div className={cn("text-blue-500")}>
        {line1 && `${line1},`} {line2 && `${line2},`} {postalCode && `${postalCode},`} {locality && `${locality}`}
      </div>
    );
  }

  return (
    <>
      <div className={cn("flex")}>
        {locality && `${locality},`} {adminArea && `${adminArea},`} {countryCode}
      </div>
      {translatedInstructions.text && (
        <MetaItems
          className={cn("mt-1")}
          items={[{ name: t((d) => d.search.labels.instructions), value: translatedInstructions.text }]}
        />
      )}
    </>
  );
};
