import { Locale, LocaleNormalizer } from "@brenger/api-client";
import { Config } from "../config";

// NOTE: use es6 MAP here so that we can perserve type information when accessing keys.
export const SUPPORTED_LOCALE_MAP = new Map<Locale, string>([
  ["en-NL", "🇪🇺 EN"],
  ["de-DE", "🇩🇪 DE"],
  ["nl-BE", "🇧🇪 NL"],
  ["nl-NL", "🇳🇱 NL"],
]);

export const SUPPORTED_LOCALE_CUSTOMER_MAP = new Map<Locale, string>([
  ["en-NL", "en-nl"],
  ["de-DE", "de-de"],
  ["nl-BE", "nl-be"],
  ["nl-NL", "nl"],
]);

export const localeNormalizer = new LocaleNormalizer({
  defaultLocale: "en-NL",
  validLocales: [...SUPPORTED_LOCALE_MAP.keys()],
  defaultTimeZone: "Europe/Amsterdam",
});

export const getCustomerAppUrl = (locale: Locale): string => {
  const customerAppLocale = SUPPORTED_LOCALE_CUSTOMER_MAP.get(locale) || "en-nl";
  return `${Config.CUSTOMER_URL}/${customerAppLocale}`;
};
