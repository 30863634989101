import React from "react";
import { useAuth } from "./useAuth";
import { useBrowser } from "./useBrowser";
import { useTranslation } from "./useTranslation";

/**
 * This hook makes it easy to send users off to the new ticket url with prefilled user and job details
 */
interface UseFeedbackUrl {
  open(e: React.FormEvent): void;
}
interface UseFeedbackUrlParams {
  jobId?: string;
}

export const useFeedbackUrl = (params?: UseFeedbackUrlParams): UseFeedbackUrl => {
  const browser = useBrowser();
  const { fullName, user } = useAuth();
  const {
    i18n: { locale },
  } = useTranslation();

  // construct url
  const url = React.useMemo(() => {
    const mappedLocale =
      {
        "nl-NL": "nl",
        "de-DE": "de",
        // We only care about NL and DE, with an "undefined" to fallback EN.
      }[locale as "nl-NL" | "de-DE"] || "en";
    const urlObj = new URL(`https://support-newcourier.brenger.nl/${mappedLocale}/support/tickets/new`);
    // Add user details
    urlObj.searchParams.append("helpdesk_ticket[email]", user?.email || "");
    urlObj.searchParams.append("helpdesk_ticket[name]", fullName);
    // Add optional job id
    if (params?.jobId) urlObj.searchParams.append("helpdesk_ticket[custom_field][cf_job_id_914865]", params.jobId);
    // return the url as string
    return urlObj.toString();
  }, [fullName, user?.email, params?.jobId, locale]);

  const open = (e: React.FormEvent): void => {
    // prevent default event, if any
    e?.preventDefault?.();
    // open the url in browser or in-app browser
    browser.open({ url });
  };

  return {
    open,
  };
};
