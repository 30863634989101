import * as React from "react";
import ReactDOM from "react-dom";
import cn from "classnames";

interface Props {
  isActive: boolean;
}

export const Dialog: React.FC<Props> = ({ isActive, children }) => {
  React.useEffect(() => {
    const body = document.querySelector("body") as HTMLBodyElement;
    const dialog = document.getElementById("dialog-root");
    if (isActive) {
      body.classList.add("overflow-hidden");
    } else {
      if (!dialog?.hasChildNodes()) {
        body.classList.remove("overflow-hidden");
      }
    }

    return (): void => {
      if (!dialog?.hasChildNodes()) {
        return body.classList.remove("overflow-hidden");
      }
    };
  }, [isActive]);

  if (isActive) {
    const dialogRoot = document.querySelector("#dialog-root") as HTMLDivElement;

    return ReactDOM.createPortal(
      // See css rules for this id, as there are some important mobile considerations stated there.
      <div className={cn("z-30", "dialog-container")} role="dialog" aria-modal="true">
        {children}
      </div>,
      dialogRoot
    );
  }

  return null;
};
