import * as React from "react";
import cn from "classnames";

import { IconCaretLeft } from "@brenger/react";

interface Props {
  moveUp?(): void;
  moveDown?(): void;
}
export const DayRouteStopEditCarets: React.FC<Props> = ({ moveUp, moveDown }) => {
  return (
    <div className={cn("flex", "flex-col", "absolute", "right-0", "top-0")}>
      <button
        className={cn("disabled:opacity-25")}
        onClick={() => {
          if (moveUp) moveUp();
        }}
        disabled={!moveUp}
      >
        <IconCaretLeft className={cn("transform", "rotate-90")} />
      </button>
      <button
        className={cn("disabled:opacity-25")}
        onClick={() => {
          if (moveDown) moveDown();
        }}
        disabled={!moveDown}
      >
        <IconCaretLeft className={cn("transform", "-rotate-90")} />
      </button>
    </div>
  );
};
