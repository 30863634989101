import { Delivery, DriverConfirmStop, Pickup } from "@brenger/api-client";
import { Button, IconLoader } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { Config } from "../../config";
import { useAuth, useDeviceInfo, useGeolocationMethods, useNativePlatform, useTranslation } from "../../hooks";
import { CacheKey, coreClient, logger, routePlannerClient } from "../../utils";
import { ConfirmStopPunctualityMessage } from "./ConfirmStopPunctualityMessage";

export type ConfirmStopLocation = Pick<
  DriverConfirmStop,
  "lat" | "lng" | "proximity" | "device_platform" | "location_service"
>;
interface Props {
  stop: Pickup | Delivery;
  isConfirmDisabled: boolean;
  isConfirmLoading: boolean;
  confirmStop(args: ConfirmStopLocation): void;
}

export const ConfirmStopButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const isNativePlatform = useNativePlatform();
  const geolocation = useGeolocationMethods();
  const hasLocationService = isNativePlatform && geolocation.hasPermission;

  const deviceInfo = useDeviceInfo();
  const auth = useAuth();

  /**
   * Retrieve proximity, only when coordinates are set
   */
  const stopId = getIdFromIri(props.stop["@id"]) || "";

  const locationQuery = useQuery(
    [CacheKey.USER_LOCATIONS, auth.userId, stopId],
    () => geolocation.getCurrentPosition(),
    {
      enabled: hasLocationService,
    }
  );
  const location = locationQuery.data;

  React.useEffect(() => {
    /**
     * FIXME/HACK
     * For some reason on mount does not reliably fire off a location request
     * Sometimes the location stays empty, causing not able to determine proximity, resulting in no data.
     * With this slight delay the location is fetched whenever we mount this stop confirmation button
     */
    if (!locationQuery.isLoading && !location && hasLocationService) {
      setTimeout(() => locationQuery.refetch(), 500);
    }
  }, [locationQuery.isLoading, location, hasLocationService]);

  const proximity = useQuery(
    [CacheKey.RETRIEVE_GEO_STOP_PROXIMITY, stopId, JSON.stringify(location?.coords)],
    () =>
      routePlannerClient.geo.retrieveProximityStop({
        stopId,
        lat: location?.coords.latitude as number,
        lng: location?.coords.longitude as number,
      }),
    {
      enabled: !geolocation.isGetLocationLoading && !!(location?.coords.latitude && location?.coords.longitude),
    }
  );
  // "OFF" means the proximity service is switched off, should be handled as positive result
  const isProximate = proximity?.data?.valid === "yes" || proximity?.data?.valid === "off";

  const logConfirmError = (error: string): void => {
    logger.error(`Confirm location error: ${error}`, {
      auth,
      deviceInfo,
    });
  };

  /**
   * Setup payload for confirmation rules and confirm
   */
  const defaultProximity = hasLocationService ? "unknown" : "no_data";

  const confirmStopPayload: ConfirmStopLocation = {
    proximity: proximity?.data?.valid || defaultProximity,
    lat: location?.coords?.latitude,
    lng: location?.coords?.longitude,
    location_service: hasLocationService,
    device_platform: deviceInfo?.platform || "unknown",
  };

  /**
   * We are loading
   * - when we have location service, AND
   * - when coordinates is loading OR
   * - when proximity is loading
   */
  const loadingLocationData = hasLocationService && (geolocation.isGetLocationLoading || proximity.isFetching);

  const confirmationRules = useQuery(
    [CacheKey.RETRIEVE_CONFIRMATION_RULES, stopId, JSON.stringify(confirmStopPayload)],
    () =>
      coreClient.stops.retrieveConfirmationRules({
        id: stopId,
        ...confirmStopPayload,
      }),
    {
      enabled: !loadingLocationData,
    }
  );

  if (loadingLocationData || confirmationRules.isLoading) {
    return (
      <>
        <div className={cn("mt-4", "flex", "justify-center", "items-center")}>
          <IconLoader className={cn("w-6", "h-6")} />
        </div>
      </>
    );
  }

  return (
    <>
      {["staging", "development"].includes(Config.NODE_ENV || "") && (
        <details>
          <summary>Debug location payload</summary>
          <div>{JSON.stringify(confirmStopPayload)}</div>
          {"Core confirmation rules:"}
          <div>{JSON.stringify(confirmationRules.data)}</div>
          {"Proximity:"}
          <div>{JSON.stringify(proximity.data)}</div>
          {"Location query:"}
          <div>{JSON.stringify(locationQuery.data)}</div>
        </details>
      )}
      <ConfirmStopPunctualityMessage
        confirmationRules={confirmationRules.data}
        hasLocationService={hasLocationService}
      />
      {/* When not proximate, but location service is switched on, offer a retry action  */}
      {!isProximate && hasLocationService && (
        <div className={cn("my-4")}>
          <Button
            disabled={props.isConfirmLoading || geolocation.isGetLocationLoading}
            className={cn("w-full")}
            onClick={() => locationQuery.refetch()}
          >
            {t((d) => d.punctuality_message.redetermine_location_cta)}
          </Button>
        </div>
      )}
      <Button
        disabled={props.isConfirmDisabled}
        buttonType="secondary"
        className={cn("w-full")}
        loading={props.isConfirmLoading || geolocation.isGetLocationLoading}
        onClick={() => {
          if (confirmStopPayload.proximity === "unknown" && confirmStopPayload.location_service) {
            logConfirmError('Proximity "unknown" with location service');
          }
          props.confirmStop(confirmStopPayload);
        }}
      >
        {t(
          (d) => d.planning.labels[props.stop["@type"] === "Pickup" ? "confirm_stop_pickup" : "confirm_stop_delivery"]
        )}
      </Button>
    </>
  );
};
