import { useState, useEffect } from "react";
import { App, AppInfo } from "@capacitor/app";

import { useNativePlatform } from ".";
import { tryToCatch } from "../utils";

export const useAppInfo = (): AppInfo | undefined => {
  const isNative = useNativePlatform();
  const [info, setInfo] = useState<AppInfo>();

  useEffect(() => {
    if (!isNative) return;

    const getInfo = async (): Promise<void> => {
      const data = await App.getInfo();
      setInfo(data);
    };

    tryToCatch(getInfo, "App.getInfo failed");
  }, [isNative, App, setInfo]);

  return info;
};
