import cn from "classnames";
import React from "react";
import { SheetModal, SheetModalProps } from ".";
import "./sheetModal.scss";

export interface ActionSheetModalOption {
  title: string;
  onClick(): void;
}

interface Props extends SheetModalProps {
  header?: string;
  options?: ActionSheetModalOption[];
}

export const ActionSheetModal: React.FC<Props> = ({ options, ...props }) => {
  return (
    <SheetModal {...props}>
      {(options || []).map((o, i) => (
        <button
          onClick={() => {
            props.close?.();
            o.onClick();
          }}
          key={i}
          type="button"
          className={cn("p-4", "w-full", { "mt-2 border-t": i !== 0 })}
        >
          {o.title}
        </button>
      ))}
    </SheetModal>
  );
};
