import { Card as CardRaw, H4 } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { Link, useParams } from "react-router-dom";

import { Grid } from "../../../components";
import { useTranslation } from "../../../hooks";
import { DayRouteAddParams, Routes } from "../../../utils";
import { Content, PageHead, Section } from "../../../layout";
import { DayRouteAddDelivery } from "./DayRouteAddDelivery";
import { DayRouteAddJob } from "./DayRouteAddJob";
import { DayRouteAddOther } from "./DayRouteAddOther";
import { DayRouteAddPickup } from "./DayRouteAddPickup";

const Card: React.FC<{ mainText: string; subText: string }> = (props) => {
  return (
    <CardRaw>
      <H4>{props.mainText}</H4>
      <div>{props.subText}</div>
    </CardRaw>
  );
};

export const DayRouteAdd: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<DayRouteAddParams>();

  switch (params.type) {
    case "job":
      return <DayRouteAddJob />;
    case "pickup":
      return <DayRouteAddPickup />;
    case "delivery":
      return <DayRouteAddDelivery />;
    case "other":
      return <DayRouteAddOther />;
  }

  return (
    <Content
      header={
        <PageHead
          sectionType="split-details"
          backUrl={Routes.dayroutes.details(params)}
          title={t((d) => d.day_route.create_stop.prompt)}
        />
      }
    >
      <Section type="split-details" isTop={true}>
        <Grid cols={{ lg: 2 }} gap={4} className={cn("mt-4")}>
          <Link to={Routes.dayroutes.add({ ...params, type: "job" })}>
            <Card
              mainText={t((d) => d.transport_job.from_a_to_b)}
              subText={t((d) => d.transport_job.from_a_to_b_desc)}
            />
          </Link>
          <Link to={Routes.dayroutes.add({ ...params, type: "pickup" })}>
            <Card
              mainText={t((d) => d.transport_job.pickup_stop)}
              subText={t((d) => d.transport_job.pickup_stop_desc)}
            />
          </Link>
          <Link to={Routes.dayroutes.add({ ...params, type: "delivery" })}>
            <Card
              mainText={t((d) => d.transport_job.delivery_stop)}
              subText={t((d) => d.transport_job.delivery_stop_desc)}
            />
          </Link>
          <Link to={Routes.dayroutes.add({ ...params, type: "other" })}>
            <Card
              mainText={t((d) => d.day_route.create_stop.other_stop)}
              subText={t((d) => d.day_route.create_stop.other_stop_desc)}
            />
          </Link>
        </Grid>
      </Section>
    </Content>
  );
};
