import { IconLoader } from "@brenger/react";
import { App as CapApp, URLOpenListenerEvent } from "@capacitor/app";
import cn from "classnames";
import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { getNewAppRedirect } from ".";
import { ProtectedRoute } from "./components";
import { useAuth } from "./hooks";
import { NewLayout } from "./layout";
import { DayRouteHome, Login, Logout, New, Support, Transports } from "./pages";
import { AppInitGate } from "./pages/appinitgate";
import { AppTour } from "./pages/apptour";
import { NotAllowed } from "./pages/auth/NotAllowed";
import { More } from "./pages/more";
import { Routes } from "./utils";

export const App: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();

  /**ß
   * DEEP LINKING:
   * This effect listens for deep links and redirects the user to the appropriate page.
   * Important to hook this in as early as possible (so don't wait for logged in state etc).
   */
  React.useEffect(() => {
    CapApp.addListener("appUrlOpen", (data: URLOpenListenerEvent) => {
      const { pathname } = new URL(data.url);
      // Map new paths to their corresponding old routes (coming from email mainly)
      const redirectTo = getNewAppRedirect(pathname);
      if (redirectTo || pathname) history.push(redirectTo || pathname);
    });

    return () => {
      CapApp.removeAllListeners();
    };
  }, [CapApp]);

  const hasWrongRole = auth.isLoggedIn && !auth.isAccountAdmin && !auth.isDriver;

  if (auth.loading) {
    return (
      <div style={{ minHeight: "20rem" }} className={cn("flex", "justify-center", "items-center", "flex-grow")}>
        <IconLoader className={cn("w-6", "h-6")} />
      </div>
    );
  }

  if (hasWrongRole) {
    return <NotAllowed />;
  }

  return (
    <AppInitGate>
      <NewLayout>
        <Switch>
          <ProtectedRoute
            isAuthorized={auth.isAccountAdmin && !auth.isSuspended}
            redirectTo={Routes.dayroutes.home()}
            exact={false}
            path={Routes.new.home()}
            component={New}
          />
          <Route exact={false} path={Routes.transports.home({ type: ":type" })} component={Transports} />
          <Route exact={false} path={Routes.dayroutes.home()} component={DayRouteHome} />
          <Route exact={true} path={Routes.support.home()} component={Support} />
          <Route exact={false} path={Routes.more.home()} component={More} />
          <Route exact={true} path={Routes.auth.login()} component={Login} />
          <Route exact={true} path={Routes.auth.logout()} component={Logout} />
          <Route exact={true} path={Routes.appTour.home()} component={AppTour} />
          <Redirect from="" to={!auth.isLoggedIn ? Routes.auth.login() : Routes.default()} />
        </Switch>
      </NewLayout>
    </AppInitGate>
  );
};
