import * as React from "react";

import { Routes } from "../../utils";
import { SplitView } from "../../layout";
import { NewClaim } from "./NewClaim";
import { NewJob } from "./NewJob";
import { NewList } from "./NewList";
import { NewStop } from "./NewStop";

export const New: React.FC = () => {
  return (
    <SplitView
      list={{ path: Routes.new.home(), component: NewList }}
      details={[
        { path: Routes.new.job({ job_id: ":job_id" }), component: NewJob },
        {
          path: Routes.new.claim({ job_id: ":job_id" }),
          component: NewClaim,
        },
        {
          path: Routes.new.stop({ job_id: ":job_id", stop_id: ":stop_id" }),
          component: NewStop,
        },
      ]}
    />
  );
};
