import { Button, IconCheck, IconEditPencil, InputText, Label, Message } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Grid } from "../../../components";
import { useForm, useTranslation } from "../../../hooks";

import { CacheKey, coreClient } from "../../../utils";

interface Props {
  canEdit: boolean;
  accountId?: string;
}

export const UserAccountForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [inEditMode, setInEditMode] = React.useState(false);

  const form = useForm({
    initialState: {
      coc_number: "",
      vat_number: "",
    },
    validators: {
      coc_number: (val) => !val,
      vat_number: (val) => !val,
    },
  });

  const account = useQuery(
    [CacheKey.RETRIEVE_ACCOUNT, props.accountId],
    () => coreClient.accounts.retrieve({ id: props.accountId as string }),
    {
      enabled: !!props.accountId,
    }
  );

  const updateAccount = useMutation(coreClient.accounts.update, {
    onSuccess: () => {
      setInEditMode(false);
      account.refetch();
    },
  });

  React.useEffect(() => {
    if (account.data) {
      form.set({
        coc_number: account.data?.coc_number || "",
        vat_number: account.data?.vat_number || "",
      });
    }
    // Run the effect when the user is present so we can initialize the form.
  }, [Boolean(account.data)]);

  return (
    <div>
      <Grid cols={{ lg: 2 }} gap={4}>
        <Label
          text={t((d) => d.profile.coc_number)}
          error={form.isFieldDirty("coc_number") && form.getError("coc_number")}
        >
          <InputText
            className={cn("w-full")}
            disabled={!inEditMode}
            value={form.getValue("coc_number")}
            onChange={(coc_number) => form.set({ coc_number })}
          />
        </Label>
        <Label
          text={t((d) => d.profile.vat_number)}
          error={form.isFieldDirty("vat_number") && form.getError("vat_number")}
        >
          <InputText
            className={cn("w-full")}
            disabled={!inEditMode}
            value={form.getValue("vat_number")}
            onChange={(vat_number) => form.set({ vat_number })}
          />
        </Label>
      </Grid>
      {props.canEdit && !inEditMode && (
        <Button className={cn("mt-6", "w-full")} onClick={() => setInEditMode(true)} icon={<IconEditPencil />}>
          {t((d) => d.profile.edit_account_details)}
        </Button>
      )}
      {props.canEdit && inEditMode && (
        <Button
          buttonType="secondary"
          className={cn("mt-6", "w-full")}
          loading={updateAccount.isLoading}
          icon={<IconCheck />}
          onClick={() => {
            if (!props.accountId) return;
            updateAccount.mutate({
              id: props.accountId,
              coc_number: form.getValue("coc_number"),
              vat_number: form.getValue("vat_number"),
            });
          }}
        >
          {t((d) => d.profile.save_personal_details)}
        </Button>
      )}
      {updateAccount.isError && (
        <Message className={cn("mt-4")} type="error">
          {(updateAccount.error as Error)?.message}
        </Message>
      )}
    </div>
  );
};
