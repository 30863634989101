"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInvalidDate = exports.getDayFromNow = exports.formatLocalDate = exports.formatRelativeDay = exports.formatDate = exports.dateFormatMap = void 0;
var date_fns_1 = require("date-fns");
exports.dateFormatMap = {
    'date-short': { day: 'numeric', month: 'numeric', year: 'numeric' },
    'month-day': { month: 'numeric', day: 'numeric' },
    'date-full': { day: 'numeric', month: 'short', year: 'numeric', weekday: 'short' },
    'month-short': { month: 'short' },
    weekday: { weekday: 'long' },
    'day-month-short': { day: 'numeric', month: 'short' },
    'day-month-long': { day: 'numeric', month: 'long' },
    'hour-minute': { hour: 'numeric', minute: 'numeric' },
    'digit-date': { day: '2-digit', month: '2-digit', year: '2-digit' },
    'weekday-month-long': { weekday: 'long', day: 'numeric', month: 'long' },
    'api-date': 'yyyy-MM-dd' // 2022-03-21
};
/** Format function that relies on the native browser function */
var formatDate = function (date, dateFormat, activeLang) {
    try {
        var formatOption = exports.dateFormatMap[dateFormat];
        var sanitizedDateString = typeof date === 'string' ? new Date(date).toUTCString() : date;
        var sanitizedDate = new Date(sanitizedDateString);
        /** Since new Date doesn't throw a error when the date is invalid we do it manually */
        if (sanitizedDateString === 'Invalid Date')
            throw new Error('invalid date');
        if (typeof formatOption === 'string') {
            return date_fns_1.format(sanitizedDate, formatOption);
        }
        return new Intl.DateTimeFormat(activeLang.locale, __assign(__assign({}, formatOption), { timeZone: activeLang.timeZone })).format(sanitizedDate);
    }
    catch (_a) {
        return 'invalid date';
    }
};
exports.formatDate = formatDate;
/**
 * Formats date to relative today, tomorrow, yesterday
 * Baded on the active lang
 */
var formatRelativeDay = function (relativeDate, activeLang) {
    try {
        var relative = new Intl.RelativeTimeFormat(activeLang.locale, { numeric: 'auto' });
        return relative.format(relativeDate, 'day');
    }
    catch (_a) {
        return 'invalid date';
    }
};
exports.formatRelativeDay = formatRelativeDay;
/** Formats date based on the difference between today and date */
var formatLocalDate = function (dateValue, activeLang, options) {
    var date = new Date(dateValue);
    var datePlusDay = exports.formatDate(new Date(date), 'weekday-month-long', activeLang);
    /**
     * Get difference between date from now and given date.
     * Check if date is today or tommorow so we can write it like that later, else return date plus day
     */
    var formatAsRelativeDate = (options === null || options === void 0 ? void 0 : options.showRelative) && (date_fns_1.isToday(date) || date_fns_1.isTomorrow(date));
    if (!formatAsRelativeDate) {
        return datePlusDay;
    }
    var diffInDays = date_fns_1.differenceInCalendarDays(date, new Date());
    var fullDate = exports.formatDate(date, 'day-month-long', activeLang);
    var relativeDay = exports.formatRelativeDay(diffInDays, activeLang);
    /**
     * Full dates can be written with a comma separator between the day and date
     * example: Samstag, 19. Februar
     * When there is a comma present, we also want to add it when relative formatting
     */
    if (datePlusDay.includes(',')) {
        relativeDay = relativeDay + ',';
    }
    return relativeDay + " " + fullDate;
};
exports.formatLocalDate = formatLocalDate;
/** Formats the date based on the past, future or relative day */
var getDayFromNow = function (date, activeLang) {
    var diffInDays = date_fns_1.differenceInCalendarDays(date, new Date());
    // HISTORY: More than 2 days back, format as simple date
    if (diffInDays <= -2) {
        return exports.formatDate(date, 'digit-date', activeLang);
    }
    // FUTURE: display a day name
    if (diffInDays >= 2) {
        return exports.formatDate(date, 'weekday', activeLang);
    }
    // TODAY, TOMORROW, YESTERDAY
    return exports.formatRelativeDay(diffInDays, activeLang);
};
exports.getDayFromNow = getDayFromNow;
var isInvalidDate = function (dateString) {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    return (dateString === null || dateString === void 0 ? void 0 : dateString.match(regEx)) === null;
};
exports.isInvalidDate = isInvalidDate;
