import { Button, IconSync, InputDate, Label, Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import { TransportsFiltersProps } from ".";
import { Grid } from "../../../components";
import { useFormatDate, useSelector, useTranslation, useTransportsFilter } from "../../../hooks";
import { deliveredFilterActions } from "../../../store/delivered/filter";
import { INPUT_DATE_MAX, INPUT_DATE_MIN } from "../../../utils";
import { SheetModal } from "../../../layout";

export const TransportsFiltersDelivered: React.FC<TransportsFiltersProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const deliveredFilter = useSelector((state) => state.deliveredFilter);
  const dispatch = useDispatch();
  const { filteredTjalsCount } = useTransportsFilter({ presentation: "delivered" });
  const formatDateForApi = useFormatDate("api-date");
  return (
    <SheetModal
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      header={"Delivered filters"}
      footer={
        <div className="flex gap-4 justify-between">
          <Button
            className="w-full lg:w-auto"
            buttonType="primary-outline"
            onClick={() => {
              dispatch(deliveredFilterActions.reset());
              setIsOpen(false);
            }}
          >
            Remove filters
          </Button>
          <Button
            className={cn("w-full", "lg:w-auto")}
            icon={<IconSync className={cn("h-4")} />}
            onClick={() => setIsOpen(false)}
          >
            {t((d, withTemplate) => {
              return withTemplate(d.delivered.filter.show, {
                number: filteredTjalsCount,
              });
            })}
          </Button>
        </div>
      }
    >
      <h4>{t((d) => d.delivered.filter.date || "n/a")}</h4>
      <Spacer h={2} />
      <Grid cols={{ lg: 2 }} gap={4}>
        <Label text={t((d) => d.search.labels.from)}>
          <InputDate
            min={formatDateForApi(INPUT_DATE_MIN)}
            max={deliveredFilter.dateTo}
            value={deliveredFilter.dateFrom}
            onChange={(date) => {
              dispatch(deliveredFilterActions.setDateFrom(date));
            }}
          />
        </Label>
        <Label text={t((d) => d.search.labels.until)}>
          <InputDate
            min={deliveredFilter.dateFrom}
            max={formatDateForApi(INPUT_DATE_MAX)}
            value={deliveredFilter.dateTo}
            onChange={(date) => {
              dispatch(deliveredFilterActions.setDateTo(date));
            }}
          />
        </Label>
      </Grid>
    </SheetModal>
  );
};
