"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNowInDateTimePeriod = exports.isNowBeforeDateTimePeriod = exports.isNowAfterCommitmentDeadline = void 0;
var fp_1 = require("date-fns/fp");
var date_fns_1 = require("date-fns");
/**
 * Customers are only able to see the commitment from 20.00 day before Pickup/Delivery
 * So this util takes a utc date time and checks if now is after that deadline
 */
var isNowAfterCommitmentDeadline = function (dtp) {
    if (!dtp)
        return false;
    if (!dtp.start || !dtp.end)
        return false;
    var now = new Date();
    var date = new Date(dtp.start);
    var deadline = [date].map(fp_1.subHours(24)).map(fp_1.set({ hours: 20, minutes: 0, seconds: 0 }))[0];
    return date_fns_1.isAfter(now, deadline);
};
exports.isNowAfterCommitmentDeadline = isNowAfterCommitmentDeadline;
/**
 * Compares client's current time to the end of the DTP
 */
var isNowBeforeDateTimePeriod = function (dtp) {
    if (!(dtp === null || dtp === void 0 ? void 0 : dtp.start) || !(dtp === null || dtp === void 0 ? void 0 : dtp.end))
        return false;
    var now = new Date();
    var dtpEnd = new Date(dtp.end);
    return date_fns_1.isBefore(now, dtpEnd);
};
exports.isNowBeforeDateTimePeriod = isNowBeforeDateTimePeriod;
/**
 * Takes two UTC time border + optional offset (in hours)
 * returns if local time is in that time window.
 */
var isNowInDateTimePeriod = function (dtp, padding) {
    if (padding === void 0) { padding = 0; }
    if (!dtp || !(dtp === null || dtp === void 0 ? void 0 : dtp.start) || !(dtp === null || dtp === void 0 ? void 0 : dtp.end))
        return false;
    var now = new Date();
    var start = new Date(dtp.start);
    var end = new Date(dtp.end);
    var windowStart = [start].map(fp_1.subHours(padding))[0];
    var windowEnd = [end].map(fp_1.addHours(padding))[0];
    return date_fns_1.isAfter(now, windowStart) && date_fns_1.isBefore(now, windowEnd);
};
exports.isNowInDateTimePeriod = isNowInDateTimePeriod;
