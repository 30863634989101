import * as React from "react";

import { TransportsConfirmStop, TransportsJob, TransportsList, TransportsStop } from "..";
import { Routes } from "../../utils";
import { SplitView } from "../../layout";

export const Transports: React.FC = () => {
  return (
    <SplitView
      list={{ path: Routes.transports.home({ type: ":type" }), component: TransportsList }}
      details={[
        { path: Routes.transports.job({ type: ":type", job_id: ":job_id" }), component: TransportsJob },
        {
          path: Routes.transports.stop({ type: ":type", job_id: ":job_id", stop_id: ":stop_id" }),
          component: TransportsStop,
        },
        {
          path: Routes.transports.confirmStop({ type: ":type", job_id: ":job_id", stop_id: ":stop_id" }),
          component: TransportsConfirmStop,
        },
      ]}
    />
  );
};
