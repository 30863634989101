import { Button, IconSync, InputCheckbox, Label, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import { TransportsFiltersProps } from ".";
import { SheetModal } from "../../../layout";
import { DriverSelect } from "../../../components";
import { useAuth, useSelector, useTranslation, useTransportsFilter } from "../../../hooks";
import { planningFilterActions } from "../../../store/planning/filter";

const ALL_USERS = "--";

export const TransportsFiltersPlanned: React.FC<TransportsFiltersProps> = ({ isOpen, setIsOpen }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { filteredTjalsCount } = useTransportsFilter({ presentation: "planning" });
  const { tjalStates, user } = useSelector((state) => state.planningFilter);
  const dispatch = useDispatch();
  return (
    <SheetModal
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      header={"Planned filters"}
      footer={
        <div className="flex gap-4 justify-between">
          <Button
            className="w-full lg:w-auto"
            buttonType="primary-outline"
            onClick={() => {
              dispatch(planningFilterActions.reset());
              setIsOpen(false);
            }}
          >
            <div className="flex justify-center items-center">Remove filters</div>
          </Button>
          <Button
            className={cn("w-full lg:w-auto")}
            onClick={() => setIsOpen(false)}
            icon={<IconSync className={cn("h-4")} />}
          >
            {t((d, withTemplate) => {
              return withTemplate(d.planning.filter.show, {
                number: filteredTjalsCount,
              });
            })}
          </Button>
        </div>
      }
    >
      <h3>{t((d) => d.planning.filter.status || "n/a")}</h3>
      <Spacer h={2} />
      <Label text={t((d) => d.transport_job_account_link.state.accepted)} position="right">
        <InputCheckbox
          checked={tjalStates.includes("accepted")}
          onChange={() => dispatch(planningFilterActions.setTjalState("accepted"))}
        />
      </Label>
      <Spacer h={2} />
      <Label text={t((d) => d.transport_job_account_link.state.pending)} position="right">
        <InputCheckbox
          checked={tjalStates.includes("pending")}
          onChange={() => dispatch(planningFilterActions.setTjalState("pending"))}
        />
      </Label>
      <Spacer h={2} />
      <Label text={t((d) => d.transport_job_account_link.state.rejected)} position="right">
        <InputCheckbox
          checked={tjalStates.includes("rejected")}
          onChange={() => dispatch(planningFilterActions.setTjalState("rejected"))}
        />
      </Label>
      <DriverSelect
        className={cn("mt-4")}
        labelText={t((d) => d.profile.select_courier)}
        value={user || ALL_USERS}
        placeholder={{ text: t((d) => d.planning.labels.all_couriers), value: ALL_USERS }}
        accountId={getIdFromIri(auth.user?.account)}
        onChange={({ userIRI }) => {
          // The redux store expects null when TJALs for "all users" are displayed. Therefore,
          // must check if all users string is selected and then coerce accordingly.
          const sanitizedUser = userIRI === ALL_USERS ? null : userIRI;
          dispatch(planningFilterActions.setUser(sanitizedUser));
        }}
      />
    </SheetModal>
  );
};
