"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocale = exports.sanitizeLocale = exports.LOCALE_SEARCH_PARAM = exports.SYSTEM_DEFAULT_LOCALE = void 0;
var navigator_1 = require("./navigator");
var params_1 = require("./params");
exports.SYSTEM_DEFAULT_LOCALE = 'nl-NL';
exports.LOCALE_SEARCH_PARAM = 'locale';
var HYPHEN = '-';
var UNDERSCORE = '_';
// NOTE: formats locales according to JS spec.
var sanitizeLocale = function (locale) { return locale.replace(UNDERSCORE, HYPHEN); };
exports.sanitizeLocale = sanitizeLocale;
var getLocale = function (defaultLocale) {
    var param = params_1.getSearchParam(exports.LOCALE_SEARCH_PARAM);
    var nav = navigator_1.getNavigator();
    // example return values: "en-US" or "de_DE" or "fr"
    var locale = param || (nav === null || nav === void 0 ? void 0 : nav.language) || defaultLocale || exports.SYSTEM_DEFAULT_LOCALE;
    return exports.sanitizeLocale(locale);
};
exports.getLocale = getLocale;
