import * as React from "react";

/**
 * The main idea of this component is to serve as a utility when inserting a placeholder into a list of options.
 * It simply accepts a list of <option> tags as children and will put an additional option at the start of the list
 * if a placeholder prop is provided.
 */
export const Options: React.FC<{
  children?: React.ReactNode[];
  placeholderText: string;
  placeholderValue?: string;
}> = (props) => {
  return (
    <>
      <option value={props.placeholderValue || ""} key="placeholder">
        {props.placeholderText}
      </option>
      {props.children}
    </>
  );
};
