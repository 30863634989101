// base Tailwind
import "@brenger/react/index.css";
// driver dashboard custom css
import "./assets/styles/index.css";
// font settings - important to go last.
import "@brenger/react/fontFace.css";
import "@brenger/react/fontClasses.css";

import { fontLoader } from "@brenger/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { Config } from "./config";
import { ErrorBoundary } from "./ErrorBoundary";
import { ViewportProvider } from "./hooks";
import { App } from "./App";
import { configureStore } from "./store";
import { logger, OldRoutes, Routes, StaleTTL } from "./utils";
import { BrowserRouter, matchPath } from "react-router-dom";
import { IonApp } from "@ionic/react";

logger.init();
fontLoader();

// We might move this to api.ts - depending on further config
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: StaleTTL.SM,
    },
  },
});

const Root: React.FC = () => {
  const { store, persistor } = configureStore();

  return (
    <>
      <Helmet defaultTitle="Brenger" titleTemplate="%s | Brenger" />
      <QueryClientProvider client={queryClient}>
        {Config.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary>
              <ViewportProvider>
                <IonApp>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </IonApp>
              </ViewportProvider>
            </ErrorBoundary>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </>
  );
};

ReactDOM.render(<Root />, document.getElementById("app-root"));

/**
 * Redirect old routes to new routes (maybe bookmarked but definitely in use by mailer)
 */
export const getNewAppRedirect = (path: string): string | undefined => {
  const search = matchPath<{ tj_id: string }>(path, OldRoutes.SEARCH_JOB_DETAILS);
  if (search?.isExact) {
    return Routes.new.job({ job_id: search.params.tj_id });
  }
  const planning = matchPath<{ tjal_id: string }>(path, OldRoutes.PLANNING_JOB_DETAILS);
  if (planning?.isExact) {
    return Routes.transports.job({ type: "planning", job_id: planning.params.tjal_id });
  }
  const delivered = matchPath<{ tjal_id: string }>(path, OldRoutes.DELIVERED_JOB_DETAILS);
  if (delivered?.isExact) {
    return Routes.transports.job({ type: "delivered", job_id: delivered.params.tjal_id });
  }
  const inbox = matchPath<{ tjal_id: string }>(path, OldRoutes.INBOX_DETAILS);
  if (inbox?.isExact) {
    return Routes.more.inbox(inbox.params.tjal_id);
  }
  const userSettings = matchPath(path, OldRoutes.USER_SETTINGS);
  if (userSettings?.isExact) {
    return Routes.more.appSettings();
  }
  const userRoutes = matchPath(path, OldRoutes.USER_ROUTES);
  if (userRoutes?.isExact) {
    return Routes.more.favouriteRoutes();
  }
};

const newPath = getNewAppRedirect(window.location.pathname);
if (newPath) {
  window.location.assign(newPath);
}
