import { Button, IconCircleClock, Small } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { Config } from "../../config";
import { useBrowser, useDeviceInfo, useTranslation } from "../../hooks";
import image from "./update.svg";

export const AppUpdatePrimer: React.FC<{ supported: boolean; dismiss(): void }> = ({ supported, dismiss }) => {
  const { t } = useTranslation();
  const transKey = supported ? "update_available" : "unstable_version";
  const browser = useBrowser();
  const deviceInfo = useDeviceInfo();
  let appStoreUrl = "";
  if (deviceInfo?.platform === "android") appStoreUrl = Config.GOOGLE_PLAY_STORE_URL;
  if (deviceInfo?.platform === "ios") appStoreUrl = Config.IOS_APP_STORE_URL;
  return (
    <div className={cn("grid", "gap-4", "text-center", "place-items-center", "w-80", "max-w-full", "mx-auto")}>
      <img src={image} />
      <h3>{t((d) => d.app[transKey].title)}</h3>
      <div>{t((d) => d.app[transKey].body)}</div>
      <Button className={"w-full mt-4"} buttonType="secondary" onClick={() => browser.open({ url: appStoreUrl })}>
        {t((d) => d.app[transKey].cta)}
      </Button>
      {supported && (
        <Button className={"w-full"} buttonType="white" onClick={dismiss}>
          <span className="text-blue-600">{t((d) => d.app.update_not_now)}</span>
        </Button>
      )}
      {!supported && (
        <div className={cn("inline-flex", "items-center")}>
          <IconCircleClock className={cn("mr-2")} />
          <Small>{t((d) => d.app.unstable_version.install_duration as string)}</Small>
        </div>
      )}
    </div>
  );
};
