import formatISO from "date-fns/formatISO";
import { ProgressLevel } from "@brenger/api-client";

import { SearchFilterState, SearchFilterAction, CountryCode, Region, SortBy, JobCondition } from "./types";
import { addOrRemoveFromArray } from "../../utils";

export const SET_COUNTRY = "SET_COUNTRY";
export const SET_REGION = "SET_REGION";
export const SET_PICKUP_DATE = "SET_PICKUP_DATE";
export const SET_JOB_CONDITION = "SET_JOB_CONDITION";
export const SET_SORT_BY = "SET_SORT_BY";
export const SET_JOB_LEVEL = "SET_JOB_LEVEL";
export const SET_HIDDEN_JOB = "SET_HIDDEN_JOB";
export const RESET_SEARCH_FILTERS = "RESET_SEARCH_FILTERS";

export const searchFilterActions = {
  setCountry: (country: CountryCode): SearchFilterAction => {
    return { type: SET_COUNTRY, payload: country };
  },
  setRegion: (region: Region): SearchFilterAction => {
    return { type: SET_REGION, payload: region };
  },
  setCondition: (condition: JobCondition): SearchFilterAction => {
    return { type: SET_JOB_CONDITION, payload: condition };
  },
  setSortBy: (sortBy: SortBy): SearchFilterAction => {
    return { type: SET_SORT_BY, payload: sortBy };
  },
  setPickupDate: (date: Date | null): SearchFilterAction => {
    return { type: SET_PICKUP_DATE, payload: date };
  },
  setJobLevel: (level: ProgressLevel): SearchFilterAction => {
    return { type: SET_JOB_LEVEL, payload: level };
  },
  setHiddenJobById: (id: string): SearchFilterAction => {
    return { type: SET_HIDDEN_JOB, payload: id };
  },
  reset: (): SearchFilterAction => {
    return { type: RESET_SEARCH_FILTERS, payload: null };
  },
};

/**
 * NOTE: whenever we add new items to the search filter state, it's important to update
 * `getNumberOfActiveSearchFilters`.
 */
const defaultState: SearchFilterState = {
  countries: [],
  regions: [],
  conditions: [],
  jobLevels: [],
  hiddenJobIds: [],
  pickupDate: null,
  sortBy: {
    field: "personalized",
    order: "desc",
  },
};

export const searchFilter = (state = defaultState, action: SearchFilterAction): SearchFilterState => {
  switch (action.type) {
    case SET_COUNTRY:
      return {
        ...state,
        // Always reset regions list when when setting or un-setting "nl" country code.
        // This is because we only care to add extra region-specific filters for "nl".
        regions: action.payload === "nl" ? [] : state.regions,
        countries: addOrRemoveFromArray(action.payload, state.countries),
      };
    case SET_REGION:
      return {
        ...state,
        regions: addOrRemoveFromArray(action.payload, state.regions),
      };
    case SET_JOB_CONDITION:
      return {
        ...state,
        conditions: addOrRemoveFromArray(action.payload, state.conditions),
      };
    case SET_PICKUP_DATE:
      return {
        ...state,
        pickupDate: action.payload ? formatISO(action.payload) : null,
      };
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };
    case SET_JOB_LEVEL:
      return {
        ...state,
        jobLevels: addOrRemoveFromArray(action.payload, state.jobLevels),
      };
    case SET_HIDDEN_JOB:
      return {
        ...state,
        hiddenJobIds: addOrRemoveFromArray(action.payload, state.hiddenJobIds),
      };
    case RESET_SEARCH_FILTERS: {
      const clonedDefaultState = { ...defaultState };
      // hiddenTjs should always persist until the open TJ list from core no longer includes them.
      Reflect.deleteProperty(clonedDefaultState, "hiddenTjs");
      return clonedDefaultState;
    }
    default:
      return state;
  }
};
