import { useQuery, useQueries } from "@tanstack/react-query";

import { coreClient, CacheKey, getCommittedDtpsForStop } from "../utils";
import {
  Pickup,
  Delivery,
  ItemSet,
  Address,
  Contact,
  TransportJob,
  DateTimePeriod,
  ProductPayment,
  Route,
} from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";

interface StopContext {
  address: Address | null;
  contact: Contact | null;
  transportJob: TransportJob | null;
  stopNumber: number | null;
  committedDtps: DateTimePeriod[] | null;
  relatedStopId: string | null;
  previousStopId: string | null;
  nextStopId: string | null;
  productPayments: ProductPayment[];
  loading: boolean;
  route?: Route;
  companyName?: string | null;
}

/**
 * This hook accepts a transport job and a stop (either pickup or delivery) and
 * places the stop into context relative to other stops within the transport job.
 */
export const useStopContext = (stop: Pickup | Delivery | null, tjalId?: string): StopContext => {
  // Fetch the transport job
  const transportJobId = getIdFromIri(stop?.transport_job);
  const tj = useQuery(
    [CacheKey.RETRIEVE_TRANSPORT_JOB, transportJobId],
    () => coreClient.transportJobs.retrieve({ id: transportJobId as string }),
    {
      enabled: !!transportJobId,
    }
  );
  const tjal = useQuery(
    [CacheKey.RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK, tjalId],
    () => coreClient.transportJobAccountLinks.retrieve({ id: tjalId as string }),
    {
      enabled: !!tjalId,
    }
  );

  /**
   * For bundled jobs, must also fetch the route.
   * Note: for regular jobs, the stop context can be inferred.
   */
  const routeId = getIdFromIri(tj.data?.transport_route);
  const route = useQuery(
    [CacheKey.RETRIEVE_ROUTE, routeId],
    () => coreClient.routes.retrieve({ id: routeId as string }),
    {
      enabled: !!routeId && tj.data?.bundled,
    }
  );

  /**
   * Product payments
   */
  const productPaymentIds = ((stop?.item_sets || []) as ItemSet[])
    .flatMap((is) => is.product_payments)
    .map((ppIri) => ({ id: getIdFromIri(ppIri) || "" }));

  const productPaymentsQuery = useQueries({
    queries: productPaymentIds.map((id) => {
      return {
        queryKey: [CacheKey.RETRIEVE_PRODUCT_PAYMENT, id],
        queryFn: () => coreClient.productPayments.retrieve(id),
      };
    }),
  });

  const productPaymentsData = productPaymentsQuery
    .filter((query) => query.isSuccess) // filter out unsuccessfull
    .map((query) => query.data) as ProductPayment[];

  /**
   * Fetch the contact and return.
   */
  const contactId = getIdFromIri(stop?.contact as string | undefined);
  const contact = useQuery(
    [CacheKey.RETRIEVE_CONTACT, contactId],
    () => coreClient.contacts.retrieve({ id: contactId as string }),
    {
      enabled: !!contactId,
    }
  );

  /**
   * GET STOP CONTEXT FOR A REGULAR JOB
   */
  if (stop && tj.data && !tj.data.bundled && (!tjalId || tjal.data)) {
    const stopId = getIdFromIri(stop) || null;
    const [pickup] = tj.data.pickups;
    const pickupAddress = pickup.address as Address;
    const pickupId = getIdFromIri(pickup) || null;
    const [delivery] = tj.data.deliveries;
    const deliveryAddress = delivery.address as Address;
    const deliveryId = getIdFromIri(delivery) || null;
    return {
      address: stopId === pickupId ? pickupAddress : deliveryAddress,
      contact: contact.data || null,
      transportJob: tj.data,
      stopNumber: stopId === pickupId ? 1 : 2,
      relatedStopId: stopId === pickupId ? deliveryId : pickupId,
      nextStopId: stopId === pickupId ? deliveryId : null,
      previousStopId: stopId === deliveryId ? pickupId : null,
      committedDtps: getCommittedDtpsForStop(stop, tjal.data),
      productPayments: productPaymentsData,
      loading: false,
      companyName: stop.company_name,
    };
  }

  /**
   * GET STOP CONTEXT FOR A BUNDLED JOB
   */
  if (stop && tj.data && route.data && (!tjalId || tjal.data)) {
    const allStopAddresses = [...tj.data.pickups, ...tj.data.deliveries].map((s) => s.address as Address);
    const stopId = getIdFromIri(stop);
    const stopAddress = allStopAddresses.find((address) => address["@id"] === stop.address);
    const stopType = stop["@type"];
    // NOTE: pass the item set (whether or not it's an expanded object or a string and let the util handle it)
    const itemSetIri = getIdFromIri(stop.item_sets[0]);
    const routeStops = route.data.stops;

    const stopIndex =
      routeStops.find((routeStop) => {
        return getIdFromIri(routeStop?.pickup || routeStop?.delivery) === stopId;
      })?.index || 0;

    const previousStop = stopIndex > 0 ? routeStops.find((routeStop) => routeStop.index === stopIndex - 1) : null;
    const nextStop =
      stopIndex < routeStops.length ? routeStops.find((routeStop) => routeStop.index === stopIndex + 1) : null;

    const oppositeStops: Array<Pickup | Delivery> = stopType === "Pickup" ? tj.data.deliveries : tj.data.pickups;

    const relatedStop = oppositeStops?.find((s) => {
      // NOTE: the item set used to be an array of IDs but recently it was expanded into a full object.
      // getIdFromIri thankfully can handle both. Type cast a string, and if expanded, it will handle it, too.
      return itemSetIri && getIdFromIri(s.item_sets[0])?.includes(itemSetIri);
    });

    return {
      address: stopAddress || null,
      contact: contact.data || null,
      transportJob: tj.data || null,
      stopNumber: stopIndex,
      relatedStopId: getIdFromIri(relatedStop) || null,
      nextStopId: getIdFromIri(nextStop?.pickup || nextStop?.delivery) || null,
      previousStopId: getIdFromIri(previousStop?.pickup || previousStop?.delivery) || null,
      committedDtps: getCommittedDtpsForStop(stop, tjal.data),
      productPayments: productPaymentsData,
      companyName: stop.company_name,
      loading: false,
      route: route.data,
    };
  }

  // Return default state when queries are not done loading or errors occurred.
  return {
    contact: contact.data || null,
    transportJob: tj.data || null,
    address: null,
    stopNumber: null,
    relatedStopId: null,
    previousStopId: null,
    nextStopId: null,
    committedDtps: null,
    productPayments: [],
    loading: contact.isLoading || tj.isLoading,
  };
};
