import { DriverConfirmStop } from "@brenger/api-client";
import { coreClient, logger } from "../../../utils";

interface ConfirmStopData {
  stopId: string;
  photo_proof_images: string[];
  item_set: string;
  signature_image?: string;
  signed_by?: string;
}

export type ConfirmStopLocation = Pick<
  DriverConfirmStop,
  "lat" | "lng" | "proximity" | "device_platform" | "location_service"
>;

export type WrappedClientMethodArgs = ConfirmStopData & ConfirmStopLocation;

/**
 * The bid idea with creating these "wrapped methods" is so that the useMutation hook can track the
 * loading state of ALL the dependent mutations in one go.
 */
export const confirmPickupMethods = async (args: WrappedClientMethodArgs & { pickup: string }): Promise<void> => {
  // Trigger the confirm stop mutation first - and let is throw its own errors if need be.
  await coreClient.pickups.confirmByDriver({
    id: args.stopId,
    proximity: args.proximity,
    lat: args.lat,
    lng: args.lng,
    device_platform: args.device_platform,
    location_service: args.location_service,
  });

  // After the above mutation, trigger the resource mutations in parallel and catch their error silently.
  try {
    await Promise.all([
      args.photo_proof_images.length > 0
        ? coreClient.photoProofs.addResourcesToPickup({
            photo_proof_images: args.photo_proof_images,
            pickup: args.pickup,
            item_set: args.item_set,
          })
        : undefined,
      args.signature_image
        ? coreClient.signatures.addResourceToPickup({
            signature_image: args.signature_image,
            pickup: args.pickup,
            item_set: args.item_set,
            signed_by: args.signed_by,
          })
        : undefined,
    ]);
  } catch (err) {
    // Log internally and fail silently.
    logger.error("Adding resources to confirm pickup failed.", err);
  }
};

export const confirmDeliveryMethods = async (args: WrappedClientMethodArgs & { delivery: string }): Promise<void> => {
  await coreClient.deliveries.confirmByDriver({
    id: args.stopId,
    proximity: args.proximity,
    lat: args.lat,
    lng: args.lng,
    device_platform: args.device_platform,
    location_service: args.location_service,
  });
  try {
    await Promise.all([
      args.photo_proof_images.length > 0
        ? coreClient.photoProofs.addResourcesToDelivery({
            photo_proof_images: args.photo_proof_images,
            delivery: args.delivery,
            item_set: args.item_set,
          })
        : undefined,
      args.signature_image
        ? coreClient.signatures.addResourceToDelivery({
            signature_image: args.signature_image,
            delivery: args.delivery,
            item_set: args.item_set,
            signed_by: args.signed_by,
          })
        : undefined,
    ]);
  } catch (err) {
    // Log internally and fail silently.
    logger.error("Adding resources to confirm delivery failed.", err);
  }
};
