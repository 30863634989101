import { DayRoute, GeoLocationDetails, UpdateDayRoute } from "@brenger/api-client";
import { Button, InputCheckbox, Label, Select } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import setDateTime from "date-fns/set";
import * as React from "react";
import { useMutation, UseQueryResult } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { InputTextWithGeoAutocomplete, Options, VehicleSelect } from "../../../components";
import { useForm, useFormatDate, useTranslation } from "../../../hooks";
import { coreClient, DayRouteParams, intervalsInTime } from "../../../utils";
import { DayRouteStartEndCard, TIME_PLACEHOLDER } from "./DayRouteStartEndCard";
import { DayRouteStartEndWrapper } from "./DayRouteStartLocationWrapper";

interface Props {
  dayRoute: UseQueryResult<DayRoute>;
}

export const DayRouteStartLocation: React.FC<Props> = ({ dayRoute }) => {
  const { t } = useTranslation();
  const params = useParams<DayRouteParams>();
  const formatHourMinute = useFormatDate("hour-minute");

  const [isEditingStartLocation, setIsEditingStartLocation] = React.useState(false);

  const updateDefaults = useMutation(coreClient.dayRoutes.updateDefaults);
  const onSuccess = (): void => {
    dayRoute.refetch();
    setIsEditingStartLocation(false);
  };

  const updateRoute = useMutation(coreClient.dayRoutes.update, { onSuccess });
  const start = setDateTime(new Date(params.date), { hours: 6, minutes: 30, seconds: 0 });
  const end = setDateTime(new Date(params.date), { hours: 17, minutes: 0, seconds: 0 });
  const startTimeOptions = intervalsInTime(start, end, 15);
  const startAddress = dayRoute.data?.start_address;
  const startTime = dayRoute.data?.start_time;
  const startedDrivingAt = dayRoute.data?.started_driving_at;

  // Use start driving at for first timeline item
  const timeLineStartTime = startedDrivingAt || startTime;

  const form = useForm({
    initialState: {
      search: startAddress ? [startAddress.line1, startAddress.locality].filter(Boolean).join(", ") : "",
      // NOTE: when there is a start time, be sure to format it as an ISO string so that it matches the options.
      // This ensures that the Select is properly controlled and can initialize with any previously selected options.
      startTime: startTime ? new Date(startTime).toISOString() : TIME_PLACEHOLDER,
      // get vehicle in
      vehicle: dayRoute.data?.vehicle?.["@id"] || null,
      place: undefined as GeoLocationDetails | undefined,
      second_driver: Boolean(dayRoute.data?.second_driver),
      saveAsDefault: false,
    },
    validators: {
      search: (val) => !val,
      startTime: (val) => !val || val === TIME_PLACEHOLDER,
    },
  });

  // When the day route changes, we need to keep the start location form up to date with active day route
  React.useEffect(() => {
    if (startAddress || startTime) {
      form.set({
        // @TODO make this DRY - same logic as in useForm's initial state.
        search: startAddress ? [startAddress.line1, startAddress.locality].filter(Boolean).join(", ") : "",
        // See note in useForm initial state.
        // @TODO make this DRY - same logic as in useForm's initial state.
        startTime: startTime ? new Date(startTime).toISOString() : TIME_PLACEHOLDER,
        // @TODO make this DRY - same logic as in useForm's initial state.
        vehicle: dayRoute.data?.vehicle?.["@id"] || null,
        second_driver: Boolean(dayRoute.data?.second_driver),
        saveAsDefault: false,
      });
    }
  }, [dayRoute.data]);

  const submitLocation = (): void => {
    // Grab the start time from the form (will be initialized there if already exists or added when selected for the first time)
    const dayRouteBody: UpdateDayRoute["dayRoute"] = {
      start_time: form.data.startTime.value,
      second_driver: form.data.second_driver.value,
      vehicle: form.data.vehicle.value,
    };

    // If there is a place returned by the geo input, favor that over the default start address.
    const place = form.data.place.value;

    if (place) {
      dayRouteBody.start_address = {
        line1: place.address.line1 || "",
        line2: place.address.line2 || "",
        postal_code: place.address.postal_code || "",
        locality: place.address.locality,
        municipality: place.address.secondary_subdivision,
        country_code: place.address.country_code,
        lat: place.address.latitude,
        lng: place.address.longitude,
      };
    }
    if (form.data.saveAsDefault.value) {
      updateDefaults.mutate({
        from: {
          hour: new Date(form.data.startTime.value).getUTCHours(),
          minute: new Date(form.data.startTime.value).getUTCMinutes(),
        },
        extraDriver: form.data.second_driver.value,
        vehicle: form.data.vehicle.value || undefined,
        user: params.user_id,
        start_address: dayRouteBody?.start_address || dayRoute.data?.start_address || undefined,
      });
    }
    updateRoute.mutate({
      routeId: getIdFromIri(dayRoute.data) as string,
      dayRoute: dayRouteBody,
    });
  };
  if (isEditingStartLocation) {
    return (
      <DayRouteStartEndWrapper type={"start"} time={timeLineStartTime}>
        <Label text={t((d) => d.day_route.start_location.labels.start_location)}>
          <InputTextWithGeoAutocomplete
            value={form.data.search.value}
            onChange={(search) => form.set({ search })}
            onSelect={(place) => form.set({ place })}
            includeHouseNumber={true}
          />
        </Label>
        <div className={cn("py-4")}>
          <Label text={t((d) => d.day_route.start_location.labels.start_time)}>
            <Select
              className={cn("w-full")}
              value={form.data.startTime.value}
              onChange={(nextStartTime: string) => {
                form.set({ startTime: nextStartTime });
              }}
            >
              <Options placeholderText={TIME_PLACEHOLDER} placeholderValue={TIME_PLACEHOLDER}>
                {startTimeOptions?.map((time, idx) => {
                  return (
                    <option key={idx} value={time}>
                      {formatHourMinute(time)}
                    </option>
                  );
                })}
              </Options>
            </Select>
          </Label>
        </div>
        <div className={cn("mb-4")}>
          <VehicleSelect
            labelText={`${t((d) => d.vehicles.single)}:`}
            value={form.data.vehicle.value}
            onChange={({ vehicleIRI }) => form.set({ vehicle: vehicleIRI })}
          />
        </div>
        <div className={cn("mb-4")}>
          <Label text={t((d) => d.vehicles.extra_driver)} position="right">
            <InputCheckbox
              checked={form.data.second_driver.value}
              onChange={() => form.set({ second_driver: !form.data.second_driver.value })}
            />
          </Label>
        </div>
        <div className={cn("mb-4")}>
          <Label text={t((d) => d.vehicles.save_as_default)} position={"right"}>
            <InputCheckbox
              checked={form.data.saveAsDefault.value}
              onChange={() => {
                form.set({ saveAsDefault: !form.data.saveAsDefault.value });
              }}
            />
          </Label>
        </div>
        <div className={cn("flex")}>
          <Button
            className={cn("mr-2", "w-full")}
            buttonType={"gray-outline"}
            onClick={() => setIsEditingStartLocation(false)}
          >
            {t((d) => d.day_route.start_location.labels.cancel)}
          </Button>
          <Button
            className={cn("ml-2", "w-full")}
            disabled={form.hasErrors}
            loading={updateRoute.isLoading}
            buttonType="secondary"
            onClick={submitLocation}
          >
            {t((d) => d.day_route.start_location.labels.save)}
          </Button>
        </div>
      </DayRouteStartEndWrapper>
    );
  }

  return (
    <DayRouteStartEndWrapper type={"start"} time={timeLineStartTime}>
      <DayRouteStartEndCard
        location={form.data?.search?.value}
        LocationTime={{
          label: t((d) => d.day_route.start_location.labels.time_start),
          value: form.data.startTime.value,
        }}
        setIsEditingEndLocation={() => setIsEditingStartLocation(true)}
        startedDrivingAt={{ label: t((d) => d.day_route.start_driving.started_driving_at), value: startedDrivingAt }}
      />
    </DayRouteStartEndWrapper>
  );
};
