import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export type UseRestoreScrollParams = {
  elId: string;
};

interface ScrollPosition {
  pathname: string;
  elId: string;
  x: number;
  y: number;
}

export const HANDLE_HISTORY_BACK = "handle-history-back";
const storageKey = "brenger_scroll_past";

export const useRestoreScroll = ({ elId }: UseRestoreScrollParams): void => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const storage = sessionStorage.getItem(storageKey);
  const past: ScrollPosition[] = storage ? JSON.parse(storage) : [];

  const store = (p: ScrollPosition[]): void => {
    sessionStorage.setItem(storageKey, JSON.stringify(p));
  };

  React.useEffect(() => {
    // Get the element to scroll, and bail early if there isn't one
    const el = document.getElementById(elId);
    if (!el) return;

    // We want to restore scroll if we are going back, or if we have a flag to handle it
    const searchParams = new URLSearchParams(search);
    const handleRestore = history.action === "POP" || searchParams.get(HANDLE_HISTORY_BACK);
    if (!handleRestore) return;

    // Find the scroll position in our storage, bail if none
    const i = past.findIndex((p) => p.pathname === pathname && p.elId === elId);
    if (i === -1) return;

    // Scroll to the position and remove everything after
    el.scrollTo(past[i].x, past[i].y);
    store(past.slice(0, i - 1));
  }, [pathname, history.action]);

  React.useEffect(() => {
    // By listen for history changes we don't need to add to click events
    // So this is a more generic approach
    const unlisten = history.listen((_, action) => {
      // Only if we go forwards we want to store the scroll position
      if (action === "PUSH") {
        const el = document.getElementById(elId);
        // Add current scroll position to the beginning of the array
        past.unshift({
          pathname,
          elId,
          x: el?.scrollLeft,
          y: el?.scrollTop,
        } as ScrollPosition);
        // Store past scroll positions
        store(past);
      }
    });
    return () => unlisten();
  }, []);
};
