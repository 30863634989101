import { Button, IconFilter2, Message } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import * as React from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useFormatDate, useSearchFilter, useSelector, useTranslation } from "../../../hooks";
import { SearchFilterState } from "../../../store/search/types";
import { Routes, TransportsJobParams } from "../../../utils";
import { Content, Section } from "../../../layout";
import { NewListFilters } from "./NewListFilters";
import { NewListItem } from "./NewListItem";

export const NewList: React.FC = () => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [scrollTo, setScrollTo] = React.useState<{ left: number; top: number } | undefined>(undefined);
  const searchFilter = useSelector((state) => state.searchFilter);
  const numberOfActiveSearchFilters = getNumberOfActiveSearchFilters(searchFilter);

  const { openTransportJobs, filteredOpenTransportJobs, isLoading, activeFilter } = useSearchFilter();

  const filteredJobsCount = filteredOpenTransportJobs.ungrouped.length;

  const zeroResults = Object.keys(filteredOpenTransportJobs.grouped).length === 0;
  /**
   * Util for formatting the header
   */
  const formatDateFull = useFormatDate("date-full");
  /**
   * Figure out if we have an active item
   */
  const location = useLocation();
  const detailsMatch = useRouteMatch<TransportsJobParams>({
    location,
    path: Routes.new.job({ job_id: ":job_id" }),
  });
  const activeId = detailsMatch?.params.job_id;

  React.useEffect(() => {
    /**
     * We only want to execute this on load, once.
     * So we wait for the component to load and set the scroll coords once.
     * The coords will be passed down to the content scrollable list.
     */
    if (isLoading || scrollTo) return;
    const rect = document.getElementById(`list_${activeId}`)?.getBoundingClientRect();
    if (!rect) return;
    setScrollTo({ left: 0, top: rect.top - 75 });
  }, [isLoading, scrollTo]);

  return (
    <>
      <Content isLoading={isLoading} scrollCoords={scrollTo}>
        <Section type="split-list" className="pt-8 pb-4 sticky top-0 bg-white z-10">
          <Button
            className="w-full !rounded-full !bg-color-light-blue"
            buttonType="primary-light"
            onClick={() => setIsFilterOpen(true)}
          >
            <div className="flex justify-between items-center">
              <span>
                {t((d) => d.transports.list.filters)}{" "}
                {numberOfActiveSearchFilters !== 0 && `(${numberOfActiveSearchFilters})`}
              </span>{" "}
              <IconFilter2 />
            </div>
          </Button>
        </Section>
        {/* Zero results */}
        {!isLoading && zeroResults && (
          <Section type="split-list">
            <Message type="info" className={"mb-4"}>
              {t((d) => d.search.filter.zero_results)}
            </Message>
          </Section>
        )}
        {!isLoading && !zeroResults && (
          <>
            {activeFilter.sortBy.field === "personalized" &&
              Object.keys(filteredOpenTransportJobs.grouped).map((date) => {
                const title =
                  date !== "NO_DTP"
                    ? formatDateFull(new Date(date).toUTCString())
                    : t((d) => d.search.heading_jobs_without_dtp);
                return (
                  <React.Fragment key={date}>
                    <Section type="split-list">
                      <h5 className="capitalize pt-6 pb-2">{title}</h5>
                    </Section>
                    {filteredOpenTransportJobs.grouped[date].map((job, i) => {
                      const id = getIdFromIri(job);
                      return (
                        <Section type="split-list" key={id} id={`list_${id}`} className="pt-2">
                          <NewListItem
                            openTJ={job}
                            isActive={id === activeId}
                            index={i}
                            totalJobsCount={openTransportJobs.data?.length || 0}
                            filteredJobsCount={filteredJobsCount}
                            activeFilter={JSON.stringify(activeFilter)}
                            sortByField={searchFilter.sortBy.field}
                          />
                        </Section>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            {activeFilter.sortBy.field !== "personalized" &&
              filteredOpenTransportJobs.ungrouped.map((job, i) => {
                const id = getIdFromIri(job);
                return (
                  <Section type="split-list" key={id} id={`list_${id}`} className="pt-2">
                    <NewListItem
                      openTJ={job}
                      isActive={id === activeId}
                      index={i}
                      totalJobsCount={openTransportJobs.data?.length || 0}
                      filteredJobsCount={filteredJobsCount}
                      activeFilter={JSON.stringify(activeFilter)}
                      sortByField={searchFilter.sortBy.field}
                    />
                  </Section>
                );
              })}
          </>
        )}
      </Content>
      <NewListFilters isOpen={isFilterOpen} setIsOpen={setIsFilterOpen} />
    </>
  );
};

/**
 * Check the search filter state for any alterations from the default state.
 * We do this in order to determine whether we show the user a "clear filters" button.
 * Having that button is important because the filters are persisted in local storage
 * and users may forget that they have previously applied them.
 */
const getNumberOfActiveSearchFilters = (state: SearchFilterState): number => {
  return (
    state.conditions.length +
    state.countries.length +
    state.regions.length +
    state.jobLevels.length +
    (state.pickupDate ? 1 : 0)
  );
};
