import { Button, Spacer, Strong } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import * as React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { DriverSelect } from "../../components";
import { useAuth, useCache, useTranslation } from "../../hooks";
import { CacheKey, coreClient } from "../../utils";

interface Props {
  tjalId: string;
  successPath?: string;
  onSuccess?(): void;
}
export const EditDriver: React.FC<Props> = ({ tjalId, successPath, onSuccess }) => {
  const [driverUser, setDriverUser] = React.useState("");
  const auth = useAuth();
  const { t } = useTranslation();
  const cache = useCache();
  const history = useHistory();

  const tjal = useQuery(
    [CacheKey.RETRIEVE_TRANSPORT_JOB_ACCOUNT_LINK, tjalId],
    () => coreClient.transportJobAccountLinks.retrieve({ id: tjalId }),
    {
      enabled: Boolean(auth.isAccountAdmin && tjalId),
    }
  );
  const tjalDriver = tjal.data?.driver_user?.["@id"];

  React.useEffect(() => {
    if (tjalDriver) setDriverUser(tjalDriver);
  }, [tjalDriver]);

  const replaceTjal = useMutation(coreClient.transportJobAccountLinks.replace, {
    onSuccess: () => {
      cache.clear();
      // Push to different path, because when the driver has been changed
      // the stop or dayroute activity will not exist for current view
      if (successPath) history.push(successPath);
      onSuccess?.();
    },
  });
  return (
    <>
      <div>
        <Strong>{t((d) => d.stop_edit.headings.change_driver)}</Strong>
      </div>
      <Spacer h={1} />
      <DriverSelect
        disabled={replaceTjal.isLoading || tjal.isLoading}
        value={driverUser}
        accountId={getIdFromIri(auth.user?.account)}
        onChange={({ userIRI }) => setDriverUser(userIRI)}
      />
      <Spacer h={2} />
      <Button
        className={"w-full"}
        disabled={tjalDriver === driverUser}
        loading={replaceTjal.isLoading}
        buttonType={"primary-outline"}
        onClick={() => {
          replaceTjal.reset();
          replaceTjal.mutate({
            id: tjalId,
            driver_user: driverUser,
          });
        }}
      >
        {t((d) => d.stop_edit.change_driver.confirm)}
      </Button>
    </>
  );
};
