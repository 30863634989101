import { DayRouteActivity } from "@brenger/api-client";
import { Button } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "../../../hooks";
import { DayRouteStopParams, Routes } from "../../../utils";

interface Props {
  activity: DayRouteActivity;
  canConfirm: boolean;
}

export const DayRouteStopStickyFooter: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<DayRouteStopParams>();

  const confirmTrans =
    {
      pickup: t((d) => d.day_route.details.actions.confirm_pickup),
      delivery: t((d) => d.day_route.details.actions.confirm_delivery),
    }[props.activity.type as "pickup" | "delivery"] ?? t((d) => d.day_route.details.actions.confirm_stop);

  return (
    <Button
      buttonType="secondary"
      disabled={!props.canConfirm}
      className={cn("w-full")}
      onClick={() => history.push(Routes.dayroutes.confirmStop(params))}
    >
      {confirmTrans}
    </Button>
  );
};
