import { Button, IconCheck, IconClose, IconStartDriving, Strong } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useTranslation } from "../../../hooks";
import { Section } from "../../../layout";

interface FooterProps {
  isEditing: boolean;
  isLoading: boolean;
  showStartButton: boolean;
  startDriving: () => void;
  saveOrder: () => void;
  cancelEditing: () => void;
}

export const DayRouteFooter: React.FC<FooterProps> = ({
  isEditing,
  isLoading,
  showStartButton,
  startDriving,
  saveOrder,
  cancelEditing,
}) => {
  const { t } = useTranslation();
  if (!showStartButton && !isEditing) return null;

  return (
    <Section type="split-list">
      <div className={cn("flex", "justify-between", "gap-2")}>
        {!isEditing && showStartButton && (
          <Button
            buttonType="secondary"
            loading={isLoading}
            className={cn("w-full")}
            onClick={startDriving}
            iconPos={"right"}
            icon={<IconStartDriving width={"20px"} height={"20px"} />}
          >
            <Strong>{t((d) => d.day_route.start_driving.cta)}</Strong>
          </Button>
        )}

        {isEditing && (
          <>
            <Button
              buttonType="warning-outline"
              icon={<IconClose className={cn("ml-1", "md:ml-0")} />}
              iconPos={"left"}
              className={cn("w-full")}
              loading={isLoading}
              onClick={cancelEditing}
            >
              <div className={cn("md:block", { hidden: showStartButton })}>
                {t((d) => d.day_route.start_location.labels.cancel)}
              </div>
            </Button>
            <Button
              buttonType="secondary"
              icon={<IconCheck className={cn("ml-1", "md:ml-0")} />}
              iconPos={"left"}
              className={cn("w-full")}
              loading={isLoading}
              onClick={saveOrder}
            >
              <div className={cn("md:block", { hidden: showStartButton })}>
                {t((d) => d.day_route.details.actions.save_route_order)}
              </div>
            </Button>
          </>
        )}
      </div>
    </Section>
  );
};
