/**
 * BIG FAT NOTE: THIS FILE IS AN EXACT COPY OF PLANNING STOP CONFIRM
 * - WE HAVEN'T DECIDED YET HOW TO MERGE PLANNING AND DAYROUTES
 * - DAYROUTE COULD REPLACE PLANNING SECTION IN THE FUTURE
 * - DECIDED TO KEEP BOTH UNTANGLED SO THAT THEY COULD EASILY LIVE NEXT TO EACH OTHER UNTIL
 * THERE IS A WINNER OR OTHER STRATEGY WHERE THEY BOTH SERVE A DIFF GOAL
 * - KEEP THEM BOTH IN SYNC, AS IN SAME FUNCTIONALITIES, UNTIL THAT POINT
 */

import { DayRouteActivity, ItemSet } from "@brenger/api-client";
import {
  Button,
  Card,
  IconAddPhoto,
  IconArrowLeft,
  IconBrokenImage,
  IconGesture,
  IconThumbsDown,
  InputCheckbox,
  InputText,
  Label,
  Message,
  Strong,
  useModalState,
} from "@brenger/react";
import { formatPrice, getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useHistory, useParams } from "react-router-dom";
import { ConfirmStopButton, ResponsiveContainer } from "../../../components";
import { useCache, useCamera, useCanConfirmStop, useFeedbackUrl, useStopContext, useTranslation } from "../../../hooks";
import {
  CacheKey,
  coreClient,
  dataURItoBlob,
  DayRouteStopParams,
  logger,
  Routes,
  userDataClient,
} from "../../../utils";
import { Content, PageHead, Section } from "../../../layout";
import {
  confirmDeliveryMethods,
  confirmPickupMethods,
  ConfirmStopLocation,
  WrappedClientMethodArgs,
} from "../../transports/TransportsConfirmStop/methods";
import { ConfirmStopImageModal } from "./ConfirmStopImageModal";
import { ConfirmStopSignatureModal } from "./ConfirmStopSignatureModal";

/**
 * A helper to append dataUrl as blob to formData so that Core can accept it.
 */
const getImageData = (dataUrl: string): FormData => {
  const blob = dataURItoBlob(dataUrl);
  const imageData = new FormData();
  imageData.append("file", blob);
  return imageData;
};

export const FAUX_PHOTO_PROOF_IRI = "/photo_proof_images/0000";

interface Props {
  activity: DayRouteActivity;
  humanIndex: number;
}

export const DayRouteConfirmNormalStop: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const signatureModal = useModalState();
  const params = useParams<DayRouteStopParams>();
  const cache = useCache();
  const [signedBy, setSignedBy] = React.useState("");
  const [signatureIri, setSignatureIri] = React.useState("");
  const [hasConfirmedProductPayment, setHasConfirmedProductPayment] = React.useState(false);
  const [selectedPhotoProofIndex, setSelectedPhotoProofIndex] = React.useState(-1);
  const [photoProofIris, setPhotoProofIris] = React.useState<string[]>([]);

  // When the stop confirmation is successful, navigate back to stop details page.
  const onSuccess = (): void => {
    // Before changing pages - reset cache so data is freshly fetched again when navigate back to details page.
    cache.clear();
    history.push(Routes.dayroutes.stop(params));
  };
  const confirmPickup = useMutation(confirmPickupMethods, { onSuccess });
  const confirmDelivery = useMutation(confirmDeliveryMethods, { onSuccess });
  const isConfirmStopLoading = confirmPickup.isLoading || confirmDelivery.isLoading;
  const confirmStopError = (confirmPickup.error || confirmDelivery.error) as Error;

  const createSignature = useMutation(coreClient.signatures.createResource, {
    onSuccess: (resource) => {
      setSignatureIri(resource["@id"]);
    },
  });

  const createPhotoProof = useMutation(coreClient.photoProofs.createResource, {
    onError: (error) => {
      setPhotoProofIris((prevPhotoProofImages) => prevPhotoProofImages.concat(FAUX_PHOTO_PROOF_IRI));
      logger.error("photoProofs.createResource failed.", error);
    },
    onSuccess: (resource) => {
      setPhotoProofIris((prevPhotoProofImages) => prevPhotoProofImages.concat(resource["@id"]));
    },
  });

  const onTakePictureSuccess = (cameraPhoto: string): void => {
    createPhotoProof.reset();
    const imageData = getImageData(cameraPhoto);
    createPhotoProof.mutate({ imageData });
  };

  const { takePicture } = useCamera(onTakePictureSuccess);

  // A day route activity, that is a brenger job has either a pickup or a delivery iri
  const dayRouteStopId = getIdFromIri(props.activity.pickup || props.activity.delivery) || "";
  const stop = useQuery([CacheKey.RETRIEVE_STOP, dayRouteStopId], () =>
    coreClient.stops.retrieve({ id: dayRouteStopId })
  );

  const stopContext = useStopContext(stop.data || null);
  const feedbackUrl = useFeedbackUrl({ jobId: stopContext.transportJob?.short_id });

  const {
    canConfirmStop,
    cannotConfirmStopReason,
    loading: isCanConfirmStopLoading,
    messageType,
  } = useCanConfirmStop({ iri: stop.data?.["@id"] });

  if (!canConfirmStop || isCanConfirmStopLoading || !stop.data || stop.error) {
    return (
      <Content
        isLoading={!stop.isLoading}
        message={stop.error}
        disablePullToRefresh={true}
        header={
          <PageHead
            sectionType="split-details"
            backUrl={Routes.dayroutes.stop(params)}
            title={`Confirm stop: ${props.humanIndex}`}
          />
        }
      >
        {cannotConfirmStopReason && <Message type={messageType}>{cannotConfirmStopReason}</Message>}
        <Button
          className={cn("mt-4")}
          buttonType="primary-outline"
          onClick={() => history.push(Routes.dayroutes.details(params))}
          icon={<IconArrowLeft />}
        >
          {t((d) => d.day_route.back_to_day_route)}
        </Button>
      </Content>
    );
  }

  const stopType = stop.data["@type"];

  // Check if driver has photo proofs
  const hasPhotoProofs = photoProofIris.length > 0;

  // No need to confirm product payment details when there are no product payments associated with this stop.
  // Moreover, the seller is always the pickup contact and driver should confirm that seller can declare last 4 of own IBAN before getting paid.
  const mustConfirmProductPayment = stopContext.productPayments.length > 0 && stopType === "Pickup";
  const hasSatisfiedProductPayment = mustConfirmProductPayment ? hasConfirmedProductPayment : true;

  const confirmStop = (location: ConfirmStopLocation): void => {
    const args = {} as WrappedClientMethodArgs;

    const stopIri = stop.data?.["@id"] || "";
    const stopId = getIdFromIri(stop.data);
    if (stopId) args.stopId = stopId;

    const itemSet = stop.data?.item_sets[0] as ItemSet | undefined;
    if (itemSet) {
      args.item_set = itemSet["@id"];
    }

    if (photoProofIris.length > 0) {
      // Remove the FAUX_PHOTO_PROOF_IRI - this only gets added in case uploading the media resource
      // fails but we still want to allow the driver to confirm the stop as if they have added something.
      // We do NOT want to attach this faux resource to the item set.
      args.photo_proof_images = photoProofIris.filter((iri) => iri !== FAUX_PHOTO_PROOF_IRI);
    }

    if (signatureIri) {
      args.signature_image = signatureIri;
    }

    if (signedBy) {
      args.signed_by = signedBy;
    }

    if (stopType === "Pickup") {
      confirmPickup.mutate({ pickup: stopIri, ...args, ...location });
    }

    if (stopType === "Delivery") {
      confirmDelivery.mutate({ delivery: stopIri, ...args, ...location });
    }
  };
  const isConfirmStopDisabled =
    !hasSatisfiedProductPayment ||
    !canConfirmStop ||
    !hasPhotoProofs ||
    createPhotoProof.isLoading ||
    createSignature.isLoading ||
    isConfirmStopLoading;
  return (
    <>
      <Content
        header={
          <PageHead
            sectionType="split-details"
            backUrl={Routes.dayroutes.stop(params)}
            title={`Confirm stop: ${props.humanIndex}`}
          />
        }
      >
        <Section type="split-details" isTop={true}>
          {/* FIRST SECTION - PHOTO PROOF - ALWAYS PRESENT */}
          <Card type="primary">
            <Button
              className={cn("w-full")}
              onClick={() => takePicture({ quality: 75, width: 1000, height: 1000 })}
              loading={createPhotoProof.isLoading}
              icon={<IconAddPhoto className={cn("h-4", "w-4")} />}
            >
              {t((d) => d.planning.labels.add_photo)}
            </Button>
            <div
              className={cn("mt-2")}
              /* eslint-disable-next-line */
              dangerouslySetInnerHTML={{ __html: t((d) => d.planning.messages.photo_proofs) }}
            />
            <ResponsiveContainer>
              {photoProofIris.map((iri, idx) => {
                // Render a small image preview with a click handler.
                return (
                  <button key={idx} className={cn("mt-2")} onClick={() => setSelectedPhotoProofIndex(idx)}>
                    {iri === FAUX_PHOTO_PROOF_IRI ? (
                      // If the create photo proof method returns the faux IRI (usually because of a network timeout),
                      // then we signify this by showing a "broken image" icon. This way driver at least that something
                      // happened and can still proceed with trying to confirm the stop (bc technically there is an IRI now in state)
                      <IconBrokenImage className={cn("w-20", "h-20", "fill-current")} />
                    ) : (
                      <img
                        className={cn("rounded", "border")}
                        style={{ maxHeight: 300 }}
                        src={userDataClient.getBaseForIri(iri)}
                      />
                    )}
                  </button>
                );
              })}
            </ResponsiveContainer>
          </Card>
          {/* SECOND SECTION - CONFIRM PRODUCT PAYMENT - ONLY PRESENT WHEN PRODUCT PAYMENT (DUH) */}
          {stopContext.productPayments.length > 0 && (
            <Card type="primary" className={cn("mt-4")}>
              <div>{t((d) => d.planning.messages.confirm_product_payment)}</div>
              {stopContext.productPayments.map((pp, idx) => {
                return (
                  <div key={idx} className={cn("my-2")}>
                    <div className={cn("flex", "justify-between")}>
                      <div>
                        <Strong>{t((d) => d.planning.labels.product_price)}</Strong>
                      </div>
                      <div>
                        {/* @TODO add currency when it becomes available on product payments */}
                        {formatPrice({ amount: pp.amount, locale: i18n.locale })}
                      </div>
                    </div>
                    <div className={cn("flex", "justify-between")}>
                      <div>
                        <Strong>{t((d) => d.planning.labels.iban_seller)}</Strong>
                      </div>
                      <div>{pp.iban}</div>
                    </div>
                  </div>
                );
              })}
              <Label
                className={cn("text-blue-600", "normal-case")}
                text={t((d) => d.planning.labels.verify_details)}
                position="right"
              >
                <InputCheckbox
                  checked={hasConfirmedProductPayment}
                  onChange={() => setHasConfirmedProductPayment((prevState) => !prevState)}
                />
              </Label>
            </Card>
          )}
          {/* THIRD SECTION - SIGNATURE - OPEN A CANVAS (OPTIONAL FOR STOP CONTACT) */}
          <Card type="primary" className={cn("mt-4")}>
            <Button
              className={cn("w-full")}
              onClick={signatureModal.open}
              loading={createSignature.isLoading}
              icon={<IconGesture className={cn("h-4", "w-4")} />}
            >
              {t((d) => d.planning.labels.add_signature)}
            </Button>
            {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
            <div className={cn("mt-2")} dangerouslySetInnerHTML={{ __html: t((d) => d.planning.messages.signature) }} />
            <ResponsiveContainer>
              {/* Show the signature first if we have one in state */}
              {signatureIri && (
                <button className={cn("w-full", "mt-2")} onClick={signatureModal.open}>
                  <img
                    className={cn("rounded", "border")}
                    style={{ maxHeight: 300 }}
                    src={userDataClient.getBaseForIri(signatureIri)}
                  />
                </button>
              )}
            </ResponsiveContainer>
          </Card>
          {/* FOURTH SECTION - STOP CONTACT NAME - OPTIONAL */}
          <Card type="primary" className={cn("mt-4")}>
            <InputText
              className={cn("w-full")}
              value={signedBy}
              onChange={setSignedBy}
              placeholder={t((d) => d.planning.labels.add_name)}
            />
            <div className={cn("mt-2")}>{t((d) => d.planning.labels.add_name)}</div>
          </Card>
          {/* FIFTH SECTION - CTA BUTTON TO OPEN ADDITIONAL FEEDBACK MODAL */}
          <Card type="primary" className={cn("mt-4")}>
            <Button
              className={cn("w-full")}
              onClick={feedbackUrl.open}
              icon={<IconThumbsDown className={cn("h-4", "w-4")} />}
            >
              {t((d) => d.feedback_transport_title)}
            </Button>
            <div className={cn("mt-2")}>{t((d) => d.feedback_transport_desc)}</div>
          </Card>
          {/* Error message */}
          {confirmStopError && (
            <Message className={cn("mt-4")} type="error">
              {confirmStopError.message}
            </Message>
          )}
          <ConfirmStopButton
            stop={stop.data}
            isConfirmDisabled={isConfirmStopDisabled}
            isConfirmLoading={isConfirmStopLoading}
            confirmStop={confirmStop}
          />
        </Section>
      </Content>
      {/* MODALS */}
      <ConfirmStopImageModal
        isActive={selectedPhotoProofIndex >= 0}
        closeHandler={() => setSelectedPhotoProofIndex(-1)}
        selectedPhotoProofIndex={selectedPhotoProofIndex}
        photoProofIris={photoProofIris}
        setPhotoProofIris={setPhotoProofIris}
      />
      <ConfirmStopSignatureModal
        isActive={signatureModal.isActive}
        closeHandler={signatureModal.close}
        signatureIri={signatureIri}
        resetSignatureIri={() => setSignatureIri("")}
        setSignatureIri={(dataUrl: string) => {
          if (dataUrl) {
            const imageData = getImageData(dataUrl);
            createSignature.mutate({ imageData });
          }
        }}
      />
    </>
  );
};
