import { Button } from "@brenger/react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "../../hooks";
import { coreClient } from "../../utils";

export const NotAllowed: React.FC = () => {
  const { t } = useTranslation();
  const logout = useMutation(coreClient.users.logout, { onSuccess: () => window.location.reload() });

  return (
    <div className="w-screen h-screen bg-blue-600 p-10 text-white">
      <h5>{t((d) => d.app.errors.access_not_allowed_title)}</h5>
      <div>{t((d) => d.app.errors.role_not_supported)}</div>
      <Button className="mt-4" onClick={() => logout.mutate()} buttonType="secondary">
        {t((d) => d.more.log_out)}
      </Button>
    </div>
  );
};
