import * as React from "react";

import { useAuth, useBrowser, useGeolocationMethods, useNativePlatform, useTranslation } from "../../hooks";

import { Locale } from "@brenger/api-client";
import {
  btnCns,
  btnSizeCns,
  btnTypeCns,
  Button,
  Card,
  IconArrowRight,
  IconPencil,
  IconPhoneClassic,
  IconWhatsapp,
  useModalState,
} from "@brenger/react";
import { createWhatsAppUrl } from "@brenger/utils";
import cn from "classnames";
import { isAfter, isBefore, set } from "date-fns";
import { Config, LOCALE_TEMPLATE } from "../../config";
import { Routes, USE_HISTORY_BACK_PARAM } from "../../utils";
import { Content, Section, SheetModal } from "../../layout";
import { MoreLinkList, MoreLinkListItem } from "../more/home/MoreLinkList";
import { GeoPrimer } from "../primers";
import { FaqSection } from "./FaqSection";

export const Support: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isRushContactOpen, setIsRushContactOpen] = React.useState(false);
  const isNative = useNativePlatform();
  const auth = useAuth();
  const browser = useBrowser();
  const geolocation = useGeolocationMethods();
  const geoModal = useModalState();
  const desktopContactModal = useModalState();
  const settings = getSupportSettings(i18n.locale);
  const isChatOpen = settings.open.isChatOpen;
  const isPhoneOpen = settings.open.isPhoneOpen;

  let selfServiceLinks: MoreLinkListItem[] = [
    {
      link: Routes.more.profile() + `?${USE_HISTORY_BACK_PARAM}=1`,
      label: t((d) => d.support.self_service.profile_settings),
    },
  ];
  if (isNative) {
    selfServiceLinks = [
      ...selfServiceLinks,
      {
        onClick: geoModal.open,
        label: t((d) => d.support.self_service.share_eta),
      },
    ];
  }
  if (auth.isAccountAdmin) {
    selfServiceLinks = [
      ...selfServiceLinks,
      {
        link: Routes.more.vehicles("create") + `?${USE_HISTORY_BACK_PARAM}=1`,
        label: t((d) => d.support.self_service.add_vehicle),
      },
      {
        onClick: () => browser.open({ url: t((d) => d.dashboard.create_sub_account_url) }),
        label: t((d) => d.support.self_service.add_sub_account),
      },
    ];
  }

  return (
    <>
      <Content
        bordered={false}
        header={
          <div className="lg:hidden">
            <div className="h-[4.9rem]" />
            <div
              className={cn(
                "text-white absolute top-0 left-0 right-0 transition-all overflow-hidden h-[4.9rem] z-50 border-b-4 border-green-400",
                {
                  "!h-screen border-b-0": isRushContactOpen,
                },
                // This makes sure that padding is added on top according to safe-area, see class for more info
                "box-content pt-safe-area"
              )}
            >
              <Section type="single-details">
                <div className="relative z-10">
                  <div className="pt-4 flex items-center">
                    <h4
                      className="grow"
                      onClick={() => {
                        const newIsRushOpen = !isRushContactOpen;
                        if (newIsRushOpen) document.body.classList.add("app-menu--mobile--hidden");
                        if (!newIsRushOpen) document.body.classList.remove("app-menu--mobile--hidden");
                        setIsRushContactOpen(newIsRushOpen);
                      }}
                    >
                      {t((d) => d.support.urgent_heading)}
                    </h4>
                    <div className="flex gap-2">
                      <WhatsappMobileButton disabled={!isChatOpen} roundWithIcon={true} whatsapp={settings.whatsapp} />
                      <CallUsMobileButton disabled={!isPhoneOpen} roundWithIcon={true} phone={settings.phone} />
                    </div>
                  </div>
                  <div className="pt-[calc(50vh-280px)]">
                    <SupportContactDisplay />
                  </div>
                </div>
              </Section>
              <div style={{ background: "var(--primary-gradient)" }} className="absolute inset-0 h-screen" />
            </div>
          </div>
        }
      >
        <Section type="single-details" isTop={true}>
          <h2>{t((d) => d.support.heading)}</h2>
          <h4 className="br-font">{t((d) => d.support.sub_heading)}</h4>
          <div className="mt-8 sm:flex sm:gap-4">
            <div className="sm:w-1/2">
              <Card className="!p-6">
                <h5 className="mb-2">{t((d) => d.support.faq_card.heading)}</h5>
                <div>{t((d) => d.support.faq_card.text)}</div>
                <Button
                  buttonType="primary-outline"
                  className="w-full mt-4"
                  onClick={() => browser.open({ url: Config.DRIVER_SUPPORT_URL.replace(LOCALE_TEMPLATE, i18n.locale) })}
                >
                  <div className="flex justify-between items-center gap">
                    {t((d) => d.support.faq_card.cta)}
                    <IconArrowRight />
                  </div>
                </Button>
              </Card>
              <Card className="!p-6">
                <h5 className="mb-2">{t((d) => d.support.personal_question.heading)}</h5>
                <div>{t((d) => d.support.personal_question.text)}</div>
                <a
                  className={cn(btnCns, btnSizeCns.md, btnTypeCns["primary-outline"], "w-full mt-4")}
                  href="mailto:koerier@brenger.nl"
                >
                  <div className="w-full flex justify-between items-center gap">
                    <b>{t((d) => d.support.email_question.cta)}</b>
                    <IconPencil />
                  </div>
                </a>
              </Card>
            </div>
            <div className="sm:w-1/2">
              <div className="hidden lg:block bg-blue-600 text-white p-4 br-rounded-default mb-4">
                <div className="flex items-center">
                  <h4 className="grow cursor-pointer" onClick={desktopContactModal.open}>
                    {t((d) => d.support.urgent_heading)}
                  </h4>
                  <div className="flex gap-2">
                    <WhatsappMobileButton disabled={!isChatOpen} roundWithIcon={true} whatsapp={settings.whatsapp} />
                    <CallUsMobileButton disabled={!isPhoneOpen} roundWithIcon={true} phone={settings.phone} />
                  </div>
                </div>
              </div>
              <Card className="!p-6">
                <MoreLinkList title={t((d) => d.support.self_service.heading)} links={selfServiceLinks} />
              </Card>
            </div>
          </div>
          <div className="mt-12">
            <FaqSection />
          </div>
        </Section>
      </Content>
      <SheetModal
        header={t((d) => d.support.urgent_heading)}
        isOpen={desktopContactModal.isActive}
        close={desktopContactModal.close}
        style="blue"
      >
        <SupportContactDisplay isDesktop={true} />
      </SheetModal>
      <SheetModal isOpen={geoModal.isActive} close={geoModal.close}>
        <GeoPrimer geolocation={geolocation} closeHandler={geoModal.close} />
      </SheetModal>
    </>
  );
};

const SupportContactDisplay: React.FC<{ isDesktop?: boolean }> = ({ isDesktop }) => {
  const { t, i18n } = useTranslation();
  const settings = getSupportSettings(i18n.locale);
  const isChatOpen = settings.open.isChatOpen;
  const isPhoneOpen = settings.open.isPhoneOpen;
  const isOpen = isChatOpen || isPhoneOpen;
  const status = `<span class="${isOpen ? "text-green-400" : "text-red-600"}">${t(
    (d) => d.support[isOpen ? "status_open" : "status_closed"]
  )}</span>`;

  return (
    <>
      <h3
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __html: `${t((d, withTemplate) => withTemplate(d.support.status_text, { status }))}${isOpen ? "*" : ""}`,
        }}
      />
      {isOpen && <div className="pt-4">{t((d) => d.support.urgent_text)}</div>}
      {!isOpen && (
        <>
          <div className="pt-4">
            {!isPhoneOpen &&
              isChatOpen &&
              t((d, withTemplate) =>
                withTemplate(d.support.phone_is_available_at, { time: settings.nextDay.phoneOpen })
              )}
            {!isPhoneOpen &&
              !isChatOpen &&
              t((d, withTemplate) => withTemplate(d.support.is_available_at, { time: settings.nextDay.chatOpen }))}
          </div>
        </>
      )}
      <div className="pt-8">
        <WhatsappMobileButton disabled={!isChatOpen} whatsapp={settings.whatsapp} />
      </div>
      {!isDesktop && (
        <div className="lg:hidden pt-4">
          <CallUsMobileButton disabled={!isPhoneOpen} phone={settings.phone} />
        </div>
      )}
      {isDesktop && (
        <Button
          onClick={() => window.open(`tel:${settings.phone}`, "_blank")}
          className={cn("w-full mt-4")}
          icon={<IconPhoneClassic className="text-blue-600" />}
          type="button"
          buttonType={"white"}
          disabled={!isPhoneOpen}
        >
          <span className="text-blue-600">
            {t((d) => d.support.call_us)} {settings.phone}
          </span>
        </Button>
      )}
      {isOpen && (
        <small className="block pt-8">
          {"* "}
          {t((d) => d.support.business_hours_disclaimer)}
        </small>
      )}
    </>
  );
};

const WhatsappMobileButton: React.FC<{ roundWithIcon?: boolean; disabled?: boolean; whatsapp: string }> = ({
  roundWithIcon = false,
  disabled,
  whatsapp,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => window.open(createWhatsAppUrl(whatsapp))}
      className={cn({ "w-full": !roundWithIcon, "w-6 !rounded-full": roundWithIcon })}
      type="button"
      buttonType={"secondary"}
      disabled={disabled}
    >
      <div className="flex justify-center items-center gap-2">
        <IconWhatsapp style={{ fontSize: 20 }} />
        {!roundWithIcon && t((d) => d.support.start_chat)}
      </div>
    </Button>
  );
};

const CallUsMobileButton: React.FC<{ roundWithIcon?: boolean; disabled?: boolean; phone: string }> = ({
  roundWithIcon = false,
  disabled,
  phone,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => window.open(`tel:${phone}`, "_blank")}
      className={cn({
        "w-full": !roundWithIcon,
        "w-6 !rounded-full border !border-white": roundWithIcon,
      })}
      type="button"
      buttonType={roundWithIcon ? "primary-outline" : "white"}
      disabled={disabled}
    >
      <div className="flex justify-center items-center gap-2">
        <IconPhoneClassic className={cn({ "text-white": roundWithIcon, "text-blue-600": !roundWithIcon })} />
        {!roundWithIcon && <span className="text-blue-600">{t((d) => d.support.call_us)}</span>}
      </div>
    </Button>
  );
};

interface Open {
  chatOpen: string;
  chatClosed: string;
  phoneOpen: string;
  phoneClosed: string;
}

interface IsOpen extends Open {
  isChatOpen: boolean;
  isPhoneOpen: boolean;
}

interface SupportSetting {
  whatsapp: string;
  phone: string;
  open: IsOpen;
  nextDay: Open;
}

const getSupportSettings = (locale: Locale): SupportSetting => {
  // Cast to string to hush TS using it as index on a object (without losing locale type as argument)
  // We fall back to dutch settings
  const whatsappNL = "+31633748236";
  const whatsapp =
    {
      "de-DE": "+4928229113057",
    }[locale as "de-DE"] || whatsappNL; // Fallback to NL

  const phone = "+31858881934"; // Not lang specific

  const now = new Date();
  const standard = {
    chatOpen: "08:00",
    chatClosed: "22:00",
    phoneOpen: "08:00",
    phoneClosed: "22:00",
  };
  const weekend = {
    chatOpen: "09:00",
    chatClosed: "21:00",
    phoneOpen: "09:00",
    phoneClosed: "21:00",
  };
  const specialDays = {
    // Saturday
    6: weekend,
    // Sunday
    0: weekend,
  };
  // the good old JS days: 0 - 6
  const dayNumber = now.getDay();
  // Special days are sat and sun, else fallback to weekday
  const supportHoursToday = (specialDays[dayNumber as 0 | 6] || standard) as Open;
  // When saturday (=== 6) then next day is 0, else it is day + 1
  const nextDayNumber = dayNumber === 6 ? 0 : dayNumber + 1;
  // Same trick different day
  const supportHoursNextDay = (specialDays[nextDayNumber as 0 | 6] || standard) as Open;

  const chatOpenTime = supportHoursToday?.chatOpen.split(":").map((time) => Number(time));
  const chatOpen = set(new Date(), {
    hours: chatOpenTime?.[0],
    minutes: chatOpenTime?.[1],
    seconds: 0,
    milliseconds: 0,
  });
  const chatClosedTime = supportHoursToday?.chatClosed.split(":").map((time) => Number(time));
  const chatClosed = set(new Date(), {
    hours: chatClosedTime?.[0],
    minutes: chatClosedTime?.[1],
    seconds: 0,
    milliseconds: 0,
  });
  const phoneOpenTime = supportHoursToday?.phoneOpen.split(":").map((time) => Number(time));
  const phoneOpen = set(new Date(), {
    hours: phoneOpenTime?.[0],
    minutes: phoneOpenTime?.[1],
    seconds: 0,
    milliseconds: 0,
  });
  const phoneClosedTime = supportHoursToday?.phoneClosed.split(":").map((time) => Number(time));
  const phoneClosed = set(new Date(), {
    hours: phoneClosedTime?.[0],
    minutes: phoneClosedTime?.[1],
    seconds: 0,
    milliseconds: 0,
  });

  return {
    phone,
    whatsapp,
    open: {
      ...supportHoursToday,
      isChatOpen: isAfter(now, chatOpen) && isBefore(now, chatClosed),
      isPhoneOpen: isAfter(now, phoneOpen) && isBefore(now, phoneClosed),
    },
    nextDay: supportHoursNextDay,
  };
};
