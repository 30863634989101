import { useQuery } from "@tanstack/react-query";
import { coreClient, CacheKey, getSanitizedUserList, getFirstAndLastName } from "../utils";
import { useAuth } from "./useAuth";
import { getIdFromIri } from "@brenger/utils";

export const useAdminDrivers = (
  disableSuspendedDrivers = true
): {
  options: JSX.Element[];
} | null => {
  const auth = useAuth();
  const accountId = getIdFromIri(auth.user?.account);

  const accountUsers = useQuery(
    [CacheKey.ACCOUNT_USERS],
    () => coreClient.accounts.listUsers({ id: accountId as string }),
    {
      enabled: !!accountId,
    }
  );

  // We want to hide the driver select if
  // 1) the auth user is not an account admin
  // 2) when results are not fetched yet or
  // 3) when there is only one account member
  const hideDriverSelect = !auth.isAccountAdmin || !accountUsers.data || accountUsers.data?.["hydra:totalItems"] === 1;

  if (hideDriverSelect) return null;

  // We should expect hydra:member to be an empty list at all times - but sometimes it is not for whatever
  // reason. Therefore, best to be defensive and guard against such cases with optional `.?` operator.
  const userList = accountUsers.data?.["hydra:member"];
  if (!userList) return null;

  const options = getSanitizedUserList(userList)
    // Lastly, create an option for the select out of each user in the list.
    .map((user) => {
      return (
        <option key={user.email} value={user["@id"]} disabled={disableSuspendedDrivers && user.is_suspended}>
          {getFirstAndLastName(user)}
        </option>
      );
    });

  if (options.length === 0) return null;
  return { options };
};
