import { AddressCreateParams, DayRouteActivity, DayRouteActivityUpdateParams } from "@brenger/api-client";
import { Button, Message, SeperateLine, Spacer } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import { useCache, useForm, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, DayRouteStopParams, Routes, serializeAddress } from "../../../utils";
import { convertStopFormDataIntoDayRouteActivity } from "../../dayroute/utils";
import { DayRouteActivityForm, DayRouteActivityFormType } from "../../dayroute/DayRouteAdd/DayRouteActivityForm";
import { SheetModal } from "../../../layout";
import { CancelCustomStop } from "../CancelCustomStop";

interface Props {
  activity: DayRouteActivity;
  isActive: boolean;
  closeHandler(): void;
}

const getInitialState = (activity: DayRouteActivity): DayRouteActivityFormType => {
  return {
    type: activity.type,
    search: activity.address ? serializeAddress(activity.address) : "",
    place: undefined,
    service_time_seconds: activity.service_time_seconds ?? 60 * 5,
    capacity_m3: activity.capacity_m3 ?? 0,
    // NOTE about date: core returns different kind of date string -- so set back to native + toISOString so that select is able to initialize value.
    // NOTE #2: there should always be a start_time and end_time when editing an existing day route activity. But just in case... give some kind of date.
    start_time: activity.start_time ? new Date(activity.start_time).toISOString() : new Date().toISOString(),
    end_time: activity.end_time ? new Date(activity.end_time).toISOString() : new Date().toISOString(),
    index: activity.index,
    description: activity.description || undefined,
  };
};

export const DayRouteEditCustomStop: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const params = useParams<DayRouteStopParams>();

  const cache = useCache();
  const onSuccess = (): void => {
    cache.clear();
    cache.queryClient.resetQueries([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date]);
  };
  const updateDayRouteActivity = useMutation(coreClient.dayRoutesActivities.update, { onSuccess });

  const form = useForm({
    initialState: getInitialState(props.activity),
    validators: {
      place: (place) => !place,
      description: (description) => !description,
      start_time: (start_time) => !start_time,
      end_time: (end_time) => !end_time,
    },
  });

  // Reset when path is changing
  const { pathname } = useLocation();
  React.useEffect(() => {
    form.reset();
  }, [pathname]);

  const onSubmit = (): void => {
    const formData = convertStopFormDataIntoDayRouteActivity(form);

    const updateParams: Partial<DayRouteActivityUpdateParams> = {};

    // NOTE only update params if the field was actaully touched/changed
    if (form.isFieldDirty("start_time")) updateParams.start_time = formData.start_time;
    if (form.isFieldDirty("end_time")) updateParams.end_time = formData.end_time;
    if (form.isFieldDirty("service_time_seconds")) updateParams.service_time_seconds = formData.service_time_seconds;
    if (form.isFieldDirty("capacity_m3")) updateParams.capacity_m3 = formData.capacity_m3;
    if (form.isFieldDirty("place")) {
      const {
        line1,
        line2,
        postal_code: pc,
        locality,
        municipality,
        administrative_area: aa,
        country_code: cc,
        lat,
        lng,
      } = formData.address;

      const addressParams: Partial<AddressCreateParams> = {};

      if (line1) addressParams.line1 = line1;
      if (line2) addressParams.line2 = line2;
      if (pc) addressParams.postal_code = pc;
      if (locality) addressParams.locality = locality;
      if (municipality) addressParams.municipality = municipality;
      if (aa) addressParams.administrative_area = aa;
      if (cc) addressParams.country_code = cc;
      if (lat) addressParams.lat = lat;
      if (lng) addressParams.lng = lng;

      updateParams.address = addressParams as AddressCreateParams;
    }

    updateDayRouteActivity.mutate({
      id: getIdFromIri(props.activity) || "",
      ...updateParams,
      address: updateParams.address as AddressCreateParams,
    });
  };

  return (
    <SheetModal
      header={t((d) => d.stop_edit.headings.change_cancel_stop)}
      isOpen={props.isActive}
      close={props.closeHandler}
    >
      <div className={cn("inline-flex", "items-center")}>
        <h6 className="uppercase">
          {props.activity.type === "custom_other" && t((d) => d.transport_job.headings.custom_other)}
          {props.activity.type !== "custom_other" &&
            t((d) => d.transport_job.headings[props.activity.type === "custom_pickup" ? "pickup" : "delivery"])}
        </h6>
        <SeperateLine spaceWidth={2} type="blueGray" />
        <h6 className={cn("text-gray-500", "uppercase")}>{t((d) => d.day_route.details.own_stop)}</h6>
      </div>
      {/* NOTE: empty list for activities is fine - this way we do not show the re-order dropdown. */}
      <DayRouteActivityForm activities={[]} form={form} />
      <Spacer h={4} />

      {updateDayRouteActivity.error && (
        <Message className={cn("mt-4")} type="error">
          {(updateDayRouteActivity.error as Error).message}
        </Message>
      )}
      <Button
        disabled={!form.isDirty}
        loading={updateDayRouteActivity.isLoading}
        className={cn("w-full")}
        buttonType="secondary"
        onClick={onSubmit}
      >
        {t((d) => d.actions.submit)}
      </Button>
      <Spacer h={4} />
      <CancelCustomStop activity={props.activity} returnUrl={Routes.dayroutes.details(params)} />
    </SheetModal>
  );
};
