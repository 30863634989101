import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isTop?: boolean;
}

export type SectionType = "split-details" | "split-list" | "single-details";

export const Section: React.FC<Props & { type: SectionType }> = ({ type, ...props }) => {
  if (type === "split-details") return <SplitDetailsSection {...props} />;
  if (type === "split-list") return <SplitListSection {...props} />;
  return <SingleDetailsSection {...props} />;
};

const SplitDetailsSection: React.FC<Props> = ({ isTop = false, className, children, ...rest }) => (
  <div
    className={cn(className, "px-4 lg:mx-4 xl:px-8 xl:mx-auto xl:max-w-[768px]", {
      "mt-8 2xl:mt-16": isTop,
    })}
    {...rest}
  >
    {children}
  </div>
);

const SplitListSection: React.FC<Props> = ({ isTop = false, className, children, ...rest }) => (
  <section className={cn(className, "block px-4 xl:px-8 2xl:px-16", { "mt-4 lg:mt-8": isTop })} {...rest}>
    {children}
  </section>
);

const SingleDetailsSection: React.FC<Props> = ({ isTop = false, className, children, ...rest }) => (
  <div
    className={cn(className, "px-4 mx-auto md:max-w-[900px]", {
      "pt-16 lg:pt-32": isTop,
    })}
    {...rest}
  >
    {children}
  </div>
);
