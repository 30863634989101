import * as React from "react";
import { Contact } from "@brenger/api-client";
import {} from "@brenger/react";
import cn from "classnames";

import { getFirstAndLastName } from "../../utils";
import { TextPlaceholder } from "..";

interface Props {
  contact: Contact | null;
  companyName?: string | null;
}

export const ContactDetails: React.FC<Props> = ({ contact, companyName }) => {
  if (!contact) {
    return (
      <div className={cn("flex", "flex-col")}>
        <TextPlaceholder />
        <TextPlaceholder />
        <TextPlaceholder />
      </div>
    );
  }

  return (
    <div>
      {companyName && <div>{companyName} </div>}
      <div>{getFirstAndLastName(contact)}</div>
      <div>{contact.email}</div>
      <div>{contact.phone}</div>
    </div>
  );
};
