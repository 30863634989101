import { Button } from "@brenger/react";
import cn from "classnames";
import { isAfter } from "date-fns";
import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { useHistory, useParams } from "react-router-dom";

import { useCache, useTranslation } from "../../../hooks";
import { coreClient, DayRouteStopParams, Routes } from "../../../utils";
import { Content, PageHead, Section } from "../../../layout";

export const DayRouteConfirmCustomStop: React.FC<{ humanIndex: number }> = (props) => {
  const { t } = useTranslation();
  const params = useParams<DayRouteStopParams>();
  const history = useHistory();
  const cache = useCache();

  const onSuccess = (): void => {
    cache.clear();
    history.push(Routes.dayroutes.stop(params));
  };

  const confirmStop = useMutation(coreClient.dayRoutesActivities.confirmCustomStop, { onSuccess });
  const canConfirm = isAfter(new Date(), new Date(params.date));
  return (
    <Content
      disablePullToRefresh={true}
      header={
        <PageHead
          sectionType={"split-details"}
          backUrl={Routes.dayroutes.details(params)}
          title={`Confirm stop: ${props.humanIndex}`}
        />
      }
      footer={
        <Button
          className={cn("w-full")}
          buttonType="secondary"
          disabled={!canConfirm || confirmStop.isLoading}
          loading={confirmStop.isLoading}
          onClick={() => confirmStop.mutate({ id: params.stop_id })}
        >
          {t((d) => d.planning.labels.confirm_stop)}
        </Button>
      }
    >
      <Section type="split-details" isTop={true}>
        <div>{t((d) => d.day_route.own_stop.confirm_body)}</div>
      </Section>
    </Content>
  );
};
