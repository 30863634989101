import { Strong, Timeline } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { DailyFoodEmoji } from "../../../components";
import { useTranslation } from "../../../hooks";
import { DayRouteStopEditCarets } from "./DayRouteStopEditCarrets";

interface Props {
  breakSeconds: number;
  isEditing: boolean;
  moveUp?(): void;
  moveDown?(): void;
}

export const DayRouteStopBreak: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Timeline isFirst={false} isLast={false} showPoint={Boolean(!props.moveDown)}>
      <div className={cn("flex", "items-center", "justify-between", "pr-4", "relative")}>
        <div className={cn("p-4", "rounded-full", "bg-blue-100", "flex")}>
          <Strong className={cn("text-blue-600", "mr-1")}>
            {t((d, withTemplate) => {
              return withTemplate(d.day_route.details.message.lunch_break, {
                minutes: Math.round(props.breakSeconds / 60),
              });
            })}
          </Strong>
          <DailyFoodEmoji />
        </div>
        {props.isEditing && <DayRouteStopEditCarets moveUp={props.moveUp} moveDown={props.moveDown} />}
      </div>
    </Timeline>
  );
};
