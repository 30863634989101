import * as React from "react";
import { useUserGeneratedContent } from "../../hooks";

interface Props {
  text?: string | null;
}

/**
 * This is a straight-forward application of the useUserGenertaedContent hook.
 * The component takes some text as a child and returns the translated string, if available.
 * This component serves as a convenience, allowing us to use the UGC hook within a
 * component's render method.
 */

export const UserGeneratedContent: React.FC<Props> = (props) => {
  const { text } = useUserGeneratedContent(props.text);

  return <>{text || null}</>;
};
