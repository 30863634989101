import { PersistedStorageKey } from "../utils";
import { usePersistedState } from "./usePersistedState";

interface UseHasSeenTour {
  value: boolean | null | undefined;
  set(value: boolean): void;
  isInitialized: boolean;
}

/**
 * One place were we check the value and increase the version
 * So it can be used after login and on app start
 */

const INIT_VALUE = null;

export const useHasSeenTour = (): UseHasSeenTour => {
  const [value, set] = usePersistedState<boolean | null>({
    initialState: INIT_VALUE,
    defaultState: false,
    // If there is new tour: increase this number
    version: 1,
    key: PersistedStorageKey.HAS_SEEN_WELCOME_TOUR,
  });

  return {
    value,
    set,
    // NOTE: Footgun-ish situation
    // Capacitor takes a split second to initialize the storage when the hook is mounted
    // Value is for that time initial value, but we need to rely on actual value in storage
    // So when this hook is used, listen to this isInitialized before acting upon it
    isInitialized: value === INIT_VALUE,
  };
};
