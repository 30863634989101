import { Button, IconBrengerSmile, IconCaretLeft, IconCaretRight, useModalState } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import { add, isToday, isTomorrow, sub } from "date-fns";
import React from "react";
import Calendar from "react-calendar";
import { DriverSelect } from "../../../components";
import { useAuth, useFormatDate, useTranslation } from "../../../hooks";
import { DayRouteParams, Routes, userDataClient } from "../../../utils";
import { SheetModal } from "../../../layout";

import "react-calendar/dist/Calendar.css";
import { useHistory } from "react-router-dom";
import "./DayRouteSelection.scss";

export const DayRouteSelection: React.FC<DayRouteParams> = ({ date: current, user_id }) => {
  const formatDateForApi = useFormatDate("api-date");
  const formatDayMonthLong = useFormatDate("day-month-short");
  const formatDay = useFormatDate("weekday");
  const { t, i18n } = useTranslation();
  const currentDate = new Date(current);
  const history = useHistory();
  const selectModal = useModalState();
  const auth = useAuth();
  const [driverId, setDriverId] = React.useState<string>(user_id);
  const [calendarDate, setCalendarDate] = React.useState<Date>(currentDate);

  const setNewDayRoute = (newDate?: Date | "next" | "prev"): void => {
    let date: Date = calendarDate;
    if (newDate === "prev") {
      date = sub(currentDate, { days: 1 });
    }
    if (newDate === "next") {
      date = add(currentDate, { days: 1 });
    }
    selectModal.close();
    history.push(Routes.dayroutes.details({ date: formatDateForApi(date.toISOString()), user_id: driverId }), "none");
  };

  const getDateHeading = (): string => {
    if (isToday(currentDate)) {
      return t((d) => d.calendar.today);
    }
    if (isTomorrow(currentDate)) {
      return t((d) => d.calendar.tomorrow);
    }
    return `${formatDay(currentDate.toISOString())}, ${formatDayMonthLong(currentDate.toISOString())}`;
  };

  return (
    <>
      <div className="flex justify-between text-blue-600">
        <IconCaretLeft style={{ minWidth: "2rem", fontSize: "2rem" }} onClick={() => setNewDayRoute("prev")} />
        <div
          className="flex items-center gap-2 cursor-pointer max-w-[calc(100%-4rem)]"
          onClick={() => {
            selectModal.open();
            setCalendarDate(currentDate);
          }}
        >
          <RenderProfileImage user_id={user_id} />
          <b className={cn("block", "first-letter:uppercase", "whitespace-nowrap", "text-ellipsis", "overflow-hidden")}>
            {getDateHeading()}
          </b>
          <IconBrengerSmile
            style={{ fontSize: "0.8em" }}
            className={cn("transition-all", { "rotate-180": selectModal.isActive })}
          />
        </div>
        <IconCaretRight style={{ minWidth: "2rem", fontSize: "2rem" }} onClick={() => setNewDayRoute("next")} />
      </div>
      <SheetModal
        header={"Selecteer een koerier of dag"}
        isOpen={selectModal.isActive}
        close={selectModal.close}
        sheetWrapperWidth={"lg:w-[360px]"}
      >
        <div className="border border-gray-300 br-rounded-default py-1 px-3 mb-4 max-w-[300px] mx-auto">
          <DriverSelect
            className="dayroute--driver-select flex gap-4 items-center w-full"
            beforeSelect={<RenderProfileImage user_id={driverId} />}
            value={`/users/${driverId}`}
            accountId={getIdFromIri(auth.user?.account)}
            noDefaultClasses={true}
            onChange={({ userIRI }) => {
              setDriverId(getIdFromIri(userIRI) || "");
            }}
          />
        </div>
        <div className="border border-gray-300 br-rounded-default py-1 px-3 mb-4  max-w-[300px] mx-auto">
          <div>
            <Calendar
              locale={i18n.locale}
              minDetail="year"
              className={"dayroute--date-select"}
              value={calendarDate}
              formatShortWeekday={(_, date) => formatDay(date.toISOString()).slice(0, 1)}
              onChange={(newCalendarDate) => setCalendarDate(newCalendarDate as Date)}
            />
            <div className="text-right pr-3">
              <button onClick={() => setCalendarDate(new Date())} className="text-blue-600 cursor-pointer self-end">
                {t((d) => d.calendar.today)}
              </button>
            </div>
          </div>
        </div>
        <Button className="w-full" onClick={() => setNewDayRoute()}>
          Bevestig
        </Button>
      </SheetModal>
    </>
  );
};

const RenderProfileImage: React.FC<{ user_id: string }> = ({ user_id }) => {
  return (
    <img
      className={cn("h-8", "w-8", "object-cover", "rounded-full")}
      src={userDataClient.getProfileImageUrl(user_id, { w: 100, h: 100 })}
      // While professional images are sort of mandatory, we ask for it explicitly in onboarding
      // It could still happen that images are not available for some reason
      onError={({ currentTarget }) => {
        // Prevents loop after rerender
        currentTarget.onerror = null;
        // Hides image
        currentTarget.style.display = "none";
      }}
    />
  );
};
