import { Label, Select, IconCheck } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { Options } from "..";
import { useAuth, useTranslation } from "../../hooks";
import { EditOrDeleteVehicle } from "../../pages/more/vehiclesDetails/EditOrDeleteVehicle";
import { CacheKey, coreClient } from "../../utils";

interface Props {
  className?: string;
  /**
   * A label for which appears above the select
   */
  labelText?: string;
  /**
   * The ID of the vehicle currently selected.
   */
  value: string | null;
  /**
   * A standard on change handler that returns a new vehicle IRI.
   */
  onChange(args: { vehicleIRI: string | null }): void;
}

export const VehicleSelect: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const vehicles = useQuery([CacheKey.RETRIEVE_VEHICLE_LIST], () => coreClient.vehicles.list({ page: 1 }));

  const vehiclesList = vehicles.data?.["hydra:member"];

  if (!vehiclesList || !auth.isAccountAdmin) return null;

  const options = vehiclesList.map((vehicle) => {
    return (
      <option key={vehicle["@id"]} value={vehicle["@id"]}>
        {vehicle.license_plate} ({vehicle.model})
      </option>
    );
  });

  if (options.length === 0) return null;

  const vehicle = vehiclesList.find((v) => v["@id"] === props.value);

  return (
    <>
      <Label text={props.labelText} className={cn(props.className)}>
        <Select
          className={cn("w-full")}
          value={props.value || undefined}
          onChange={(vehicleIRI) => props.onChange({ vehicleIRI: vehicleIRI || null })}
        >
          <Options placeholderText={"--"} placeholderValue={""}>
            {options}
          </Options>
        </Select>
      </Label>
      <div className={cn("flex", "justify-start", "mt-2")}>
        {vehicle?.has_tailgate && (
          <div className={cn("flex", "items-center", "mr-4")}>
            <IconCheck className={cn("text-green-400", "mr-2")} />
            {t((d) => d.vehicles.tailgate)}
          </div>
        )}
        {vehicle?.has_pallet_jack && (
          <div className={cn("flex", "items-center", "mr-4")}>
            <IconCheck className={cn("text-green-400", "mr-2")} />
            {t((d) => d.vehicles.pallet_jack)}
          </div>
        )}

        {vehicle && <EditOrDeleteVehicle vehicle={vehicle} onEditSuccess={vehicles.refetch} />}
      </div>
    </>
  );
};
