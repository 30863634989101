import { Button, IconSmile } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import * as React from "react";
import { ExternalLink, Grid } from "../../../components";
import { useAuth, useTranslation } from "../../../hooks";
import { Content, PageHead, Section } from "../../../layout";
import { CacheKey, Routes, coreClient, getDeletedUserList, getSanitizedUserList } from "../../../utils";
import { SubAccountCard } from "./SubAccountCard";

export const MoreCouriers: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const accountUsers = useQuery(
    [CacheKey.RETRIEVE_USERS, auth.user?.account?.["@id"]],
    () => coreClient.accounts.listUsers({ id: getIdFromIri(auth.user?.account) as string }),
    {
      enabled: !!auth.user,
    }
  );

  const userList = accountUsers.data?.["hydra:member"];
  const sanitizedUserList = userList ? getSanitizedUserList(userList) : [];
  const deletedUserList = userList ? getDeletedUserList(userList) : [];
  return (
    <Content
      header={<PageHead sectionType="single-details" title={t((d) => d.more.drivers)} backUrl={Routes.more.home()} />}
      isLoading={accountUsers.isLoading}
    >
      <Section type="single-details" isTop={true}>
        <Grid cols={{ lg: 2 }} gap={4}>
          {sanitizedUserList.map((user) => (
            <SubAccountCard key={user["@id"]} user={user} />
          ))}
        </Grid>
        {deletedUserList.length !== 0 && (
          <>
            <div className={cn("flex", "items-center", "pt-4")}>
              <hr className={cn("grow")} />
              <div className={cn("text-white", "px-4")}>
                <div>{t((d) => d.overview_couriers_deleted)}</div>
              </div>
              <hr className={cn("grow")} />
            </div>
            <div className={cn("text-white", "text-center", "pb-4")}>
              <div>{t((d) => d.overview_couriers_deleted_explain)}</div>
            </div>
            <Grid cols={{ lg: 2 }} gap={4}>
              {deletedUserList.map((user) => (
                <SubAccountCard key={user["@id"]} user={user} />
              ))}
            </Grid>
          </>
        )}
        <Grid cols={{ default: 2 }} gap={4} className="mt-8">
          <ExternalLink url={t((d) => d.dashboard.performance_tips_url)}>
            <Button
              type="button"
              buttonType="secondary"
              iconPos={"right"}
              className="w-full"
              icon={<IconSmile className={cn("text-white", "h-4", "w-4")} />}
            >
              <div className={cn("inline-block")}>{t((d) => d.dashboard.performance_tips)}</div>
            </Button>
          </ExternalLink>
          <ExternalLink url={t((d) => d.dashboard.create_sub_account_url)}>
            <Button type="button" iconPos={"right"} icon={<IconSmile className={cn("text-white", "h-4", "w-4")} />}>
              <div className={cn("inline-block")}>{t((d) => d.profile.add_user)}</div>
            </Button>
          </ExternalLink>
        </Grid>
      </Section>
    </Content>
  );
};
