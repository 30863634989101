import * as Sentry from "@sentry/react";
import BackgroundGeolocation, {
  CurrentPositionRequest,
  Location,
  State,
} from "@transistorsoft/capacitor-background-geolocation";
import * as React from "react";
import { useNativePlatform } from "./useNativePlatform";
import { useSelector } from "./useSelector";
import { useTranslation } from "./useTranslation";
import { BrengerProviderChangeEvent } from "../store/appSettings/types";
import { useDeviceInfo } from "./useDeviceInfo";
import { logger } from "../utils";

interface SubscriptionArgs {
  callback?(): void;
}

export interface UseGeolocationMethods {
  start(args?: SubscriptionArgs): Promise<void>;
  stop(args?: SubscriptionArgs): Promise<void>;
  prompt(args?: SubscriptionArgs & { triggerStart?: boolean }): Promise<void>;
  getCurrentPosition(args?: CurrentPositionRequest): Promise<undefined | Location | null>;
  isBackgroundTrackingEnabled: boolean;
  isPreciseLocationServiceAvailable: boolean;
  isGetLocationLoading: boolean;
  getLocationError: null | string;
  state: null | State;
  provider: BrengerProviderChangeEvent | null;
  isLoading: boolean;
  hasPermission: boolean;
}

/**
 * Consists of simple interactions with the geo plugin
 */
export const useGeolocationMethods = (): UseGeolocationMethods => {
  const isNativePlatform = useNativePlatform();
  const platform = useDeviceInfo()?.platform;
  const [pluginState, setPluginState] = React.useState<null | State>(null);
  const [isBackgroundTrackingEnabled, setIsBackgroundTrackingEnabled] = React.useState(false);
  const [isGetLocationLoading, setIsGetLocationLoading] = React.useState(false);
  const [getLocationError, setGetLocationError] = React.useState<null | string>(null);
  const provider = useSelector((state) => state.appSettings.location.provider);

  /**
   * See AuthorizationStatus of
   */
  const hasPermission = !!(provider?.status || 0 <= 3);

  const { t } = useTranslation();
  // With this bool we can say something about if all needed services are switched on to be able to have a precise location
  const isPreciseLocationServiceAvailable = !!(provider && provider.gps && !provider.airplane);

  const stateFetcher = async (): Promise<void> => {
    const state = await BackgroundGeolocation.getState();
    setPluginState(state);
    setIsBackgroundTrackingEnabled(state.enabled || state.schedulerEnabled);
  };

  React.useEffect(() => {
    if (!isNativePlatform) return;
    stateFetcher();
    const listener = BackgroundGeolocation.onSchedule((state) => {
      setIsBackgroundTrackingEnabled(state.enabled);
    });
    return () => listener.remove();
  }, [isNativePlatform]);

  /**
   * START GEOLOCATION PLUGIN
   */
  const start = async (args?: SubscriptionArgs): Promise<void> => {
    if (!isNativePlatform) return;
    try {
      await BackgroundGeolocation.startSchedule();
      setIsBackgroundTrackingEnabled(true);
      await args?.callback?.();
    } catch (error) {
      const message = (error as Error | null)?.message;
      // Nothing to do, courier tries to kick off the schedule, out side of schedule times
      if (message && message.includes("Did you configure a #schedule")) {
        setIsBackgroundTrackingEnabled(true);
        await args?.callback?.();
      } else {
        Sentry.captureMessage("[GP METHODS] Failed starting.", { extra: { error, pluginState } });
      }
    }
  };

  /**
   * STOP GEOLOCATION PLUGIN
   */
  const stop = async (args: SubscriptionArgs): Promise<void> => {
    if (!isNativePlatform) return;
    try {
      // https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/config.html#schedule:~:text=BackgroundGeolocation.stop()%3B
      // explicitly stop tracking
      await BackgroundGeolocation.stop();
      // explicitly stop the schedule
      await BackgroundGeolocation.stopSchedule();
      setIsBackgroundTrackingEnabled(false);
      await args?.callback?.();
    } catch (error) {
      Sentry.captureMessage("[GP METHODS] Failed stopping.", { extra: { error, pluginState } });
    }
  };

  /**
   * PROMPT USER MANUALLY
   * According to the docs location methods (start, schedule, getLocation etc) automatically aks for permission (when not asked yet).
   * But when outside "tracking schedule" it fails, so it doesn't prompt the user and doesn't do anything.
   * Doing it manual is fail safe.
   */
  const prompt = async (args: SubscriptionArgs & { triggerStart?: boolean }): Promise<void> => {
    if (!isNativePlatform) return;
    try {
      // All
      const permission = await BackgroundGeolocation.requestPermission();
      // Check permission type: 3 = wheninuse and 4 = always, so only then execute start
      if (permission > 2 && args.triggerStart) {
        await start();
      }
      await args?.callback?.();
    } catch (error) {
      Sentry.captureMessage("[GP METHODS] Failed stopping.", { extra: { error, pluginState } });
    }
  };

  const getCurrentPosition = async (a?: CurrentPositionRequest): Promise<undefined | Location | null> => {
    if (!isNativePlatform) return;
    setIsGetLocationLoading(true);
    setGetLocationError(null);
    try {
      const options = {
        desiredAccuracy: 50, // Try to fetch a location with an accuracy of `50` meters.
        timeout: 30, // 30 second timeout to fetch location
        maximumAge: 1000, // Accept the last-known-location if not older than 1000 ms.
        samples: 3, // How many location samples to attempt.
        ...(a || {}),
      };
      logger.dev(`['location'] getting location - options: ${JSON.stringify(options)}`);
      const location = await BackgroundGeolocation.getCurrentPosition(options);
      setIsGetLocationLoading(false);
      if (location) {
        return location;
      }
      // This should never happen, plugin should populate the catch below if something is wrong
      setGetLocationError(t((d) => d.location.error));
      return null;
    } catch (error) {
      Sentry.captureMessage("[GP METHODS] Failed getting current location.", { extra: { error, pluginState } });
      setIsGetLocationLoading(false);
      setGetLocationError(t((d) => d.location.error));
      return null;
    }
  };

  return {
    start,
    stop,
    getCurrentPosition,
    getLocationError,
    prompt,
    isPreciseLocationServiceAvailable,
    isBackgroundTrackingEnabled,
    isGetLocationLoading,
    state: pluginState,
    provider,
    isLoading: !platform || (isNativePlatform && provider === null),
    hasPermission,
  };
};
