import {
  CountryCode,
  DayRouteActivity,
  DayRouteActivityCreationParams,
  DayRouteActivityForUpdate,
  DayRouteActivityType,
} from "@brenger/api-client";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import { DayRouteActivityFormType } from "./DayRouteAdd/DayRouteActivityForm";
import { ErrorLevel } from "../../types";
import { formatSecondsToHoursMinutes } from "../../utils";
import { UseForm } from "../../hooks";
import { getIdFromIri } from "@brenger/utils";

export interface Time {
  hours: number;
  minutes: number;
}
export interface TravelMeta {
  arrivalTime: string | null;
  departureTime: string | null;
  travelTime: Time | null;
  travelDistanceInKm: string | null;
}

interface TravelMetaParams {
  eta: string | null;
  etd: string | null;
  driving_duration_seconds: number | null;
  driving_distance_meters: number | null;
}

/**
 * Method to calculate arrival, departure and travel time per activity
 */
export const getTravelMeta = (params: TravelMetaParams): TravelMeta => {
  // NOTE: ETAs come straight from the API as dateTimeIso
  // Departure time
  const arrivalTime = params.eta;

  // Arrival time is departure time minus service time
  const departureTime = params.etd;

  let travelTime: Time | null = null;
  if (params.driving_duration_seconds) {
    travelTime = formatSecondsToHoursMinutes(params.driving_duration_seconds);
  }

  let travelDistanceInKm = null;

  if (params.driving_distance_meters) {
    travelDistanceInKm = (params.driving_distance_meters / 1000).toFixed(0);
  }

  return {
    arrivalTime,
    departureTime,
    travelTime,
    travelDistanceInKm,
  };
};

export type ActivityValidationError =
  | "delivery_is_before_pickup"
  | "pickup_is_after_delivery"
  | "eta_is_before_available_dtp"
  | "eta_is_after_available_dtp"
  | "eta_is_before_committed_start_time"
  | "eta_is_after_committed_end_time"
  | "eta_is_before_committed_start_time_but_still_changeable"
  | "eta_is_after_committed_end_time_but_still_changeable";

export const activityValidationErrorLevelMap: Record<ActivityValidationError, ErrorLevel> = {
  delivery_is_before_pickup: "error",
  pickup_is_after_delivery: "error",
  eta_is_before_available_dtp: "error",
  eta_is_after_available_dtp: "error",
  eta_is_before_committed_start_time: "error",
  eta_is_after_committed_end_time: "error",
  eta_is_before_committed_start_time_but_still_changeable: "warning",
  eta_is_after_committed_end_time_but_still_changeable: "warning",
};

const PICKUP_TYPES: DayRouteActivityType[] = ["pickup", "custom_pickup"];
const DELIVERY_TYPES: DayRouteActivityType[] = ["delivery", "custom_delivery"];

type ActivityWithEta = DayRouteActivity & { eta: string | null };

export const validateActivity = (
  activity: ActivityWithEta,
  activities: DayRouteActivity[]
): ActivityValidationError | null => {
  const {
    index,
    type,
    eta,
    last_change_time_deadline: lastChangeDeadline,
    start_time: startTime,
    end_time: endTime,
    related_day_route_activity: relatedActivityIri,
    active_available_datetime_period: availableDtp,
  } = activity;
  const relatedActivityIndex = activities.find((a) => a["@id"] === relatedActivityIri)?.index;

  // ORDERING ISSUE: this is the most problematic validation issue, ie, when related stop is incorrectly ordered
  if (relatedActivityIndex) {
    if (PICKUP_TYPES.includes(type) && relatedActivityIndex < index) {
      return "delivery_is_before_pickup";
    }

    if (DELIVERY_TYPES.includes(type) && relatedActivityIndex > index) {
      return "pickup_is_after_delivery";
    }
  }

  // OUTSIDE AVAILABLE DTP
  if (eta && availableDtp) {
    const isEtaBeforeAvailableDtp = isBefore(new Date(eta), new Date(availableDtp.start));

    if (isEtaBeforeAvailableDtp) {
      return "eta_is_before_available_dtp";
    }

    const isEtaAfterAvailableDtp = isAfter(new Date(eta), new Date(availableDtp.end));

    // OUTSIDE AVAILABLE DTP
    if (isEtaAfterAvailableDtp) {
      return "eta_is_after_available_dtp";
    }
  }

  // OUTSIDE COMMITTED DTP
  if (eta && startTime && endTime) {
    let isNowBeforeLastChangeDeadline = true;

    if (lastChangeDeadline) {
      isNowBeforeLastChangeDeadline = isBefore(new Date(), new Date(lastChangeDeadline));
    }

    const isEtaBeforeStartTime = isBefore(new Date(eta), new Date(startTime));

    if (isEtaBeforeStartTime) {
      if (isNowBeforeLastChangeDeadline) {
        return "eta_is_before_committed_start_time_but_still_changeable";
      }

      return "eta_is_before_committed_start_time";
    }

    const isEtaAfterStartTime = isAfter(new Date(eta), new Date(endTime));

    if (isEtaAfterStartTime) {
      if (isNowBeforeLastChangeDeadline) {
        return "eta_is_after_committed_end_time_but_still_changeable";
      }

      return "eta_is_after_committed_end_time";
    }
  }

  return null;
};

// NOTE: this is a stub function for now - eventually, should be smarter and automatically calculate an index.
// eslint-disable-next-line
export const calculateIndexForActivity = (_: {
  newActivity: DayRouteActivityForUpdate;
  activities?: DayRouteActivityForUpdate[];
}): number => {
  return 0;
};

// NOTE: This util massages the StopForm fields into a valid DayRouteActivityCreationParam.
export const convertStopFormDataIntoDayRouteActivity = (
  form: UseForm.Form<DayRouteActivityFormType>
): DayRouteActivityCreationParams => {
  const {
    type,
    index,
    description,
    place,
    service_time_seconds: serviceTime,
    start_time: startTime,
    end_time: endTime,
    capacity_m3: capacity,
    temp_uuid: tempUuid,
    temp_related_day_route_activity: tempRelatedUuid,
  } = form.data;

  const { address } = place?.value || {};

  return {
    type: type.value,
    pickup: null,
    delivery: null,
    index: index?.value || 0,
    service_time_seconds: serviceTime.value,
    start_time: startTime.value,
    end_time: endTime.value,
    description: description?.value || null,
    capacity_m3: capacity?.value || null,
    temp_uuid: tempUuid?.value || undefined,
    temp_related_day_route_activity: tempRelatedUuid?.value || undefined,
    address: {
      line1: address?.line1 || "",
      line2: address?.line2 || "",
      postal_code: address?.postal_code || "",
      locality: address?.locality || "",
      municipality: address?.municipality || "",
      administrative_area: address?.administrative_area || "",
      country_code: address?.country_code || "NL",
      lat: address?.latitude,
      lng: address?.longitude,
    },
  };
};

type FormatActivities = (
  activity: DayRouteActivityForUpdate,
  index: number,
  activities: DayRouteActivityForUpdate[]
) => DayRouteActivityForUpdate[];

export const formatActivities = (): FormatActivities => {
  return (currentActivity, currentIndex, activities) => {
    // Do we have allready a break activity?
    const hasBreak = activities.some((activity) => activity.type === "break");
    // If we don't have a break, we want to add it when there are more then three activities
    const breakIndex = Math.floor(activities.length / 2);
    const shouldAddBreak = !hasBreak && activities.length > 3 && currentIndex === breakIndex;
    // Remove break when activities drop below 4 + 1 (+1 accounts for the break activity once it's been included)
    const shouldRemoveBreak = hasBreak && activities.length < 5 && currentActivity.type === "break";

    if (shouldAddBreak) {
      // Format a break to pass to core
      const breakActivity: DayRouteActivityCreationParams = {
        pickup: null,
        delivery: null,
        // Represents a 0.5 hour lunch break
        service_time_seconds: 1800,
        end_time: null,
        type: "break",
        index: currentIndex,
        description: null,
        start_time: null,
        capacity_m3: null,
        address: {
          line1: "",
          line2: "",
          postal_code: "",
          locality: "",
          // NOTE: typecast to correct type in order to avoid sending an actual country code.
          country_code: "" as CountryCode,
        },
      };

      return [currentActivity, breakActivity];
    }

    if (shouldRemoveBreak) return [];

    return [currentActivity];
  };
};

type AddIndex = (
  activity: DayRouteActivityForUpdate,
  index: number,
  activities: DayRouteActivityForUpdate[]
) => DayRouteActivityForUpdate;

export const addIndex = (): AddIndex => {
  return (currentActivity, currentIndex) => {
    return { ...currentActivity, index: currentIndex };
  };
};

export const getActivityAndHumanIndex = ({
  activities,
  activityId,
}: {
  activities: DayRouteActivity[];
  activityId: string;
}): { activity: DayRouteActivity | null; humanIndex: number } => {
  const activitiesWithoutBreak = activities?.filter((a) => a.type !== "break") || [];
  const activityIndex = activitiesWithoutBreak.findIndex((act) => getIdFromIri(act) === activityId);
  const activity = activitiesWithoutBreak[activityIndex || 0] || null;
  return {
    activity,
    humanIndex: activityIndex + 1,
  };
};
