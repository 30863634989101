import * as React from "react";
import cn from "classnames";
import { DriverUser } from "@brenger/api-client";
import { Small } from "@brenger/react";

import { getFirstAndLastName } from "../../utils";

interface Props {
  /**
   * A TJAL state pill component.
   */
  state: React.ReactNode;
  /**
   * Can be the email or name or username - just some identifying string.
   */
  driver: DriverUser | null;
}

/**
 * This component handles the layout of TJAL details as seen on the cards
 * in the planning & delivered list views.
 */
export const TjalDetails: React.FC<Props> = (props) => {
  return (
    <div className={cn("flex", "flex-wrap", "justify-between")}>
      <div className={cn("mt-1")}>{props.state && <div className={cn("inline-block", "mr-4")}>{props.state}</div>}</div>
      <div className={cn("mt-1")}>{props.driver && <Small>{getFirstAndLastName(props.driver)}</Small>}</div>
    </div>
  );
};
