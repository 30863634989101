import { Button } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { UseTracking, useTranslation } from "../../hooks";
import image from "./tracking.svg";

export const TrackingPrimer: React.FC<{ tracking: UseTracking; closeHandler(): void }> = ({
  tracking,
  closeHandler,
}) => {
  const { t } = useTranslation();
  const [showContinue, setShowContinue] = React.useState(false);
  return (
    <div className={cn("grid", "gap-4", "text-center", "place-items-center", "w-80", "max-w-full", "mx-auto")}>
      <img src={image} />
      <h3>{t((d) => d.settings.tracking.primer.title)}</h3>
      <div>{t((d) => d.settings.tracking.primer.text)}</div>
      {!showContinue && (
        <>
          <Button
            className={cn("w-full")}
            buttonType="secondary"
            onClick={async () => await tracking.prompt({ callback: () => setShowContinue(true) })}
          >
            {t((d) => d.settings.tracking.primer.cta_enable)}
          </Button>
          <Button className={"w-full"} buttonType="white" onClick={closeHandler}>
            <span className="text-blue-600">{t((d) => d.settings.tracking.primer.cta_dismiss)}</span>
          </Button>
        </>
      )}
      {showContinue && (
        <Button className={"w-full mt-4"} buttonType="secondary" onClick={closeHandler}>
          {t((d) => d.actions.continue)}
        </Button>
      )}
    </div>
  );
};
