import * as React from "react";
import cn from "classnames";
import {} from "@brenger/react";
import { formatMeters } from "@brenger/utils";

import { MetaItem } from "..";
import { useTranslation } from "../../hooks";

// NOTE: adds "+" sign for positive values.
const getSign = (num: number): string => {
  if (num && num > 0) return "+";
  return "";
};

interface Props {
  delta: number;
  total: number;
  type: "Pickup" | "Delivery";
}

export const VolumeDetails: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={cn("flex")}>
      <MetaItem
        name={t((d) => d.transport_job[props.type === "Pickup" ? "load" : "unload"])}
        value={
          <>
            {/* NOTE: we want to show "+" sign for positive values */}
            {getSign(props.delta)}
            {formatMeters({ meters: props.delta, locale: i18n.locale })}
            <sup>3</sup>
          </>
        }
      />
      {/* This total is sometimes missing or nearly zero (0.003). In these cases, hide it. Does not add anything. */}
      {props.total > 0.009 && (
        <>
          <div className={cn("mx-2")}>|</div>
          <MetaItem
            name={t((d) => d.transport_job.total_load)}
            value={
              <>
                {formatMeters({ meters: props.total, locale: i18n.locale })}
                <sup>3</sup>
              </>
            }
          />
        </>
      )}
    </div>
  );
};
