import { Button, IconCheck, IconEditPencil, InputText, Label, Message } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm, useTranslation } from "../../../hooks";

import { CacheKey, coreClient } from "../../../utils";

interface Props {
  canEdit: boolean;
  bankAccountIds?: string[];
}

export const UserBankAccountForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [inEditMode, setInEditMode] = React.useState(false);

  const form = useForm({
    initialState: {
      iban: "",
    },
    validators: {
      iban: (val) => !val,
    },
  });

  const id = props.bankAccountIds?.[0] as string;
  const bankAccount = useQuery([CacheKey.RETRIEVE_BANK_ACCOUNT, id], () => coreClient.bankAccounts.retrieve({ id }), {
    enabled: !!id,
  });

  const updateBankAccount = useMutation(coreClient.bankAccounts.update, {
    onSuccess: () => {
      setInEditMode(false);
      bankAccount.refetch();
    },
  });

  React.useEffect(() => {
    if (bankAccount.isSuccess) {
      form.set({ iban: bankAccount.data?.account_number || "" });
    }
  }, [Boolean(bankAccount.data)]);

  return (
    <div>
      <Label text={t((d) => d.profile.iban)} error={form.isFieldDirty("iban") && form.getError("iban")}>
        <InputText
          className={cn("w-full")}
          disabled={!inEditMode}
          value={form.getValue("iban")}
          onChange={(iban) => form.set({ iban })}
        />
      </Label>
      {props.canEdit && !inEditMode && (
        <Button className={cn("mt-6", "w-full")} onClick={() => setInEditMode(true)} icon={<IconEditPencil />}>
          {t((d) => d.profile.edit_bank_details)}
        </Button>
      )}
      {props.canEdit && inEditMode && (
        <Button
          buttonType="secondary"
          className={cn("mt-6", "w-full")}
          loading={updateBankAccount.isLoading}
          icon={<IconCheck />}
          onClick={() => {
            // @TODO: add select for which bank account to use?
            const firstBankAccountId = getIdFromIri(bankAccount.data?.["@id"]);
            // NOTE: only using the first account for now
            updateBankAccount.reset();
            updateBankAccount.mutate({
              id: firstBankAccountId as string,
              account_number: form.getValue("iban"),
            });
          }}
        >
          {t((d) => d.profile.save_personal_details)}
        </Button>
      )}
      {updateBankAccount.isError && (
        <Message className={cn("mt-4")} type="error">
          {(updateBankAccount.error as Error)?.message}
        </Message>
      )}
    </div>
  );
};
