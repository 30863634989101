import { IconCheckCircle } from "@brenger/react";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import * as React from "react";

import { useGeolocationMethods } from "../../hooks";
import { Content } from "../../layout";
import { coreClient, Routes } from "../../utils";

export const Logout: React.FC = () => {
  const geoMethods = useGeolocationMethods();

  // This query triggers the logout as soon as the component mounts.
  const doLogout = async (): Promise<boolean> => {
    // // Stop geolocation tracking when user logs out. We do this for two reasons:
    // // 1. It's a nice expectation for drivers
    // // 2. We no longer have a valid userId to include
    await geoMethods.stop();
    // Logout
    await coreClient.users.logout();

    // In a few cases the redirect didn't happen
    // This might be caused but being not logged in, rappid state changes as result
    // So this location assigment is a saver way out
    // and since cache is not persisted we start fresh
    await window.location.assign(Routes.auth.login());

    return true;
  };

  useQuery(["LOGOUT"], doLogout);

  return (
    <Content>
      <div style={{ minHeight: "20rem" }} className={cn("flex", "justify-center", "items-center", "flex-grow")}>
        <IconCheckCircle
          className="animate-ping text-green-400"
          style={{
            fontSize: "4rem",
            animationIterationCount: 1,
            animationDelay: ".25s",
            animationFillMode: "forwards",
          }}
        />
      </div>
    </Content>
  );
};
