import { Card, Small, Strong } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { useQuery } from "@tanstack/react-query";

import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { Grid, PillTjalState } from "../../../components";
import { useFormatDate, useInboxFilter, useTranslation } from "../../../hooks";
import { CacheKey, Routes, coreClient, logger } from "../../../utils";
import { Content, PageHead, Section } from "../../../layout";
import { TransportJobAccountLink } from "@brenger/api-client";
import { Link } from "react-router-dom";

export const MoreInboxList: React.FC = () => {
  const { t } = useTranslation();
  const formatDateFull = useFormatDate("date-full");

  // Fetch conversations
  const conversations = useQuery([CacheKey.INBOX_LIST], coreClient.conversations.listForDriver);
  const filteredConversations = useInboxFilter(conversations.data?.["hydra:member"]);

  const genericError =
    conversations.error || filteredConversations.length === 0 ? t((d) => d.app.empty_state.generic) : undefined;

  return (
    <Content
      isLoading={conversations.isLoading}
      message={(conversations.error as Error)?.message || genericError}
      header={<PageHead sectionType="single-details" title={t((d) => d.more.inbox)} backUrl={Routes.more.home()} />}
    >
      <Section type="single-details" isTop={true}>
        <Grid cols={{ lg: 2 }} gap={4}>
          {filteredConversations.map((conversation) => {
            return (
              <Link
                key={conversation["@id"]}
                className={cn("block")}
                to={Routes.more.inbox(
                  getIdFromIri(
                    getTjalIriOrReturnTjal(conversation.transport_job_account_link as TransportJobAccountLink)
                  )
                )}
              >
                <Card type="gray">
                  <div className={cn("flex", "justify-between")}>
                    <div style={{ height: "50px", width: "50px" }} className={cn("overflow-hidden", "rounded-full")}>
                      <img
                        className={cn("h-full", "w-full", "object-cover")}
                        src={conversation.conversation_party.profile_image || missingImageSrc}
                      />
                    </div>
                    <div style={{ width: "calc(100% - 65px)" }}>
                      <div className={cn("truncate", "h-6")}>
                        <div>
                          <Strong>
                            {conversation.pickup_locality} — {conversation.delivery_locality}
                          </Strong>
                        </div>
                      </div>
                      {/* eslint-disable-next-line */}
                      <div className={cn("truncate", "h-6")} dangerouslySetInnerHTML={{ __html: conversation.body }} />
                    </div>
                  </div>
                  <div className={cn("flex", "flex-wrap", "justify-between", "mt-2")}>
                    <Small className={cn("text-gray-500", "capitalize")}>
                      {formatDateFull(conversation.updated_at as string)}
                    </Small>
                    <PillTjalState state={conversation.transport_job_account_link.state} />
                  </div>
                </Card>
              </Link>
            );
          })}
        </Grid>
      </Section>
    </Content>
  );
};

// FIXME: Fixtures from Core somehow lack a '/' as prefix, we prefix it here manually.
// Added to laundry list: https://gitlab.com/brenger/api/core/-/issues/733
const getTjalIriOrReturnTjal = (tjal?: TransportJobAccountLink): TransportJobAccountLink | string | undefined => {
  try {
    if (!tjal) return tjal;
    const iri = tjal["@id"];
    if (typeof iri !== "string") return tjal;
    if (Array.from(iri)[0] === "/") return tjal;
    return `/${iri}`;
  } catch (e) {
    logger.error("Conversation id could not be retrieved", e);
    return tjal;
  }
};
