import * as React from "react";
import { TransportJobAccountLinkState } from "@brenger/api-client";
import { Pill, PillType } from "@brenger/react";

import { useTranslation } from "../../hooks";

interface PillTjalStateProps extends React.HTMLAttributes<HTMLSpanElement> {
  state: TransportJobAccountLinkState;
}

export const PillTjalState: React.FC<PillTjalStateProps> = ({ state, ...unused }) => {
  const { t } = useTranslation();

  // Default is blue
  let type: PillType = "blue";

  switch (state) {
    case "delivered":
      type = "green";
      break;
    case "pending":
      type = "yellow";
      break;
    case "transport_job_cancelled":
      type = "red";
      break;
    case "cancelled_by_driver":
      type = "red";
      break;
    case "accepted":
      type = "green";
      break;
    case "transport_job_unbundled":
      type = "red";
      break;
  }

  return (
    <Pill marginY={"none"} type={type} {...unused}>
      {t((d) => d.transport_job_account_link.state[state])}
    </Pill>
  );
};
