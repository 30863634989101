import * as React from "react";

import cn from "classnames";

/**
 * Update this interface as new responsive layouts come up.
 */
interface Cols {
  default?: 1 | 2 | 3;
  lg?: 2;
}

type Gap = 0 | 1 | 2 | 3 | 4;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  cols: Cols;
  gap: Gap;
}

const gapMap: { [key in Gap]: string } = {
  0: "gap-0",
  1: "gap-1",
  2: "gap-2",
  3: "gap-3",
  4: "gap-4",
};

/**
 * This util takes the "Cols" prop and builds up some logical classnames.
 */
export const generateCols = (cols: Cols): { [className: string]: boolean } => {
  // Even if not provided, there must be a "default" number of cols specified.
  // Therefore, when not present, set to 1. All other sizes ("sm", "md", etc) modify
  // this number when the viewport gets there.
  if (!cols.default) {
    cols.default = 1;
  }

  // Note: Classnames should be spelled out explicitly (rather than composed by templates)
  // so that the Tailwind compiler can scan for all used classNames prior to purging unused ones.
  return {
    "grid-cols-1": cols.default === 1,
    "grid-cols-2": cols.default === 2,
    "grid-cols-3": cols.default === 3,
    "lg:grid-cols-2": cols.lg === 2,
  };
};

export const Grid: React.FC<Props> = ({ cols, gap, children, className, ...unused }) => {
  return (
    <div className={cn(className, "grid", gapMap[gap], generateCols(cols))} {...unused}>
      {children}
    </div>
  );
};
