import { GeoLocationDetails } from "@brenger/api-client";
import { Button, Card, H3, IconAddLocation, IconDelete, InputCheckbox, Label, Spacer, Timeline } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { Grid, InputTextWithGeoAutocomplete } from "../../../components";
import { useAuth, useForm, useTranslation } from "../../../hooks";
import { CacheKey, Routes, coreClient } from "../../../utils";
import { Content, PageHead, Section } from "../../../layout";

export const MoreFavouriteRoutes: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const form = useForm({
    initialState: {
      back_and_forth: false,
      from_search: "",
      from_place: undefined as GeoLocationDetails | undefined,
      to_search: "",
      to_place: undefined as GeoLocationDetails | undefined,
    },
  });

  const preferredRoutes = useQuery([CacheKey.RETRIEVE_PREFERRED_ROUTES], () =>
    coreClient.users.listPreferredRoutesForCurrentUser()
  );
  const createRoute = useMutation(coreClient.preferredRoutes.create);
  const deleteRoute = useMutation(coreClient.preferredRoutes.delete, { onSuccess: () => preferredRoutes.refetch() });

  return (
    <Content
      header={
        <PageHead sectionType="single-details" title={t((d) => d.more.favourite_routes)} backUrl={Routes.more.home()} />
      }
    >
      <Section type="single-details" isTop={true}>
        <div>{t((d) => d.profile.routes_desc)}</div>
        <Spacer h={4} />
        <H3>{t((d) => d.profile.create_route_label)}</H3>
        <Spacer h={2} />
        <Grid cols={{ lg: 2 }} gap={4}>
          <Label text={t((d) => d.profile.route_origin)}>
            <InputTextWithGeoAutocomplete
              value={form.data.from_search.value}
              onChange={(from_search) => form.set({ from_search })}
              onSelect={(from_place) => form.set({ from_place })}
              includeHouseNumber={false}
            />
          </Label>
          <Label text={t((d) => d.profile.route_destination)}>
            <InputTextWithGeoAutocomplete
              value={form.data.to_search.value}
              onChange={(to_search) => form.set({ to_search })}
              onSelect={(to_place) => form.set({ to_place })}
              includeHouseNumber={false}
            />
          </Label>
        </Grid>
        <Spacer h={4} />
        <Label text={t((d) => d.profile.back_and_forth)} position="right">
          <InputCheckbox
            checked={form.data.back_and_forth.value}
            onChange={() => {
              form.set({ back_and_forth: !form.data.back_and_forth.value });
            }}
          />
        </Label>
        <Spacer h={4} />
        <Button
          className={cn("w-full")}
          buttonType="secondary"
          // Do not allow user to add a route until both queries for looking up the place IDs have completed.
          disabled={!form.data.from_place.value || !form.data.to_place.value}
          loading={createRoute.isLoading}
          icon={<IconAddLocation className={cn("h-4")} />}
          onClick={() => {
            const fromPlace = form.data.from_place.value;
            const toPlace = form.data.to_place.value;

            // Include a series of "escapes" in order go guarantee type-safety below.
            if (!auth.user || !toPlace || !fromPlace) return;

            const args = [
              {
                user: auth.user["@id"],
                to: toPlace.label,
                to_locality: toPlace.address.locality,
                to_lat: toPlace.address.latitude,
                to_lng: toPlace.address.longitude,
                from: fromPlace.label,
                from_locality: fromPlace.address.locality,
                from_lat: fromPlace.address.latitude,
                from_lng: fromPlace.address.longitude,
                transport_size_class: "",
              },
            ];
            // If back_and_forth is selected, add the inverse args as well!
            if (form.data.back_and_forth.value) {
              args.push({
                user: auth.user["@id"],
                to: fromPlace.label,
                to_locality: fromPlace.address.locality,
                to_lat: fromPlace.address.latitude,
                to_lng: fromPlace.address.longitude,
                from: toPlace.label,
                from_locality: toPlace.address.locality,
                from_lat: toPlace.address.latitude,
                from_lng: toPlace.address.longitude,
                transport_size_class: "",
              });
            }
            args.forEach((route) => {
              createRoute.mutate(route, {
                onSuccess: () => preferredRoutes.refetch(),
              });
            });
            // Clear form state after submitting a new route to core.
            form.reset();
          }}
        >
          {t((d) => d.profile.create_route_cta)}
        </Button>
        {(preferredRoutes.data?.["hydra:totalItems"] || 0) > 0 && (
          <>
            <Spacer h={6} />
            <H3>
              {t((d) => d.profile.saved_routes_title)} ({preferredRoutes.data?.["hydra:totalItems"]})
            </H3>
            <Spacer h={2} />
          </>
        )}
        <Grid gap={4} cols={{ lg: 2 }}>
          {preferredRoutes.data?.["hydra:member"].map((route) => {
            return (
              <Card type="gray" key={route["@id"]}>
                <div className={cn("flex", "justify-between")}>
                  <div>
                    <Timeline isFirst={true} isLast={false}>
                      <div>{route.from_locality}</div>
                    </Timeline>
                    <Timeline isFirst={false} isLast={true}>
                      <div>{route.to_locality}</div>
                    </Timeline>
                  </div>
                  <button
                    onClick={() => {
                      const routeId = getIdFromIri(route);
                      if (routeId) deleteRoute.mutate({ routeId });
                    }}
                  >
                    <H3>
                      <IconDelete className={cn("text-red-600")} />
                    </H3>
                  </button>
                </div>
              </Card>
            );
          })}
        </Grid>
      </Section>
    </Content>
  );
};
