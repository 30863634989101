import { Button } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useNativePlatform, UsePushNotifications, useTranslation } from "../../hooks";
import image from "./push.svg";

export const PushPrimer: React.FC<{ push: null | UsePushNotifications; closeHandler(): void }> = ({
  push,
  closeHandler,
}) => {
  const { t } = useTranslation();
  const [showContinue, setShowContinue] = React.useState(false);
  const isNative = useNativePlatform();
  return (
    <div className={cn("grid", "gap-4", "text-center", "place-items-center", "w-80", "max-w-full", "mx-auto")}>
      <img src={image} />
      <h3>{t((d) => d.web_push_modal.title)}</h3>
      <div>{t((d) => d[isNative ? "native_push_modal" : "web_push_modal"].body)}</div>
      {!showContinue && (
        <>
          <Button
            className={"w-full mt-4"}
            buttonType="secondary"
            onClick={async () => await push?.subscribe({ callback: () => setShowContinue(true) })}
          >
            {t((d) => d.web_push_modal.yes)}
          </Button>
          <Button className={"w-full"} buttonType="white" onClick={closeHandler}>
            <span className="text-blue-600">{t((d) => d.no_thanks)}</span>
          </Button>
        </>
      )}
      {showContinue && (
        <Button className={"w-full mt-4"} buttonType="secondary" onClick={closeHandler}>
          {t((d) => d.actions.continue)}
        </Button>
      )}
    </div>
  );
};
