import * as React from "react";
import { ItemSet } from "@brenger/api-client";

import { MetaItems } from "..";
import { useTranslation } from "../../hooks";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  itemSets?: ItemSet[];
}

export const ClientReferences: React.FC<Props> = ({ itemSets, ...unused }) => {
  const { t } = useTranslation();
  const references = (itemSets || []).flatMap((set) => set.client_reference).filter(Boolean);

  if (!references.length) return null;

  return (
    <MetaItems
      {...unused}
      items={[
        {
          name: t((d) => d.transport_job.item_sets.client_reference),
          value: references.join(", "),
        },
      ]}
    />
  );
};
