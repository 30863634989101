import * as React from "react";
import { PayoutState, PayoutType } from "@brenger/api-client";
import { Pill, PillType } from "@brenger/react";

import { useTranslation } from "../../hooks";

interface PillTjalStateProps extends React.HTMLAttributes<HTMLSpanElement> {
  state: PayoutState;
  type: PayoutType;
}

export const PillPayoutState: React.FC<PillTjalStateProps> = ({ state, type, ...unused }) => {
  const { t } = useTranslation();

  let pillType: PillType = "blue";

  switch (state) {
    case "new":
      pillType = type === "penalty" ? "yellow" : "blue";
      break;
    case "xml_generated":
      pillType = type === "penalty" ? "red" : "green";
      break;
    case "paid_out":
      pillType = type === "penalty" ? "red" : "green";
      break;
  }

  return (
    <Pill type={pillType} {...unused}>
      {t((d) => d.transport_job_account_link.payout_state[state])}
    </Pill>
  );
};
