import { HorizontalLine, IconCheck, Pill, Spacer, IconEditPencil } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { MetaItem } from "../../../components";
import { useFormatDate } from "../../../hooks";

export const TIME_PLACEHOLDER = "--.--";

type DayRouteStartEnd = {
  location?: string;
  LocationTime: { label: string; value: string | null };
  setIsEditingEndLocation?: () => void;
  startedDrivingAt?: { label: string; value?: string | null };
};

export const DayRouteStartEndCard: React.FC<DayRouteStartEnd> = (props) => {
  const formatHourMinute = useFormatDate("hour-minute");

  return (
    <div className={cn("flex", "items-center", "justify-between")}>
      <div className={cn("flex-grow")}>
        <div className={cn("br-text-md", "br-font-bold", "block", "text-blue-600")}>{props.location || "-"}</div>
        <Spacer h={1} />
        <HorizontalLine className={cn("mr-6")} type="gray" />
        {props.startedDrivingAt?.value && (
          // NOTE: show a green pill with the actual start time next to the driver's given start time
          <Pill type={"green"} marginY={"none"}>
            <span className={cn("flex", "items-center")}>
              <IconCheck className={cn("mr-1")} />
              {formatHourMinute(props.startedDrivingAt.value)}
            </span>
          </Pill>
        )}
        {!props.startedDrivingAt?.value && (
          <small className={cn("uppercase", "br-text-bold")}>
            <MetaItem
              name={props.LocationTime.label}
              value={
                props.LocationTime.value !== TIME_PLACEHOLDER
                  ? formatHourMinute(props.LocationTime.value)
                  : TIME_PLACEHOLDER
              }
            />
          </small>
        )}
      </div>
      {props.setIsEditingEndLocation && (
        <button onClick={props.setIsEditingEndLocation}>
          <div>
            <IconEditPencil />
          </div>
        </button>
      )}
    </div>
  );
};
