import { IconEditPencil } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { TextPlaceholder, UserStatusPill } from "../../../components";
import { useCache, useCamera } from "../../../hooks";
import { CacheKey, coreClient, dataURItoBlob } from "../../../utils";
import { uuid } from "@brenger/utils";

interface Props {
  canEdit: boolean;
  accountName?: string;
  userId?: string;
}

export const UserProfileImage: React.FC<Props> = (props) => {
  const cache = useCache();
  const [imgV, setImgV] = React.useState(uuid());
  const user = useQuery(
    [CacheKey.RETRIEVE_USER, props.userId],
    () => coreClient.users.retrieve({ userId: props.userId as string }),
    {
      enabled: !!props.userId,
    }
  );

  const createProfileImage = useMutation(coreClient.users.createProfileImageForCurrentUser, {
    onSuccess: () => {
      cache.queryClient.removeQueries([CacheKey.RETRIEVE_USER, props.userId as string]);
      setImgV(uuid());
    },
  });

  const { takePicture } = useCamera((dataUrl) => {
    // Success callback for takePicture
    const blob = dataURItoBlob(dataUrl);
    const imageData = new FormData();
    imageData.append("file", blob);
    createProfileImage.mutate({ imageData });
  });
  const image = user.data?.profile_image || missingImageSrc;
  return (
    <div className={cn("flex", "items-center")}>
      <div
        style={{
          // Utilize minWidth to prevent image dim distoration on small viewports.
          minWidth: "6rem",
        }}
        className={cn("relative", "overflow-hidden", "rounded-full", "w-24", "h-24", "bg-gray-200")}
      >
        <img className={cn("h-full", "w-full", "object-cover")} src={`${image}?v=${imgV}&w=200&h=200`} />
        {props.canEdit && (
          <button
            onClick={() => takePicture()}
            className={cn("absolute", "bottom-0", "flex", "justify-center", "w-full", "py-2")}
            style={{ backgroundColor: "rgba(255,255,255,0.6)" }}
          >
            <IconEditPencil />
          </button>
        )}
      </div>
      <div className={cn("ml-2")}>
        {props.accountName && <h4>{props.accountName}</h4>}
        <h5>
          {user.data?.first_name || <TextPlaceholder />} {user.data?.last_name}
        </h5>
        <div className={cn("mt-1")}>{user.data && <UserStatusPill user={user.data} />}</div>
      </div>
    </div>
  );
};
