import * as React from "react";
import { ItemSet } from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";

import { useTranslation } from "../../hooks";
import { ExternalLink } from "..";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  itemSets?: ItemSet[];
}

export const createDownloadLinksForExternalInvoices = (itemSets: ItemSet[] | undefined): string[] => {
  const invoices = itemSets?.flatMap((itemSet) => itemSet.external_invoices);

  if (!invoices) return [];

  return invoices.map((invoice) => {
    // @TODO HACK ALERT: https://gitlab.com/brenger/api/core/-/issues/919
    // We expect invoice to be media object but comes in as string sometimes.
    // getIdFromIri accepts a string but will also check for "@id" if it is present.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return `https://user-data.brenger.nl/external_documents/${getIdFromIri(invoice as any)}`;
  });
};

/**
 * Accepts itemSets and renders a download button for each external invoice.
 */
export const ExternalInvoices: React.FC<Props> = ({ itemSets, ...unused }) => {
  const { t } = useTranslation();

  const downloadLinks = createDownloadLinksForExternalInvoices(itemSets);

  if (!downloadLinks.length) return null;

  return (
    <div {...unused}>
      {downloadLinks.map((downloadLink, idx) => {
        return (
          <div
            key={idx}
            // The last button should not carry any margin.
            // Top-level components shoulds handle outer margins.
            className={cn("text-blue-600", "underline", { "mb-3": idx !== downloadLinks.length - 1 })}
          >
            <ExternalLink url={downloadLink}>{t((d) => d.transport_job.actions.download_invoice)}</ExternalLink>
          </div>
        );
      })}
    </div>
  );
};
