import {
  AppSettings,
  AppSettingsActions,
  BrengerProviderChangeEvent,
  SetLocationProviderAction,
  SetPushInitAction,
} from "./types";

export const SET_APP_PUSH_INIT = "SET_APP_PUSH_INIT";
export const SET_APP_LOCATION_PROVIDER = "SET_APP_LOCATION_PROVIDER";

export const appSettingsActions = {
  setInitPush: (payload: boolean): SetPushInitAction => {
    return { type: SET_APP_PUSH_INIT, payload };
  },
  setLocationProvider: (payload: null | BrengerProviderChangeEvent): SetLocationProviderAction => {
    return { type: SET_APP_LOCATION_PROVIDER, payload };
  },
};

const defaultState: AppSettings = {
  push: {
    isInit: false,
  },
  location: {
    provider: null,
  },
};

export const appSettings = (state = defaultState, action: AppSettingsActions): AppSettings => {
  switch (action.type) {
    case SET_APP_PUSH_INIT:
      return {
        ...state,
        push: {
          ...state.push,
          isInit: action.payload,
        },
      };
    case SET_APP_LOCATION_PROVIDER:
      return {
        ...state,
        location: {
          ...state.location,
          provider: action.payload,
        },
      };
    default:
      return state;
  }
};
