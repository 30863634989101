import * as React from "react";

import {
  Button,
  Emphasis,
  IconEditBox,
  IconInvoice,
  IconMail,
  Message,
  Pill,
  Spacer,
  useModalState,
} from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import { useHistory, useParams } from "react-router-dom";
import {
  BundledJobSummary,
  ExternalLink,
  ImageGallery,
  MetaItem,
  PillTjalState,
  RegularJobSummary,
  StaticMap,
  TooltipDressCode,
} from "../../../components";
import { useMapContext, useTjalContext, useTranslation, useUserGeneratedContent } from "../../../hooks";
import { Routes, TransportsJobParams } from "../../../utils";
import { TransportJobEdit } from "../../edit/TransportJob/edit";
import { Content, PageHead, Section } from "../../../layout";

export const TransportsJob: React.FC = () => {
  const params = useParams<TransportsJobParams>();
  const router = useHistory();
  const { t } = useTranslation();
  const { tjal, tj } = useTjalContext(params.job_id);
  const shortId = tj.data?.["@id"].slice(-6) || "";
  const mapContext = useMapContext({ presentation: "planning", tj: tj.data });
  const disputeReason = useUserGeneratedContent(tjal.data?.dispute_reason);
  const itemSets = tj.data?.item_sets || [];
  const editModal = useModalState();
  /**
   * Show loader or error
   */
  if (
    tj.isLoading ||
    tjal.isLoading ||
    !tj.data ||
    tj.error ||
    !tjal.data ||
    tjal.error ||
    tj.data?.state === "unbundled"
  ) {
    const errorFromCore = tj.error || tjal.error;
    let error = errorFromCore ? (errorFromCore as Error)?.message || "Something went wrong" : undefined;
    if (tj.data?.state === "unbundled") error = t((d) => d.app.errors.general);
    return (
      <Content
        header={
          <PageHead
            sectionType={"split-details"}
            backUrl={Routes.transports.home({ type: params.type })}
            title={`Short ID: ${shortId}`}
          />
        }
        message={error}
        messageWrap="split-details"
        isLoading={tjal.isLoading || tj.isLoading}
      />
    );
  }

  /**
   * Data is ready and no error
   */
  // Pluck TJ data we'll be using
  const { state, bundled: isBundled } = tj.data;
  // Check if TJ is in a disputed state
  const isDisputed = state === "disputed";

  const invoiceUrl = tjal.data?.payout_invoice_url;

  return (
    <>
      <Content
        header={
          <PageHead
            sectionType={"split-details"}
            backUrl={Routes.transports.home(params)}
            title={`Short ID: ${shortId}`}
            afterTitle={
              <>
                {!isDisputed && params.type === "planning" && (
                  <button
                    className={cn("text-blue-600", "flex", "items-center", "cursor-pointer")}
                    onClick={editModal.open}
                  >
                    <IconEditBox className={cn("mr-1")} /> {t((d) => d.actions.edit)}
                  </button>
                )}
              </>
            }
          />
        }
      >
        <Section type="split-details" isTop={true}>
          {isDisputed && disputeReason.text && (
            <Message type="error" className={cn("mb-2")}>
              <MetaItem
                name={t((d) => d.planning.messages.dispute_reason)}
                value={<Emphasis>{disputeReason.text}</Emphasis>}
              />
            </Message>
          )}
          <div className={cn("flex", "flex-wrap", "items-center", "justify-between")}>
            {/* NOTE: Do not show the TJAL state when the TJ is in dispute. */}
            {tjal.data && !isDisputed && <PillTjalState state={tjal.data.state} />}
            {isDisputed && <Pill type="red">{t((d) => d.transport_job.in_dispute)}</Pill>}
          </div>
          {itemSets.flatMap((i) => i.services).includes("safety_shoes_required") && (
            <>
              <Spacer h={2} />
              <TooltipDressCode />
            </>
          )}
          <Spacer h={4} />
          <h3 className={cn("mr-2", "capitalize", "truncate")}>{tj.data.title}</h3>
          <Spacer h={3} />
          <StaticMap markers={mapContext.markers} points={mapContext.points} googleMapsUrl={mapContext.googleMapsUrl} />
          <Spacer h={2} />
          {tj.data.bundled ? (
            <BundledJobSummary tj={tj.data} tjal={tjal.data} presentation="planning" />
          ) : (
            <RegularJobSummary tj={tj.data} tjal={tjal.data} presentation="planning" />
          )}
          <Spacer h={3} />
          <ImageGallery itemSets={itemSets} />
        </Section>
        {!isBundled && (
          <Section type="split-details">
            <Button
              className={cn("w-full")}
              onClick={() => router.push(Routes.more.inbox(getIdFromIri(tjal.data)), "forward")}
              icon={<IconMail className={cn("h-4")} />}
            >
              {t((d) => d.transport_job.actions.view_messages)}
            </Button>
          </Section>
        )}
        {invoiceUrl && params.type === "delivered" && (
          <ExternalLink url={invoiceUrl} className={cn("mt-3")}>
            <Button buttonType="primary-outline" icon={<IconInvoice className={cn("h-4")} />}>
              {t((d) => d.transport_job.actions.download_invoice)}
            </Button>
          </ExternalLink>
        )}
      </Content>
      <TransportJobEdit isActive={editModal.isActive} closeHandler={editModal.close} />
    </>
  );
};
