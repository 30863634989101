import { useState, useEffect } from "react";
import { Device } from "@capacitor/device";

import { tryToCatch } from "../utils";

export const useDeviceId = (): string | undefined => {
  const [id, setId] = useState<string>();

  useEffect(() => {
    const getId = async (): Promise<void> => {
      const data = await Device.getId();
      setId(data.identifier);
    };

    tryToCatch(getId, "Device.getId failed");
  }, [Device, setId]);

  return id;
};
