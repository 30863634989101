import { IconClose, IconLoader } from "@brenger/react";
import { Animation, ModalOptions } from "@ionic/core";
import { createAnimation, IonModal } from "@ionic/react";
import cn from "classnames";
import React from "react";
import "./sheetModal.scss";
/**
 * See docs: https://ionicframework.com/docs/ja/v6/api/modal
 */
export interface SheetModalProps extends Omit<ModalOptions, "enterAnimation" | "leaveAnimation" | "component"> {
  isOpen: boolean;
  isLoading?: boolean;
  close?(): void;
  header?: string;
  footer?: React.ReactNode;
  style?: "white" | "blue";
  sheetWrapperWidth?: string;
}

export const SheetModal: React.FC<SheetModalProps> = ({
  children,
  header,
  close,
  footer,
  isLoading,
  style = "white",
  sheetWrapperWidth,
  ...props
}) => {
  React.useEffect(() => {
    if (props.isOpen) document.body.classList.add("sheet-modal-present");
    else document.body.classList.remove("sheet-modal-present");
  }, [props.isOpen]);

  return (
    <IonModal {...props} enterAnimation={enterAnimation} leaveAnimation={leaveAnimation}>
      <div
        className={cn(
          "sheet-backdrop",
          "h-screen",
          "w-screen",
          "bg-black/50",
          "pt-8-safe-area",
          "opacity-0",
          "flex",
          "lg:py-[8vh]",
          "lg:justify-center"
        )}
        onClick={close}
      >
        <div
          className={cn(
            "sheet-wrapper",
            { "bg-white": style === "white", "bg-blue-600": style === "blue", "text-white": style === "blue" },
            "rounded-t-lg",
            "w-full",
            { "lg:w-[700px]": !sheetWrapperWidth },
            "self-end",
            "lg:rounded-lg",
            "lg:self-center",
            "max-h-full",
            "flex",
            "flex-col",
            "pb-safe-area",
            sheetWrapperWidth
          )}
          onClick={(e) => e.stopPropagation()}
          style={{ transformStyle: "preserve-3d" }}
        >
          {(header || close) && (
            <div
              className={cn(
                "flex",
                "items-center",
                { "text-blue-600 border-b border-gray-300": style === "white" },
                "p-4",
                "lg:px-6",
                "flex-none"
              )}
            >
              {close && <IconClose className={"mr-auto -ml-8 relative left-8"} onClick={close} />}
              {header && (
                <div className="grow text-center">
                  <b>{header}</b>
                </div>
              )}
            </div>
          )}
          <div className={cn("p-4", "lg:p-6", "overflow-auto", "grow")}>
            {isLoading && <IconLoader />}
            {!isLoading && children}
          </div>
          {footer && <div className={cn("p-4", "lg:p-6", "flex-none", "border-t", "border-gray-300")}>{footer}</div>}
        </div>
      </div>
    </IonModal>
  );
};

const enterAnimation = (baseEl: HTMLElement): Animation => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector(".sheet-backdrop") as HTMLElement)
    .keyframes([
      { offset: 0, opacity: "0" },
      { offset: 0.5, opacity: "1" },
      { offset: 1, opacity: "1" },
    ]);

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector(".sheet-wrapper") as HTMLElement)
    .keyframes([
      { offset: 0, opacity: "1", transform: "translate3d(0,5vh,0)" },
      // { offset: 0.5, opacity: "1", transform: "translate3d(0,20vh,0)" },
      // { offset: 0.8, opacity: "1", transform: "translate3d(0,10vh,0)" },
      { offset: 1, opacity: "1", transform: "translate3d(0,0,0)" },
    ]);

  return createAnimation().addAnimation([backdropAnimation, wrapperAnimation]).duration(150);
};

const leaveAnimation = (baseEl: HTMLElement): Animation => {
  return enterAnimation(baseEl).direction("reverse");
};
