import * as React from "react";
import { ItemSet } from "@brenger/api-client";
import { Pill } from "@brenger/react";

import { useTranslation } from "../../hooks";
import { StopType } from "../../types";

interface Props {
  stopType: StopType;
  itemSets: ItemSet[];
}

export const StopConfirmedPill: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  if (props.stopType === "Pickup") {
    const timestamps = props.itemSets.map((d) => d.driver_pickup_confirmed_at);
    if (timestamps.every(Boolean)) {
      return <Pill type="green">{t((d) => d.transport_job.picked_up)}</Pill>;
    }
  }

  if (props.stopType === "Delivery") {
    const timestamps = props.itemSets.map((d) => d.driver_delivery_confirmed_at);
    if (timestamps.every(Boolean)) {
      return <Pill type="green">{t((d) => d.transport_job.delivered)}</Pill>;
    }
  }

  return null;
};
