import * as React from "react";
import { useHistory } from "react-router-dom";

type ValidSearchParam = "user_iri";

export type UseURLSearchParam = {
  [key in ValidSearchParam]: string;
};

interface Args {
  key: ValidSearchParam;
  /**
   * When no value is found, use this as the "fallback" or default value.
   */
  defaultValue?: string;
  /**
   * Whether to scrub (ie, replace) the search string from the URL
   */
  delete?: boolean;
}

/**
 * This hook uses the native URL Search Params method to check the url
 * for any relevant params "on mount". If it finds a param, it will
 * return its value (decoded) and, optionally, also replace it (ie, delete)
 * if from the URL.
 */
export const useUrlSearchParam = (args: Args): string | null => {
  const defaultValue = args.defaultValue || null;
  const history = useHistory();
  /**
   * Capture the search param state in two state variables.
   * This ensures that re-renders don't erase the initial URL value present "on mount".
   * The cloned copy can be acted upon to re-create a pared down version of the URL
   * after the desired param has been parsed out and deleted.
   */
  const [params] = React.useState(new URLSearchParams(history.location.search));
  const [clonedParams] = React.useState(new URLSearchParams(history.location.search));

  React.useEffect(() => {
    // If we want to delete the search param from the URL after it was added to state
    if (clonedParams.has(args.key) && args.delete) {
      clonedParams.delete(args.key);
      // Serialize updated params and append to pathname
      const updatedParams = clonedParams.toString();
      if (updatedParams.length) {
        // Add any remaining params back to pathname if exist
        history.replace(`${location.pathname}?${updatedParams}`);
      } else {
        history.replace(history.location.pathname);
      }
    }
  }, [clonedParams]);

  return params.has(args.key) ? decodeURI(params.get(args.key) as string) : defaultValue;
};
