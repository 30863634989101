import { Locale } from "@brenger/api-client";
import { getLocale } from "@brenger/utils";

import { UserSettingsAction, UserSettingsState } from "./types";

import { localeNormalizer } from "../../utils";

export const SET_LOCALE = "SET_LOCALE";

export const userSettingsActions = {
  setLocale: (locale: Locale): UserSettingsAction => {
    return { type: SET_LOCALE, payload: { locale: locale, timeZone: localeNormalizer.parseTimeZone(locale) } };
  },
};

const locale = localeNormalizer.parseLocale(getLocale());
const timeZone = localeNormalizer.parseTimeZone(locale);

const defaultState: UserSettingsState = { locale, timeZone };

export const settings = (state = defaultState, action: UserSettingsAction): UserSettingsState => {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
        timeZone: action.payload.timeZone,
      };
    default:
      return state;
  }
};
