/**
 * Time has three types:
 * - time with icon stopwatch
 * - track and trace with icon location
 * - delivered status (green, red, orange)
 */

import { DateTimePeriodParams, DayRouteActivity } from "@brenger/api-client";
import { IconCheck, IconLiveTimeFrame, IconStopwatch, Pill, Spacer, Strong } from "@brenger/react";
import cn from "classnames";
import { useFormatDate } from "../../hooks";

type StopTime = {
  activity: DayRouteActivity;
  startedDriving: boolean;
};

export const DayRouteStopTime: React.FC<StopTime> = (props) => {
  const formatHourMinute = useFormatDate("hour-minute");

  if (props.activity.confirmed_by_driver_at) {
    return (
      <>
        <Pill type={"green"} marginY={"none"}>
          <span className={cn("flex", "items-center")}>
            <IconCheck className={cn("mr-1")} />
            {formatHourMinute(props.activity.confirmed_by_driver_at)}
          </span>
        </Pill>
      </>
    );
  }
  if (props.startedDriving && props.activity.eta_window) {
    return (
      <>
        <div className={cn("flex", "items-center")}>
          <IconLiveTimeFrame />
        </div>
        <Spacer w={1} />
        <div className={cn("text-blue-600")}>
          <Strong>
            {formatHourMinute(props.activity.eta_window.start)} - {formatHourMinute(props.activity.eta_window.end)}
          </Strong>
        </div>
      </>
    );
  }
  if (props.activity.committed_date_time_period) {
    return (
      <FormatCommitedStopTime
        textClassname={["font-bold"]}
        start={props.activity.committed_date_time_period.start}
        end={props.activity.committed_date_time_period.end}
      />
    );
  }

  const dtp = getActivityCommitedDTP(props.activity);

  if (!dtp) {
    return null;
  }
  return <FormatCommitedStopTime textClassname={["font-bold"]} {...dtp} />;
};

const getActivityCommitedDTP = (activity: DayRouteActivity): DateTimePeriodParams | null => {
  if (activity.committed_date_time_period) {
    return activity.committed_date_time_period;
  }
  if (activity.start_time && activity.end_time) {
    return { start: activity.start_time, end: activity.end_time };
  }
  return null;
};

type CommitTimePeriodProps = { start: string | null; end: string | null; textClassname?: string[] };
export const FormatCommitedStopTime: React.FC<CommitTimePeriodProps> = ({ start, end, textClassname }) => {
  const formatHourMinute = useFormatDate("hour-minute");

  return (
    <>
      <div className={cn("flex", "items-center", "mr-1")}>
        <IconStopwatch width={"16px"} height={"16px"} />
      </div>
      <Spacer w={1} />
      <div className={cn(textClassname)}>
        <Strong>
          {formatHourMinute(start)} - {formatHourMinute(end)}
        </Strong>
      </div>
    </>
  );
};
