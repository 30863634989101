import { HorizontalLine, Strong, useModalState, IconInfoCircle } from "@brenger/react";
import cn from "classnames";
import * as React from "react";

import { useEffect, useState } from "react";
import { Dialog } from "..";
import { usePersistedState, useTranslation } from "../../hooks";
import { PersistedStorageKey } from "../../utils";

type Coordinates = Partial<DOMRect> & { pinx: number };

export const getElementVisiblity = (element: number, offset: number, screen: number): number => {
  // Element is fully visible
  try {
    const visible = offset - element;
    if (visible >= 0 && visible <= screen) {
      return element; // element is fully visible;
    }
    // Search for size which fits the screen
    else {
      return getElementVisiblity(element / 1.2, offset, screen);
    }
  } catch {
    return element / 2;
  }
};

const getTopTooltip = ({ top, left, width, height }: DOMRect, elementVisibleWidth: number): Partial<DOMRect> => ({
  bottom: window.innerHeight - top - height * 2 - 16,
  left: left + width * 2 - elementVisibleWidth,
});

const getBottomTooltip = ({ top, height, left, width }: DOMRect, elementVisibleWidth: number): Partial<DOMRect> => ({
  top: top + height - 16,
  left: left + width * 2 - elementVisibleWidth,
});

const getRightTooltip = ({ top, right, height }: DOMRect): Partial<DOMRect> => ({
  top: top + height / 2 + 8,
  left: right - 12,
});

const getLeftTooltip = ({ top, height, width, right }: DOMRect): Partial<DOMRect> => ({
  top: top + height / 2 + window.scrollY + 8,
  right: window.innerWidth - window.scrollX - right + width,
});

export const getToolTipPosition = (position: string, tooltipRect: DOMRect): Coordinates => {
  const maxWidthTooltip = 640 - 32; // minus margin;
  const maxHeightTooltip = 240 - 32; // minus margin;
  const elementVisibleWidth = getElementVisiblity(maxWidthTooltip, tooltipRect.right, window.innerWidth);
  const elementVisibleHeight = getElementVisiblity(maxHeightTooltip, tooltipRect.bottom, window.innerHeight);

  const basic = {
    // set pin in middle of ref
    pinx: elementVisibleWidth / 2 - tooltipRect.width - tooltipRect.width / 2,
    width: elementVisibleWidth,
    height: elementVisibleHeight,
  };
  let postionCoord: Partial<DOMRect> = {};
  switch (position) {
    case "top":
      postionCoord = getTopTooltip(tooltipRect, elementVisibleWidth);
      break;
    case "right":
      postionCoord = getRightTooltip(tooltipRect);
      break;

    case "bottom":
      postionCoord = getBottomTooltip(tooltipRect, elementVisibleWidth);
      break;

    case "left":
      postionCoord = getLeftTooltip(tooltipRect);
      break;
  }
  return { ...basic, ...postionCoord };
};

export const ToolTip: React.FC<{ withPersistedState?: PersistedStorageKey }> = ({ withPersistedState, children }) => {
  const toggle = useModalState();

  const [coord, setCoord] = useState<Coordinates>();
  const [position, setPostion] = useState<"top" | "bottom">();
  const { t } = useTranslation();
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  const [hasSeenToolTip, setHasSeenToolTip] = usePersistedState<boolean>({
    initialState: true,
    defaultState: false,
    version: 1,
    key: withPersistedState || "",
  });

  React.useEffect(() => {
    if (!hasSeenToolTip && withPersistedState) toggle.open();
  }, [withPersistedState, hasSeenToolTip]);

  const closeTooltip = (): void => {
    setHasSeenToolTip(true);
    toggle.close();
  };

  // This function calculate Position
  const getPosition = (): void => {
    if (tooltipRef && tooltipRef.current) {
      const element = tooltipRef.current?.getBoundingClientRect();
      const visibleHeight = window.innerHeight / 2;
      if (element) {
        const topOrBottom = element.top > visibleHeight ? "top" : "bottom";
        const tooltip = getToolTipPosition(topOrBottom, element);
        setPostion(topOrBottom);
        setCoord(tooltip);
      }
    }
  };

  // Get the position on start
  useEffect(() => {
    getPosition();
  }, []);

  // Re-calculate Position when the window is resized
  useEffect(() => {
    window.addEventListener("resize", getPosition);
    return () => {
      window.removeEventListener("resize", getPosition);
    };
  }, []);
  // Re-calculate Position when scrolling
  useEffect(() => {
    window.addEventListener("scroll", getPosition, true);
    return () => {
      window.removeEventListener("scroll", getPosition, true);
    };
  }, []);

  // Close tooltip if clicked outside element
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event): void {
      const tooltip = document.getElementById("tooltip");
      if (tooltip && !tooltip.contains(event.target as Node)) {
        closeTooltip();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={cn("text-blue-300")} ref={tooltipRef} onClick={toggle.open}>
        <IconInfoCircle />
      </div>

      {toggle.isActive && (
        <Dialog isActive={toggle.isActive}>
          <div
            className={cn("relative", "w-screen", "h-screen", "overflow-hidden")}
            style={{
              backgroundColor: "rgba(0,0,0,0.32)",
            }}
          />
          <div className={cn("absolute", "inset-0", "overflow-y-auto")}>
            <div
              onClick={(evt) => evt.stopPropagation()}
              style={{ ...coord }}
              className={cn("absolute", "pt-6", "pb-4")}
            >
              <div
                id="tooltip"
                className={cn("flex flex-col", "items-center", "mb-[-8.25rem]", {
                  hidden: !toggle.isActive,
                })}
              >
                {position == "bottom" && (
                  <div
                    className={cn("relative", "w-3", "h-3", "-mb-2", "rotate-45", "bg-blue-600")}
                    style={{
                      left: coord?.pinx,
                    }}
                  />
                )}

                <div className={cn("relative", "z-10", "px-6", "py-4", "bg-blue-600", "rounded-md", "text-white")}>
                  {children}
                  <HorizontalLine spaceWidth={2} type="gray" />
                  <button onClick={closeTooltip} className={cn("inline-flex", "flex-wrap")}>
                    <Strong>{t((d) => d.tooltip.understood)}</Strong>
                    <div className={cn("ml-1")}>👌</div>
                  </button>
                </div>

                {position == "top" && (
                  <div
                    className={cn("relative", "w-3", "h-3", "-mt-2", "rotate-45", "bg-blue-600")}
                    style={{
                      left: coord?.pinx,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
