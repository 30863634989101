import { Select } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { Options } from "..";
import { useAdminDrivers } from "../../hooks/useAdminDrivers";

interface Props {
  className?: string;
  /**
   * A label for this use of the driver select.
   */
  labelText?: string;
  /**
   * The ID of the account from which to fetch driver users.
   */
  accountId?: string;
  /**
   * The ID of the driver user currently selected.
   */
  value: string;
  /**
   * A standard on change handler that returns a new driver user IRI.
   */
  onChange(args: { userIRI: string }): void;
  /**
   * Whether to disable suspended drivers as an available option.
   * Defaults to true
   */
  disableSuspendedDrivers?: boolean;
  /**
   * In some cases, we do not necesarily want to pre-select a user.
   * Therefore, we provide the placeholder interface from the <Options> component.
   */
  placeholder?: {
    text: string;
    value?: string;
  };
  showBorder?: boolean;
  noDefaultClasses?: boolean;
  disabled?: boolean;
  beforeSelect?: React.ReactNode;
}

export const DriverSelect: React.FC<Props> = (props) => {
  const driverAdmin = useAdminDrivers(props.disableSuspendedDrivers);

  if (!driverAdmin || !driverAdmin.options?.length) return null;
  return (
    <div className={props.className}>
      {props.beforeSelect}
      <Select
        className={cn("w-full")}
        labelText={props.labelText}
        value={props.value}
        showBorder={props.showBorder}
        disabled={props.disabled}
        onChange={(userIRI) => {
          props.onChange({ userIRI });
        }}
      >
        {props.placeholder ? (
          <Options placeholderText={props.placeholder.text} placeholderValue={props.placeholder.value}>
            {driverAdmin?.options}
          </Options>
        ) : (
          driverAdmin?.options
        )}
      </Select>
    </div>
  );
};
