export const transpose = (indexA, indexB) => {
    return (value, index, array) => {
        if (index === indexA)
            return [array[indexB]];
        if (index === indexB)
            return [array[indexA]];
        return [value];
    };
};
export default transpose;
