import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { CacheKey, DayRouteStopParams, Routes, coreClient } from "../../../utils";
import { Content, PageHead } from "../../../layout";
import { getActivityAndHumanIndex } from "../utils";
import { DayRouteConfirmCustomStop } from "./DayRouteConfirmCustomStop";
import { DayRouteConfirmNormalStop } from "./DayRouteConfirmNormalStop";

export const DayRouteConfirmStop: React.FC = () => {
  const params = useParams<DayRouteStopParams>();
  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );

  const { activity, humanIndex } = getActivityAndHumanIndex({
    activities: dayRoute.data?.day_route_activities || [],
    activityId: params.stop_id,
  });

  if (!activity) {
    return (
      <Content
        header={
          <PageHead
            sectionType="split-details"
            backUrl={Routes.dayroutes.details(params)}
            title={`Stop: ${humanIndex}`}
          />
        }
        message={(dayRoute.error as Error)?.message}
        messageWrap="split-details"
        isLoading={dayRoute.isLoading}
      />
    );
  }
  if (["pickup", "delivery"].includes(activity.type)) {
    return <DayRouteConfirmNormalStop activity={activity} humanIndex={humanIndex} />;
  }
  return <DayRouteConfirmCustomStop humanIndex={humanIndex} />;
};
