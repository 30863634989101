import { CarryingHelp, ItemSet, ItemSetServices, StopSituation } from "@brenger/api-client";
import {
  IconAssembly,
  IconAuction,
  IconDresscode,
  IconFragile,
  IconHeavy,
  IconPersonAdd,
  IconShop,
  IconTailgate,
} from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { ResponsiveContainer } from "..";
import { hasProductSelectionService } from "../../utils";

interface Props {
  details?: {
    situation: StopSituation[];
    carrying_help: CarryingHelp[];
  };
  services?: ItemSetServices[];
  itemSets?: ItemSet[];
  iconProps?: React.SVGAttributes<SVGElement>;
}

export const StopDetailIcons: React.FC<Props> = ({ details, services, iconProps, itemSets }) => {
  const stopServices = services ? services : itemSets?.flatMap((item) => item.services);
  const iconAttributes = (specs?: { addWith: boolean }): React.SVGAttributes<SVGElement> =>
    iconProps ? iconProps : { className: cn(specs?.addWith ? ["m-1", "w-6", "h-6"] : ["m-1"]) };

  return (
    <ResponsiveContainer>
      {details?.situation.includes("auction") && <IconAuction {...iconAttributes()} />}
      {details?.situation.includes("store") && <IconShop {...iconAttributes()} />}
      {details?.carrying_help.includes("extra_driver") && <IconPersonAdd {...iconAttributes()} />}
      {details?.carrying_help.includes("equipment_tailgate_extra_driver") && <IconPersonAdd {...iconAttributes()} />}
      {details?.carrying_help.includes("equipment_tailgate") && <IconTailgate {...iconAttributes({ addWith: true })} />}
      {details?.carrying_help.includes("equipment_tailgate_pallet_jack") && (
        <IconTailgate {...iconAttributes({ addWith: true })} />
      )}

      {stopServices?.includes("assembly") && <IconAssembly {...iconAttributes()} />}
      {stopServices?.includes("safety_shoes_required") && <IconDresscode {...iconAttributes()} />}
      {hasProductSelectionService(itemSets, "fragile") && <IconFragile {...iconAttributes({ addWith: true })} />}
      {hasProductSelectionService(itemSets, "heavy") && <IconHeavy {...iconAttributes({ addWith: true })} />}
    </ResponsiveContainer>
  );
};
