import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";

import { CacheKey, coreClient } from "../utils";
import { useFormatDate } from "../hooks";
import { useQueries } from "@tanstack/react-query";
import { TransportJobAccountLinkListParams } from "@brenger/api-client";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useDashboardQuery = () => {
  const formatDateForApi = useFormatDate("api-date");

  const jobsData = useQueries({
    queries: [
      {
        presentation: "planning",
        date_from: formatDateForApi(subDays(new Date(), 14).toISOString()),
        date_to: formatDateForApi(addDays(new Date(), 365).toISOString()),
      },
      {
        presentation: "planning",
        state: ["pending", "temporary_accepted"],
        date_from: formatDateForApi(subDays(new Date(), 14).toISOString()),
        date_to: formatDateForApi(addDays(new Date(), 365).toISOString()),
      },
      {
        presentation: "delivered",
        state: ["delivered"],
        date_from: formatDateForApi(subDays(new Date(), 90).toISOString()),
        date_to: formatDateForApi(addDays(new Date(), 365).toISOString()),
      },
      {
        presentation: "delivered",
        state: ["delivered"],
      },
    ].map((args) => {
      return {
        queryKey: [CacheKey.RETRIEVE_DASHBOARD, args.presentation, args.state],
        queryFn: () => coreClient.transportJobAccountLinks.list(args as TransportJobAccountLinkListParams),
      };
    }),
  });

  // Parse out the relevant part of the jobs data response
  const [plannedJobsCount, pendingJobsCount, lastQuarterJobsCount, totalJobsCount] = (jobsData || []).map(
    (d) => d.data?.["hydra:totalItems"]
  );

  const jobsWithError = jobsData.find((d) => d.error);

  return {
    plannedJobsCount,
    pendingJobsCount,
    lastQuarterJobsCount,
    totalJobsCount,
    error: jobsWithError?.error,
  };
};
