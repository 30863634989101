import * as Sentry from "@sentry/react";
import { CaptureContext } from "@sentry/types";

export function tryToCatch<T>(fn: () => T, message?: string, context?: CaptureContext): [T | null, Error | null] {
  try {
    const res = fn();
    return [res, null];
  } catch (err) {
    if (message) Sentry.captureMessage(message, context);
    return [null, err as Error];
  }
}
