import { DeviceInfo } from "@capacitor/device";
import * as Sentry from "@sentry/react";
import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import * as React from "react";

import { useNativePlatform } from ".";
import { Config } from "../config";
import { useDispatch } from "react-redux";
import { appSettingsActions } from "../store/appSettings";
import { BrengerProviderChangeEvent } from "../store/appSettings/types";

interface UseGeolocationReadyParams {
  deviceId: string;
  deviceInfo: DeviceInfo;
  userId: string;
  channelName: string;
  rationaleTitle: string;
  rationaleText: string;
  notificationTitle: string;
  notificationText: string;
}

export interface UseGeolocation {
  isPluginEnabled: boolean;
  ready(params: UseGeolocationReadyParams): Promise<void>;
}

export const useGeolocationReady = (): UseGeolocation => {
  const isNativePlatform = useNativePlatform();
  const [isPluginEnabled, setIsPluginEnabled] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const dispatch = useDispatch();

  /**
   * MAKE THE GEOLOCATION PLUGIN "READY"
   */
  const ready = React.useCallback(
    async (params: UseGeolocationReadyParams) => {
      if (!isNativePlatform || isReady) return;

      try {
        await BackgroundGeolocation.ready({
          // Read as desired "location setting".
          // It's basically up to user, but in app we will provide more guidance to make the right choice
          // Either by the priming modal before giving permission, or by the red message on top of the app to draw attention
          // Setting this option to "any" prevents "backgroundPermissionRationale" modal to pop up.
          locationAuthorizationRequest: "WhenInUse",
          desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
          // Allow the background-service to continue tracking when app terminated.
          stopOnTerminate: false,
          // Auto start tracking when device is powered-up
          startOnBoot: true,
          // https://transistorsoft.github.io/capacitor-background-geolocation/interfaces/config.html#distancefilter:~:text=Optional-,distanceFilter,-distanceFilter%3A
          distanceFilter: 100,
          // keeping max persisted records 10.000 record
          // this is to prevent the app from growing a lot in disk size, and still have about 1000 kms of logging if needed.
          maxRecordsToPersist: 10000,
          // Only track working hours
          schedule: ["1-7 6:00-22:00"],
          foregroundService: true,
          forceReloadOnBoot: true,
          url: Config.USER_LOCATION_URL,
          backgroundPermissionRationale: {
            title: params.rationaleTitle,
            message: params.rationaleText,
          },
          notification: {
            channelName: params.channelName,
            title: params.notificationTitle,
            text: params.notificationText,
            smallIcon: "drawable/b_icon",
            largeIcon: "drawable/b_icon",
          },
          headers: {
            // Use this header to communicate the version of the plugin that is provding coordinates.
            // The "old" plugin we initially used provided a different body, so our geo service needs to know.
            "X-GEO-PAYLOAD-VERSION": 2,
          },
          extras: {
            userId: params.userId,
            deviceId: params.deviceId || null,
          },
        });
        setIsReady(true);
        setIsPluginEnabled(true);
      } catch (error) {
        Sentry.captureMessage("Making geolocation plugin ready failed.", {
          extra: { error },
        });
      }
    },
    [isNativePlatform, isReady]
  );

  /**
   * MOUNT GEOLOCATION EVENT LISTENERS
   */
  React.useEffect(() => {
    if (!isNativePlatform) return;
    /**
     * onEnabledChange - eg: when start/stop schedule get's triggerd.
     */
    BackgroundGeolocation.onSchedule((state) => {
      setIsPluginEnabled(state.enabled);
    });

    /**
     * onProviderChange - when something on the device is changed - eg: permissions, location settings, etc.
     */
    BackgroundGeolocation.onProviderChange((e) =>
      // See BrengerProviderEvent for details
      dispatch(appSettingsActions.setLocationProvider(e as BrengerProviderChangeEvent))
    );

    return () => {
      BackgroundGeolocation.removeListeners();
    };
  }, [isNativePlatform]);

  return {
    ready,
    isPluginEnabled,
  };
};
