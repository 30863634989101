import { IconArrowLeft } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useHistory } from "react-router";
import { Section, SectionType } from ".";
import { USE_HISTORY_BACK_PARAM } from "../utils";
import { HANDLE_HISTORY_BACK } from "../hooks";

export interface PageHeadProps {
  title?: string;
  backUrl?: string;
  beforeTitle?: React.ReactNode;
  afterTitle?: React.ReactNode;
  sectionType: SectionType;
}

export const PageHead: React.FC<PageHeadProps> = ({ title, backUrl, beforeTitle, afterTitle, sectionType }) => {
  const history = useHistory();
  // Set up url constructor
  const urlObj = new URL(window.location.href);
  // Check if we want to use history back
  const isGoBack = !!urlObj.searchParams.get(USE_HISTORY_BACK_PARAM);
  // If we have a backUrl, set it and add a flag to handle it in useRestoreScroll
  if (backUrl) {
    urlObj.pathname = backUrl;
    urlObj.searchParams.set(HANDLE_HISTORY_BACK, "true");
  }
  // Adjust backUrl if we have it, otherwise set to undefined
  const adjustedBackurl = !backUrl ? undefined : `${urlObj.pathname}${urlObj.search}`;

  return (
    <Section type={sectionType}>
      <div className={cn("flex items-justify", "br-text-blue-600")}>
        {adjustedBackurl && (
          <button onClick={isGoBack ? () => history.goBack() : () => history.push(adjustedBackurl)}>
            <IconArrowLeft className="w-6 h-8" style={{ minWidth: "1.5rem" }} />
          </button>
        )}
        {beforeTitle}
        {title && <b className="mx-auto block relative top-[.1rem]">{title}</b>}
        {afterTitle}
      </div>
    </Section>
  );
};
