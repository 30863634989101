import * as React from "react";
import cn from "classnames";
import { Message } from "@brenger/react";

import { ExternalLink } from "..";
import { useTranslation } from "../../hooks";
import { Config, LOCALE_TEMPLATE } from "../../config";

/**
 * This component is re-used on all the various claim forms (regular, bundled, etc).
 */
export const ClaimJobAgreement: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <Message type="alert">
      <span>
        {t((d) => d.search.messages.tr_agreement)}
        <ExternalLink url={Config.DRIVER_AGREEMENT_URL.replace(LOCALE_TEMPLATE, i18n.locale)}>
          <span className={cn("underline", "pointer", "ml-1")}>{t((d) => d.search.messages.tr_agreement_link)}</span>
        </ExternalLink>
      </span>
    </Message>
  );
};
