import { User } from "@brenger/api-client";
import { Card, H4, IconHorizontalDots, useModalState } from "@brenger/react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { missingImageSrc } from "../../../../assets/images/missingImageSrc";
import { MetaItem, UserStatusPill } from "../../../../components";
import { useAuth, useTranslation, UseURLSearchParam } from "../../../../hooks";
import { getFirstAndLastName, Routes } from "../../../../utils";
import { ActionSheetModal, ActionSheetModalOption } from "../../../../layout/SheetModal/ActionSheetModal";

interface Props {
  user: User;
}

export const SubAccountCard: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const isLoggedInUser = user["@id"] === auth.user?.["@id"];
  const actionModal = useModalState();
  const history = useHistory();
  const search = new URLSearchParams({
    user_iri: user["@id"],
  } as UseURLSearchParam).toString();
  const actions: ActionSheetModalOption[] = [
    {
      title: t((d) => d.app.tabs.details),
      onClick: () => history.push(Routes.more.profile() + `?${search}`),
    },
    {
      title: t((d) => d.app.menu.reviews),
      onClick: () => history.push(Routes.more.reviews() + `?${search}`),
    },
  ];
  return (
    <Card>
      <div className={cn("flex", "items-center")}>
        <div
          style={{ minWidth: "4rem" }}
          className={cn("relative", "overflow-hidden", "rounded-full", "w-16", "h-16", "bg-gray-200")}
        >
          <img className={cn("h-full", "w-full", "object-cover")} src={user?.profile_image || missingImageSrc} />
        </div>
        <div className={cn("flex", "flex-col", "justify-center", "ml-4", "w-full")} style={{ minWidth: 0 }}>
          {user.deletion_token === null && (
            <div className={cn("flex", "justify-between")}>
              <H4>
                {getFirstAndLastName(user)} {isLoggedInUser ? "**" : null}
              </H4>
              <button className={cn("px-1")} onClick={actionModal.open}>
                <IconHorizontalDots />
              </button>
            </div>
          )}
          {user.deletion_token && <H4>{user.deletion_token}</H4>}
          <div>
            <UserStatusPill user={user} />
          </div>
          {user.total_confirmation_with_proximity_success_rate && (
            <MetaItem
              name={t((d) => d.dashboard.total_confirmation_success_rate)}
              value={`${Math.round(user.total_confirmation_with_proximity_success_rate * 100)}%`}
            />
          )}
        </div>
      </div>
      <ActionSheetModal isOpen={actionModal.isActive} close={actionModal.close} options={actions} />
    </Card>
  );
};
