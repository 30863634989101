import * as React from "react";
import cn from "classnames";

import { useTranslation } from "../../../hooks";
import { ActivityValidationError, activityValidationErrorLevelMap } from "../utils";
import { ErrorLevel } from "../../../types";

interface Props {
  error: ActivityValidationError;
}

const errorColorMap: Record<ErrorLevel, string> = {
  error: "text-red-600",
  warning: "text-orange-500",
};

export const DayRouteValidation: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  const textColor = errorColorMap[activityValidationErrorLevelMap[error]] || "text-current";

  return <small className={cn("br-font-bold", textColor)}>{t((d) => d.day_route.validation[error])}</small>;
};
