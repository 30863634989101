import { User } from "@brenger/api-client";

/**
 * Use this util in a .sort() callback when ordering users by first name (ascending order)
 */
export const compareByNameAsc = (userA: User, userB: User): number => {
  return (userA.first_name || "")[0].toLowerCase() < (userB.first_name || "")[0].toLowerCase() ? 1 : -1;
};

export const getSanitizedUserList = (users: User[]): User[] => {
  return (
    users
      // First, only show users WITHOUT a deletion_token (users with a deletion token had the forgot option selected in the admin panel)
      .filter((user) => user.deletion_token === null)
      // Second, sort users by alphabetical order according to email.
      .sort(compareByNameAsc)
      // Third, move suspended users to the bottom of the list.
      .sort((a) => (a.is_suspended ? 1 : -1))
  );
};

export const getDeletedUserList = (users: User[]): User[] => {
  return (
    users
      // First, only show users WITHOUT a deletion_token (users with a deletion token had the forgot option selected in the admin panel)
      .filter((user) => user.deletion_token !== null)
      // Second, sort users by alphabetical order according to email.
      .sort(compareByNameAsc)
  );
};
