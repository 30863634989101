import { Vehicle } from "@brenger/api-client";
import { Strong } from "@brenger/react";
import cn from "classnames";

export const LicensePlate: React.FC<{ vehicle?: Vehicle }> = (props) => {
  if (!props.vehicle) {
    return null;
  }
  return (
    <div className={cn("inline-block", "border-solid", "border-2", "border-black", "rounded", "bg-white", "mb-1")}>
      <Strong className={cn("px-2", "py-1", "bg-blue-600", "text-white", "inline-block")}>
        {props.vehicle.license_plate_country_code}
      </Strong>
      <Strong className={cn("px-2", "py-1", "inline-block")}>{props.vehicle.license_plate}</Strong>
    </div>
  );
};
