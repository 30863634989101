import { Button, IconEditPencil, IconCheck, InputText, Label, Message } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Grid } from "../../../components";
import { useForm, useTranslation, UseURLSearchParam } from "../../../hooks";

import { CacheKey, coreClient, getDigitLength, isEmailValid } from "../../../utils";

interface Props {
  canEdit: boolean;
  userId?: string;
}

export const UserDetailsForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [inEditMode, setInEditMode] = React.useState(false);

  const form = useForm({
    initialState: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
    validators: {
      first_name: (val) => !val,
      last_name: (val) => !val,
      email: (val) => !isEmailValid(val),
      phone: (val) => !val || getDigitLength(val) < 10 || getDigitLength(val) > 14,
    },
  });

  const user = useQuery(
    [CacheKey.RETRIEVE_USER, props.userId],
    () => coreClient.users.retrieve({ userId: props.userId || "" }),
    {
      enabled: !!props.userId,
    }
  );

  const updateUser = useMutation(coreClient.users.update, {
    onSuccess: () => {
      // In case a user's email gets updated, it is best to fully reload the page in this callback.
      // This will ensure that all updated properties propagate correctly.
      const userIri = user.data ? user.data["@id"] : "";
      const searchParams = new URLSearchParams({ user_iri: userIri } as UseURLSearchParam).toString();
      // Reload the page with the updated user's IRI in the search params.
      // Doing this ensures that the page reload with the recently-updated user already pre-selected.
      window.location.assign(`${location.pathname}?${searchParams}`);
    },
  });

  React.useEffect(() => {
    if (user.data) {
      form.set({
        first_name: user.data.first_name || "",
        last_name: user.data.last_name || "",
        email: user.data.email || "",
        phone: user.data.phone || "",
      });
    }
    // Run the effect when the user is present so we can initialize the form.
  }, [user.data]);

  return (
    <div>
      <Grid cols={{ lg: 2 }} gap={4}>
        <Label
          text={t((d) => d.profile.first_name)}
          error={form.isFieldDirty("first_name") && form.getError("first_name")}
        >
          <InputText
            className={cn("w-full")}
            disabled={!inEditMode}
            value={form.getValue("first_name")}
            onChange={(first_name) => form.set({ first_name })}
          />
        </Label>
        <Label
          text={t((d) => d.profile.last_name)}
          error={form.isFieldDirty("last_name") && form.getError("last_name")}
        >
          <InputText
            className={cn("w-full")}
            disabled={!inEditMode}
            value={form.getValue("last_name")}
            onChange={(last_name) => form.set({ last_name })}
          />
        </Label>
        <Label text={t((d) => d.profile.email)} error={form.isFieldDirty("email") && form.getError("email")}>
          <InputText
            className={cn("w-full")}
            disabled={!inEditMode}
            value={form.getValue("email")}
            onChange={(email) => form.set({ email })}
          />
        </Label>
        <Label text={t((d) => d.profile.phone_number)} error={form.isFieldDirty("phone") && form.getError("phone")}>
          <InputText
            className={cn("w-full")}
            disabled={!inEditMode}
            value={form.getValue("phone")}
            onChange={(phone) => form.set({ phone })}
          />
        </Label>
      </Grid>
      {props.canEdit && !inEditMode && (
        <Button className={cn("mt-6", "w-full")} onClick={() => setInEditMode(true)} icon={<IconEditPencil />}>
          {t((d) => d.profile.edit_personal_details)}
        </Button>
      )}
      {props.canEdit && inEditMode && (
        <Button
          buttonType="secondary"
          className={cn("mt-6", "w-full")}
          loading={updateUser.isLoading}
          icon={<IconCheck />}
          onClick={() => {
            if (!props.userId) return;
            updateUser.mutate({
              userId: props.userId,
              first_name: form.getValue("first_name"),
              last_name: form.getValue("last_name"),
              email: form.getValue("email"),
              phone: form.getValue("phone"),
            });
          }}
        >
          {t((d) => d.profile.save_personal_details)}
        </Button>
      )}
      {updateUser.isError && (
        <Message className={cn("mt-4")} type="error">
          {(updateUser.error as Error)?.message}
        </Message>
      )}
    </div>
  );
};
