import * as Sentry from "@sentry/react";
import OneSignalNative from "onesignal-cordova-plugin";
import * as React from "react";
import OneSignalWeb from "react-onesignal";
import { Config } from "../config";
import { logger } from "../utils";
import { useAuth } from "./useAuth";
import { useDeviceInfo } from "./useDeviceInfo";
import { useSelector } from "./useSelector";
import { useDispatch } from "react-redux";
import { appSettingsActions } from "../store/appSettings";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";

declare global {
  interface Window {
    plugins: {
      OneSignal: typeof OneSignalWeb;
    };
  }
}

interface SubscriptionArgs {
  callback?(): void;
}

export interface UsePushNotifications {
  /**
   * Trigger once to initialize push SDK
   */
  init(): Promise<void>;
  /**
   * Does this client has push notifications enabled?
   */
  isEnabled: boolean;
  isInit: boolean;
  /**
   * isLoading, false when all permission are ready to use
   */
  isLoading: boolean;
  /**
   * enable push subscription
   */
  subscribe(args?: SubscriptionArgs): Promise<void>;
  toggleSubsription(args?: SubscriptionArgs): Promise<void>;
  /**
   * WEB PUSH ONLY!
   * Reflects permission state
   */
  permission?: null | NotificationPermission;
}

export const usePushNotifications = (): null | UsePushNotifications => {
  const platform = useDeviceInfo()?.platform;
  const web = useWebPush();
  const native = useNativePush();
  if (platform === "web") {
    return web;
  }
  if (platform === "ios" || platform === "android") {
    return native;
  }
  return null;
};

const useWebPush = (): UsePushNotifications => {
  const auth = useAuth();
  const isInit = useSelector((state) => state.appSettings.push.isInit);
  const dispatch = useDispatch();
  const [permission, setPermission] = React.useState<null | NotificationPermission>(null);
  const [isEnabled, setIsEnabled] = React.useState<null | boolean>(null);
  const platform = useDeviceInfo()?.platform;

  const init = async (): Promise<void> => {
    // Bail early
    if (platform !== "web" || !Config.ONE_SIGNAL_FCM_ID || isInit) return;
    // Flag initialized
    log("Init");
    dispatch(appSettingsActions.setInitPush(true));
    await OneSignalWeb.init({
      appId: Config.ONE_SIGNAL_FCM_ID,
      // NOTE: Localhost for web-push should be secure in development, to test it.
      // In the native SDK (below: useNativePush) this doesn't count since the native SDK always runs from localhost.
      allowLocalhostAsSecureOrigin: Config.NODE_ENV === "development",
      // When cache is cleared and revisted the app, the user gets resubscribed again
      autoResubscribe: true,
    });
  };

  const updateStatus = async (): Promise<void> => {
    if (platform !== "web") return;
    log("Get status");
    const status = await OneSignalWeb.getNotificationPermission();
    const enabled = await OneSignalWeb.isPushNotificationsEnabled();
    setPermission(status);
    setIsEnabled(enabled);
    log(`Status: ${status}`);
  };

  /**
   * Get status on mount, and update details on auth change
   */
  React.useEffect(() => {
    if (platform !== "web") return;
    // isInit is a dependency, because we want this effect only to run when init() is called
    if (!isInit) return;
    updateStatus();
    // setting user id and fallback communication details
    if (auth.isLoggedIn && auth.userId) {
      OneSignalWeb.setExternalUserId(auth.userId);
      return;
    }
    // removing user id and communication details
    if (!auth.isLoggedIn) {
      OneSignalWeb.removeExternalUserId();
    }
  }, [auth.userId, isInit, platform]);

  const prompt = async (args?: SubscriptionArgs): Promise<void> => {
    if (platform !== "web") return;

    log("Show prompt");
    await OneSignalWeb.showNativePrompt();
    await OneSignalWeb.setSubscription(true);
    await updateStatus();
    await args?.callback?.();
  };

  const subscribe = async (args?: SubscriptionArgs): Promise<void> => {
    if (platform !== "web") return;
    if (!isEnabled) {
      try {
        await prompt(args);
      } catch (e) {
        Sentry.captureMessage("[WEB PUSH] Failed to subscribe, while prompting.", {
          extra: { isEnabled, error: e },
        });
      }
      return;
    }
    // We shouldn't get here, but just in case
    Sentry.captureMessage("[WEB PUSH] Failed to subscribe, but callback fired.", {
      extra: { isEnabled },
    });
    await args?.callback?.();
  };

  const toggleSubsription = async (args?: SubscriptionArgs): Promise<void> => {
    if (platform !== "web") return;
    if (!isEnabled) {
      await subscribe(args);
      return;
    }
    await OneSignalWeb.setSubscription(false);
    await updateStatus();
    args?.callback?.();
  };

  const log = (message: string): void => {
    logger.dev(`[WEB PUSH] ${message}`);
  };

  return {
    init,
    subscribe,
    toggleSubsription,
    isEnabled: !!isEnabled,
    isLoading: isEnabled === null,
    isInit,
    permission,
  };
};

const useNativePush = (): UsePushNotifications => {
  const auth = useAuth();
  const isInit = useSelector((state) => state.appSettings.push.isInit);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = React.useState<null | boolean>(null);
  const platform = useDeviceInfo()?.platform;
  const isNative = platform === "android" || platform === "ios";

  const init = async (): Promise<void> => {
    if (!Config.ONE_SIGNAL_FCM_ID || !isNative || isInit) {
      return;
    }
    // Flag initialized
    dispatch(appSettingsActions.setInitPush(true));

    await OneSignalNative.initialize(Config.ONE_SIGNAL_FCM_ID);
  };

  const getPermissionStatus = async (): Promise<void> => {
    const permission = await OneSignalNative.Notifications.getPermissionAsync();
    setIsEnabled(permission);
  };

  React.useEffect(() => {
    if (!isInit || !isNative) return;
    getPermissionStatus();
    OneSignalNative.Notifications.addEventListener("permissionChange", (granted: boolean) => {
      setIsEnabled(granted);
    });
  }, [isInit, isNative]);
  /**
   * Get status on mount, and update details on auth change
   */
  React.useEffect(() => {
    // isInit is a dependency, because we want the rest of the effect only to run when init() is called
    if (!isNative || !isInit) return;

    // setting user id and fallback communication details
    if (auth.isLoggedIn && auth.userId) {
      OneSignalNative.login(auth.userId);
    }

    // removing user id and communication details
    if (!auth.isLoggedIn) {
      OneSignalNative.logout();
    }
  }, [auth, isInit, isNative, platform]);

  const prompt = async (args?: SubscriptionArgs): Promise<void> => {
    if (!isNative) return;
    // This is actually prompting the user with a native modal
    // Adding "true" as parameter indicates to fallback to permission settings if the user was already prompted
    await OneSignalNative.Notifications.requestPermission(true);
    await args?.callback?.();
  };

  const subscribe = async (args?: SubscriptionArgs): Promise<void> => {
    if (!isNative) return;
    try {
      if (!isEnabled) {
        await prompt(args);
        return;
      }
      await toggleSubsription(args);
    } catch (e) {
      Sentry.captureMessage("[NATIVE PUSH] Failed to subscribe, while prompting.", {
        extra: { isEnabled, error: e },
      });
    }
  };

  const toggleSubsription = async (args?: SubscriptionArgs): Promise<void> => {
    if (!isNative) return;
    try {
      if (platform == "android") {
        await NativeSettings.openAndroid({ option: AndroidSettings.AppNotification });
      }
      if (platform == "ios") {
        await NativeSettings.openIOS({ option: IOSSettings.App });
      }
    } catch (e) {
      Sentry.captureMessage("[NATIVE PUSH] Failed to subscribe, while toggling.", {
        extra: { isEnabled, error: e },
      });
    }
    args?.callback?.();
  };

  return {
    init,
    subscribe,
    toggleSubsription,
    isEnabled: !!isEnabled,
    isLoading: isEnabled === null,
    isInit,
  };
};
