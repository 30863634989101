import * as React from "react";

import { useBrowser } from "../../hooks";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  url: string;
}

export const ExternalLink: React.FC<Props> = ({ url, children, ...rest }) => {
  const browser = useBrowser();

  return (
    <span onClick={() => browser.open({ url })} {...rest}>
      <a>{children}</a>
    </span>
  );
};
