import * as React from "react";
import cn from "classnames";
import { Strong } from "@brenger/react";

export interface IMetaItem {
  name: string;
  value?: string | number | null | React.ReactNode;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  items: IMetaItem[];
}

export const MetaItem: React.FC<IMetaItem> = (props) => {
  return (
    <div className={cn("inline-flex")}>
      <div>
        <Strong>{props.name}</Strong>
      </div>
      {props.value ? <div className={cn("ml-1")}>{props.value}</div> : null}
    </div>
  );
};

/**
 * This is a convienance component for redering key/value pairs.
 */
export const MetaItems: React.FC<Props> = ({ items, className, ...unused }) => {
  return (
    <div className={cn("flex", "flex-col", className)} {...unused}>
      {items.map((item, idx) => {
        return <MetaItem {...item} key={idx} />;
      })}
    </div>
  );
};
