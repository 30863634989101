import { Address, TransportJob, RouteMeta, DayRoute, DayRouteActivity, Pickup, Delivery } from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import { useQuery } from "@tanstack/react-query";

import { JobPresentation, CacheKey, coreClient } from "../utils";
import { Location } from "../components";

const GOOGLE_MAPS_BASE_URL = "https://www.google.com/maps";

/**
 * Create a less accurate address by removing some properties..
 */
const sanitizeAddress = (address: Address | null): Partial<Address> => {
  // Create a new copy of the address that we can sanitize
  const sanitizedAddress = {
    ...address,
    // Make the lat & lng's less precise
    lat: address ? parseFloat(address.lat?.toFixed(3)) || undefined : undefined,
    lng: address ? parseFloat(address.lng?.toFixed(3)) || undefined : undefined,
  };

  // Delete sensitive properties
  Reflect.deleteProperty(sanitizedAddress, "line1");
  Reflect.deleteProperty(sanitizedAddress, "line2");
  Reflect.deleteProperty(sanitizedAddress, "postal_code");

  return sanitizedAddress;
};

/**
 * This util serializes core addresses into a format that Google Maps can interpret in a query param.
 */
const encodeAddress = (address: Partial<Address>): string => {
  /**
   * We removed the use of lat/lngs as orientation.
   * - The latlngs are usefull when calculating prices (general flow / business flow)
   * - But when it comes to directions: google will try to name the latlngs in directions resulting
   * in slightly off address details.
   * Example: feeding 52.1074995,5.0923084 to google, shows Werner Helmichstraat 12, Utrecht
   * While it should point to my home address which is number 14.
   */
  const addressParts = [
    address.line1,
    address.line2,
    address.locality,
    address.postal_code,
    address.administrative_area,
    address.country_code,
  ]
    .filter(Boolean)
    .join(", ");

  return encodeURI(addressParts);
};

/**
 * Create a Google Maps URL that leaves the origin as blank and the destination as the provided address.
 */
export const createGoogleMapsLinkFromSingleAddress = (address: Partial<Address> | null): string => {
  if (!address) return "";

  // NOTE: add second slash after dir (dir//) so that direction origin is left empty and only destination is pre-filled.
  return `${GOOGLE_MAPS_BASE_URL}/dir//${encodeAddress(address)}`;
};

/**
 * Create a route along a series of address.
 */
export const createGoogleMapsLinkFromMultipleAddresses = (addresses: Partial<Address | null>[]): string => {
  return addresses
    ? `${GOOGLE_MAPS_BASE_URL}/dir/${addresses
        .filter(Boolean)
        // NOTE: save to cast as Partial<Address> due to preceeding Boolean filter
        .map((address) => encodeAddress(address as Partial<Address>))
        .join("/")}`
    : "";
};

const generateMarker = (address: Partial<Address> | null): Location | string => {
  if (!address) return "";

  if (address?.lat && address?.lng) {
    return { lat: address.lat, lng: address.lng };
  }

  return encodeAddress(address);
};

type CommonStop = {
  "@id": string;
  "@type": string;
  type?: DayRouteActivity["type"];
  address: Address;
};

// NOTE: this hook accepts transport jobs and day routes. Both of these entities share in common
// data that is relevant to creating a proper map context. However, for ease of use, we want to
// convert these entities in a common interface so that they are easier and more generic to work with.
const getCommonStopInterface = (stop: Pickup | Delivery | DayRouteActivity): CommonStop => {
  return {
    "@id": stop["@id"],
    "@type": stop["@type"],
    address: stop.address as Address,
  };
};

// based on external route service we receive different routepoint types
export type RoutePointTomTom = { latitude: number; longitude: number };
export type RoutePointGoogle = number[];

interface MapContextParams {
  presentation: JobPresentation;
  /**
   * The day route contains all the information required to display markers/route on a map.
   */
  dayRoute?: DayRoute | null;
  drRoute?: (RoutePointGoogle | RoutePointTomTom)[];
  /**
   * A UNBUNDLED TJ contains all the information required to display markers/route on a map.
   * A BUNDLED TJ requires an additional fetch for the route.
   */
  tj?: TransportJob | null;
  /**
   * Adding a stop ID will focus the map on that specific stop, instead of the entire route.
   */
  stopId?: string;
}

export interface Marker {
  location: Location | string;
  stopNumber: string | undefined;
  markerColor?: "green" | "blue";
}
export interface MapContext {
  markers: Marker[];
  points: Location[];
  googleMapsUrl: string;
}

const MAX_NUMBER_OF_ROUTE_POINTS = 100;

export const useMapContext = ({ presentation, tj, dayRoute, stopId, drRoute }: MapContextParams): MapContext => {
  // These presentation layers allow for precise address details (ie, no sanitizing necessary)
  const isAccurate = ["planning", "delivered"].includes(presentation);

  /**
   * For bundled jobs, must also fetch the route.
   * Note: for regular jobs, the stop context can be inferred.
   */
  const routeId = getIdFromIri(tj?.transport_route);
  const route = useQuery(
    [CacheKey.RETRIEVE_ROUTE, routeId],
    () => coreClient.routes.retrieve({ id: routeId as string }),
    {
      enabled: !!routeId && !tj?.bundled,
    }
  );

  // Initialize a base map context
  const mapContext: MapContext = {
    markers: [],
    points: [],
    googleMapsUrl: "",
  };

  // Nothing to do here if BOTH tj and dayRoute are not provided.
  if (!tj && !dayRoute) return mapContext;

  // If bundled TJ, Nothing to do here until the route is fetched.
  if (tj && tj.bundled && !route.data) return mapContext;

  let stopList: CommonStop[] = [];

  if (tj) {
    stopList = [...tj.pickups, ...tj.deliveries].map(getCommonStopInterface);
  }

  if (dayRoute) {
    stopList = (dayRoute?.day_route_activities || [])
      .filter((stop) => stop.type !== "break")
      .map((dr) => ({ ...getCommonStopInterface(dr), type: dr.type }));
    // NOTE: add start address to beginning of stop list in a day route (start address is not included in the day route activities)!
    const firstStop: CommonStop = {
      "@type": "DayRouteActivity",
      "@id": "",
      address: dayRoute.start_address,
    };

    stopList = [firstStop, ...stopList];
  }

  // Gather a list of all stops for this TJ and sanitize the address to match the presentation we are in.
  const formattedStopList = stopList
    .map((stop, idx) => {
      const address = stop.address as Address | null;
      let stopNumber: number | undefined;

      // CASE: regular jobs - deduce the stop number based on whether it's the pickup or delivery
      if (stopId) {
        stopNumber = stop["@type"] === "Pickup" ? 1 : 2;
      }

      // CASE: bundled jobs - use the route to figure out the stop index.
      if (tj?.bundled) {
        // Find the stop number from the route
        const bundleIndex = route.data?.stops.find((routeStop) => {
          return [routeStop.pickup, routeStop.delivery].includes(stop["@id"]);
        })?.index;
        stopNumber = bundleIndex !== undefined ? bundleIndex + 1 : bundleIndex;
      }

      // CASE: day route
      if (dayRoute) {
        // NOTE: the day route activities are returned in correct order from backend.
        // Therefore, can use index while mapping.
        stopNumber = idx + 1;
      }

      return {
        iri: stop["@id"],
        type: stop.type,
        stopNumber,
        address: isAccurate ? address : sanitizeAddress(address),
      };
    })
    // Sort the stop by stop number.
    .sort((stopA, stopB) => (stopA.stopNumber || 0) - (stopB.stopNumber || 0));
  // If we have a stopId available, it means this map will display a single marker.
  if (stopId) {
    // Getting a stopId means we are looking at a stop details page, therefore only create a maps url for one address.
    const stop = formattedStopList.find((s) => getIdFromIri(s.iri) === stopId);
    if (stop) {
      mapContext.googleMapsUrl = createGoogleMapsLinkFromSingleAddress(stop.address as Address);
      mapContext.markers = [
        {
          markerColor: stop.type === "proposed_delivery" || stop.type === "proposed_pickup" ? "green" : "blue",
          stopNumber: stop.stopNumber ? stop.stopNumber.toString() : undefined,
          location: generateMarker(stop.address),
        },
      ];
    }
  } else {
    // If we have NO stopId, it means we are displaying a route along a series of markers.
    // Set the route if we have them (this is for the lines that connect the markers)
    let pointsRaw = (tj?.route as RouteMeta | undefined)?.points;

    if (dayRoute) pointsRaw = drRoute;

    if (pointsRaw) {
      let points = pointsRaw
        .map((routePoint): RoutePointGoogle => {
          // Convert TomTom objects into GooglePoints [number, number]
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((routePoint as any)?.latitude) {
            const point = routePoint as unknown;
            return [(point as RoutePointTomTom).latitude, (point as RoutePointTomTom).longitude] as RoutePointGoogle;
          }

          return routePoint as RoutePointGoogle;
        })
        // Enforce a max precision - we don't need super long decimals.
        .map((routePoint): Location => {
          const [routePointA, routePointB] = routePoint;
          // FIX ME, probably no filtering needed
          const lat = typeof routePointA === "number" ? parseFloat(routePointA.toFixed(6)) : 0;
          const lng = typeof routePointB === "number" ? parseFloat(routePointB.toFixed(6)) : 0;

          return { lat, lng };
        })
        // Remove the nulls!
        .filter((point) => point.lat !== 0 && point.lng !== 0);

      if (points.length > MAX_NUMBER_OF_ROUTE_POINTS) {
        const keepRatio = MAX_NUMBER_OF_ROUTE_POINTS / points.length;
        const divider = Math.ceil(1 / keepRatio);
        points = points.filter((...params) => {
          const idx = params[1];
          return idx % divider === 0;
        });
      }

      mapContext.points = points;
    }

    mapContext.markers = formattedStopList.map((stop) => {
      return {
        markerColor: stop.type === "proposed_delivery" || stop.type === "proposed_pickup" ? "green" : "blue",
        stopNumber: stop.stopNumber !== undefined ? stop.stopNumber.toString() : undefined,
        location: generateMarker(stop.address),
      };
    });

    mapContext.googleMapsUrl = createGoogleMapsLinkFromMultipleAddresses(formattedStopList.map((stop) => stop.address));
  }

  return mapContext;
};
