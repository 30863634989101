import * as React from "react";
import cn from "classnames";
import { Emphasis } from "@brenger/react";

import { useTranslation, useFormatDate } from "../../hooks";

interface Props {
  customerName: string;
  pickupDate: string;
  pickupStart: string;
  pickupEnd: string;
  deliveryDate: string;
  deliveryStart: string;
  deliveryEnd: string;
  driverMessage: string;
  driverName: string;
}

export const ClaimJobMessagePreview: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const formatDateShort = useFormatDate("date-short");
  const formatHourMinuteDate = useFormatDate("hour-minute");

  return (
    <div className={cn("bg-gray-200", "rounded", "p-4")}>
      {t((d, withTemplate) => {
        return withTemplate(d.search.messages.claim_job_message, {
          customer_name: props.customerName,
          pickup_date: formatDateShort(props.pickupDate),
          pickup_start: formatHourMinuteDate(props.pickupStart),
          pickup_end: formatHourMinuteDate(props.pickupEnd),
          delivery_date: formatDateShort(props.deliveryDate),
          delivery_start: formatHourMinuteDate(props.deliveryStart),
          delivery_end: formatHourMinuteDate(props.deliveryEnd),
          driver_message: props.driverMessage,
          driver_name: props.driverName,
        });
      })
        .split("\n")
        .map((bit, idx) => {
          return (
            <div key={idx} className={cn("leading-loose")}>
              <Emphasis>{bit}</Emphasis>
            </div>
          );
        })}
    </div>
  );
};
