import { Button, IconCaretLeft, IconCaretRight, IconFilter2, Message } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { TransportsFilters, TransportsListItem } from "../..";
import { NpsForm } from "../../../components";
import { useFormatDate, usePagination, useTranslation, useTransportsFilter } from "../../../hooks";
import { Routes, SHOW_NPS_PARAM, TransportsJobParams, TransportsListParams } from "../../../utils";
import { Content, Section } from "../../../layout";
import { TransportsListSwitch } from "./TransportsListTypeSwitch";

export const TransportsList: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [scrollTo, setScrollTo] = React.useState<{ left: number; top: number } | undefined>(undefined);
  const listMatch = useRouteMatch<TransportsListParams>({
    location,
    path: Routes.transports.home({ type: ":type" }),
  });
  const detailsMatch = useRouteMatch<TransportsJobParams>({
    location,
    path: Routes.transports.job({ type: ":type", job_id: ":job_id" }),
  });

  /**
   * Setup ref to execute scroll acions
   */
  const activeId = detailsMatch?.params.job_id;

  // Decide if we need to include the NPS modal
  const searchParams = new URL(window.location.href).searchParams;
  const includeNps = !!searchParams.get(SHOW_NPS_PARAM);

  const type = listMatch?.params.type || detailsMatch?.params.type;
  const presentation = (type === ":type" ? "planning" : type) || "planning";
  const [page, setPage] = React.useState(1);
  const { filteredTjals, isLoading, query, activeFilterCount } = useTransportsFilter({ presentation, page });
  const pagination = usePagination({ list: query.data, setPage, page });
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);

  const zeroResults = Object.keys(filteredTjals).length === 0;

  /**
   * Util for formatting the header
   */
  const formatDateFull = useFormatDate("date-full");

  /**
   * handle page nav, and scroll the virtual list to the top item
   */
  const prevPage = (): void => {
    pagination.goToPreviousPage();
  };
  const nextPage = (): void => {
    pagination.goToNextPage();
  };

  React.useEffect(() => {
    /**
     * We only want to execute this on load, once.
     * So we wait for the component to load and set the scroll coords once.
     * The coords will be passed down to the content scrollable list.
     */
    if (isLoading || scrollTo) return;
    const rect = document.getElementById(`list_${activeId}`)?.getBoundingClientRect();
    if (!rect) return;
    setScrollTo({ left: 0, top: rect.top - 75 });
  }, [isLoading, scrollTo]);

  const days = Object.keys(filteredTjals).sort();
  const daysOrderd = presentation === "planning" ? days : days.reverse();
  return (
    <>
      <Content
        isLoading={isLoading}
        scrollCoords={scrollTo}
        header={
          <Section type="split-list" className={cn("border-b", "border-gray-300", "flex-0", "-mb-[calc(1rem+1px)]")}>
            <TransportsListSwitch
              value={presentation}
              options={[
                {
                  value: "planning",
                  label: t((d) => d.transports.list.toggle.planning),
                  path: Routes.transports.home({ type: "planning" }),
                  onClick: () => setPage(1),
                },
                {
                  value: "delivered",
                  label: t((d) => d.transports.list.toggle.delivered),
                  path: Routes.transports.home({ type: "delivered" }),
                  onClick: () => setPage(1),
                },
              ]}
            />
          </Section>
        }
      >
        {/* Filters */}
        <Section type={"split-list"} className="mt-8">
          <Button
            className="w-full mb-4 !rounded-full !bg-color-light-blue"
            buttonType="primary-light"
            onClick={() => setIsFilterOpen(true)}
          >
            <div className="flex justify-between items-center">
              <span>
                {t((d) => d.transports.list.filters)} {activeFilterCount !== 0 && `(${activeFilterCount})`}
              </span>{" "}
              <IconFilter2 />
            </div>
          </Button>
        </Section>
        {/* Zero results */}
        {!isLoading && zeroResults && (
          <Section type="split-list">
            <Message type="info" className={"mb-4"}>
              {t((d) => d.planning.filter.zero_results)}
            </Message>
          </Section>
        )}
        {/* JOBS */}
        {!isLoading &&
          !zeroResults &&
          daysOrderd.map((date) => {
            return (
              <React.Fragment key={date}>
                <Section type={"split-list"}>
                  <h5 className="capitalize pt-6 pb-2">{formatDateFull(new Date(date).toUTCString())}</h5>
                </Section>
                {filteredTjals[date].map((tjal) => {
                  const id = getIdFromIri(tjal);
                  return (
                    <div key={id} id={`list_${id}`} className="mt-2">
                      <Section type={"split-list"}>
                        <TransportsListItem presentation={presentation} tjal={tjal} isActive={id === activeId} />
                      </Section>
                    </div>
                  );
                })}
              </React.Fragment>
            );
          })}
        {/* Pagination */}
        {(pagination.hasPreviousPage || pagination.hasNextPage) && (
          <Section type={"split-list"} className="flex pt-4">
            <div className={cn("mr-2")}>
              <Button
                className="w-12"
                buttonType="primary-light"
                onClick={prevPage}
                disabled={!pagination.hasPreviousPage}
              >
                <IconCaretLeft
                  style={{
                    fontSize: "1.75em",
                    position: "relative",
                    left: "-0.1em",
                    top: "0.1em",
                    marginTop: "-0.2em",
                  }}
                />
              </Button>
            </div>
            <div className={cn("ml-2")}>
              <Button className="w-12" buttonType="primary-light" onClick={nextPage} disabled={!pagination.hasNextPage}>
                <IconCaretRight
                  style={{ fontSize: "1.75em", position: "relative", top: "0.1em", marginTop: "-0.2em" }}
                />
              </Button>
            </div>
          </Section>
        )}
      </Content>
      <TransportsFilters isOpen={isFilterOpen} setIsOpen={setIsFilterOpen} />
      {includeNps && <NpsForm />}
    </>
  );
};
