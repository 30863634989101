import { Route, Switch } from "react-router-dom";
import { Routes } from "../../utils";
import { MoreAppSettings } from "./appSettings";
import { MoreForgetAccount } from "./appSettings/ForgetAccount";
import { MoreBrengerId } from "./brengerId";
import { MoreCouriers } from "./couriers";
import { MoreDashboard } from "./dashboard";
import { MoreFavouriteRoutes } from "./favouriteRoutes";
import { MoreHome } from "./home";
import { MoreInboxDetails } from "./inboxDetails";
import { MoreInboxList } from "./inboxList";
import { MoreProfile } from "./profile";
import { MoreReviews } from "./reviews";
import { MoreVehiclesCreate } from "./vehiclesCreate";
import { MoreVehiclesDetails } from "./vehiclesDetails";
import { MoreVehiclesList } from "./vehiclesList";

export const More: React.FC = () => {
  return (
    <Switch>
      <Route exact={true} path={Routes.more.home()} component={MoreHome} />
      <Route exact={true} path={Routes.more.inbox()} component={MoreInboxList} />
      <Route exact={true} path={Routes.more.inbox(":tjal_id")} component={MoreInboxDetails} />
      <Route exact={true} path={Routes.more.dashboard()} component={MoreDashboard} />
      <Route exact={true} path={Routes.more.reviews()} component={MoreReviews} />
      <Route exact={true} path={Routes.more.brengerId()} component={MoreBrengerId} />
      <Route exact={true} path={Routes.more.profile()} component={MoreProfile} />
      <Route exact={true} path={Routes.more.vehicles()} component={MoreVehiclesList} />
      <Route exact={true} path={Routes.more.vehicles("create")} component={MoreVehiclesCreate} />
      <Route exact={true} path={Routes.more.vehicles(":id")} component={MoreVehiclesDetails} />
      <Route exact={true} path={Routes.more.couriers()} component={MoreCouriers} />
      <Route exact={true} path={Routes.more.favouriteRoutes()} component={MoreFavouriteRoutes} />
      <Route exact={true} path={Routes.more.appSettings()} component={MoreAppSettings} />
      <Route exact={true} path={Routes.more.forget()} component={MoreForgetAccount} />
    </Switch>
  );
};
