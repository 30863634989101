import * as React from "react";
import cn from "classnames";

/**
 * A natural placeholder for text-based UI elements that are not immediately loaded on mount.
 * This is a nice alternative to showing a full-blown loader. Slightly more subtle.
 * Also prevents the UI from moving round too much when the text data finally loads in,
 * as the placeholder's dims are roughly similiar to that of actual text.
 * Some eventual improvements: add a subtle gradient animation.
 */

// @TODO: add props for height and width (but keep sane defaults)
export const TextPlaceholder: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({ className, ...unused }) => {
  return (
    <span
      className={cn(
        "bg-gray-200",
        "text-gray-200",
        "w-24",
        "h-4",
        "rounded",
        "border",
        "border-white",
        "box-content",
        "inline-block",
        className
      )}
      {...unused}
    />
  );
};
