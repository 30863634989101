import { TransportJobAccountLinkState } from "@brenger/api-client";

import { PlanningFilterAction, PlanningFilterState } from "./types";
import { addOrRemoveFromArray } from "../../utils";

export const SET_TJAL_STATE = "SET_TJAL_STATE";
export const SET_USER = "SET_PLANNING_USER";
export const RESET_PLANNING_FILTERS = "RESET_PLANNING_FILTERS";

export const planningFilterActions = {
  setTjalState: (tjalState: TransportJobAccountLinkState): PlanningFilterAction => {
    return { type: SET_TJAL_STATE, payload: tjalState };
  },
  setUser: (user: string | null): PlanningFilterAction => {
    return { type: SET_USER, payload: user };
  },
  reset: (): PlanningFilterAction => {
    return { type: RESET_PLANNING_FILTERS };
  },
};

const defaultState: PlanningFilterState = {
  /**
   * When user is "null" it means that all drivers are visible.
   * Otherwise, it is the IRI of the user.
   */
  user: null,
  tjalStates: [],
};

export const planningFilter = (state = defaultState, action: PlanningFilterAction): PlanningFilterState => {
  switch (action.type) {
    case SET_TJAL_STATE:
      return {
        ...state,
        tjalStates: addOrRemoveFromArray(action.payload, state.tjalStates),
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case RESET_PLANNING_FILTERS:
      return defaultState;
    default:
      return state;
  }
};
