import { useKeyPress } from "@brenger/react";
import { Keyboard } from "@capacitor/keyboard";
import * as Sentry from "@sentry/react";
import { App } from "@capacitor/app";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAppInfo, useAuth, useDeviceId, useDeviceInfo, useNativePlatform, useSelector, useTranslation } from ".";

import { useHistory } from "react-router-dom";
import { coreClient } from "../utils";
import { useGeolocationReady } from "./useGeolocationReady";

/**
 * This hook is a collection of effects that should run when the app mounts on a native platform.
 * To this end, every effect within this hook must have `isNativePlatform` in its dependency list.
 */
const locationArray: string[] = [];

export const useNativeAppEffects = (): void => {
  const { t } = useTranslation();
  const deviceInfo = useDeviceInfo();
  const deviceId = useDeviceId();
  const appInfo = useAppInfo();
  const isNativePlatform = useNativePlatform();
  const auth = useAuth();
  const onEnter = useKeyPress("Enter");
  const geolocation = useGeolocationReady();
  const locationSettings = useSelector((state) => state.appSettings.location);
  const upsertInternalAttributes = useMutation(coreClient.users.upsertInternalAttributes);
  const history = useHistory();

  /**
   * <IonApp /> We have to ditch this component, but it expects <IonRouter /> to be present,
   * which is why the normal back behaviour is not working.
   *
   * Hook into handler of the back button on Android, custom event dispatched by framework
   * https://ionicframework.com/docs/developing/hardware-back-button
   * - After invoking register you set priority
   * - Priority is set to 10, so that you can overwrite if needed (use case, close modal for example)
   * - Important that priority means: everything lower will NOT be executed (so it doesn't mean executed later)
   */
  useEffect(() => {
    // Button only exists on android devices, so no need to attach listener when !android to avoid side effects
    if (deviceInfo?.platform !== "android") return;

    // We keep a list of visited locations.
    history.listen((loc, action) => {
      if (action === "PUSH") locationArray.push(loc.pathname);
      if (action === "POP") locationArray.pop();
    });

    document.addEventListener("ionBackButton", (ev) => {
      (ev as CustomEvent).detail.register(10, () => {
        // If possible to go back then we go back, else we minimize app
        if (locationArray.length) {
          history.goBack();
        } else {
          App.minimizeApp();
        }
      });
    });
  }, [deviceInfo?.platform]);

  /**
   * ----------
   * Configure keyboard
   * ----------
   */
  useEffect(() => {
    // Make sure iOS shows "done" on native select
    // For more, see https://github.com/ionic-team/capacitor/discussions/3478
    if (deviceInfo?.platform === "ios") {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
  }, [Keyboard, deviceInfo?.platform]);

  /**
   * This effect is to handle a special case in a mobile context. It's annoying for users who
   * are on mobile devices to tap outside the keyboard in order to blur the input they are focused on.
   * Rather, such users should be able to escape the input by simply hitting "Enter" on the mobile keyboard.
   * Therefore, whenever a mobile device user hits the enter button, let's be sure to blur the active element.
   */
  useEffect(() => {
    if (isNativePlatform && onEnter) {
      const activeElement = document.activeElement;
      if (activeElement) {
        // NOTE: optimistically type cast activeElement as an HTMLElement so we have access to blur method.
        // However, be aware that the active element can also be something else, and therefore we must
        // wrap in try/catch to avoid breaking the app in such cases. Catch can be a simple no-op, though.
        try {
          (activeElement as HTMLElement).blur();
        } catch (e) {
          // No-op. Nothing to do here. Carry on.
        }
      }
    }
  }, [isNativePlatform, onEnter]);

  /**
   * ----------
   * CONFIGURE GEOLOCATION
   * ----------
   */
  useEffect(() => {
    if (!isNativePlatform) return;

    // Ensure that all deps are present before calling "ready" method on geolocation plugin.
    if (!t) return;
    if (!auth.userId) return;
    if (!deviceId) return;
    if (!deviceInfo) return;

    // You must call #ready once and only once, each time your app is launched.
    geolocation.ready({
      deviceInfo,
      deviceId,
      userId: auth.userId,
      channelName: t((d) => d.app.background_geolocation.notification.channel),
      notificationTitle: t((d) => d.app.background_geolocation.notification.title),
      notificationText: t((d) => d.app.background_geolocation.notification.message),
      rationaleTitle: t((d) => d.app.background_geolocation.notification.rationale_title),
      rationaleText: t((d) => d.app.background_geolocation.notification.rationale_message),
    });
  }, [isNativePlatform, t, auth.userId, deviceInfo, deviceId]);

  /**
   * ----------
   * ROUTE CHANGE TODOS
   * ----------
   */
  // useEffect(() => {
  //   if (!isNativePlatform) return;

  //   const onHistoryChange = (): void => {
  //     nativeAppVersion.checkVersion();
  //     // geolocation.triggerPluginStateCallback();
  //     // geolocation.triggerProviderStateCallback();
  //   };

  //   // Check everything on mount.
  //   onHistoryChange();

  //   // Then, on each subsequent history change event, check everything again.
  //   const removeHistoryListener = history.listen(onHistoryChange);

  //   return () => {
  //     removeHistoryListener();
  //   };
  // }, [isNativePlatform, geolocation.triggerPluginStateCallback, geolocation.triggerProviderStateCallback]);

  /**
   * --------------------
   * CONFIG SENTRY
   * --------------------
   */
  useEffect(() => {
    if (!isNativePlatform) return;

    if (appInfo) {
      Sentry.configureScope((scope) => {
        if (appInfo?.version) scope.setTag("app_version", appInfo?.version);
        if (appInfo?.build) scope.setTag("app_build", appInfo.build);
      });
    }
  }, [isNativePlatform, appInfo]);

  /**
   * --------------------
   * SET INTERNAL USER ATTRIBUTES
   * --------------------
   */
  useEffect(
    () => {
      if (!isNativePlatform) return;

      if (auth.userId && deviceInfo && appInfo && deviceId) {
        // Hackery: coerce device info into an acceptable type for the api-client
        // Sept 2021 update: keep device_info so that JSON structure stays same format.
        // However, capacitor moved app-specific info to a new key from v2 => v3.
        // Therefore, re-map to old way here.
        const coercedDeviceInfo = deviceInfo as unknown as {
          [key: string]: string | number | boolean;
        };
        upsertInternalAttributes.mutate({
          id: auth.userId,
          internal_attributes: {
            device_info: {
              device_id: deviceId,
              appVersion: appInfo.version,
              ...coercedDeviceInfo,
            },
            background_geolocation: {
              isPluginEnabled: geolocation.isPluginEnabled,
              // Same hackyness as above, this is just for analysis so no need to extra type this
              ...((locationSettings as unknown) || {}),
            },
          },
        });
      }
    },
    // Whenever any of these states change, "phone home" to core to track it.
    [isNativePlatform, auth.userId, deviceId, appInfo, deviceInfo, geolocation.isPluginEnabled, locationSettings]
  );
};
