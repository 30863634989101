import React from "react";
import { PersistedStorageKey } from "../utils";
import { usePersistedState } from "../hooks";
import { Menu } from "./Menu";
import cn from "classnames";
import "./layout.scss";

export const NewLayout: React.FC = ({ children }) => {
  const [isExpandedMenu, setIsExpandedMenu] = usePersistedState<boolean>({
    initialState: true,
    defaultState: true,
    version: 1,
    key: PersistedStorageKey.IS_MENU_EXPANDED,
  });
  return (
    <div className={cn("app-layout", { "app-layout--menu-expanded": isExpandedMenu })}>
      {/* Important: this div takes care of content positioning */}
      <div className="relative w-full h-full">{children}</div>
      <Menu toggleExpand={() => setIsExpandedMenu(!isExpandedMenu)} />
    </div>
  );
};
