import * as React from "react";
import cn from "classnames";
import * as Sentry from "@sentry/react";
import { Spacer, H1, Button, Strong, Message } from "@brenger/react";

interface State {
  hasError: boolean;
  errorMsg: string;
  eventId: string;
}

export class ErrorBoundary extends React.Component<React.ComponentClass, State> {
  constructor(props: React.ComponentClass) {
    super(props);
    this.state = { hasError: false, errorMsg: "", eventId: "" };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMsg: error.message, eventId: "" };
  }

  componentDidCatch(error: Error): void {
    const eventId = Sentry.captureException(error, { level: "fatal" });
    if (eventId) {
      this.setState({ eventId });
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className={cn("bg-blue-600", "min-h-screen", "p-6", "flex", "items-center")}>
          <div
            className={cn(
              "flex",
              "flex-col",
              "items-center",
              "container",
              "mx-auto",
              "text-white",
              "text-center",
              "mt-8"
            )}
          >
            <H1>
              {/* @TODO trans needed */}
              Er is iets fout gegaan.
            </H1>
            <Spacer h={6} />
            <Strong>{this.state.eventId}</Strong>
            <Spacer h={6} />
            <Message type="error">{this.state.errorMsg}</Message>
            <Spacer h={6} />
            <Button
              className={cn("w-full")}
              buttonType="secondary"
              onClick={() => {
                // Clear everything from local and session storage.
                sessionStorage.clear();
                localStorage.clear();
                // Reload page and point at root - let client-side router take over after that.
                window.location.assign("/");
              }}
            >
              {/* @TODO trans needed */}
              Herlaad Pagina
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
