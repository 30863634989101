/**
 * Important note: this started as a copy of usePersistedState from react-sage.
 * However, this app is a hybrid app that also uses some native API, including
 * native Device storage. Capacitor's device storage solution is therefore baked
 * into this hook as the only storage option. It will fall back to localStorage
 * on PWAs.
 * Also important to note: because accessing device storage is an async operation,
 * it is critical to consider what the intial and default states ought to be.
 * In other words, the initial state will always flash before the persisted state (if it exists)
 * is retrieved by the hook from the storage. Therefore, if nothing has been set, use
 * the default state.
 * For more details: https://capacitorjs.com/docs/apis/storage
 */

import * as React from "react";
import { Preferences } from "@capacitor/preferences";

interface StorageOptions {
  key: string;
  version?: number;
}

interface Options<S> extends StorageOptions {
  initialState: S;
  defaultState: S;
}

interface Data<S> {
  version: number;
  data: S;
}

function formatForStorage<S>(version: number, data: S): Data<S> {
  return {
    version,
    data,
  };
}

export function usePersistedState<S>({
  key,
  initialState,
  defaultState,
  version = 0.1,
}: Options<S>): [S | undefined, (value: S) => void, () => void] {
  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    const getInitialStateFromStorage = async (): Promise<void> => {
      try {
        const { value } = await Preferences.get({ key });

        if (value) {
          const { version: existingVersion, data } = JSON.parse(value) || {};
          const hasNewerVersion = parseFloat(existingVersion) !== version;

          if (hasNewerVersion) {
            // @TODO: add migration option
            await Preferences.remove({ key });
            throw new Error(`Newer version for ${key} in persisted state. Over writing existing version.`);
          }

          setState(data);
        } else {
          setState(defaultState);
        }
      } catch (err) {
        setState(defaultState);
        // no-op
      }
    };

    getInitialStateFromStorage();
  }, []);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const set = (value: S): void => {
    try {
      // Allow value to be a function so we have same API as useState
      // Save state
      setState((prevState) => {
        const valueToStore = value instanceof Function ? value(prevState) : value;
        Preferences.set({ key, value: JSON.stringify(formatForStorage(version, valueToStore)) });
        return valueToStore;
      });
    } catch (err) {
      // no-op
    }
  };

  const remove = (): void => {
    try {
      setState(() => {
        Preferences.remove({ key });
        return initialState;
      });
    } catch (err) {
      // no-op
    }
  };

  return [state, set, remove];
}
