import { Button } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { UseGeolocationMethods, useTranslation } from "../../hooks";
import image from "./geo.png";

export const GeoPrimer: React.FC<{ geolocation: UseGeolocationMethods; closeHandler(): void }> = ({
  geolocation,
  closeHandler,
}) => {
  const { t } = useTranslation();
  const [showContinue, setShowContinue] = React.useState(false);
  return (
    <div className={cn("grid", "gap-4", "text-center", "place-items-center", "w-80", "max-w-full", "mx-auto")}>
      <img src={image} />
      <h3>{t((d) => d.settings.enable_geolocation)}</h3>
      <div>{t((d) => d.settings.geolocation_desc)}</div>
      {!showContinue && (
        <>
          <Button
            className={cn("w-full")}
            buttonType="secondary"
            onClick={async () =>
              await geolocation.prompt({ triggerStart: true, callback: () => setShowContinue(true) })
            }
          >
            {t((d) => d.actions.enable)}
          </Button>
          <Button
            className={"w-full"}
            buttonType="white"
            onClick={() => {
              geolocation.stop();
              closeHandler();
            }}
          >
            <span className="text-blue-600">{t((d) => d.no_thanks)}</span>
          </Button>
        </>
      )}
      {showContinue && (
        <Button className={"w-full mt-4"} buttonType="secondary" onClick={closeHandler}>
          {t((d) => d.actions.continue)}
        </Button>
      )}
    </div>
  );
};
