import {
  Button,
  Card,
  Emphasis,
  H3,
  IconArrowLeft,
  IconArrowRight,
  IconCancel,
  IconCheck,
  IconLoader,
  Message,
  Small,
  Spacer,
  StarRating,
  Strong,
} from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";

import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { DriverSelect, Grid, ListSummary } from "../../../components";
import { useAuth, useFormatDate, usePagination, useTranslation, useUrlSearchParam } from "../../../hooks";
import { CacheKey, Routes, coreClient } from "../../../utils";
import { Content, PageHead, Section } from "../../../layout";

export const MoreReviews: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const userParam = useUrlSearchParam({ key: "user_iri", delete: true, defaultValue: auth.user?.["@id"] });
  const [page, setPage] = React.useState(1);
  const [selectedUserIRI, setSelectedUserIRI] = React.useState("");
  const selectedUserId = getIdFromIri(selectedUserIRI) || "";
  const formatDateShort = useFormatDate("date-short");
  React.useEffect(() => {
    if (userParam) setSelectedUserIRI(userParam);
  }, [userParam]);

  const reviewSummary = useQuery(
    [CacheKey.RETRIEVE_SUMMARY, selectedUserId],
    () => coreClient.users.retrieveReviewSummary({ userId: selectedUserId }),
    {
      enabled: !!selectedUserIRI,
    }
  );

  const reviews = useQuery(
    [CacheKey.RETRIEVE_REVIEWS, selectedUserId],
    () => coreClient.users.listReviewsForUser({ userId: selectedUserId, page }),
    {
      enabled: !!selectedUserIRI,
    }
  );

  // Scroll user to the top when the page number changes.
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const paginationControls = usePagination({ list: reviews.data, setPage, page });

  const allTimeAverage = reviewSummary.data?.all?.average || "--";
  const avgPunctuality = reviewSummary.data?.all?.averages_by_category.punctuality || "--";
  const avgCommunication = reviewSummary.data?.all?.averages_by_category.communication || "--";
  const avgFriendliness = reviewSummary.data?.all?.averages_by_category.friendliness || "--";

  const renderBoolean = (value: undefined | boolean | null): React.ReactNode => {
    if (value === false) {
      return <IconCancel className={cn("text-2xl", "text-red-400")} />;
    }
    if (value === true) {
      return <IconCheck className={cn("text-2xl", "text-green-400")} />;
    }
    return <Strong>{t((d) => d.reviews.not_applicable_abbr)}</Strong>;
  };

  return (
    <Content
      header={<PageHead sectionType="single-details" title={t((d) => d.more.reviews)} backUrl={Routes.more.home()} />}
    >
      <Section type="single-details" isTop={true}>
        <DriverSelect
          className={cn("mb-4")}
          labelText={t((d) => d.profile.select_courier)}
          accountId={getIdFromIri(auth.user?.account)}
          value={selectedUserIRI}
          onChange={({ userIRI }) => setSelectedUserIRI(userIRI)}
        />
        {/*
        REVIEW SUMMARY
      */}
        <section>
          <div className={cn("flex", "flex-wrap", "justify-between", "items-center")}>
            <H3>{t((d) => d.dashboard.all_time_average_rating)}</H3>
            <H3>{allTimeAverage} / 5</H3>
          </div>
          <Spacer h={2} />
          <div>
            <div>{t((d) => d.reviews.review_desc)}</div>
          </div>
          <Spacer h={4} />
          <div className={cn("flex", "flex-col")}>
            <div className={cn("flex", "justify-between", "py-4", "px-2", "border-t", "border-b")}>
              <div>{t((d) => d.reviews.punctuality)}</div>
              <Strong>{avgPunctuality}</Strong>
            </div>
            <div className={cn("flex", "justify-between", "py-4", "px-2", "border-b")}>
              <div>{t((d) => d.reviews.communication)}</div>
              <Strong>{avgCommunication}</Strong>
            </div>
            <div className={cn("flex", "justify-between", "py-4", "px-2", "border-b")}>
              <div>{t((d) => d.reviews.friendliness)}</div>
              <Strong>{avgFriendliness}</Strong>
            </div>
          </div>
        </section>
        <Spacer h={4} />
        {/*
        REVIEWS LIST
        ONLY ACCOUNT ADMINS SHOULD SEE INDIVIDUAL REVIEWS
      */}
        {auth.isAccountAdmin && (
          <section>
            <H3>{t((d) => d.profile.reviews_list_title)}</H3>
            {reviews.data?.["hydra:totalItems"] === 0 ? (
              <Message type="info" className={cn("mt-4")}>
                {t((d) => d.profile.no_reviews_yet)}
              </Message>
            ) : null}
            {reviews.isLoading && (
              <div className={cn("flex", "justify-center", "items-center", "my-8")}>
                <IconLoader className={cn("w-6", "h-6")} />
              </div>
            )}
            {/*
            Render the review cards in a simple grid in larger viewports.
          */}
            <Grid cols={{ lg: 2 }} gap={4} className={cn("my-4")}>
              {!reviews.isLoading &&
                reviews.data?.["hydra:member"].map((review) => {
                  const authorFullName = review.author_full_name || t((d) => d.profile.anonymous);
                  return (
                    <Card type="gray" key={review["@id"]}>
                      <div className={cn("flex", "flex-col")}>
                        <div className={cn("flex", "items-center", "pb-2")}>
                          <div className={cn("overflow-hidden", "rounded-full", "w-12", "h-12")}>
                            <img alt={authorFullName} src={review.author_profile_image || missingImageSrc} />
                          </div>
                          <div className={cn("ml-4")}>
                            <div>{authorFullName}</div>
                            <Small className={cn("text-gray-600")}>
                              {review.created_at ? formatDateShort(review.created_at) : null}
                            </Small>
                          </div>
                        </div>
                        <div>
                          <div className={cn("flex", "p-1")}>
                            <Small className={cn("mr-2")}>{t((d) => d.reviews.moving_supplies)}</Small>
                            {renderBoolean(review.moving_supplies)}
                          </div>
                          <div className={cn("flex", "p-1")}>
                            <Small className={cn("mr-2")}>{t((d) => d.reviews.neat_vehicle)}</Small>
                            {renderBoolean(review.neat_vehicle)}
                          </div>
                        </div>
                        <div>
                          <div className={cn("flex", "justify-between", "flex-wrap", "p-1")}>
                            <Small>{t((d) => d.reviews.punctuality)}</Small>
                            <StarRating rating={review.punctuality} />
                          </div>
                          <div className={cn("flex", "justify-between", "flex-wrap", "p-1")}>
                            <Small>{t((d) => d.reviews.communication)}</Small>
                            <StarRating rating={review.communication} />
                          </div>
                          <div className={cn("flex", "justify-between", "flex-wrap", "p-1")}>
                            <Small>{t((d) => d.reviews.friendliness)}</Small>
                            <StarRating rating={review.friendliness} />
                          </div>
                        </div>
                        {review.body && (
                          <div className={cn("my-2")}>
                            <Emphasis>{review.body}</Emphasis>
                          </div>
                        )}
                      </div>
                    </Card>
                  );
                })}
            </Grid>
            <ListSummary {...paginationControls} />
            {(paginationControls.hasPreviousPage || paginationControls.hasNextPage) && (
              <div className={cn("flex", "justify-between", "mt-4")}>
                <Button
                  className={cn("w-full")}
                  buttonType="primary-outline"
                  disabled={!paginationControls.hasPreviousPage || reviews.isLoading}
                  onClick={paginationControls.goToPreviousPage}
                  icon={<IconArrowLeft />}
                  iconPos="left"
                >
                  {t((d) => d.actions.previous)}
                </Button>
                <Spacer w={8} />
                <Button
                  className={cn("w-full")}
                  buttonType="primary-outline"
                  disabled={!paginationControls.hasNextPage || reviews.isLoading}
                  onClick={paginationControls.goToNextPage}
                  icon={<IconArrowRight />}
                  iconPos="right"
                >
                  {t((d) => d.actions.next)}
                </Button>
              </div>
            )}
          </section>
        )}
      </Section>
    </Content>
  );
};
