import * as React from "react";

import {
  Address,
  Delivery,
  DeliveryCommitment,
  Pickup,
  PickupCommitment,
  TransportJobAccountLink,
} from "@brenger/api-client";
import { Card } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import { isSameDay } from "date-fns";
import { TransportJobDetails, getStopDetails, TjalDetails, PillTjalState } from "../../../components";
import { useAuth, useFormatDate, useFormatTimeframe } from "../../../hooks";
import { Routes, TransportsType, formatLocality } from "../../../utils";
import { Link } from "react-router-dom";

interface Props {
  presentation: TransportsType;
  tjal: TransportJobAccountLink;
  isActive: boolean;
}

export const TransportsListItem: React.FC<Props> = ({ presentation, tjal, isActive }) => {
  const id = getIdFromIri(tjal) as string;
  const auth = useAuth();
  const formatTimeframe = useFormatTimeframe();
  const formatDateFull = useFormatDate("date-full");
  // When there is a dispute_reason and the TJAL shows up in this list, we can
  // safely assume that the TJ has a "disputed" state.
  const isDisputed = Boolean(tjal.dispute_reason);
  // Parse out the first and last delivery commitments
  const firstPickupCommitment = tjal.pickup_commitments[0] as PickupCommitment;
  const lastDeliveryCommitment = tjal.delivery_commitments[tjal.delivery_commitments.length - 1] as DeliveryCommitment;
  const firstStop = firstPickupCommitment.pickup as Pickup;
  const firstStopAddress = firstStop.address as Address;
  const lastStop = lastDeliveryCommitment.delivery as Delivery;
  const lastStopAddress = lastStop.address as Address;
  let pickupPeriod = formatTimeframe({
    start: firstPickupCommitment.committed_datetime_period.start,
    end: firstPickupCommitment.committed_datetime_period.end,
  });
  let deliveryPeriod = formatTimeframe({
    start: lastDeliveryCommitment.committed_datetime_period.start,
    end: lastDeliveryCommitment.committed_datetime_period.end,
  });

  // Check if the pickup & delivery are on the same day.
  const isJobSameDay = isSameDay(
    new Date(firstPickupCommitment.committed_datetime_period.start),
    new Date(lastDeliveryCommitment.committed_datetime_period.end)
  );

  // If NOT, append the date to the timeframe for maximal clarity.
  if (!isJobSameDay) {
    pickupPeriod = `${pickupPeriod} (${formatDateFull(firstPickupCommitment.committed_datetime_period.start)})`;
    deliveryPeriod = `${deliveryPeriod} (${formatDateFull(lastDeliveryCommitment.committed_datetime_period.end)})`;
  }

  // The stop details are deep in the stop commitments
  const pickups = (tjal.pickup_commitments as PickupCommitment[]).map((e) => e.pickup) as Pickup[];
  const deliveries = (tjal.delivery_commitments as DeliveryCommitment[]).map((e) => e.delivery) as Delivery[];
  const tjalDetails = getStopDetails(pickups, deliveries);

  return (
    <Link className="br-text-default" to={Routes.transports.job({ type: presentation, job_id: id })}>
      <Card
        key={id}
        className={cn("!mb-0", "cursor-pointer", {
          "lg:!border-blue-600": isActive,
        })}
      >
        <TransportJobDetails
          from={formatLocality(firstStopAddress.locality, firstStopAddress.administrative_area)}
          pickupPeriod={pickupPeriod}
          to={formatLocality(lastStopAddress.locality, lastStopAddress.administrative_area)}
          deliveryPeriod={deliveryPeriod}
          // Calculate the stop count by getting the length of all committments
          stopCount={[...tjal.pickup_commitments, ...tjal.delivery_commitments].length}
          // Only allow account admins to see the price
          price={auth.isAccountAdmin ? tjal.transport_job_total_payout : undefined}
          isDisputed={isDisputed}
          isPrepaid={tjal.transport_job_prepaid}
          isBundled={tjal.transport_job_bundled}
          isBusiness={tjal.transport_job_is_business}
          day={
            firstPickupCommitment.committed_datetime_period.start &&
            formatDateFull(firstPickupCommitment.committed_datetime_period.start)
          }
          all_stop_details={{
            carrying_help: tjalDetails.map((f) => f?.carrying_help),
            situation: tjalDetails.map((s) => s.situation),
          }}
        />
        <TjalDetails
          // Only account admins should see the name of the driver. Sub accounts only see their own jobs.
          driver={auth.isAccountAdmin ? tjal.driver_user : null}
          state={tjal.state ? <PillTjalState state={tjal.state} /> : null}
        />
      </Card>
    </Link>
  );
};
