import { Button, IconAdd, IconClose, IconPencil } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { DayRouteParams } from "../../../utils";

interface FooterProps {
  hasActivities: boolean;
  setIsEditing: (v: boolean) => void;
  isEditing: boolean;
  cancelEditing: () => void;
}

export const DayRouteEditActions: React.FC<FooterProps> = ({
  hasActivities,
  isEditing,
  setIsEditing,
  cancelEditing,
}) => {
  const params = useParams<DayRouteParams>();
  const history = useHistory();
  const goToDayRouteAdd = (): void => history.push(`/day-route/${params.date}/${params.user_id}/add`);

  const buttonCN = cn("!rounded-full", "!p-0", "h-8", "w-8", "text-center");
  if (!hasActivities) {
    return (
      <Button className={buttonCN} buttonType="primary" onClick={goToDayRouteAdd}>
        <IconAdd style={{ fontSize: "1.5rem" }} className="inline-block ml-[.2rem]" />
      </Button>
    );
  }
  return (
    <div className={cn("flex gap-2")}>
      <Button className={buttonCN} buttonType="primary" onClick={goToDayRouteAdd}>
        <IconAdd style={{ fontSize: "1.5rem" }} className="inline-block ml-[.2rem]" />
      </Button>
      {isEditing && (
        <Button buttonType="warning-outline" className={buttonCN} onClick={cancelEditing}>
          <IconClose style={{ fontSize: "1rem" }} className="inline-block ml-[.55rem]" />
        </Button>
      )}
      {!isEditing && (
        <Button buttonType="primary" className={buttonCN} onClick={() => setIsEditing(true)}>
          <IconPencil style={{ fontSize: "1rem" }} className="inline-block ml-2" />
        </Button>
      )}
    </div>
  );
};
