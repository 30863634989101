import * as React from "react";
import cn from "classnames";
import { InputText, Small } from "@brenger/react";

export interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  disabled?: boolean;
  onChange?: (value: string) => void;
  options?: JSX.Element[] | null;
  zeroResultsMessage?: string;
}

interface AutocompleteOptionProps {
  onClick: () => void;
}

export const AutocompleteOption: React.FC<AutocompleteOptionProps> = (props) => {
  return (
    <li onClick={props.onClick} className={cn("leading-loose", "cursor-pointer", "px-3")}>
      <Small>{props.children}</Small>
    </li>
  );
};

const ZeroResultMessage: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <li className={cn("leading-loose", "text-gray-600", "px-3")}>
      <Small>{props.children}</Small>
    </li>
  );
};

export const InputTextWithAutocomplete: React.FC<Props> = (props) => {
  const [showOptions, setShowOptions] = React.useState(false);
  return (
    <div className={cn("relative")}>
      <InputText
        disabled={props.disabled}
        className={cn("w-full")}
        value={props.value}
        onChange={props.onChange}
        onBlur={() => {
          // Add a timeout to allow for any click events on the dropdown to get registered.
          window.setTimeout(() => {
            setShowOptions(false);
          }, 250);
        }}
        onFocus={() => setShowOptions(true)}
      />
      <div className={cn("relative")}>
        {((props.options || []).length > 0 || props.zeroResultsMessage) && showOptions && (
          <ul
            // Push the options container slightly down so that it still looks connected, but also has a bit of breathing room.
            style={{ marginTop: "0.1rem" }}
            className={cn("absolute", "top-0", "z-30", "bg-white", "w-full", "shadow-md", "border", "py-2", "rounded")}
          >
            {(props.options || []).length > 0 && props.options}
            {(props.options || []).length === 0 && props.zeroResultsMessage && (
              <ZeroResultMessage>{props.zeroResultsMessage}</ZeroResultMessage>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};
