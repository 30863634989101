import { UseForm } from "../hooks";

export const getFormValues = <FormState>(state: UseForm.State<FormState>): FormState => {
  const keys = Object.keys(state) as (keyof FormState)[];
  const values = {} as FormState;
  keys.forEach((key) => (values[key] = state[key].value));
  return values;
};

export const getFormChangedValues = <FormState>(form: UseForm.Form<FormState>): Partial<FormState> => {
  const formData = getFormValues(form.data);
  const updateParams: Partial<FormState> = {};
  const keys = Object.keys(form.data) as (keyof FormState)[];
  keys.forEach((key) => {
    if (form.isFieldDirty(key)) {
      updateParams[key] = formData[key];
    }
  });
  return updateParams;
};
export const isRequired = (val: string | number): boolean => !val;
