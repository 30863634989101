import { Button, Strong } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";

import { useHistory } from "react-router-dom";
import { missingImageSrc } from "../../../assets/images/missingImageSrc";
import { useAuth, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, Routes } from "../../../utils";

interface Props {
  tjalId: string;
}

export const ConversationParty: React.FC<Props> = (props) => {
  const auth = useAuth();
  const history = useHistory();
  const { t } = useTranslation();

  const conversation = useQuery(
    [CacheKey.LIST_MESSAGES_USER_AND_TJAL, props.tjalId, auth.userId],
    () => coreClient.messages.listByUserAndTjal({ user_id: auth.userId as string, tjal_id: props.tjalId as string }),
    {
      enabled: !!auth.userId && !!props.tjalId,
    }
  );

  // Fetching conversations to filter out the converationParty later
  const conversationList = useQuery([CacheKey.INBOX_LIST], coreClient.conversations.listForDriver);

  // Handle loading & error state before rendering any subsequent UI.
  if (conversation.isLoading || conversation.isError) {
    return null;
  }

  // Filter conversationList on current conversation, plus get the conversation party from that list item
  const conversationParty = conversationList.data?.["hydra:member"].find((convo) => {
    return getIdFromIri(convo.transport_job_account_link) === props.tjalId;
  })?.conversation_party;

  return (
    <div className={cn("flex", "items-center", "bg-white", "py-2")}>
      <div className={cn("flex-shrink-0", "overflow-hidden", "rounded-full", "w-10", "h-10")}>
        <img
          className={cn("h-full", "w-full", "object-cover")}
          src={conversationParty?.profile_image || missingImageSrc}
        />
      </div>
      <div className={cn("flex", "flex-grow", "items-center", "justify-between", "w-full", "pl-2")}>
        <div className={cn("flex", "flex-wrap")}>
          <div>
            <Strong className={cn("block", "mr-1")}>{conversationParty?.first_name}</Strong>
          </div>
          <div>
            <Strong className={cn("block")}>{conversationParty?.last_name}</Strong>
          </div>
        </div>
        <Button
          size="sm"
          onClick={() => history.push(Routes.transports.job({ type: "planning", job_id: props.tjalId }))}
        >
          {t((d) => d.transport_job.actions.view)}
        </Button>
      </div>
    </div>
  );
};
