import { ItemSet } from "@brenger/api-client";
import { IconClose } from "@brenger/react";
import React from "react";
import { Dialog } from "../";
import { useTranslation } from "../../hooks";
import { userDataClient } from "../../utils";

// https://react-slick.neostack.com/docs/get-started
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./ImageGallery.scss";
import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  itemSets: ItemSet[];
}

interface SlideItem {
  image: string;
  title: string;
}

export const ImageGallery: React.FC<Props> = ({ itemSets, ...rest }) => {
  const { t } = useTranslation();
  // per item in the itemset we have an array of slides
  const itemSetSlides: SlideItem[][] = [];
  // Loop over itemsets and items
  itemSets.forEach((is) => {
    is.items.forEach((item, index) => {
      // type guard
      if (typeof item === "string") return;
      // Only the first item gets the generic itemset.images attached (we don't know to which item they belong)
      const itemSlides: SlideItem[] =
        index > 0 ? [] : (is.images || []).map((image) => ({ image: image as string, title: is.title }));
      // add image and title
      if (item.job_image) {
        // Add the "main" image at the beginning of the array, so it appears as thumb
        itemSlides.unshift({ image: item.job_image, title: item.title || is.title });
      }
      // Don't push empty arrays
      if (!itemSlides.length) return;

      itemSetSlides.push(itemSlides);
    });
  });

  const [initialSlide, setInitialSlide] = React.useState<null | number>(null);
  const [activeSlide, setActiveSlide] = React.useState<number>(0);

  const gallerySlides = itemSetSlides.flatMap((slideItem) => slideItem);

  if (!gallerySlides.length) {
    return (
      <div>
        <h5>{t((d) => d.transport_job.headings.images)}</h5>
        <i>{t((d) => d.app.empty_state.generic)}</i>
      </div>
    );
  }

  return (
    <>
      <div {...rest}>
        <h5>{t((d) => d.transport_job.headings.images)}</h5>
        <div className="flex flex-wrap gap-2 mt-2">
          {itemSetSlides.map((slides) => (
            <div
              key={slides[0].image}
              className="relative inline-block w-[calc(25%-.375rem)] sm:w-20 cursor-pointer overflow-hidden rounded gallery-trigger"
              onClick={() => setInitialSlide(gallerySlides.findIndex((slide) => slide.image === slides[0].image))}
            >
              <img
                className="object-cover aspect-square w-full"
                src={userDataClient.getBaseForIri(slides[0].image, { w: 160, h: 160 })}
              />
              {slides.length > 1 && (
                <div
                  className="absolute right-1 bottom-1 text-white flex items-end z-10"
                  style={{ fontSize: "14px", lineHeight: 1 }}
                >
                  <b>{slides.length}</b>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-1"
                  >
                    <rect x="2.5" y="0.5" width="7" height="7" rx="1.5" stroke="currentcolor" />
                    <rect y="2" width="8" height="8" rx="2" fill="currentcolor" />
                  </svg>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Dialog isActive={initialSlide !== null}>
        <div
          className={cn("bg-black lg:bg-black/75 relative w-full h-full", {
            "slick-no-arrows": gallerySlides.length === 1,
          })}
        >
          <Slider
            afterChange={(index) => setActiveSlide(index)}
            dots={gallerySlides.length > 1}
            slidesToShow={1}
            infinite={false}
            initialSlide={initialSlide || 0}
          >
            {gallerySlides.map((slide) => {
              return (
                <div key={slide.image} className="w-screen h-screen" onClick={() => setInitialSlide(null)}>
                  <div className="w-screen h-screen lg:my-[15vh] lg:mx-[15vw] lg:w-[70vw] lg:h-[70vh] text-center">
                    <img
                      onClick={(e) => e.stopPropagation()}
                      className="w-screen h-screen object-contain lg:w-auto lg:h-auto lg:max-w-full lg:max-h-full !inline-block"
                      src={userDataClient.getBaseForIri(slide.image, { w: 1000, h: 1000 })}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
          <div className="text-white absolute left-1/4 top-3 z-10 cursor-pointer w-1/2 truncate text-center">
            {gallerySlides[activeSlide].title}
          </div>
          <div className="text-white absolute right-4 top-4 z-10 cursor-pointer" onClick={() => setInitialSlide(null)}>
            <IconClose />
          </div>
        </div>
      </Dialog>
    </>
  );
};
