import { Emphasis, Spacer } from "@brenger/react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../../hooks";
import { Routes } from "../../../utils";
import { Content, PageHead, Section } from "../../../layout";
import { UserVehicleForm } from "../vehiclesDetails/UserVehicleForm";

export const MoreVehiclesCreate: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Content
      header={
        <PageHead sectionType="single-details" title={t((d) => d.more.vehicles)} backUrl={Routes.more.vehicles()} />
      }
    >
      <Section type="single-details" isTop={true}>
        <div>
          <Emphasis>{t((d) => d.vehicles.sub_title)}</Emphasis>
        </div>
        <Spacer h={4} />
        <UserVehicleForm onGoBack={() => history.push(Routes.more.vehicles())} />
      </Section>
    </Content>
  );
};
