import { Card, IconSmile, IconVan, IconVerified, Spacer } from "@brenger/react";
import cn from "classnames";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";

import { Grid } from "../../../../components";
import { useAuth, useDashboardQuery, useTranslation } from "../../../../hooks";
import { CacheKey, coreClient, Routes } from "../../../../utils";
import { Link } from "react-router-dom";

export const DashboardForUser: React.FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const reviewSummary = useQuery(
    [CacheKey.RETRIEVE_SUMMARY, auth.userId],
    () => coreClient.users.retrieveReviewSummary({ userId: auth.userId as string }),
    {
      enabled: !!auth.userId,
    }
  );

  const allReviewAverage = reviewSummary.data?.all?.average;
  const last25ReviewAverage = reviewSummary.data?.last_25?.average;

  // Call all the jobs data in one batch query
  const jobsData = useDashboardQuery();

  return (
    <div>
      <div className={cn("text-center")}>
        <h4>{t((d) => d.dashboard.page_desc)}</h4>
        <h2>Hi {auth.user?.first_name}</h2>
      </div>
      <Spacer h={6} />
      <div className={cn("flex", "justify-center")}>
        <IconVerified className={cn("w-12", "h-12")} />
      </div>
      <Spacer h={6} />
      <Grid cols={{ default: 2 }} gap={4}>
        <Link to={Routes.more.reviews()}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.all_time_average_rating)}</div>
              <div>{allReviewAverage || "--"}</div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
        <Link to={Routes.more.reviews()}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.last_25_average_rating)}</div>
              <div>{last25ReviewAverage || "--"}</div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
      </Grid>
      <Spacer h={6} />
      <div className={cn("flex", "justify-center")}>
        <IconVan className={cn("text-white", "w-12", "h-12")} />
      </div>
      <Spacer h={6} />
      <Grid cols={{ default: 2 }} gap={4}>
        <Link to={Routes.transports.home({ type: "planning" })}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.planned)}</div>
              <div>{jobsData.plannedJobsCount || "--"}</div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
        <Link to={Routes.transports.home({ type: "planning" })}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.pending)}</div>
              <div>{jobsData.pendingJobsCount || "--"}</div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
        <Link to={Routes.transports.home({ type: "delivered" })}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.transports_total)}</div>
              <div>{jobsData.totalJobsCount || "--"}</div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
        <Link to={Routes.transports.home({ type: "delivered" })}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.transports_last_90_days)}</div>
              <div>{jobsData.lastQuarterJobsCount || "--"}</div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
        <Link to={Routes.transports.home({ type: "delivered" })}>
          <Card className="flex items-center justify-between  gap-4 cursor-pointer text-black">
            <div>
              <div>{t((d) => d.dashboard.total_confirmation_with_proximity_success_rate_15june_onward)}</div>
              <div>
                {auth.user?.total_confirmation_with_proximity_success_rate
                  ? `${Math.round(auth.user.total_confirmation_with_proximity_success_rate * 100)}%`
                  : "--"}
              </div>
            </div>
            <IconSmile className={cn("text-blue-600", "h-4", "w-4", "self-end", "mb-1")} />
          </Card>
        </Link>
      </Grid>
    </div>
  );
};
