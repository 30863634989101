import React from "react";
import { useAuth } from "../../../hooks";
import { Routes } from "../../../utils";
import { Content, PageHead, Section } from "../../../layout";
import { DashboardForAdmin } from "./DashboardForAdmin";
import { DashboardForUser } from "./DashboardForUser";

export const MoreDashboard: React.FC = () => {
  const auth = useAuth();
  return (
    <Content header={<PageHead sectionType="single-details" title="dashboard" backUrl={Routes.more.home()} />}>
      <Section type="single-details" isTop={true}>
        {auth.isLoggedIn && auth.isAccountAdmin && <DashboardForAdmin />}
        {auth.isLoggedIn && !auth.isAccountAdmin && <DashboardForUser />}
      </Section>
    </Content>
  );
};
