import { DayRouteActivityForUpdate } from "@brenger/api-client";
import { Button, Message, Spacer } from "@brenger/react";
import { getIdFromIri, uuid } from "@brenger/utils";
import cn from "classnames";
import insert from "flatmap-fns/insert";
import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { useCache, useForm, useTranslation } from "../../../hooks";
import { CacheKey, coreClient, DayRouteParams, Routes } from "../../../utils";

import { useParams } from "react-router-dom";
import { Content, PageHead, Section } from "../../../layout";
import {
  addIndex,
  calculateIndexForActivity,
  convertStopFormDataIntoDayRouteActivity,
  formatActivities,
} from "../utils";
import {
  DayRouteActivityForm,
  DayRouteActivityFormType,
  getDefaultDatetimePeriodForDayRouteActivity,
} from "./DayRouteActivityForm";

const PICKUP_UUID = uuid();
const DELIVERY_UUID = uuid();

const getInitialStateForPickup = (date: string): DayRouteActivityFormType => {
  const { start, end } = getDefaultDatetimePeriodForDayRouteActivity(date);

  return {
    type: "custom_pickup",
    search: "",
    place: undefined,
    // 5 minutes in seconds
    service_time_seconds: 60 * 5,
    start_time: start.toISOString(),
    end_time: end.toISOString(),
    index: 0,
    // NOTE: only need to include capacity on the pickup - not the delivery. They're related stops, after all.
    capacity_m3: 5,
    temp_uuid: PICKUP_UUID,
    temp_related_day_route_activity: DELIVERY_UUID,
  };
};

const getInitialStateForDelivery = (date: string): DayRouteActivityFormType => {
  const { start, end } = getDefaultDatetimePeriodForDayRouteActivity(date);

  return {
    type: "custom_delivery",
    search: "",
    place: undefined,
    // 5 minutes in seconds
    service_time_seconds: 60 * 5,
    start_time: start.toISOString(),
    end_time: end.toISOString(),
    index: 0,
    temp_uuid: DELIVERY_UUID,
    temp_related_day_route_activity: PICKUP_UUID,
  };
};

export const DayRouteAddJob: React.FC = () => {
  const { t } = useTranslation();
  const cache = useCache();
  const params = useParams<DayRouteParams>();

  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );

  const activities = dayRoute.data?.day_route_activities || [];

  const pickupForm = useForm({
    initialState: getInitialStateForPickup(params.date),
    validators: {
      place: (place) => !place,
      start_time: (start_time) => !start_time,
      end_time: (end_time) => !end_time,
      capacity_m3: (capacity_m3) => capacity_m3 === undefined || capacity_m3 <= 0 || capacity_m3 > 10,
    },
  });

  const deliveryForm = useForm({
    initialState: getInitialStateForDelivery(params.date),
    validators: {
      place: (place) => !place,
      start_time: (start_time) => !start_time,
      end_time: (end_time) => !end_time,
    },
  });

  const hasErrors = pickupForm.hasErrors || deliveryForm.hasErrors;

  const onSuccess = (): void => {
    window.location.assign(Routes.dayroutes.details(params));
  };

  const updateDayRoute = useMutation(coreClient.dayRoutes.update, { onSuccess });

  const onSubmitJob = (): void => {
    const newPickup = convertStopFormDataIntoDayRouteActivity(pickupForm);
    const calculatedPickupIndex = calculateIndexForActivity({ newActivity: newPickup, activities });
    const selectedPickupIndex = pickupForm.data.index?.value;
    const pickupIndex = selectedPickupIndex ?? calculatedPickupIndex;

    const newDelivery = convertStopFormDataIntoDayRouteActivity(deliveryForm);
    const calculatedDeliveryIndex = calculateIndexForActivity({ newActivity: newDelivery, activities });
    const selectedDeliveryIndex = deliveryForm.data.index?.value;
    const deliveryIndex = selectedDeliveryIndex ?? calculatedDeliveryIndex;

    // Handle cases when there are no activities yet - ie, seed with first one or insert.
    const seededActivities =
      activities.length > 0
        ? activities.flatMap(insert<DayRouteActivityForUpdate>(newDelivery, deliveryIndex))
        : [newDelivery];

    // After taking care of seeding, go ahead and insert and format.
    const updatedActivities = seededActivities
      .flatMap(insert<DayRouteActivityForUpdate>(newPickup, pickupIndex))
      .flatMap(formatActivities())
      .map(addIndex());

    updateDayRoute.mutate(
      {
        routeId: getIdFromIri(dayRoute.data) || "",
        dayRoute: {
          day_route_activities: updatedActivities,
        },
      },
      {
        onSuccess: () => cache.clear(),
      }
    );
  };

  return (
    <Content
      header={<PageHead sectionType="split-details" backUrl={Routes.dayroutes.details(params)} title={"Add job"} />}
    >
      <Section type="split-details" isTop={true}>
        <h3 className={cn("mb-4")}>{t((d) => d.transport_job.pickup)}</h3>
        <DayRouteActivityForm activities={activities} form={pickupForm} />
        <Spacer h={6} />
        <h3 className={cn("mb-4")}>{t((d) => d.transport_job.delivery)}</h3>
        <DayRouteActivityForm activities={activities} form={deliveryForm} />
        {updateDayRoute.isError && (
          <Message className={cn("mt-4")} type="error">
            {(updateDayRoute.error as Error)?.message}
          </Message>
        )}
        <Button
          className={cn("w-full")}
          buttonType="secondary"
          disabled={hasErrors}
          onClick={onSubmitJob}
          loading={updateDayRoute.isLoading}
        >
          {t((d) => d.day_route.create_stop.add_transport)}
        </Button>
      </Section>
    </Content>
  );
};
