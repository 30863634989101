import { Emphasis, Timeline } from "@brenger/react";
import { formatKilometers } from "@brenger/utils";
import cn from "classnames";
import * as React from "react";

import { useTranslation } from "../../../hooks";

interface Props {
  hours?: number;
  minutes?: number;
  distance: string | null;
  isEditing: boolean;
}

export const DayRouteStopTravelTime: React.FC<Props> = ({ hours = 0, minutes = 0, isEditing, distance }) => {
  const { t, i18n } = useTranslation();
  const classes = cn("py-2", "px-5", "br-text-sm", "br-leading-snug");

  // Positive as in: bigger than zero
  const positiveHours = hours > 0 ? hours : 0;
  const positiveMinutes = minutes > 0 ? minutes : 0;

  // Only show info when there is something to show
  if (positiveHours === 0 && positiveMinutes === 0 && distance === null) {
    return (
      <Timeline showPoint={false} isDotted={true}>
        <Emphasis>
          <div className={classes}>{t((d) => d.day_routes.travel_info_not_available)}</div>
        </Emphasis>
      </Timeline>
    );
  }

  // When editing order, the numbers are not accurate anymore, so we present a recalculate message
  if (isEditing) {
    return (
      <Timeline showPoint={false}>
        <div className={classes}>
          <Emphasis>{t((d) => d.day_route.details.message.travel_time_recalculated)}</Emphasis>
        </div>
      </Timeline>
    );
  }

  const withHours = positiveHours > 0;

  return (
    <Timeline showPoint={false} isDotted={true}>
      <div className={classes}>
        {t((d, withTemplate) =>
          withTemplate(d.day_route.details.message[withHours ? "travel_time_with_hours" : "travel_time_minutes_only"], {
            hours: positiveHours,
            minutes: positiveMinutes,
          })
        )}
        {distance !== null &&
          `, ${formatKilometers({
            kilometers: parseFloat(distance),
            locale: i18n.locale,
          })}`}
      </div>
    </Timeline>
  );
};
