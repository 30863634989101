import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useAuth, useDeviceInfo, usePushNotifications, useTracking, useTranslation } from ".";

/**
 * This hook is a collection of effects that should run whenever the app mounts (regardless of the underlying platform).
 */
export const useAppEffects = (): void => {
  const { i18n } = useTranslation();
  const auth = useAuth();
  const deviceInfo = useDeviceInfo();
  const push = usePushNotifications();

  useTracking();

  useEffect(() => {
    if (!push) return;
    push.init();
  }, [push]);

  /**
   * ----------
   * Preferred locale
   * ----------
   * NOTE: When user has been loaded AND that user has a preferred locale, ensure app language is set accordingly.
   */
  const hasUser = Boolean(auth.user);
  useEffect(() => {
    const userPreferredLocale = auth.user?.preferred_locale;
    if (hasUser && userPreferredLocale) {
      i18n.changeLocale(userPreferredLocale);
    }
  }, [hasUser]);

  /**
   * SET DEVICE AS ATTRIBUTE ON BODY
   * Reason to implement was to target platform specific css.
   */
  useEffect(() => {
    if (deviceInfo?.platform) {
      document.body.setAttribute("data-platform", deviceInfo.platform);
    }
  }, [deviceInfo]);

  /**
   * ----------
   * SENTRY SCOPE CONFIG
   * ----------
   */
  useEffect(() => {
    Sentry.configureScope((scope) => {
      if (auth.user) {
        scope.setUser({ email: auth.user.email });
      }

      if (deviceInfo) {
        const { platform } = deviceInfo;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        scope.setContext("device_info", deviceInfo as any);
        scope.setTag("platform", platform);
      }
    });
  }, [auth.user, deviceInfo]);
};
