import { Account, TransportJob, TransportJobAccountLink } from "@brenger/api-client";
import { Button, H2, IconCheck, InputDate, Label, Message, Select, Spacer, Textarea } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import addHours from "date-fns/addHours";
import addYears from "date-fns/addYears";
import formatISO from "date-fns/formatISO";
import parseDate from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import subDays from "date-fns/subDays";
import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { ClaimJobAgreement, ClaimJobMessagePreview, DriverSelect, Options } from "../../../components";
import {
  useAuth,
  useConfirmModal,
  useCreateLinkError,
  useForm,
  useFormatDate,
  useTranslation,
  useUserGeneratedContent,
} from "../../../hooks";
import { coreClient, getDateTimeInUTC, intervalsForDateTimePeriod, Routes, NewJobParams } from "../../../utils";
import { useParams } from "react-router-dom";
import { Content, PageHead, Section } from "../../../layout";

interface Props {
  tj: TransportJob;
  onSuccessFullyClaimed(tjal: TransportJobAccountLink): void;
}

const now = new Date();
const MIN_PICKUP_DATE = addHours(now, 2);
export const ClaimJobWithoutAvailableDates: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const formatHourMinute = useFormatDate("hour-minute");
  const formatDateForApi = useFormatDate("api-date");
  const params = useParams<NewJobParams>();
  const shortId = params.job_id.slice(-6);

  const [pickup] = props.tj.pickups;
  const [delivery] = props.tj.deliveries;

  // Only ONE item_set for these types of jobs, therefore it is safe to pluck out first one.
  const [assemblyDescriptionRaw] = props.tj.item_sets.map((is) => is.assembly_description);
  const assemblyDescription = useUserGeneratedContent(assemblyDescriptionRaw);

  const form = useForm({
    initialState: {
      driverUser: "",
      // Set default pickup date to TWO hours from current time.
      pickupDate: formatISO(MIN_PICKUP_DATE),
      pickupStart: "",
      pickupEnd: "",
      deliveryDate: formatISO(MIN_PICKUP_DATE),
      deliveryStart: "",
      deliveryEnd: "",
      driverMessage: "",
    },
    validators: {
      driverUser: (val) => !val,
      pickupDate: (val) => !val,
      pickupStart: (val) => !val,
      pickupEnd: (val) => !val,
      deliveryDate: (val) => !val,
      deliveryStart: (val) => !val,
      deliveryEnd: (val) => {
        // @TODO / NOTE: can also handle logic for incorrect dates here.
        return !val;
      },
    },
  });

  React.useEffect(() => {
    // Pre-select the current user as the assumed driverUser who will be assigned to this job.
    auth.user && form.set({ driverUser: auth.user["@id"] });
  }, [auth.user]);

  // Prepare the mutation
  const createLink = useMutation(coreClient.transportJobAccountLinks.create, {
    onSuccess: props.onSuccessFullyClaimed,
  });

  const createLinkError = useCreateLinkError(createLink.error as Error);

  const [ConfirmModal, activateConfirmModal] = useConfirmModal(() => {
    // On success callback when user agrees to confirm modal.
    createLink.mutate({
      driver_user: form.data.driverUser.value,
      driver_account: (auth.user?.account as Account | undefined)?.["@id"] || "",
      transport_job: props.tj["@id"],
      driver_message_body: form.data.driverMessage.value,
      pickup_commitments: [
        {
          pickup: pickup["@id"],
          committed_datetime_period: {
            start: getDateTimeInUTC(form.data.pickupStart.value),
            end: getDateTimeInUTC(form.data.pickupEnd.value),
          },
        },
      ],
      delivery_commitments: [
        {
          delivery: delivery["@id"],
          committed_datetime_period: {
            start: getDateTimeInUTC(form.data.deliveryStart.value),
            end: getDateTimeInUTC(form.data.deliveryEnd.value),
          },
        },
      ],
    });
  });

  // Create a JS Date object from the serialized form pickup date value
  const pickupDate = parseISO(form.data.pickupDate.value);
  // Create a set of 30 minute intervals between 9 - 18
  // Slice off the last 2 hours to leave room for a reasonable delivery window.
  const pickupStartIntervals = intervalsForDateTimePeriod(
    formatISO(new Date(pickupDate.getFullYear(), pickupDate.getMonth(), pickupDate.getDate(), 9, 0)),
    formatISO(new Date(pickupDate.getFullYear(), pickupDate.getMonth(), pickupDate.getDate(), 18, 0)),
    30
  )?.slice(0, -3);
  // Clamp the start of the pickup end intervals to the pickup start time.
  const pickupEndIntervals = intervalsForDateTimePeriod(
    form.data.pickupStart.value,
    formatISO(new Date(pickupDate.getFullYear(), pickupDate.getMonth(), pickupDate.getDate(), 18, 0)),
    30
  )?.slice(3, 8);

  // Create a JS Date object from the serialized form delivery date value
  // Same idea as above.
  const deliveryDate = parseISO(form.data.deliveryDate.value);
  const deliveryStartIntervals = intervalsForDateTimePeriod(
    formatISO(new Date(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate(), 9, 0)),
    formatISO(new Date(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate(), 18, 0)),
    30
  )?.slice(0, -3);
  // Clamp the start of the delivery end intervals to the delivery start time.
  const deliveryEndIntervals = intervalsForDateTimePeriod(
    form.data.deliveryStart.value,
    formatISO(new Date(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate(), 18, 0)),
    30
  )?.slice(3, 8);

  return (
    <>
      <Content
        header={<PageHead sectionType="split-details" backUrl={Routes.new.job(params)} title={`Claim: ${shortId}`} />}
        footer={
          <Section type="split-details">
            <Button
              className={cn("w-full")}
              icon={<IconCheck />}
              buttonType="secondary"
              disabled={form.hasErrors}
              onClick={() => {
                createLink.reset();
                activateConfirmModal({
                  title: t((d) => d.search.messages.confirm_claim_modal_title),
                  message: t((d) => d.search.messages.confirm_claim_regular_job),
                });
              }}
            >
              {t((d) => d.transport_job.actions.make_offer)}
            </Button>
          </Section>
        }
      >
        <Section type="split-details">
          {assemblyDescription.text && (
            <Message className={cn("mb-4")} type="info">
              {t((d, withTemplate) => {
                return withTemplate(d.transport_job.assembly_message, {
                  assembly_description: assemblyDescription.text,
                });
              })}
            </Message>
          )}
          <div>{t((d) => d.search.messages.claim_job_instructions)}</div>
          <Spacer h={2} />
          {/* PICKUP HEADER */}
          <H2>{t((d) => d.transport_job.pickup)}</H2>
          <Spacer h={2} />
          <Label text="Date">
            <InputDate
              value={formatDateForApi(new Date(form.data.pickupDate.value).toISOString())}
              min={formatDateForApi(subDays(now, 1).toISOString())}
              max={formatDateForApi(addYears(now, 1).toISOString())}
              onChange={(nextPickupDate) => {
                const nativeNextPickupDate = parseDate(nextPickupDate, "yyyy-MM-dd", new Date());
                // Clear state for delivery start and end
                form.set({
                  pickupDate: formatISO(nativeNextPickupDate),
                  deliveryDate: formatISO(nativeNextPickupDate),
                  pickupStart: "",
                  pickupEnd: "",
                  deliveryStart: "",
                  deliveryEnd: "",
                });
              }}
            />
          </Label>
          <Spacer h={4} />
          <div className={cn("grid", "gap-4")}>
            <div>
              {/* FROM */}
              <Label text={t((d) => d.search.labels.from)}>
                <Select
                  className={cn("w-full")}
                  value={form.data.pickupStart.value}
                  onChange={(pickupStart) => {
                    // Reset all start and end times when the first start time is updated.
                    form.set({ pickupStart, pickupEnd: "", deliveryStart: "", deliveryEnd: "" });
                  }}
                >
                  <Options placeholderText="--">
                    {pickupStartIntervals?.map((dateTimePeriod, idx) => {
                      return (
                        <option key={`${dateTimePeriod.start}-${idx}`} value={dateTimePeriod.start}>
                          {formatHourMinute(dateTimePeriod.start)}
                        </option>
                      );
                    })}
                  </Options>
                </Select>
              </Label>
            </div>
            <div>
              {/* UNTIL */}
              <Label text={t((d) => d.search.labels.until)}>
                <Select
                  className={cn("w-full")}
                  disabled={!form.data.pickupStart.value}
                  value={form.data.pickupEnd.value}
                  onChange={(pickupEnd) => form.set({ pickupEnd })}
                >
                  <Options placeholderText="--">
                    {pickupEndIntervals?.map((dateTimePeriod, idx) => {
                      return (
                        <option key={`${dateTimePeriod.end}-${idx}`} value={dateTimePeriod.end}>
                          {formatHourMinute(dateTimePeriod.end)}
                        </option>
                      );
                    })}
                  </Options>
                </Select>
              </Label>
            </div>
          </div>
          <Spacer h={4} />
          {/* DELIVERY HEADER */}
          <H2>{t((d) => d.transport_job.delivery)}</H2>
          <Spacer h={2} />
          <Label text="Date">
            <InputDate
              value={formatDateForApi(
                new Date(form.data.deliveryDate.value || form.data.pickupDate.value).toISOString()
              )}
              min={formatDateForApi(new Date(form.data.pickupDate.value).toISOString())}
              max={formatDateForApi(addYears(now, 1).toISOString())}
              onChange={(nextDeliveryDate) => {
                const nativeNextDeliveryDate = parseDate(nextDeliveryDate, "yyyy-MM-dd", new Date());
                // Clear state for delivery start and end
                form.set({
                  deliveryDate: formatISO(nativeNextDeliveryDate),
                });
              }}
            />
          </Label>
          <Spacer h={4} />
          <div className={cn("grid", "gap-4")}>
            <div>
              {/* FROM */}
              <Label text={t((d) => d.search.labels.from)}>
                <Select
                  className={cn("w-full")}
                  value={form.data.deliveryStart.value}
                  onChange={(deliveryStart) => form.set({ deliveryStart, deliveryEnd: "" })}
                >
                  <Options placeholderText="--">
                    {deliveryStartIntervals?.map((dateTimePeriod, idx) => {
                      return (
                        <option key={`${dateTimePeriod.start}-${idx}`} value={dateTimePeriod.start}>
                          {formatHourMinute(dateTimePeriod.start)}
                        </option>
                      );
                    })}
                  </Options>
                </Select>
              </Label>
            </div>
            <div>
              {/* UNTIL */}
              <Label text={t((d) => d.search.labels.until)}>
                <Select
                  className={cn("w-full")}
                  disabled={!form.data.deliveryStart.value}
                  value={form.data.deliveryEnd.value}
                  onChange={(deliveryEnd) => form.set({ deliveryEnd })}
                >
                  <Options placeholderText="--">
                    {deliveryEndIntervals?.map((dateTimePeriod, idx) => {
                      return (
                        <option key={`${dateTimePeriod.end}-${idx}`} value={dateTimePeriod.end}>
                          {formatHourMinute(dateTimePeriod.end)}
                        </option>
                      );
                    })}
                  </Options>
                </Select>
              </Label>
            </div>
          </div>
          <DriverSelect
            className={cn("mt-6")}
            labelText={t((d) => d.search.labels.select_driver)}
            accountId={getIdFromIri(auth.user?.account)}
            value={form.data.driverUser.value}
            onChange={({ userIRI }) => form.set({ driverUser: userIRI })}
          />
          <Spacer h={6} />
          <Label text={t((d) => d.search.labels.message)}>
            <Textarea
              className={cn("w-full")}
              onChange={(driverMessage) => form.set({ driverMessage })}
              value={form.data.driverMessage.value}
            />
          </Label>
          {/* WHEN FORM IS COMPLETE, SHOW MESSAGE PREVIEW */}
          {!form.hasErrors && (
            <Label text={t((d) => d.search.labels.message_preview)} className={cn("mt-4")}>
              <ClaimJobMessagePreview
                // @TODO replace "klant" - but figure out where to get customer name from?
                customerName="klant"
                pickupDate={form.data.pickupDate.value}
                pickupStart={form.data.pickupStart.value}
                pickupEnd={form.data.pickupEnd.value}
                deliveryDate={form.data.deliveryDate.value}
                deliveryStart={form.data.deliveryStart.value}
                deliveryEnd={form.data.deliveryEnd.value}
                driverMessage={form.data.driverMessage.value}
                driverName={(auth.user?.account as Account)?.name}
              />
            </Label>
          )}
          {form.data.deliveryEnd.value && form.data.deliveryEnd.error && (
            <Message type="error" className={cn("mt-4")}>
              {form.data.deliveryEnd.error}
            </Message>
          )}
          {createLinkError && (
            <Message type="error" className={cn("mt-4")}>
              {createLinkError}
            </Message>
          )}
        </Section>
      </Content>
      <ClaimJobAgreement />
      <ConfirmModal />
    </>
  );
};
