import * as React from "react";
import cn from "classnames";
import { StaticGoogleMap, Marker, Path } from "react-static-google-map";
import { Config } from "../../config";
import { StopMapProps } from "..";
import { useEffect, useRef } from "react";
import { useModalState, IconCancel, IconGoogleMaps } from "@brenger/react";

const styleParts = [
  "feature:administrative",
  "element:labels.text.fill",
  "color:0x444444&style=feature:landscape",
  "color:0xf2f2f2&style=feature:poi",
  "visibility:off&style=feature:road",
  "saturation:-100",
  "lightness:45&style=feature:road.arterial",
  "element:labels.icon",
  "visibility:off&style=feature:road.highway",
  "visibility:simplified&style=feature:transit",
  "visibility:off&style=feature:water",
  "color:0xaad2e3",
  "visibility:on",
];

const containerBaseClassnames = ["relative", "z-0", "bg-gray-200", "shadow"];
const subContainerBaseClassnames = ["absolute", "inset-0"];
const brengerBlue = "0x0073FA";
enum MapDims {
  /**
   * Use this one for small viewports.
   */
  SM = "640x300",
  /**
   * Use this one for large viewports.
   */
  LG = "1280x200",
}

export const StaticMapToggle: React.FC<StopMapProps> = (props) => {
  const toggleMap = useModalState();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event): void => {
      // Clicking outside the ref should close the toggeld map
      if (toggleMap.isActive && ref.current && !ref.current.contains(e.target as Node)) {
        toggleMap.close();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [toggleMap]);

  const style = encodeURI(styleParts.join("|"));

  const points = props.points ? <Path weight={4} color={brengerBlue} points={props.points} /> : null;

  const markers = props.markers
    // Filter out malformed markers, apparently sometimes these are returned
    .filter(({ location }) => location && typeof location !== "string" && "lat" in location)
    .map(({ location, stopNumber, markerColor }, idx) => {
      return (
        <Marker
          key={idx}
          location={location}
          /**
           * Google maps: up to five unique custom icons per request. So we are not using the iconUrl
           * We are forcing the the type string to never because the package doesnt give as the correct type
           * Google maps color can be a 24-bit color (example: color=0xFFFFCC) or a predefined color from the set {black, brown, green, purple, yellow, blue, gray, orange, red, white}
           *
           *
           */
          color={(markerColor === "green" ? "0x00cc8f" : "0x0073fa") as never}
          label={stopNumber}
          anchor="center"
        ></Marker>
      );
    });

  return (
    <div
      ref={ref}
      className={cn(
        // Open map in animation movement
        "duration-300",
        "overflow-hidden",

        {
          "max-h-max": toggleMap.isActive,
          "max-h-[100px]": !toggleMap.isActive,
          "md:max-h-[200px]": !toggleMap.isActive,
        }
      )}
      onClick={() => (toggleMap.isActive ? toggleMap.close() : toggleMap.open())}
    >
      <div>
        <div className={cn("md:container", "relative")}>
          {/*
        We add a container with padding that matches the default ratio of the size map it contains.
        This is used in order to prevent jarring layout changes as map image loads in.
        We basically create a placeholder this way.
      */}
          {props.googleMapsUrl && (
            <a
              target={"_blank"}
              rel="noreferrer"
              href={props.googleMapsUrl || ""}
              className={cn(
                "absolute",
                "z-20",
                "m-4",
                "md:m-4",
                "md:mt-1",
                "md:mx-5",
                "right-0",
                "no-print",
                "cursor-pointer",
                {
                  // NOTE: this CSS effectively disables the external link in cases where an empty string is provided for the google maps url.
                  "pointer-events-none": !props.googleMapsUrl,
                }
              )}
            >
              <IconGoogleMaps className={cn("w-8", "h-8")} />
            </a>
          )}

          {toggleMap.isActive && (
            <div className={cn("absolute", "z-20", "mx-4", "mt-5", "md:mx-6", "md:mt-1", "left-0", "no-print")}>
              <button className={cn("w-7", "h-7", "bg-white", "rounded-md")} onClick={toggleMap.close}>
                <IconCancel className={cn("ml-1", "w-5", "h-5", "text-blue-600")} />
              </button>
            </div>
          )}
          <a
            target={"_blank"}
            rel="noreferrer"
            href={props.googleMapsUrl || ""}
            className={cn("no-print", "cursor-pointer", "w-max", {
              // NOTE: this CSS effectively disables the external link in cases where an empty string is provided for the google maps url.
              "pointer-events-none": !props.googleMapsUrl || !toggleMap.isActive,
            })}
          >
            <div className={cn(containerBaseClassnames, "block", "md:hidden")} style={{ paddingBottom: "46.875%" }}>
              {/* <div className={cn("right-2", "z-20", "absolute")} onClick={props.openMap}> */}

              <div className={cn(subContainerBaseClassnames)}>
                {markers.length > 0 && (
                  <StaticGoogleMap
                    // @TODO remove ts-expect-error annotation below when underlying package has added correct types.
                    // Follow this pull request: https://github.com/bondz/react-static-google-map/pull/8
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    rootURL={Config.STATIC_MAPS_ROOT_URL}
                    size={MapDims.SM}
                    scale="2"
                    zoom={props.zoom}
                    apiKey={Config.STATIC_MAPS_KEY}
                    style={style}
                  >
                    {markers}
                    {points}
                  </StaticGoogleMap>
                )}
              </div>
            </div>
            <div className={cn(containerBaseClassnames, "hidden", "md:block")} style={{ paddingBottom: "31.25%" }}>
              <div className={cn(subContainerBaseClassnames)}>
                {markers.length > 0 && (
                  <StaticGoogleMap
                    // @TODO remove ts-expect-error annotation below when underlying package has added correct types.
                    // Follow this pull request: https://github.com/bondz/react-static-google-map/pull/87
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    rootURL={Config.STATIC_MAPS_ROOT_URL}
                    size={MapDims.LG}
                    scale="2"
                    zoom={props.zoom}
                    apiKey={Config.STATIC_MAPS_KEY}
                    style={style}
                  >
                    {markers}
                    {points}
                  </StaticGoogleMap>
                )}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
