import { DayRouteActivity, DayRouteActivityType, UpdateDayRoute } from "@brenger/api-client";
import { Button, Card, IconLoader } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import transpose from "flatmap-fns/transpose";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useHistory, useParams } from "react-router-dom";
import { StaticMapToggle, TextPlaceholder } from "../../../components";
import { useFormatDate, useMapContext, useTranslation, useViewport } from "../../../hooks";

import { CacheKey, coreClient, DayRouteParams, Routes } from "../../../utils";
import { Content, Section } from "../../../layout";
import { addIndex, formatActivities, getTravelMeta, validateActivity } from "../utils";
import { DayRouteEditActions } from "./DayRouteEditActions";
import { DayRouteEndLocation } from "./DayRouteEndLocation";
import { DayRouteFooter } from "./DayRouteFooter";
import { DayRouteSelection } from "./DayRouteSelection";
import { DayRouteStartLocation } from "./DayRouteStartLocation";
import { DayRouteStop } from "./DayRouteStop";
import { DayRouteStopBreak } from "./DayRouteStopBreak";
import { DayRouteStopTravelTime } from "./DayRouteStopTravelTime";

const DELETABLE_ACTIVITY_TYPES = ["custom_other", "custom_pickup", "custom_delivery"] as DayRouteActivityType[];

export const DayRouteDetails: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<DayRouteParams>();
  const history = useHistory();
  const formatDateForApi = useFormatDate("api-date");
  const { isSplitview } = useViewport();
  const dayRoute = useQuery([CacheKey.RETRIEVE_DAY_ROUTE, params.user_id, params.date], () =>
    coreClient.dayRoutes.retrieveByUserAndDate({ userId: params.user_id, date: params.date })
  );

  const dayRouteData = dayRoute.data;
  const dayRouteId = dayRouteData?.["@id"];
  const dayRouteActivities = dayRouteData?.day_route_activities || [];
  const drRouteETA = useQuery(
    [CacheKey.RETRIEVE_DAY_ROUTE_LEGS, getIdFromIri(dayRouteId)],
    () => coreClient.dayRoutes.retrieveLegs({ routeId: getIdFromIri(dayRouteId) || "" }),
    {
      enabled: dayRouteActivities.length > 0,
    }
  );

  const mapContext = useMapContext({
    presentation: "planning",
    dayRoute: dayRouteData,
    drRoute: drRouteETA.data?.route,
  });

  const [activities, setActivities] = useState<DayRouteActivity[]>([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // When day route activities are loaded, add them to local state so we can re-order them later if need be.
  useEffect(() => {
    // NOTE: listen to dayRoute.loading in case we refresh the day route and therefore need to reset
    // the day route activities in local state based on the updated day route.
    if (!dayRoute.isLoading && dayRouteActivities) {
      setActivities(dayRouteActivities);
    }
    // Add the dayRouteId as a dependency ref - therefore, when day route changes, we update/set the activites in local state.
  }, [dayRouteId, dayRoute.isLoading]);

  const reorder = (currentIndex: number, targetIndex: number): void => {
    const updatedActivities = activities
      .flatMap(transpose(currentIndex, targetIndex))
      .flatMap(formatActivities())
      .map(addIndex());
    setActivities(updatedActivities as DayRouteActivity[]);
    setHasChanged(true);
  };

  // Callback after successfully updating the day route
  const onUpdateSuccess = (): void => {
    // Toggle states
    setHasChanged(false);
    setIsEditing(false);
    // Just to be sure that start driving is updated
    dayRoute.refetch();
  };

  const updateDayRoute = useMutation(coreClient.dayRoutes.update, { onSuccess: onUpdateSuccess });

  // Generic method to update day route
  const saveDayRoute = (dayroute: UpdateDayRoute["dayRoute"]): void => {
    updateDayRoute.mutate({
      routeId: getIdFromIri(dayRoute.data) || "",
      dayRoute: dayroute,
    });
  };

  /** Specific dayroute actions that can be passed down */
  const startDriving = (): void => {
    saveDayRoute({ started_driving_at: new Date().toISOString() });
  };
  const saveDayrouteOrder = (): void => {
    if (!hasChanged) {
      setIsEditing(false);
      return;
    }
    saveDayRoute({ day_route_activities: activities });
  };

  const cancelEditing = (): void => {
    setIsEditing(false);
    setActivities(dayRouteActivities);
  };

  const goToDayRouteAdd = (): void => {
    history.push(Routes.dayroutes.add(params));
  };

  const hasActivities = activities.length > 0;

  const legs = drRouteETA.data?.legs || [];
  const legsLength = legs.length;
  const endLeg = dayRoute.data?.end_address && legs[legsLength - 1];
  const endAddressTravelMeta = getTravelMeta({
    eta: endLeg?.eta || null,
    etd: endLeg?.etd || null,
    driving_duration_seconds: endLeg?.duration || null,
    driving_distance_meters: endLeg?.distance || null,
  });

  /**
   * Show start driving
   */
  const startedDriving = !!dayRoute.data?.started_driving_at;
  const today = new Date().toISOString();
  const dateToday = formatDateForApi(today);
  const isDayRouteForToday = dateToday === params.date;
  const showStartButton = isDayRouteForToday && Boolean(activities.length) && !startedDriving;

  /**
   * FIXME: When settings are adjusted pull in new ETAs
   */
  return (
    <Content
      header={
        <Section type="split-list">
          <DayRouteSelection {...params} />
        </Section>
      }
      headerClassname={cn({ "shadow-md relative z-10": !isSplitview })}
      isLoading={dayRoute.isLoading}
      message={(dayRoute.error as Error)?.message}
      messageWrap="split-list"
      footer={
        <DayRouteFooter
          isEditing={isEditing}
          isLoading={dayRoute.isFetching || updateDayRoute.isLoading}
          showStartButton={showStartButton}
          cancelEditing={cancelEditing}
          startDriving={startDriving}
          saveOrder={saveDayrouteOrder}
        />
      }
    >
      <StaticMapToggle {...mapContext} />
      <Section type="split-list">
        <div className="flex gap-4 justify-between py-4">
          <div>
            <h4>{activities.filter((a) => a.type !== "break").length} Stops</h4>
          </div>
          <DayRouteEditActions
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            cancelEditing={cancelEditing}
            hasActivities={hasActivities}
          />
        </div>

        {/* // NOTE: only show loader when there are no activities in local state yet - otherwise, all subsequent refreshes to
            // the already-loaded day route will show loader and led to janky-feeling UX. */}
        {!dayRoute.data ? (
          <>
            {/* NOTE: add a faux card with some placeholder text while day routes load for a more graceful UX */}
            <Card type="gray">
              <div className={cn("flex", "flex-col")}>
                <TextPlaceholder />
                <TextPlaceholder />
              </div>
            </Card>
            <div className={cn("mt-8", "w-full")}>{<IconLoader className={cn("w-6", "h-6", "mx-auto")} />}</div>
          </>
        ) : (
          <>
            <DayRouteStartLocation dayRoute={dayRoute} />
            {hasActivities &&
              activities.map((activity, index) => {
                const canDelete = DELETABLE_ACTIVITY_TYPES.includes(activity.type);
                const moveUp = index === 0 ? undefined : () => reorder(index, index - 1);
                const moveDown = activities.length - 1 === index ? undefined : () => reorder(index, index + 1);

                if (activity.type === "break") {
                  return (
                    <DayRouteStopBreak
                      key={index}
                      isEditing={isEditing}
                      moveUp={moveUp}
                      moveDown={moveDown}
                      breakSeconds={activity.service_time_seconds || 0}
                    />
                  );
                }

                const meta = legs.find((leg) => leg.index === activity.index);
                const travelMeta = getTravelMeta({
                  eta: meta?.eta || null,
                  etd: meta?.etd || null,
                  driving_duration_seconds: meta?.duration || null,
                  driving_distance_meters: meta?.distance || null,
                });
                const validation = validateActivity({ ...activity, eta: meta?.eta || null }, activities);

                // NOTE: this component is the same in all cases, so create once and interpolate accordingly.
                const dayRouteStopTravelTime = (
                  <DayRouteStopTravelTime
                    hours={travelMeta.travelTime?.hours}
                    minutes={travelMeta.travelTime?.minutes}
                    distance={travelMeta.travelDistanceInKm || null}
                    isEditing={isEditing}
                  />
                );

                if (canDelete) {
                  return (
                    <div key={index}>
                      {dayRouteStopTravelTime}
                      <DayRouteStop
                        startedDriving={startedDriving}
                        key={index}
                        activity={activity}
                        isEditing={isEditing}
                        moveUp={moveUp}
                        moveDown={moveDown}
                        travelMeta={travelMeta}
                        validationError={validation}
                        customStop={true}
                      />
                    </div>
                  );
                }

                return (
                  <div key={index}>
                    {dayRouteStopTravelTime}
                    <DayRouteStop
                      startedDriving={startedDriving}
                      key={index}
                      activity={activity}
                      isEditing={isEditing}
                      moveUp={moveUp}
                      moveDown={moveDown}
                      travelMeta={travelMeta}
                      validationError={validation}
                    />
                  </div>
                );
              })}
            <DayRouteStopTravelTime
              hours={endAddressTravelMeta.travelTime?.hours}
              minutes={endAddressTravelMeta.travelTime?.minutes}
              distance={endAddressTravelMeta.travelDistanceInKm}
              isEditing={isEditing}
            />
            <DayRouteEndLocation dayRoute={dayRoute} />
            {!hasActivities && (
              // NOTE: show empty state with helpful CTAs when there are no activities yet for the given day route.
              <Card className={cn("mt-8 !bg-color-light-blue")}>
                <div>{t((d) => d.day_route.empty_state)}</div>
                <div className={cn("grid", "grid-cols-2", "gap-2", "mt-3")}>
                  <Button buttonType="primary-outline" onClick={goToDayRouteAdd}>
                    {t((d) => d.day_route.details.own_stop)}
                  </Button>
                  <Button size="sm" onClick={() => history.push(Routes.new.home())}>
                    Brenger stop
                  </Button>
                </div>
              </Card>
            )}
          </>
        )}
      </Section>
    </Content>
  );
};
