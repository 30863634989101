import * as React from "react";
import { ConversationItem } from "@brenger/api-client";

export const useInboxFilter = (conversations?: ConversationItem[]): ConversationItem[] => {
  const length = conversations?.length;
  return React.useMemo(() => {
    let filteredConversations = conversations || [];

    /**
     * Don't show cancelled jobs.
     */
    filteredConversations = filteredConversations.filter(
      (conversation) => conversation.transport_job_account_link.state !== "transport_job_cancelled"
    );

    /**
     * Sort by updated_at field - show most recently updated messages at the top of the list.
     */
    filteredConversations = filteredConversations.sort((a, b) => {
      return new Date(b.updated_at as string).getTime() - new Date(a.updated_at as string).getTime();
    });

    // @TODO && @NOTE: we are slicing this list at 100 because we do not have proper pagination yet on this.
    return filteredConversations.slice(0, 100);

    // We only care if the conversation list changes lengths. Therefore, only add that logic to dependency array below.
  }, [length]);
};
