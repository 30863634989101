import { Item, ItemSet } from "@brenger/api-client";
import cn from "classnames";
import * as React from "react";

interface Props {
  truncate?: boolean;
  itemSets: Array<ItemSet | undefined>;
}

/**
 *  A summary of all the items in the collection of item sets.
 */
export const ItemDetails: React.FC<Props> = ({ itemSets, truncate = true }) => {
  const items = itemSets
    // Remove undefined
    .flatMap((d) => (d === undefined ? [] : d))
    // Create a list of the items only and correctly typecast
    .flatMap((itemSet) => {
      return itemSet.items as Item[];
    });

  return (
    <div className={cn("flex", "flex-col")}>
      {items.map((item, idx) => {
        const parts = [];
        parts.push(`(${item.count}x)`);
        parts.push(`${item.length_inflated} × ${item.width_inflated} × ${item.height_inflated} cm`);
        parts.push("—");
        parts.push(item.title);

        const itemDetailsString = parts.filter(Boolean).join(" ");

        return (
          <div key={idx} className={cn({ truncate, "break-words": !truncate })}>
            {itemDetailsString}
          </div>
        );
      })}
    </div>
  );
};
