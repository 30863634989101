import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Routes } from "../../../utils";
import { TransportsFiltersPlanned } from "./TransportsFiltersPlanned";
import { TransportsFiltersDelivered } from "./TransportsFiltersDelivered";

export interface TransportsFiltersProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

export const TransportsFilters: React.FC<TransportsFiltersProps> = (props) => {
  return (
    <Switch>
      <Route
        exact={false}
        path={Routes.transports.home({ type: "planning" })}
        render={() => <TransportsFiltersPlanned {...props} />}
      />
      <Route
        exact={false}
        path={Routes.transports.home({ type: "delivered" })}
        render={() => <TransportsFiltersDelivered {...props} />}
      />
    </Switch>
  );
};
