import cn from "classnames";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { useViewport } from "../hooks";

interface Props {
  list: {
    path: string;
    component: React.ComponentType;
  };
  details: {
    path: string;
    component: React.ComponentType;
  }[];
}
export const getIsSplitView = (): boolean => window.innerWidth >= 768;

export const SplitView: React.FC<Props> = ({ list, details }) => {
  const { isSplitview } = useViewport();
  if (isSplitview) {
    return (
      <>
        <div
          className={cn(
            "absolute",
            "inset-0",
            "right-auto",
            "w-80",
            "border-r",
            "border-gray-300",
            "xl:w-[25rem]",
            "2xl:w-[33rem]"
          )}
        >
          <Route exact={false} path={list.path} component={list.component} />
        </div>
        <div
          className={cn(
            "absolute",
            "inset-0",
            "left-auto",
            "overflow-auto",
            "w-[calc(100%-20rem)]",
            "xl:w-[calc(100%-25rem)]",
            "2xl:w-[calc(100%-33rem)]",
            "lg:border-l",
            "lg:border-white/25"
          )}
        >
          <Switch>
            {details.map((item, i) => {
              return <Route key={i} exact={true} path={item.path} component={item.component} />;
            })}
          </Switch>
        </div>
      </>
    );
  }
  return (
    <Switch>
      <Route exact={true} path={list.path} component={list.component} />
      {details.map((item, i) => {
        return <Route key={i} exact={true} path={item.path} component={item.component} />;
      })}
    </Switch>
  );
};
