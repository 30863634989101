import * as React from "react";
import cn from "classnames";
import { Pill } from "@brenger/react";
import { StopDetails, ItemSet } from "@brenger/api-client";

import { ResponsiveContainer } from "..";
import { useTranslation } from "../../hooks";
import { hasProductSelectionService } from "../../utils";

interface Props {
  details: StopDetails;
  itemSets?: ItemSet[];
}

export const StopDetailPills: React.FC<Props> = ({ details, itemSets }) => {
  const { t } = useTranslation();
  const services = itemSets?.flatMap((itemSet) => itemSet.services);

  return (
    <ResponsiveContainer className={cn("-mx-1", "mt-2")}>
      {(details.situation === "home" || details.situation === "book_a_van") && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.situation.home)}</Pill>
      )}
      {details.situation === "auction" && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.situation.auction)}</Pill>
      )}
      {details.situation === "store" && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.situation.store)}</Pill>
      )}
      {details.floor_level === 0 ? (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.ground_floor)}</Pill>
      ) : (
        <Pill type="gray-outline">
          {t((d, withTemplate) =>
            withTemplate(d.transport_job.stop_details.floor_level, { floor_level: details.floor_level })
          )}
        </Pill>
      )}
      {details.elevator && <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.elevator)}</Pill>}
      {details.carrying_help === "needed" && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.carrying_help.needed)}</Pill>
      )}
      {details.carrying_help === "extra_driver" && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.carrying_help.extra_driver)}</Pill>
      )}
      {details.carrying_help === "equipment_tailgate" && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.carrying_help.equipment_tailgate)}</Pill>
      )}
      {details.carrying_help === "equipment_tailgate_pallet_jack" && (
        <Pill type="gray-outline">
          {t((d) => d.transport_job.stop_details.carrying_help.equipment_tailgate_pallet_jack)}
        </Pill>
      )}
      {details.carrying_help === "equipment_provided_by_customer" && (
        <Pill type="gray-outline">
          {t((d) => d.transport_job.stop_details.carrying_help.equipment_provided_by_customer)}
        </Pill>
      )}
      {details.carrying_help === "equipment_tailgate_extra_driver" && (
        <Pill type="gray-outline">
          {t((d) => d.transport_job.stop_details.carrying_help.equipment_tailgate_extra_driver)}
        </Pill>
      )}
      {services?.includes("assembly") && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.services.assembly)}</Pill>
      )}
      {services?.includes("extra_insurance") && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.services.extra_insurance)}</Pill>
      )}
      {services?.includes("live_video") && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.services.live_video)}</Pill>
      )}
      {services?.includes("safety_shoes_required") && (
        <Pill type="gray-outline">
          {/* use different translation */}
          {t((d) => d.transport_job.stop_details.services.dress_code)}
        </Pill>
      )}
      {hasProductSelectionService(itemSets, "fragile") && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.services.fragile)}</Pill>
      )}
      {hasProductSelectionService(itemSets, "heavy") && (
        <Pill type="gray-outline">{t((d) => d.transport_job.stop_details.services.heavy)}</Pill>
      )}
    </ResponsiveContainer>
  );
};
